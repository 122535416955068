import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import env from '../../environment';
import { Category } from '../../store/interfaces/category';
import { clientCache } from '../graphql';

const GQL_GET_CATEGORY = gql`
    query ($id: Int!) {
        category(id: $id) {
            id
            name
        }
    }`;

export class CategoryRepository {
  public static forGlobal(): CategoryRepository {
    const url = env.urlFactory.global().service('data').resolve('graphql');
    const client = clientCache.getItem(url);
    if (!client) throw new Error('Unable to create GraphQL client');
    return new CategoryRepository(client);
  }

  constructor(
    private readonly client: ApolloClient<{}>,
  ) { }

  public async findById(id: number): Promise<Category> {
    const { data } = await this.client.query<{ category: Category }>({
      query: GQL_GET_CATEGORY,
      variables: { id },
    });
    return data.category || undefined;
  }
}
