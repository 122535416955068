import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import env from '../../environment';
import { SimpleDocumentStatus } from '../../store/interfaces/batch-learning';
import { clientCache } from '../graphql';

const GQL_GET_DOCUMENT_STATUS = gql`
  query ($documentId: String!) {
    document(id: $documentId) {
      status
    }
  }`;

const GQL_GET_LEGACY_ELS_URL = gql`
  query ($documentId: String!) {
    documentLegacyElsUrl(documentId: $documentId)
  }`;

export class DocumentRepository {
  public static forRegion(regionId: string): DocumentRepository {
    const url = env.urlFactory.regional(regionId).service('data').resolve('graphql');
    const client = clientCache.getItem(url);
    if (!client) throw new Error('Unable to create GraphQL client');
    return new DocumentRepository(client);
  }

  constructor(
    public readonly client: ApolloClient<{}>, // TODO private
  ) { }

  public async getDocumentStatus(documentId: string): Promise<string | undefined> {
    const { data } = await this.client.query<{ document: SimpleDocumentStatus | undefined }>({
      fetchPolicy: 'no-cache',
      query: GQL_GET_DOCUMENT_STATUS,
      variables: { documentId },
    });
    return data.document && data.document.status;
  }

  public async getLegacyElsUrl(documentId: string): Promise<string | undefined> {
    const { data } = await this.client.query<{ documentLegacyElsUrl: string | undefined }>({
      fetchPolicy: 'no-cache',
      query: GQL_GET_LEGACY_ELS_URL,
      variables: { documentId },
    });
    return data.documentLegacyElsUrl;
  }
}
