import { parseApolloError } from '../../helpers/graphql';
import store from '../../store';
import { CodedError } from '../../store/interfaces/error';
import { BatchModificationMutations } from '../../store/mutations/batch-modification.mutations';
import { BatchMutations } from '../../store/mutations/batch.mutations';
import { IndexOf } from '../../store/types';
import { UserRepository } from '../user/user.repository';
import { BatchModification, BatchWorkflow, Client, Document, InputFile, Page, Workflow } from './../../store/interfaces/batch-modification';
import { BatchWorkflowRepository } from './../batch-workflow/batch-workflow.repository';
import { InputFileRepository } from './../input-file/input-file.repository';
import { BatchRepository } from './batch.repository';

export namespace BatchDomain {
  export async function load(regionId: string | undefined, batchWorkflowId: number | undefined, batchId: string | undefined) {
    if (!regionId || !batchWorkflowId || !batchId) {
      if (store.getState().batch.value !== undefined) {
        store.dispatch(BatchMutations.current(null));
      }
      return;
    }

    store.dispatch(BatchMutations.currentLoading());
    try {
      const repository = BatchRepository.forRegion(regionId);
      const result = await repository.findById(batchWorkflowId, batchId);
      store.dispatch(BatchMutations.current(result));
    } catch (err) {
      const errors = parseApolloError(err);
      store.dispatch(BatchMutations.currentError(new CodedError(errors[0].message, 'error')));
    }
  }

  export async function loadModificationData(regionId: string | undefined, batchWorkflowId: number | undefined, batchId: string | undefined) {
    if (!regionId || !batchWorkflowId || !batchId) {
      if (store.getState().batch.value !== undefined) {
        store.dispatch(BatchModificationMutations.setValue(null));
      }
      return;
    }

    store.dispatch(BatchMutations.currentLoading());
    try {
      const repository = BatchRepository.forRegion(regionId);
      const result = await repository.findById(batchWorkflowId, batchId);

      if (result === null) {
        store.dispatch(BatchModificationMutations.setValue(null));
        return;
      }

      const documents = result.documents.reduce(
        (acc, document) => ({
          ...acc, [document.id]: {
            batchId: result.id,
            id: document.id,
            pageIds: document.pages.map((p) => p.id),
            regionId: document.regionId,
            status: document.status,
            workflowId: document.currentDocumentWorkflow.workflowId,
          },
        }),
        {} as IndexOf<Document>);

      const pages = result.documents.reduce(
        (acc, document) => {
          return document.pages.reduce(
            (acc1, page) => ({
              ...acc1,
              [page.id]: {
                documentId: document.id,
                fullImage: page.fullImage,
                id: page.id,
                inputFileId: page.inputFileId,
                ordinal: page.ordinal,
                thumbnail: page.thumbnail,
              },
            }),
            { ...acc });
        },
        {} as IndexOf<Page>);

      const batchWorkflowRepository = BatchWorkflowRepository.forGlobal();
      const workflowIds = Array.from(new Set(result.documents.map((d) => d.currentDocumentWorkflow.workflowId)));
      const batchWorkflowResult = await batchWorkflowRepository.findBatchWorkflowsAndWorkflows([result.batchWorkflowId], workflowIds);

      const batchWorkflows = batchWorkflowResult.batchWorkflows.reduce(
        (acc, batchWorkflow) => ({
          ...acc, [batchWorkflow.id]: {
            clientId: batchWorkflow.client.id,
            id: batchWorkflow.id,
            name: batchWorkflow.name,
          },
        }),
        {} as IndexOf<BatchWorkflow>);

      const workflows = batchWorkflowResult.workflows.reduce(
        (acc, workflow) => ({
          ...acc, [workflow.id]: {
            clientId: workflow.client.id,
            id: workflow.id,
            name: workflow.name,
          },
        }),
        {} as IndexOf<Workflow>);

      const clients = batchWorkflowResult.workflows.reduce(
        (acc, workflow) => ({
          ...acc, [workflow.client.id]: {
            id: workflow.client.id,
            name: workflow.client.name,
          },
        }),
        {} as IndexOf<Client>);

      if (result.currentLock && result.currentLock.lockedByUserId) {
        const userRepository = UserRepository.forGlobal();
        const lockedByUser = await userRepository.findById(result.currentLock.lockedByUserId);
        result.currentLock.lockedByUser = lockedByUser;
      }

      const data: BatchModification = {
        batch: {
          batchWorkflowId: result.batchWorkflowId,
          currentLock: result.currentLock,
          currentStatus: result.currentStatus,
          id: result.id,
          regionId: regionId,
        },
        batchWorkflows,
        clients,
        documentIds: result.documents.map((d) => d.id),
        documents,
        inputFiles: undefined,
        inputSessions: undefined,
        lightboxModal: undefined,
        orphanedPages: [],
        pages,
        undoStack: [],
        workflows,
      };
      store.dispatch(BatchModificationMutations.setValue(data));

      const inputFileRepository = InputFileRepository.forRegion(regionId);
      const inputFileIds = Array.from(new Set(Object.keys(pages).map((pageId) => pages[pageId].inputFileId)));
      const inputFileResult = await inputFileRepository.findByIds(inputFileIds);

      const inputFiles = inputFileResult.reduce(
        (acc, inputFile) => ({
          ...acc, [inputFile.id]: {
            dataSourceId: inputFile.dataSourceId,
            id: inputFile.id,
            inputSessionId: inputFile.inputSessionId,
            pageCount: inputFile.pages.length,
          },
        }),
        {} as IndexOf<InputFile>);

      const final = {
        ...data,
        inputFiles,
        inputSessions: {},
      };

      store.dispatch(BatchModificationMutations.setValue(final));
    } catch (err) {
      const errors = parseApolloError(err);
      errors.forEach((error) => store.dispatch(BatchModificationMutations.setError(new CodedError(error.message, 'error'))));
    }
  }
}
