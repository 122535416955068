import { createAction } from '..';
import { SnackbarMessage } from '../interfaces/app';
import { CodedError } from './../interfaces/error';

export namespace AppMutations {
  function errorToSnackbarMessage(error: Error, duration?: number): SnackbarMessage {
    return {
      autoHideDuration: duration ? duration : 5000,
      messageTranslationKey: (error as CodedError).code,
      rawMessage: error.message,
      variant: 'error',
    };
  }

  export const setErrorSnackbar = (error: Error, duration?: number) => createAction((state) => {
    return {
      ...state,
      snackbarMessage: errorToSnackbarMessage(error, duration),
    };
  });

  export const setSnackbarMessage = (snackbarMessage: SnackbarMessage | undefined) => createAction((state) => ({
    ...state,
    snackbarMessage: snackbarMessage,
  }));
}
