/* eslint jsx-a11y/mouse-events-have-key-events: 0 */
import React from 'react';

interface Props {
  /** The path to the image. It can be a url. */
  img: string;
  /** The zoom scale. */
  zoomScale: number;
  /** The height of the image in pixels */
  height: number;
  /** The width of the image in pixels */
  width: number;
  /** The time (in seconds) that will take to scale your image. */
  transitionTime?: number;
}

interface State {
  mouseX: number | null;
  mouseY: number | null;
  zoom: boolean;
}

function absolutePosition(el: HTMLElement) {
  let found;
  let left = 0;
  let top = 0;
  let width = 0;
  let height = 0;
  // tslint:disable-next-line:no-any
  let offsetBase = (document.body as any).offsetBase;

  if (!offsetBase && document.body) {
    // tslint:disable-next-line:no-any
    offsetBase = (document.body as any).offsetBase = document.createElement('div');
    offsetBase.style.cssText = 'position:absolute;left:0;top:0';
    document.body.appendChild(offsetBase);
  }

  if (el && el.ownerDocument === document && 'getBoundingClientRect' in el && offsetBase) {
    const boundingRect = el.getBoundingClientRect();
    const baseRect = offsetBase.getBoundingClientRect();
    found = true;
    left = boundingRect.left - baseRect.left;
    top = boundingRect.top - baseRect.top;
    width = boundingRect.right - boundingRect.left;
    height = boundingRect.bottom - boundingRect.top;
  }

  return {
    bottom: top + height,
    found: found,
    height: height,
    left: left,
    right: left + width,
    top: top,
    width: width,
  };
}

class Zoom extends React.Component<Props, State> {
  public state: State;

  private imageRef: React.RefObject<HTMLDivElement>;
  private innerDivStyle: React.CSSProperties;
  private outerDivStyle: React.CSSProperties;

  constructor(props: Props) {
    super(props);

    this.state = {
      mouseX: null,
      mouseY: null,
      zoom: false,
    };

    const {
      height,
      transitionTime,
      width,
    } = props;

    this.outerDivStyle = {
      height: `${height}px`,
      overflow: 'hidden',
      width: `${width}px`,
    };

    this.innerDivStyle = {
      transition: `transform ${transitionTime}s ease-out`,
      width: `${width}px`,
      willChange: 'transform',
    };

    this.imageRef = React.createRef();

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleMouseMovement = this.handleMouseMovement.bind(this);
  }

  handleMouseOver() {
    this.setState({
      zoom: true,
    });
  }

  handleMouseOut() {
    this.setState({
      zoom: false,
    });
  }

  handleMouseMovement(e: React.MouseEvent) {
    if (!this.imageRef.current) return;

    const {
      left: offsetLeft,
      top: offsetTop,
    } = absolutePosition(this.imageRef.current); // .getBoundingClientRect();

    const {
      current: {
        style: {
          height,
          width,
        },
      },
    } = this.imageRef;

    const x = ((e.pageX - offsetLeft) / parseInt(width || '100', 10)) * 110 - 5;
    const y = ((e.pageY - offsetTop) / parseInt(height || '100', 10)) * 110 - 5;

    this.setState({
      mouseX: x,
      mouseY: y,
    });
  }

  render() {
    const {
      mouseX,
      mouseY,
      zoom,
    } = this.state;

    const {
      img,
      zoomScale,
    } = this.props;

    const transform = {
      transformOrigin: `${mouseX}% ${mouseY}%`,
    };

    return (
      <div
        style={this.outerDivStyle}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onMouseMove={this.handleMouseMovement}
        ref={this.imageRef}
      >
        <img
          alt={'Page'}
          src={img}
          style={{
            ...transform,
            ...this.innerDivStyle,
            transform: zoom ? `scale(${zoomScale})` : 'scale(1.0)',
          }}
        />
      </div>
    );
  }
}

export default Zoom;
