import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { WorkflowActions } from '../../../actions/workflow.actions';
import { BatchQuery, StateRoot } from '../../../store/interfaces';
import { BatchModification, Workflow } from '../../../store/interfaces/batch-modification';
import { Loadable } from '../../../store/loader';
import { style } from './style';

interface Props {
  onClose: (value?: Workflow) => void;
  open: boolean;
  documentId: string | null;
}

interface StateProps {
  data: Loadable<BatchQuery, BatchModification>;
}

interface ViewProps {
  handleClose: () => void;
}

interface ContentViewProps {
  documentId: string;
  handleSelect: (workflow: Workflow) => () => void;
  sourceWorkflow: Workflow | undefined;
  workflows: Workflow[];
}

interface Triggers {
  loadWorkflows: (clientId: number) => void;
}

class ChangeWorkflowDialogController extends React.Component<Props & StateProps & Triggers> {
  componentDidMount() {
    const data = this.props.data.value;
    if (!data || !data.batchWorkflows) return;

    const batchWorkflow = data.batchWorkflows[data.batch.batchWorkflowId];
    if (batchWorkflow.clientId) {
      this.props.loadWorkflows(batchWorkflow.clientId);
    }
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleListItemClick = (value: Workflow) => () => {
    this.props.onClose(value);
  }

  render() {
    const { onClose, documentId, data, loadWorkflows, ...other } = this.props;

    if (!data.value || !documentId) {
      return (
        <Dialog onClose={this.handleClose} {...other}>
          <ChangeWorkflowErrorView handleClose={this.handleClose} />
        </Dialog>
      );
    }

    const workflows = [];

    const storeData: BatchModification = data.value;
    const workflowId = storeData.documents && storeData.documents[documentId]
      ? storeData.documents[documentId].workflowId
      : undefined;
    const sourceWorkflow = workflowId && storeData.workflows
      ? storeData.workflows[workflowId]
      : undefined;

    if (storeData.workflows) {
      for (const workflowIndex in storeData.workflows) {
        if (storeData.workflows.hasOwnProperty(workflowIndex)) {
          if (workflowId && (workflowIndex !== workflowId.toString())) {
            workflows.push(storeData.workflows[workflowIndex]);
          }
        }
      }
    }

    return (
      <Dialog onClose={this.handleClose} {...other}>
        <ChangeWorkflowContentView
          documentId={documentId}
          handleSelect={this.handleListItemClick}
          sourceWorkflow={sourceWorkflow}
          workflows={workflows}
        />
      </Dialog>
    );
  }
}

const ChangeWorkflowErrorView = withStyles(style)(withTranslation()(({
  handleClose,
  t,
}: ViewProps & WithTranslation & WithStyles) => (
    <React.Fragment>
      <DialogTitle>{t('batchModification.changeWorkflow.unableToOpen')}</DialogTitle>
      <Button onClick={handleClose} color="primary" autoFocus={true}>{t('terms.close')}</Button>
    </React.Fragment>
  )
));

const ChangeWorkflowContentView = withStyles(style)(withTranslation()(({
  classes,
  documentId,
  handleSelect,
  sourceWorkflow,
  t,
  workflows,
}: ContentViewProps & WithTranslation & WithStyles) => (
    <React.Fragment>
      <DialogTitle>
        <Typography className={classes.changeWorkflowTitle}>{t('batchModification.changeWorkflow.title')} #{documentId}</Typography>
        {sourceWorkflow
          ?
          <Typography>
            {t('batchModification.changeWorkflow.sourceWorkflowLabel')} <strong>{sourceWorkflow.name} (#{sourceWorkflow.id})</strong>
          </Typography>
          : null
        }
        <Typography>{t('batchModification.changeWorkflow.instruction')}</Typography>
      </DialogTitle>
      <List className={classes.changeWorkflowList}>
        {workflows.map(workflow => (
          <ListItem button={true} onClick={handleSelect(workflow)} key={workflow.id}>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={workflow.name} />
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  )
));

const mapStateToProps = (state: StateRoot): StateProps => ({
  data: state.batchModification,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): Triggers => ({
  loadWorkflows: (clientId: number) => dispatch(WorkflowActions.loadByClientId(clientId)),
});

export const ChangeWorkflowDialog = connect(mapStateToProps, mapDispatchToProps)(ChangeWorkflowDialogController);
