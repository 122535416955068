export interface WithQuery<Q> {
  query: Q;
}

export interface MaybeWithQuery<Q> {
  query?: Q;
}

export function withQuery<T, Q>(WrappedComponent: React.ComponentType<T & WithQuery<Q>>) {
  return (props: T & MaybeWithQuery<Q>) => props.query ? <WrappedComponent query={props.query} {...props} /> : null;
}
