import Grid from '@material-ui/core/Grid';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '../../components/app-bar';
import PageContainer from '../../components/page-container';
import RegionList from '../../components/region-list';

const ProfileView: React.FunctionComponent<{}> = () => (
  <PageContainer>
    <Grid item={true} xs={12}>
      <AppBar>
        <Typography variant="h6" color="inherit">
          Profile
        </Typography>
      </AppBar>
    </Grid>
    <Grid item={true}>
      <Paper>
        <Toolbar>
          <Typography variant="h6" color="inherit">
            Regions
              </Typography>
        </Toolbar>
        <MenuList>
          <RegionList />
        </MenuList>
      </Paper>
    </Grid>
  </PageContainer>
);

export const ProfileScene = ProfileView;
