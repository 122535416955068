import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React from 'react';

export interface State {
  showPassword: boolean;
}

export class PasswordField extends React.PureComponent<TextFieldProps, State> {
  public handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  }

  constructor(props: TextFieldProps) {
    super(props);

    this.state = { showPassword: false };
  }

  public render() {
    return (
      <TextField
        {...this.props}
        type={this.state.showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
              >
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}
