import { withStyles, WithStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IconButtonWithTooltip } from '../../../components/icon-button-tooltip';
import { BatchModification } from '../../../store/interfaces/batch-modification';
import SplitIcon from './split-icon';
import { style } from './style';

interface Props {
  data: BatchModification;
  documentId: string;
  handleShowMore: () => void;
  handleSplitDocument: (documentId: string, pageIndex: number) => () => void;
  indexFrom: number;
  indexTo: number;
}

const MorePagesView: React.FunctionComponent<Props & WithTranslation & WithStyles> = ({
  classes,
  data,
  documentId,
  handleShowMore,
  handleSplitDocument,
  indexFrom,
  indexTo,
  t,
}) => {
  return (
    <React.Fragment>
      <IconButtonWithTooltip
        className={classes.showMoreButton}
        onClick={handleShowMore}
        tooltip={t('batchModification.manipulations.showHiddenPage', { count: indexTo - indexFrom + 1 })}
      >
        <MoreIcon />
      </IconButtonWithTooltip>
      <IconButtonWithTooltip
        className={classes.splitButton}
        onClick={handleSplitDocument(documentId, indexTo)}
        tooltip={t('batchModification.manipulations.split')}
      >
        <SplitIcon />
      </IconButtonWithTooltip>
    </React.Fragment>
  );
};

export const MorePages = withStyles(style)(withTranslation()(MorePagesView));
