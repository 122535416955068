import IconButton from '@material-ui/core/IconButton';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import { WithT } from 'i18next';
import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';
import { Placeholder } from '../../../components/placeholder';
import { RelativeDate } from '../../../components/relative-date';
import { DashboardBatch, ReadableBatchStatus } from '../../../domain/batch-dashboard/dto/batch-dashboard-data';
import { WithMenuShow } from '../../../helpers/types';
import { Batch } from '../../../store/interfaces/batch';
import { BatchFieldSet } from '../../../store/interfaces/batch-dashboard';
import { IndexOf } from '../../../store/types';
import { Fields } from './fields';
import { RowGraph } from './row-graph';
import { style } from './style';

export interface RowProps {
  currentUserId: number | undefined;
  batch: DashboardBatch;
  fieldSets: IndexOf<BatchFieldSet> | null;
}

export interface LoadingRowProps {
  batchId: string;
}

const RowView: React.FunctionComponent<RowProps & WithMenuShow<Batch> & WithT & WithStyles> = ({
  batch,
  fieldSets,
  classes,
  onMenuShow,
  currentUserId,
  t,
}) => {
  const disableRow = (batch.currentStatus === undefined)
    || (batch.currentStatus.status !== ReadableBatchStatus.ready && batch.currentStatus.status !== ReadableBatchStatus.onhold);
  const lockedBySomeoneElse = (batch.currentLock !== undefined) && (batch.currentLock.lockedByUserId !== currentUserId);

  const createdDate = DateTime.fromISO(batch.initialRevision.created, { zone: 'utc' }).toLocal().toFormat('y-MM-dd HH:mm:ss');

  function handleMenuClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.stopPropagation();
    event.preventDefault();
    onMenuShow(batch, event.currentTarget);
  }

  return (
    <NavLink
      to={batch.currentStatus
        && (!batch.currentLock || batch.currentLock.lockedByUserId === currentUserId)
        && (batch.currentStatus.status === ReadableBatchStatus.ready || batch.currentStatus.status === ReadableBatchStatus.onhold)
        ? `/batch-workflow/${batch.batchWorkflowId}/batch/${batch.id}/preview`
        : `/batch-workflow/${batch.batchWorkflowId}/dashboard`
      }
      className={(disableRow || lockedBySomeoneElse) ? `row ${classes.rowDisabled}` : `row ${classes.row}`}
    >
      <div>
        <div>{t('batchWorkflowDashboard.docs')}:&nbsp;<strong>{batch.documents.length}</strong></div>
        <div>{t('batchWorkflowDashboard.pages')}:&nbsp;<strong>{batch.pages}</strong></div>
        <div><small>#{batch.id}</small></div>
      </div>
      <div>
        {batch.currentLock
          ? <div>
            <div><strong>{t('batchWorkflowDashboard.statuses.lockedBy')}</strong></div>
            <div>
              {batch.currentLock.lockedByUser
                ? batch.currentLock.lockedByUserId === currentUserId ? t('terms.you') : batch.currentLock.lockedByUser.name
                : <div />
              }</div>
            <div><small><RelativeDate date={DateTime.fromISO(batch.currentLock.locked, { zone: 'utc' })} /></small></div>
            <div><small>{t('batchWorkflowDashboard.createdDate', { createdDate })}</small></div>
          </div>
          : batch.currentStatus
            ? <div>
              <div><strong>{batch.currentStatus.status}</strong></div>
              {batch.currentStatus.createdByUser
                && (batch.currentStatus.status === ReadableBatchStatus.deleted
                  || batch.currentStatus.status === ReadableBatchStatus.done
                  || batch.currentStatus.status === ReadableBatchStatus.onhold)
                ? <div>{t('terms.by')} {batch.currentStatus.createdByUserId === currentUserId
                  ? t('terms.you')
                  : batch.currentStatus.createdByUser.name}</div>
                : <div />
              }
              <div><small><RelativeDate date={DateTime.fromISO(batch.currentStatus.created, { zone: 'utc' })} /></small></div>
              <div><small>{t('batchWorkflowDashboard.createdDate', { createdDate })}</small></div>
            </div>
            : <div>
              <div><strong>-</strong></div>
            </div>
        }
      </div>
      <div>
        {
          batch.currentCategory && batch.currentCategory.category
            ? <div>
              {batch.currentCategory.category.name}
              {
                batch.currentCategory.createdByUser
                  ? <div>{t('terms.by')} {batch.currentCategory.createdByUserId === currentUserId
                    ? t('terms.you')
                    : batch.currentCategory.createdByUser.name}</div>
                  : <div />
              }
              <div><small><RelativeDate date={DateTime.fromISO(batch.currentCategory.created, { zone: 'utc' })} /></small></div>
            </div>
            : <div>-</div>
        }
      </div>
      <div>
        {batch.currentStatus
          && (batch.currentStatus.status === ReadableBatchStatus.ready || batch.currentStatus.status === ReadableBatchStatus.onhold)
          && batch.documentWorkflowData && batch.documentWorkflowData.length
          ? batch.documentWorkflowData.map((workflowData, i) =>
            <RowGraph key={i} documentWorkflowData={workflowData} index={i} classes={classes} />
          )
          : <div>-</div>
        }
      </div>
      {batch.currentStatus
        && (batch.currentStatus.status === ReadableBatchStatus.ready || batch.currentStatus.status === ReadableBatchStatus.onhold)
        && batch.currentData
        ? <Fields
          classes={classes}
          fieldSetIndex={fieldSets}
          fieldSets={batch.currentData.fieldSets}
          position={`left`}
          t={t}
        />
        : <div>-</div>
      }
      {batch.currentStatus
        && (batch.currentStatus.status === ReadableBatchStatus.ready || batch.currentStatus.status === ReadableBatchStatus.onhold)
        && batch.currentData
        ? <Fields
          classes={classes}
          fieldSetIndex={fieldSets}
          fieldSets={batch.currentData.fieldSets}
          position={`right`}
          t={t}
        />
        : <div>-</div>
      }
      <div className={'right buttonContainer'}>
        <IconButton onClick={handleMenuClick} disabled={disableRow}>
          <MoreIcon fontSize="small" />
        </IconButton>
      </div>
    </NavLink>
  );
};

const LoadingRowView: React.FunctionComponent<LoadingRowProps & WithT & WithStyles> = ({
  batchId,
  classes,
  t,
}) => {
  return (
    <div
      className={`row ${classes.rowDisabled}`}
    >
      <div>
        <div>{t('batchWorkflowDashboard.docs')}:&nbsp;<Placeholder /></div>
        <div>{t('batchWorkflowDashboard.pages')}:&nbsp;<Placeholder /></div>
        <div><small>#{batchId}</small></div>
      </div>
      <div>
        <Placeholder />
      </div>
      <div>
        <Placeholder />
      </div>
      <div>
        <Placeholder />
      </div>
      <div>
        <Placeholder />
      </div>
      <div>
        <Placeholder />
      </div>
      <div className={'right'}>
        <Placeholder />
      </div>
    </div>
  );
};

export const Row = withStyles(style)(RowView);
export const LoadingRow = withStyles(style)(LoadingRowView);
