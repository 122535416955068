import { createStyles, Theme } from '@material-ui/core/styles';

export const style = (theme: Theme) => createStyles({
  appBarRoot: {
    backgroundColor: 'white',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    position: 'fixed',
    top: 0,
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 112px)',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1168px',
    },
  },
  batchId: {
    color: '#888',
    fontSize: '0.9rem',
    textAlign: 'end',
  },
  batchTitle: {
    fontSize: '1.2rem',
    paddingBottom: '0.4rem',
  },
  batchWorkflowEditButton: {
    marginRight: '5px',
  },
  batchWorkflowListActionButton: {
    '&:hover': {
      cursor: 'pointer',
    },
    background: '#00C088',
    borderRadius: '50%',
    color: '#FFFFFF',
    marginRight: '5px',
    padding: '4px',
  },
  batchWorkflowListRowActionContainer: {
    padding: '4px 12px 4px 8px !important',
  },
  chip: {
    margin: theme.spacing(),
  },
  disabled: {
    backgroundColor: '#999999',
  },
  grow: {
    flexGrow: 1,
  },
  inputInput: {
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(),
    paddingTop: theme.spacing(),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
});
