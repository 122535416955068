import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import StarBorderIcon from '@material-ui/icons/StarBorderRounded';
import StarIcon from '@material-ui/icons/StarRounded';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RegionActions } from '../../actions/region.actions';
import { DataList } from '../../store/data-list';
import { StateRoot } from '../../store/interfaces';
import { Region } from '../../store/interfaces/region';

export const style = () => createStyles({
  item: {
    marginRight: 24,
  },
});

interface Props {
  regions: DataList<string, Region>;
}

interface Triggers {
  toggleFavorite: (region: Region) => () => void;
}

const RegionListView = withTranslation()(
  ({
    classes,
    regions,
    toggleFavorite,
    t,
  }: Props & Triggers & WithTranslation & WithStyles) => (
      <React.Fragment>
        {regions.items.map((region: Region) => (
          <MenuItem key={region.id} value={region.id} button={true}>
            <ListItemText className={classes.item} primary={t(`regions.${region.id}.text`)} />
            <ListItemSecondaryAction>
              <IconButton disabled={region.refreshing} onClick={toggleFavorite(region)} aria-label={t('terms.favorite')}>
                {region.refreshing
                  ? <CircularProgress size={24} />
                  : region.favoriteOrdinal !== null
                    ? <StarIcon htmlColor="#ffc107" />
                    : <StarBorderIcon />}
              </IconButton>
            </ListItemSecondaryAction>
          </MenuItem>
        ))}
      </React.Fragment>
    )
);

const mapStateToProps = (state: StateRoot): Props => ({
  regions: state.regions,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): Triggers => ({
  toggleFavorite: (region: Region) => () => dispatch(RegionActions.toggleFavorite(region)),
});

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(RegionListView));
