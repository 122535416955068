import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CircularProgressComponent } from '../../../components/circular-progress';
import { ChangeDocumentWorkflowDialogContext, MenuContext } from '../../../helpers/types';
import store from '../../../store';
import { BatchQuery } from '../../../store/interfaces';
import { BatchModification, Workflow } from '../../../store/interfaces/batch-modification';
import { Loadable } from '../../../store/loader';
import { BatchModificationMutations } from '../../../store/mutations/batch-modification.mutations';
import { NotFoundScene } from '../../not-found';
import { ChangeWorkflowDialog } from './change-workflow';
import { BatchDocument } from './document';

interface ControllerProps {
  data: Loadable<BatchQuery, BatchModification>;
}

interface ViewProps {
  data: BatchModification;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}

interface ViewTriggers {
  onReversePageOrder: (documentId: string) => void;
  onChangeWorkflow: (documentId: string, newWorkflowId: number) => void;
  onRemoveFromBatch: (documentId: string) => void;
}

class DocumentListController extends React.PureComponent<ControllerProps> {
  readonly onDragEnd = (result: DropResult, provided: ResponderProvided): void => {
    const value = this.props.data.value;
    if (!value) return;

    const { destination, source, reason, type } = result;

    if (!destination || reason !== 'DROP') return;

    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    switch (type) {
      case 'document': {
        store.dispatch(BatchModificationMutations.moveDocument(source.index, destination.index));
        return;
      }
      case 'page': {
        store.dispatch(BatchModificationMutations.movePage(
          {
            documentId: source.droppableId,
            index: source.index,
          },
          {
            documentId: destination.droppableId,
            index: destination.index,
          }
        ));
        return;
      }
      default:
        return;
    }
  }

  render() {
    const { data } = this.props;

    if (data.loading || data.valuePending) {
      return <CircularProgressComponent />;
    }
    const value = data.value;
    if (!value) {
      return <NotFoundScene />;
    }

    return <DocumentListView data={value} onDragEnd={this.onDragEnd} />;
  }
}

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): ViewTriggers => ({
  onReversePageOrder: (documentId: string) =>
    dispatch(BatchModificationMutations.reversePageOrder(documentId)),

  onChangeWorkflow: (documentId: string, newWorkflowId: number) =>
    dispatch(BatchModificationMutations.changeWorkflow(documentId, newWorkflowId)),

  onRemoveFromBatch: (documentId: string) =>
    dispatch(BatchModificationMutations.removeFromBatch(documentId)),
});

type AllViewProps = ViewProps & ViewTriggers & WithTranslation;

const DocumentListView = connect(undefined, mapDispatchToProps)(withTranslation()(({
  data,
  onReversePageOrder,
  onChangeWorkflow,
  onRemoveFromBatch,
  onDragEnd,
  t,
}: AllViewProps) => {
  const [menuAnchor, setMenuAnchor] = React.useState<MenuContext<string> | null>(null);

  const [changeWorkflowDialogAnchor, setChangeWorkflowDialogAnchor] = React.useState<ChangeDocumentWorkflowDialogContext<string | undefined>>({
    documentId: null,
    open: false,
  });

  function handleMenuShow(id: string, element: HTMLElement) {
    setMenuAnchor({ element, item: id });
  }

  function handleMenuHide() {
    setMenuAnchor(null);
  }

  function handleReversePageOrder() {
    setMenuAnchor(null);
    if (!menuAnchor) return;
    onReversePageOrder(menuAnchor.item);
  }

  function handleChangeWorkflowDialogShow() {
    setMenuAnchor(null);
    if (!menuAnchor) return;
    setChangeWorkflowDialogAnchor({ open: true, documentId: menuAnchor.item });
  }

  function handleChangeWorkflowDialogHide(value?: Workflow) {
    setChangeWorkflowDialogAnchor({ open: false, documentId: undefined });
    if (value && changeWorkflowDialogAnchor.documentId) {
      onChangeWorkflow(changeWorkflowDialogAnchor.documentId, value.id);
    }
  }

  function handleRemoveFromBatch() {
    setMenuAnchor(null);
    if (!menuAnchor) return;
    onRemoveFromBatch(menuAnchor.item);
  }

  const changeWorkflowDialog = changeWorkflowDialogAnchor.open && changeWorkflowDialogAnchor.documentId ?
    (
      <ChangeWorkflowDialog
        onClose={handleChangeWorkflowDialogHide}
        open={true}
        documentId={changeWorkflowDialogAnchor.documentId}
      />
    ) : null;

  return (
    <Paper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="docs" type="document" direction="vertical">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {data.documentIds.map((id, i) => <BatchDocument
                key={id}
                data={data}
                documentId={id}
                index={i}
                isLast={i === data.documentIds.length - 1}
                onMenuShow={handleMenuShow}
              />)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Menu anchorEl={menuAnchor && menuAnchor.element} open={Boolean(menuAnchor)} onClose={handleMenuHide}>
        <MenuItem onClick={handleReversePageOrder}>{t('batchModification.actions.reversePages')}</MenuItem>
        <MenuItem onClick={handleChangeWorkflowDialogShow}>{t('batchModification.actions.changeWorkflow')}</MenuItem>
        {menuAnchor && menuAnchor.element && (menuAnchor.item.indexOf('**new**') < 0) ?
          <MenuItem onClick={handleRemoveFromBatch}>{t('batchModification.actions.removeFromBatch')}</MenuItem>
          : null}
      </Menu>
      {changeWorkflowDialog}
    </Paper>
  );
}));

export const DocumentList = DocumentListController;
