import { withStyles, Grid, WithStyles } from '@material-ui/core';
import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BatchFieldSet } from '../../../store/interfaces/batch-workflow';
import { BatchFieldSetScene } from './batch-field-set';
import { RemovableBatchComponent } from './batch-workflow-admin';
import { BatchWorkflowNewObjectData } from './batch-workflow.new-objects';
import { BatchWorkflowValidation } from './batch-workflow.validation';
import { style } from './style';

export interface ExternalControllerProps {
  batchFieldSets: BatchFieldSet[];
  newObjectData?: BatchWorkflowNewObjectData;
  validationData?: BatchWorkflowValidation;
  onChangeBatchFieldSet: (batchFieldSet: BatchFieldSet, index: number, notImportant?: boolean, newBatchFieldIndex?: number) => void;
  onDragEnd: (result: DropResult) => void;
  onDeleteBatchComponent: (removableBatchComponent: RemovableBatchComponent) => void;
}

type ControllerProps = ExternalControllerProps;

class BatchFieldSetListController extends React.PureComponent<ControllerProps> {
  render() {
    return (
      <BatchFieldSetListView
        batchFieldSets={this.props.batchFieldSets}
        newObjectData={this.props.newObjectData}
        validationData={this.props.validationData}
        onDragEnd={this.props.onDragEnd}
        onDeleteBatchComponent={this.props.onDeleteBatchComponent}
        handleBatchFieldSetChange={this.props.onChangeBatchFieldSet}
      />
    );
  }
}

interface ViewProps {
  batchFieldSets: BatchFieldSet[];
  newObjectData?: BatchWorkflowNewObjectData;
  validationData?: BatchWorkflowValidation;
  handleBatchFieldSetChange: (batchFieldSet: BatchFieldSet, index: number, notImportant?: boolean, newBatchFieldIndex?: number) => void;
  onDragEnd: (result: DropResult) => void;
  onDeleteBatchComponent: (removableBatchComponent: RemovableBatchComponent) => void;
}

type AllViewProps = ViewProps & WithTranslation & WithStyles;

const BatchFieldSetListView = withStyles(style)(withTranslation()(({
  batchFieldSets,
  newObjectData,
  validationData,
  handleBatchFieldSetChange,
  onDragEnd,
  onDeleteBatchComponent,
  classes,
}: AllViewProps) => {
  return (
    <Grid item={true} xs={12} className={classes.batchFieldSetCard}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="batchFieldSets" type="batchFieldSet" direction="vertical">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {
                batchFieldSets.map((batchFieldSet, index) =>
                  <BatchFieldSetScene
                    key={index}
                    batchFieldSet={batchFieldSet}
                    batchFieldSetIndex={index}
                    newObjectData={newObjectData}
                    validationData={validationData}
                    handleBatchFieldSetChange={handleBatchFieldSetChange}
                    onDeleteBatchComponent={onDeleteBatchComponent}
                  />
                )
              }
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {newObjectData && newObjectData.newBatchFieldSet && !newObjectData.newBatchFieldSet.newBatchField ?
        <div style={{ height: 80 }} /> : undefined
      }
    </Grid>
  );
}));

export const BatchFieldSetList = BatchFieldSetListController;
