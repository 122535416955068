import { BatchDashboardRepository } from '../domain/batch-dashboard/batch-dashboard.repository';
import { BatchWorkflowUpdates } from '../domain/batch-workflow/dto/batch-workflow-updates-subscription';
import { BatchRepository } from '../domain/batch/batch.repository';
import { UserRepository } from '../domain/user/user.repository';
import { WorkflowRepository } from '../domain/workflow/workflow.repository';
import { parseApolloError } from '../helpers/graphql';
import { AuthStatus } from '../store/interfaces/auth';
import { BatchStatus, Document as RawDocument } from '../store/interfaces/batch';
import {
  Document,
  Workflow
} from '../store/interfaces/batch-learning';
import { InputFile } from '../store/interfaces/batch-learning';
import { CodedError } from '../store/interfaces/error';
import { BatchLearningMutations } from '../store/mutations/batch-learning.mutations';
import { Action, IndexOf } from '../store/types';
import { InputFileRepository } from './../domain/input-file/input-file.repository';
import { BatchActions } from './batch.actions';

export namespace BatchLearningActions {
  export function releaseBatch(batchId: string, batchWorkflowId: number, regionId: string | undefined): Action {
    return async (dispatch) => {
      if (!batchId || !batchWorkflowId || !regionId) return;

      dispatch(BatchLearningMutations.setLoading());
      await dispatch(BatchActions.releaseBatch(batchId, batchWorkflowId, regionId));
      await dispatch(BatchLearningActions.load(batchId, batchWorkflowId, regionId));
    };
  }

  export function load(batchId: string, batchWorkflowId: number, regionId: string | undefined, documentId?: string | undefined): Action {
    return async (dispatch) => {
      dispatch(BatchLearningMutations.setQuery(batchId, batchWorkflowId, regionId));

      if (!batchId || !batchWorkflowId || !regionId) return;

      dispatch(BatchLearningMutations.setLoading());

      try {
        const repository = BatchRepository.forRegion(regionId);
        const result = await repository.findById(batchWorkflowId, batchId);

        if (result === null) {
          dispatch(BatchLearningMutations.setError(
            new CodedError(
              `Unable to find batch with batchId: ${batchId}, batchWorkflowId: ${batchWorkflowId}, regionId: ${regionId}`,
              'not-found'
            )));
          return;
        }

        const inputFileIds: string[][] = [];
        const dataSourceIds: number[] = [];
        const documents = result.documents.reduce(
          (acc, document) => {
            inputFileIds.push(document.pages.map((page) => page.inputFileId));
            dataSourceIds.push(document.dataSourceId);
            return {
              ...acc, [document.id]: {
                batchId: result.id,
                id: document.id,
                pageCount: document.pages.length,
                status: document.status,
                thumbnail: document.pages.length ? document.pages[0].thumbnail : undefined,
                workflowId: document.currentDocumentWorkflow.workflowId,
              },
            };
          },
          {} as IndexOf<Document>);

        const inputFileRepository = InputFileRepository.forRegion(regionId);
        const getInputFileIds = inputFileIds.flat();

        let inputFileResult = await inputFileRepository.findByIds(getInputFileIds);
        inputFileResult = inputFileResult.filter((inputFile) => dataSourceIds.indexOf(inputFile.dataSourceId) === -1);

        const inputFiles = inputFileResult.reduce(
          (acc, inputFile) => ({
            ...acc, [inputFile.id]: {
              batchId: result.id,
              dataSourceId: inputFile.dataSourceId,
              id: inputFile.id,
              thumbnail: inputFile.pages.length ? inputFile.pages[0].thumbnail : undefined,
              workflowId: inputFile.workflowId,
            },
          }),
          {} as IndexOf<InputFile>);

        if (result.currentLock && result.currentLock.lockedByUserId) {
          const userRepository = UserRepository.forGlobal();
          const lockedByUser = await userRepository.findById(result.currentLock.lockedByUserId);
          result.currentLock.lockedByUser = lockedByUser;
        }

        dispatch(BatchLearningMutations.setRegionalData(
          { id: result.id, regionId, currentLock: result.currentLock, currentStatus: result.currentStatus },
          documents,
          inputFiles,
          result.documents.map((doc) => doc.id),
          inputFileResult.map((doc) => doc.id)
        ));

        const inputFileWorkflows = Array.from(new Set(inputFileResult.map((input) => input.workflowId)));
        const workflowIds = Array.from(new Set(result.documents.map((d) => d.currentDocumentWorkflow.workflowId))).concat(inputFileWorkflows);
        const workflowsResult = await WorkflowRepository.forGlobal().findWorkflows(workflowIds);

        const workflows = workflowsResult.reduce(
          (acc, workflow) => ({
            ...acc, [workflow.id]: {
              id: workflow.id,
              name: workflow.name,
            },
          }),
          {} as IndexOf<Workflow>);

        dispatch(BatchLearningMutations.setWorkflows(workflows));

        if (result.documents.length) {
          if (documentId && result.documents.find((doc: RawDocument) => doc.id === documentId)) {
            dispatch(BatchLearningMutations.setActiveDocument({ id: documentId, regionId, type: 'Document' }));
          } else {
            dispatch(BatchLearningMutations.setActiveDocument({ id: result.documents[0].id, regionId, type: 'Document' }));
          }
        }
      } catch (err) {
        const errors = parseApolloError(err);
        errors.forEach((error) => dispatch(BatchLearningMutations.setError(new CodedError(error.message, 'error'))));
      }
    };
  }

  export function subscribe(regionId: string, batchWorkflowId: number): Action {
    return async (dispatch, getState) => {
      const state = getState();
      const auth = state.auth;
      const batchLearningQuery = state.batchLearning.query;
      const batchDashboardRepository = BatchDashboardRepository.forRegion(regionId);
      const batchWorkflowSubClient = await batchDashboardRepository.subscribeToBatchWorkflow(batchWorkflowId);
      const subscriptionClient = batchWorkflowSubClient.subscribe({
        // tslint:disable-next-line:no-any
        async next(data: any) {
          const batchWorkflowUpdates: BatchWorkflowUpdates = data.data.batchWorkflowUpdates;
          if (!batchWorkflowUpdates || !batchLearningQuery || !(state.auth.status === AuthStatus.AUTHENTICATED)) return;
          if (batchWorkflowUpdates.batchId === batchLearningQuery.batchId) {
            if (batchWorkflowUpdates.__typename === 'BatchStatusUpdate') {
              if (batchWorkflowUpdates.status === BatchStatus.DELETED || batchWorkflowUpdates.status === BatchStatus.HOLD) {
                let name: string = '';
                if (auth.status === AuthStatus.AUTHENTICATED && (auth.tokenSet.idToken.payload.sub as number) === batchWorkflowUpdates.userId) {
                  name = 'terms.you';
                } else {
                  const userRepository = UserRepository.forGlobal();
                  const user = await userRepository.findById(batchWorkflowUpdates.userId);
                  name = user.name;
                }
                dispatch(BatchLearningMutations.setSubUpdateInfo({
                  by: name,
                  type: batchWorkflowUpdates.status as 'deleted' | 'onhold',
                }));
              }
            } else if (batchWorkflowUpdates.__typename === 'BatchUnlockSub') {
              let name: string = '';
              if (
                auth.status === AuthStatus.AUTHENTICATED &&
                (auth.tokenSet.idToken.payload.sub as number) === batchWorkflowUpdates.unlockedByUserId
              ) {
                name = 'terms.you';
              } else {
                const userRepository = UserRepository.forGlobal();
                const user = await userRepository.findById(batchWorkflowUpdates.unlockedByUserId);
                name = user.name;
              }
              dispatch(BatchLearningMutations.setSubUpdateInfo({
                by: name,
                type: 'unlock',
              }));
            }
          }
        },
      });
      dispatch(BatchLearningMutations.setSubscriber(subscriptionClient));
    };
  }
}
