import { createStyles, Theme } from '@material-ui/core/styles';

export const sortStyle = (theme: Theme) => createStyles({
  button: {
    float: 'right',
    margin: '10px 5px 0px',
  },
  fieldSetNameContainer: {
    color: '#ccc',
    marginTop: '5px',
  },
  formControl: {
    width: '70%',
  },
  groupBadgeStyles: {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  },
  groupStyles: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  popperStyle: {
    zIndex: 1200,
  },
  resetButton: {
    float: 'right',
    marginTop: theme.spacing(),
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    marginTop: '15px',
    padding: theme.spacing(2),
    width: '100%',
  },
  select: {
    minWidth: '170px',
  },
  sortContainer: {
    marginTop: '10px',
  },
});
