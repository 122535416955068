// tslint:disable:no-any
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'perfect-scrollbar';
import React from 'react';
import { sidebarStyle } from '../../styles';

class SidebarWrapper extends React.PureComponent<any> {
  public sidebarWrapper: React.RefObject<HTMLDivElement>;
  private ps: PerfectScrollbar;

  public componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1 && this.sidebarWrapper && this.sidebarWrapper.current) {
      this.ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  public componentWillUnmount() {
    if (this.ps) {
      this.ps.destroy();
    }
  }

  public render() {
    const { classes, className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        <Divider className={classes.dividerBefore} />
        {user}
        {headerLinks}
        <Divider className={classes.dividerAfter} />
        {links}
      </div>
    );
  }
}

export default withStyles(sidebarStyle)(SidebarWrapper);
