interface Props {
  color?: string;
  height: string;
  verticalMargin?: string;
  horizontalMargin?: string;
}

const VerticalDividerView = ({
  color,
  height,
  verticalMargin,
  horizontalMargin,

}: Props) => (
  <div
    style={{
      backgroundColor: color || 'rgba(0, 0, 0, 0.12)',
      height: height || '100%',
      margin: `${verticalMargin || '0'} ${horizontalMargin || '0'}`,
      width: 1,
    }}
  />
);

export const VerticalDivider = VerticalDividerView;
