import { BatchDashboardRepository } from '../domain/batch-dashboard/batch-dashboard.repository';
import { BatchPreviewRepository } from '../domain/batch-preview/batch-preview.repository';
import { FieldDataOption } from '../domain/batch-preview/dto/batch-preview-data';
import { BatchDataFieldSet } from '../domain/batch-preview/dto/batch-preview-modify-data';
import { BatchWorkflowRepository } from '../domain/batch-workflow/batch-workflow.repository';
import { BatchWorkflowUpdates } from '../domain/batch-workflow/dto/batch-workflow-updates-subscription';
import { UserRepository } from '../domain/user/user.repository';
import { parseApolloError } from '../helpers/graphql';
import { PreviewButtons } from '../scenes/batch/preview/preview-buttons';
import { AuthStatus } from '../store/interfaces/auth';
import { BatchStatus } from '../store/interfaces/batch';
import { BatchPreviewData } from '../store/interfaces/batch-preview';
import { CodedError } from '../store/interfaces/error';
import { AppMutations } from '../store/mutations/app.mutations';
import { BatchPreviewMutations } from '../store/mutations/batch-preview.mutations';
import { Action, IndexOf } from '../store/types';
import { BatchDashboardActions } from './batch-dashboard.actions';
import { getBatchConfidence, getProblems, getWorkflowIds, indexDocuments, indexFieldSets, indexWorkflows } from './batch-preview.helpers';
import { BatchActions } from './batch.actions';

export namespace BatchPreviewActions {
  export function lockAndLoad(
    regionId: string | undefined,
    batchWorkflowId: number | undefined,
    batchId: string | undefined
  ): Action {
    return async (dispatch) => {
      if (!regionId || !batchId || !batchWorkflowId) {
        dispatch(BatchPreviewMutations.setValue(null));
        return;
      }

      dispatch(BatchPreviewMutations.setQuery({ regionId, batchWorkflowId, batchId }));
      dispatch(BatchPreviewMutations.setLoading());

      try {
        const batchPreviewRepository = BatchPreviewRepository.forRegion(regionId);
        const batchData = await batchPreviewRepository.findOne(batchId);

        if (batchData === null) {
          dispatch(BatchPreviewMutations.setValue(null));
          return;
        }
        const currentCategoryId = batchData.currentCategory ? batchData.currentCategory.categoryId : undefined;

        const batchWorkflowRepository = BatchWorkflowRepository.forGlobal();
        const batchWorkflow = await batchWorkflowRepository.findBatchWorkflowAndFields(batchWorkflowId, currentCategoryId);

        if (batchData.currentLock) {
          if (batchData.currentLock.lockedByUserId) {
            const userRepository = UserRepository.forGlobal();
            const lockedByUser = await userRepository.findById(batchData.currentLock.lockedByUserId);
            batchData.currentLock.lockedByUser = lockedByUser;
          }
        } else {
          if (batchData.currentStatus && batchData.currentStatus.status === BatchStatus.READY) {
            // lock batch
            dispatch(BatchActions.lockBatch(batchId, regionId));
          }
        }

        const workflowIds = getWorkflowIds(batchData);
        const batchWorkflowResult = await batchWorkflowRepository.findBatchWorkflowsAndWorkflows([batchData.batchWorkflowId], workflowIds);
        const documents = indexDocuments(batchId, batchData.documents);
        const fieldSets = indexFieldSets(batchWorkflow.fieldSets);
        const workflows = indexWorkflows(batchWorkflowResult.workflows);

        const data: BatchPreviewData = {
          batch: batchData,
          batchId: batchData.id,
          batchWorkflow,
          batchWorkflowId: batchData.batchWorkflowId,
          confidenceLevels: batchData.currentData ? getBatchConfidence(batchData.currentData.fieldSets, fieldSets, documents, workflows) : null,
          documents,
          fieldSets,
          problems: getProblems(batchData),
          workflows,
        };

        dispatch(BatchPreviewMutations.setValue(data));
      } catch (err) {
        const errors = parseApolloError(err);
        errors.forEach((error) => dispatch(BatchPreviewMutations.setError(new CodedError(error.message, 'error'))));
      }
    };
  }

  export function releaseBatch(batchId: string, batchWorkflowId: number, regionId: string | undefined): Action {
    return async (dispatch) => {
      if (!batchId || !batchWorkflowId || !regionId) return;
      dispatch(BatchPreviewMutations.setLoading());
      await dispatch(BatchActions.releaseBatch(batchId, batchWorkflowId, regionId));
      await dispatch(BatchPreviewActions.lockAndLoad(regionId, batchWorkflowId, batchId));
    };
  }

  export function updateField(
    fieldSetId: string,
    fieldId: string,
    value: FieldDataOption | undefined
  ): Action {
    return async (dispatch, getState) => {
      dispatch(BatchPreviewMutations.updateField(fieldSetId, fieldId, value));
    };
  }

  export function reExtract(documentId: string): Action {
    return async (dispatch, getState) => {
      const state = getState();
      if (!state.batchPreview.value || !state.batchPreview.query || state.batchPreview.loading) return;
      const data = state.batchPreview.value;
      dispatch(BatchPreviewMutations.setLoading());

      const repository = BatchPreviewRepository.forRegion(data.batch.regionId);
      await repository.reExtract(documentId).catch((err) => {
        const errors = parseApolloError(err);
        errors.forEach((error) => dispatch(BatchPreviewMutations.setError(new CodedError(error.message, 'error'))));
      });
      dispatch(lockAndLoad(data.batch.regionId, data.batchWorkflowId, data.batchId));
    };
  }

  export function save(
    newData: IndexOf<BatchDataFieldSet>,
    ignoredDocuments: string[],
    completeFlag: boolean = false,
  ): Action {
    return async (dispatch, getState) => {
      const state = getState();
      if (!state.batchPreview.value || !state.batchPreview.query || state.batchPreview.loading) return;
      const data = state.batchPreview.value;
      const saveData = {
        batchDataId: data.batch.currentData.id,
        batchId: data.batchId,
        completeFlag,
        fieldSets: newData,
        ignoredDocuments,
      };

      dispatch(BatchPreviewMutations.setLoading());

      const repository = BatchPreviewRepository.forRegion(data.batch.regionId);
      await repository.saveData(saveData).catch((err) => {
        const errors = parseApolloError(err);
        errors.forEach((error) => dispatch(BatchPreviewMutations.setError(new CodedError(error.message, 'error'))));
      });

      if (completeFlag) {
        dispatch(BatchDashboardActions.updateBatchCompleted(data.batchId));
      } else {
        dispatch(lockAndLoad(data.batch.regionId, data.batchWorkflowId, data.batchId));
      }
    };
  }

  export function subscribe(regionId: string, batchWorkflowId: number): Action {
    return async (dispatch, getState) => {
      const state = getState();
      const auth = state.auth;
      const batchPreviewQuery = state.batchPreview.query;
      const batchDashboardRepository = BatchDashboardRepository.forRegion(regionId);
      const batchWorkflowSubClient = await batchDashboardRepository.subscribeToBatchWorkflow(batchWorkflowId);
      const subscriptionClient = batchWorkflowSubClient.subscribe({
        // tslint:disable-next-line:no-any
        async next(data: any) {
          const batchWorkflowUpdates: BatchWorkflowUpdates = data.data.batchWorkflowUpdates;
          if (!batchWorkflowUpdates || !batchPreviewQuery || !(state.auth.status === AuthStatus.AUTHENTICATED)) return;
          if (batchWorkflowUpdates.batchId === batchPreviewQuery.batchId) {
            if (batchWorkflowUpdates.__typename === 'BatchDataUpdate') {
              dispatch(AppMutations.setSnackbarMessage({
                action: PreviewButtons.UpdateAppButton,
                messageTranslationKey: 'batchPreview.problems.batchDataUpdated',
                variant: 'info',
              }));
            } else if (batchWorkflowUpdates.__typename === 'BatchStatusUpdate') {
              if (batchWorkflowUpdates.status === BatchStatus.DELETED || batchWorkflowUpdates.status === BatchStatus.HOLD) {
                let name: string = '';
                if (auth.status === AuthStatus.AUTHENTICATED && (auth.tokenSet.idToken.payload.sub as number) === batchWorkflowUpdates.userId) {
                  name = 'terms.you';
                } else {
                  const userRepository = UserRepository.forGlobal();
                  const user = await userRepository.findById(batchWorkflowUpdates.userId);
                  name = user.name;
                }
                if (batchWorkflowUpdates.status === BatchStatus.DELETED ||
                  (batchWorkflowUpdates.status === BatchStatus.HOLD && name !== 'terms.you')) {
                  dispatch(BatchPreviewMutations.setSubUpdateInfo({
                    by: name,
                    type: batchWorkflowUpdates.status as 'deleted' | 'onhold',
                  }));
                }
              } else if (batchWorkflowUpdates.status !== BatchStatus.READY) {
                dispatch(AppMutations.setSnackbarMessage({
                  action: PreviewButtons.backToDashboard(batchPreviewQuery.batchWorkflowId),
                  messageTranslationKey: 'batchPreview.problems.batchStatusChanged',
                  messageTranslationVariable: { status: batchWorkflowUpdates.status },
                  variant: 'info',
                }));
              }
            } else if (batchWorkflowUpdates.__typename === 'BatchUnlockSub') {
              let name: string = '';
              if (
                auth.status === AuthStatus.AUTHENTICATED &&
                (auth.tokenSet.idToken.payload.sub as number) === batchWorkflowUpdates.unlockedByUserId
              ) {
                name = 'terms.you';
              } else {
                const userRepository = UserRepository.forGlobal();
                const user = await userRepository.findById(batchWorkflowUpdates.unlockedByUserId);
                name = user.name;
              }
              dispatch(BatchPreviewMutations.setSubUpdateInfo({
                by: name,
                type: 'unlock',
              }));
            }
          }
        },
      });
      dispatch(BatchPreviewMutations.setSubscriber(subscriptionClient));
    };
  }
}
