import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import env from '../../environment';
import { User } from '../../store/interfaces/user';
import { clientCache } from '../graphql';

const GQL_GET_USER = gql`
  query ($id: Int!) {
    user(id: $id) {
      id
      name
    }
  }`;

export class UserRepository {
  public static forGlobal(): UserRepository {
    const url = env.urlFactory.global().service('data').resolve('graphql');
    const client = clientCache.getItem(url);
    if (!client) throw new Error('Unable to create GraphQL client');
    return new UserRepository(client);
  }

  constructor(
    private readonly client: ApolloClient<{}>,
  ) { }

  public async findById(id: number): Promise<User> {
    const { data } = await this.client.query<{ user: User }>({
      query: GQL_GET_USER,
      variables: { id },
    });
    return data.user || undefined;
  }
}
