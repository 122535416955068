import GridList from '@material-ui/core/GridList';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import JoinIcon from '@material-ui/icons/FirstPage';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IconButtonWithTooltip } from '../../../components/icon-button-tooltip';
import { BatchModification, Document, Page } from '../../../store/interfaces/batch-modification';
import { BatchModificationMutations } from '../../../store/mutations/batch-modification.mutations';
import { MorePages } from './more-pages';
import { DocumentPage } from './page';
import { style } from './style';

interface ControllerProps {
  data: BatchModification;
  documentId: string;
  index: number;
  isLast: boolean;
}

interface ControllerState {
  expanded: boolean;
}

interface ViewProps {
  data: BatchModification;
  document: Document;
  documentId: string;
  expanded: boolean;
  handleShowMore: () => void;
  handleSplitDocument: (documentId: string, pageIndex: number) => () => void;
  index: number;
  isLast: boolean;
}

interface ViewTriggers {
  handleJoinDocumentToNext: (documentId: string) => () => void;
  handleSplitDocument: (documentId: string, pageIndex: number) => () => void;
  handleShowLightbox: (page: Page | undefined) => () => void;
}

class PageListController extends React.PureComponent<ControllerProps, ControllerState> {
  readonly handleShowMore = () => {
    this.setState({ expanded: true });
  }

  constructor(props: ControllerProps) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  render() {
    const { data, documentId, index, isLast } = this.props;

    if (!data.documents || !data.documents[documentId]) {
      return <div>loading</div>; // TODO loading
    }

    const document = data.documents[documentId];
    return (
      <PageListView
        data={data}
        document={document}
        documentId={documentId}
        expanded={this.state.expanded}
        handleShowMore={this.handleShowMore}
        index={index}
        isLast={isLast}
      />
    );
  }
}

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): ViewTriggers => ({
  handleJoinDocumentToNext: (documentId: string) => () =>
    dispatch(BatchModificationMutations.joinDocumentToNext(documentId)),
  handleShowLightbox: (page: Page | undefined) => () =>
    dispatch(BatchModificationMutations.setLightboxModal(page)),
  handleSplitDocument: (documentId: string, pageIndex: number) => () =>
    dispatch(BatchModificationMutations.splitDocument(documentId, pageIndex)),
});

const PageListView = connect(undefined, mapDispatchToProps)(withStyles(style)(withTranslation()(({
  classes,
  data,
  document,
  documentId,
  expanded,
  handleJoinDocumentToNext,
  handleShowMore,
  handleShowLightbox,
  handleSplitDocument,
  isLast,
  t,
}: ViewProps & ViewTriggers & WithTranslation & WithStyles) => (
    <GridList className={classes.pageList}>
      <Droppable droppableId={documentId} direction="horizontal" type="page">
        {(provided, snapshot) => (
          <div
            className="droppable"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {!document.pageIds.length
              ?
              <div className={classes.noPages}>
                <div>{t('batchModification.noPages')}</div>
              </div>
              : document.pageIds.length <= 4 || expanded || snapshot.isDraggingOver
                ?
                document.pageIds.map((id, i) => (
                  <DocumentPage
                    key={id}
                    data={data}
                    documentId={documentId}
                    draggable={true}
                    pageId={id}
                    handleShowLightbox={handleShowLightbox}
                    handleSplitDocument={handleSplitDocument}
                    index={i}
                    isFirst={i === 0}
                    isLast={i === document.pageIds.length - 1}
                  />
                ))
                :
                <React.Fragment>
                  {document.pageIds.slice(0, 2).map((id, i) => (
                    <DocumentPage
                      key={id}
                      data={data}
                      documentId={documentId}
                      draggable={false}
                      pageId={id}
                      handleShowLightbox={handleShowLightbox}
                      handleSplitDocument={handleSplitDocument}
                      index={i}
                      isFirst={i === 0}
                      isLast={false}
                    />
                  ))}
                  <MorePages
                    data={data}
                    documentId={documentId}
                    handleShowMore={handleShowMore}
                    handleSplitDocument={handleSplitDocument}
                    indexFrom={2}
                    indexTo={document.pageIds.length - 3}
                  />
                  {document.pageIds.slice(-2).map((id, i) => (
                    <DocumentPage
                      key={id}
                      data={data}
                      documentId={documentId}
                      draggable={false}
                      pageId={id}
                      handleShowLightbox={handleShowLightbox}
                      handleSplitDocument={handleSplitDocument}
                      index={i + document.pageIds.length - 2}
                      isFirst={false}
                      isLast={i === 1}
                    />
                  ))}
                </React.Fragment>
            }
            {provided.placeholder}
            {!isLast
              ?
              <IconButtonWithTooltip
                className={classes.joinButton}
                onClick={handleJoinDocumentToNext(documentId)}
                tooltipClass={classes.tooltipWithMaxWidth}
                tooltip={t('batchModification.manipulations.join')}
              >
                <JoinIcon />
              </IconButtonWithTooltip>
              : null}
          </div>
        )}
      </Droppable>
    </GridList>
  )
)));

export const PageList = PageListController;
