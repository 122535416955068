export interface Region {
  id: string;
  favoriteOrdinal: number | null;
  refreshing: true | undefined;
}

export function compareRegions(a: Region, b: Region) {
  if (a.favoriteOrdinal === b.favoriteOrdinal) {
    return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
  }
  if (a.favoriteOrdinal === null) return 1;
  if (b.favoriteOrdinal === null) return -1;
  return a.favoriteOrdinal! < b.favoriteOrdinal! ? 1 : -1;
}
