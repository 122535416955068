import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { submit, SubmitButton } from '../../../../helpers/form';
import { NotAuthenticatedMfaState } from '../../../../store/interfaces/auth';
import logo from '../../images/logo.svg';
import TotpField from './components/totp-field';

export interface Props {
  auth: NotAuthenticatedMfaState;
  handleSubmit: (email: string) => void;
  handleReset: () => void;
}

export interface State {
  code: string;
}

class AuthTotp extends React.PureComponent<Props, State> {
  public onInputChanged = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // tslint:disable-next-line:no-any
    const change: any = { [prop]: event.target.value };
    this.setState(change);
  }

  public handleSubmit = async () => {
    this.props.handleSubmit(this.state.code);
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      code: '',
    };
  }

  public render() {
    return (
      <AuthTotpView
        {...this.props}
        loading={this.props.auth.loading}
        onInputChanged={this.onInputChanged}
        handleSubmit={this.handleSubmit}
        handleReset={this.props.handleReset}
      />
    );
  }
}

interface ViewProps {
  loading: boolean;
  onInputChanged: (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => Promise<void>;
  handleReset: () => void;
}

const AuthTotpView = withTranslation(['auth'])(
  ({
    t,
    loading,
    onInputChanged,
    handleSubmit,
    handleReset,
  }: ViewProps & WithTranslation) => (
      <Paper className="card totp">
        <form onSubmit={submit(handleSubmit)}>
          <Grid item={true} container={true} direction="column" alignItems="stretch" spacing={2}>
            <Grid className="logo" container={true} alignItems="stretch">
              <img alt={'Xtracta Logo'} src={logo} />
            </Grid>
            <Grid item={true} container={true}>
              <Typography variant="body1" gutterBottom={true}>
                {t('totp.text')}
              </Typography>
            </Grid>
            <Grid item={true} container={true} alignItems="stretch">
              <TotpField
                id="code-input"
                label={t('totp.inputs.code.label')}
                type="tel"
                name="code"
                margin="normal"
                variant="outlined"
                autoComplete="off"
                autoFocus={true}
                onChange={onInputChanged('code')}
                fullWidth={true}
                disabled={loading}
              />
            </Grid>
            <Grid item={true} container={true} justify="center">
              <Fab
                className="primary-button"
                variant="extended"
                color="secondary"
                onClick={handleSubmit}
                component={SubmitButton}
                disabled={loading}
              >
                {t('totp.buttons.submit')!}
              </Fab>
            </Grid>
            <Grid item={true} />
            <Grid item={true} />
            <Grid item={true} container={true} justify="center">
              <Link onClick={handleReset}>{t('totp.buttons.reset')}</Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    )
);

export default AuthTotp;
