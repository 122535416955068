import { createAction } from '..';
import { getProblems } from '../../actions/batch-preview.helpers';
import { BatchData, FieldDataOption } from '../../domain/batch-preview/dto/batch-preview-data';
import { BatchPreviewData, BatchPreviewQuery } from '../interfaces/batch-preview';
import { CodedError } from '../interfaces/error';

export namespace BatchPreviewMutations {
  export const setQuery = (query: BatchPreviewQuery) => createAction((state) => ({
    ...state,
    batchPreview: state.batchPreview.setQuery(query),
  }));

  export const setValue = (value: BatchPreviewData | null) => createAction((state) => ({
    ...state,
    batchPreview: state.batchPreview.setValue(value),
  }));

  export const setBatchId = (batchId: string) => createAction((state) => {
    if (!state.batchPreview.value) return state;
    return {
      ...state,
      batchPreview: state.batchPreview.setValue({
        ...state.batchPreview.value,
        batchId,
      }),
    };
  });

  export const setResults = (batch: BatchData) => createAction((state) => {
    if (!state.batchPreview.value) return state;
    return {
      ...state,
      batchPreview: state.batchPreview.setValue({
        ...state.batchPreview.value,
        batch,
      }),
    };
  });

  export const setLoading = () => createAction((state) => ({
    ...state,
    batchPreview: state.batchPreview.setLoading(),
  }));

  export const setError = (err: CodedError) => createAction((state) => ({
    ...state,
    batchPreview: state.batchPreview.setError(err),
  }));

  export const clear = () => createAction((state) => ({
    ...state,
    batchPreview: state.batchPreview.clear(),
  }));

  export const validate = () => createAction((state) => {
    if (!state.batchPreview.value) return state;
    return {
      ...state,
      batchPreview: state.batchPreview.setValue({
        ...state.batchPreview.value,
        problems: getProblems(state.batchPreview.value.batch),
      }),
    };
  });

  export const updateField = (
    fieldSetId: string,
    fieldId: string,
    value: FieldDataOption | undefined
  ) => createAction((state) => {
    if (!state.batchPreview.value || !value) return state;

    const batch = state.batchPreview.value.batch;
    const data = batch.currentData;
    const fieldSet = data.fieldSets[fieldSetId];
    if (!fieldSet || fieldSet.type !== 'PerBatchDataFieldSet') return state;

    const field = fieldSet.fields[fieldId];
    if (!field) return state;

    field.selected = value;

    return {
      ...state,
      batchPreview: state.batchPreview.setValue({
        ...state.batchPreview.value,
        batch: {
          ...batch,
          currentData: {
            ...data,
            fieldSets: {
              ...data.fieldSets,
              [fieldSetId]: {
                ...fieldSet,
                fields: {
                  ...fieldSet.fields,
                  [fieldId]: field,
                },
              },
            },
          },
        },
      }),
    };
  });

  export const setSubUpdateInfo = (subUpdateInfo: { type: 'deleted' | 'onhold' | 'unlock', by: string }) => createAction((state) => {
    if (!state.batchPreview.value) return state;

    return {
      ...state,
      batchPreview: state.batchPreview.setValue({
        ...state.batchPreview.value,
        subUpdateInfo,
      }),
    };
  });

  export const setSubscriber = (value: ZenObservable.Subscription) => createAction((state) => ({
    ...state,
    subscriber: value,
  }));
}
