import { RouteComponentProps } from 'react-router-dom';
import { BatchWorkflowActions } from '../actions/batch-workflow.actions';
import { BatchDomain } from '../domain/batch/batch.domain';
import store from '../store';
import { BatchModificationMutations } from '../store/mutations/batch-modification.mutations';
import { BatchWorkflowMutations } from '../store/mutations/batch-workflow.mutations';
import { BatchMutations } from '../store/mutations/batch.mutations';
import { BatchQuery } from './../store/interfaces/index';
import { objectEquals } from './object-equals';

export interface WithBatchId extends WithBatchWorkflowId {
  batchId: string | undefined;
}

export interface WithBatchWorkflowId {
  batchWorkflowId: string | undefined;
}

export interface WithDocumentId extends WithBatchId {
  documentId: string | undefined;
}

export namespace RouterProps {
  export function getDocumentId(props: RouteComponentProps<WithDocumentId>): string | undefined {
    return props.match.params.documentId;
  }

  export function getBatchId(props: RouteComponentProps<WithBatchId>): string | undefined {
    return props.match.params.batchId;
  }

  export function getBatchWorkflowId(props: RouteComponentProps<WithBatchWorkflowId>): number | undefined {
    return props.match.params.batchWorkflowId
      ? Number(props.match.params.batchWorkflowId)
      : undefined;
  }

  export function handleBatchRoute(
    props: RouteComponentProps<WithBatchId>,
    previousProps?: RouteComponentProps<WithBatchId>,
  ) {
    const state = store.getState();
    const batch = state.batch;
    const regionId = state.currentRegionId;
    const batchWorkflowId = RouterProps.getBatchWorkflowId(props);
    const batchId = RouterProps.getBatchId(props);
    const query: BatchQuery = { regionId, batchWorkflowId, batchId };
    if (!objectEquals(query, batch.query)) {
      store.dispatch(BatchMutations.currentQuery(query));
    }

    if (batch.query && batch.loadRequired && !batch.error) {
      BatchDomain.load(batch.query.regionId, batch.query.batchWorkflowId, batch.query.batchId);
    }
    return batchId;
  }

  export function handleBatchModificationRoute(
    props: RouteComponentProps<WithBatchId>,
    forceReload?: boolean,
  ) {
    const state = store.getState(); // don't use state here to make this 'pure'
    const data = state.batchModification;
    const regionId = state.currentRegionId;
    const batchWorkflowId = RouterProps.getBatchWorkflowId(props);
    const batchId = RouterProps.getBatchId(props);
    const query: BatchQuery = { regionId, batchWorkflowId, batchId };
    if (!objectEquals(query, data.query)) {
      store.dispatch(BatchModificationMutations.setQuery(query));
    }

    if (data.query && (forceReload || (data.loadRequired && !data.error))) {
      BatchDomain.loadModificationData(data.query.regionId, data.query.batchWorkflowId, data.query.batchId);
    }
    return batchId;
  }

  export function handleBatchWorkflowRoute(
    props: RouteComponentProps<WithBatchWorkflowId>,
    previousProps?: RouteComponentProps<WithBatchWorkflowId>,
  ) {
    const batchWorkflowId = RouterProps.getBatchWorkflowId(props);
    const update = !previousProps || RouterProps.getBatchWorkflowId(previousProps) !== batchWorkflowId;
    if (update) {
      store.dispatch(BatchWorkflowMutations.currentId(batchWorkflowId));
    }

    const batchWorkflow = store.getState().batchWorkflow;
    if (batchWorkflow.query && batchWorkflow.loadRequired && !batchWorkflow.error) {
      BatchWorkflowActions.load(batchWorkflow.query);
    }
    return batchWorkflowId;
  }
}
