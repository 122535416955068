import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BatchWorkflowAdminActions } from '../../../actions/batch-workflow-admin.actions';
import { StateRoot } from '../../../store/interfaces';
import {
  BatchFieldSet,
  BatchWorkflow,
  BatchWorkflowModification,
  WorkflowFieldSet,
  WorkflowFieldSetSimple
} from '../../../store/interfaces/batch-workflow';
import { Loadable } from '../../../store/loader';
import { style } from './style';

interface Props {
  open: boolean;
  batchFieldSetIndex: number | undefined;
  batchFieldSet: BatchFieldSet | undefined;
  workflowId: number;
  onClose: (value?: WorkflowFieldSet) => void;
}

interface StateProps {
  data: Loadable<BatchWorkflowModification, BatchWorkflow>;
}

interface ViewProps {
  handleClose: () => void;
}

interface ContentViewProps {
  batchFieldSetLabel: string | undefined;
  currentFieldSet: WorkflowFieldSetSimple | undefined;
  repeatingFieldSets: WorkflowFieldSet[];
  handleSelect: (fieldSet: WorkflowFieldSet) => () => void;
  handleClose: () => void;
}

interface Triggers {
  loadFieldSets: (workflowId: number) => void;
}

class ChangeFieldSetDialogController extends React.Component<Props & StateProps & Triggers> {
  componentDidMount() {
    const { workflowId, loadFieldSets } = this.props;
    loadFieldSets(workflowId);
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleListItemClick = (value: WorkflowFieldSet) => () => {
    this.props.onClose(value);
  }

  render() {
    const { open, batchFieldSetIndex, batchFieldSet, data } = this.props;

    if (!data.value || (batchFieldSetIndex == null)) {
      return (
        <Dialog open={open} onClose={this.handleClose}>
          <ChangeFieldSetErrorView handleClose={this.handleClose} />
        </Dialog>
      );
    }

    const batchWorkflow: BatchWorkflow = data.value;
    const batchFieldSetData: BatchFieldSet = batchFieldSet ? batchFieldSet : batchWorkflow.fieldSets[batchFieldSetIndex];
    const repeatingFieldSets: WorkflowFieldSet[] = [];

    let batchFieldSetLabel: string | undefined;
    let currentFieldSet: WorkflowFieldSetSimple | undefined;

    if (batchFieldSetData) {
      batchFieldSetLabel = batchFieldSetData.label;
      if (batchFieldSetData.workflowData && batchFieldSetData.workflowData.fieldSet) {
        currentFieldSet = batchFieldSetData.workflowData.fieldSet;
      }
    }

    if (batchWorkflow.repeatingFieldSets) {
      for (const fieldSetIndex in batchWorkflow.repeatingFieldSets) {
        if (batchWorkflow.repeatingFieldSets.hasOwnProperty(fieldSetIndex)
          && (!currentFieldSet || (currentFieldSet.id !== Number(fieldSetIndex)))) {
          repeatingFieldSets.push(batchWorkflow.repeatingFieldSets[fieldSetIndex]);
        }
      }
    }

    return (
      <Dialog open={open} onClose={this.handleClose}>
        <ChangeFieldSetContentView
          batchFieldSetLabel={batchFieldSetLabel}
          currentFieldSet={currentFieldSet}
          repeatingFieldSets={repeatingFieldSets}
          handleClose={this.handleClose}
          handleSelect={this.handleListItemClick}
        />
      </Dialog>
    );
  }
}

const ChangeFieldSetErrorView = withTranslation()(({
  handleClose,
  t,
}: ViewProps & WithTranslation) => (
  <React.Fragment>
    <DialogTitle>{t('batchWorkflowEdit.general.unableToLoad')}</DialogTitle>
    <Button onClick={handleClose} color="primary" autoFocus={true}>{t('terms.close')}</Button>
  </React.Fragment>
));

const ChangeFieldSetContentView = withStyles(style)(withTranslation()(({
  batchFieldSetLabel,
  currentFieldSet,
  repeatingFieldSets,
  handleSelect,
  handleClose,
  classes,
  t,
}: ContentViewProps & WithStyles & WithTranslation) => (
  <React.Fragment>
    <DialogTitle className={classes.selectDialogTitleContainer}>
      <CloseIcon onClick={handleClose} className={classes.closeIconButton} />
      <Typography className={classes.selectDialogTitle}>
        {t('batchWorkflowEdit.general.batchFieldSet')} : Field Set
      </Typography>
      {currentFieldSet
        ?
        <Typography>
          {t('batchWorkflowEdit.selectFieldSet.labelCurrent')}<strong>{currentFieldSet.label}</strong>
        </Typography>
        : undefined
      }
      <Typography>
        {t('batchWorkflowEdit.selectFieldSet.title')}<strong>{batchFieldSetLabel}</strong>
      </Typography>
      <br />
    </DialogTitle>
    <List>
      {repeatingFieldSets.map(fieldSet => (
        <ListItem button={true} key={fieldSet.id} onClick={handleSelect(fieldSet)} className={classes.selectDialogItemButton}>
          <ListItemText primary={fieldSet.label} className={classes.selectDialogNameItem} />
        </ListItem>
      ))}
    </List>
  </React.Fragment>
)));

const mapStateToProps = (state: StateRoot): StateProps => ({
  data: state.batchWorkflowModification,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): Triggers => ({
  loadFieldSets: (workflowId: number) => dispatch(BatchWorkflowAdminActions.loadWorkflowFieldSets(workflowId)),
});

export const ChangeFieldSetDialog = connect(mapStateToProps, mapDispatchToProps)(ChangeFieldSetDialogController);
