import { ExtendedKeyboardEvent, MousetrapInstance } from 'mousetrap';
import React from 'react';

export interface WithHotKeys {
  bindHotKey: (key: KeyCombination, callback: (e: ExtendedKeyboardEvent, combo: string) => void) => void;
  unbindHotKey: (key: KeyCombination) => void;
}

export type KeyCombination = string | string[];

export function withHotKeys<P extends object>(Component: React.ComponentType<P & WithHotKeys>) {
  return class extends React.Component<P & WithHotKeys> {
    readonly mousetrap: MousetrapInstance;
    readonly bindings: KeyCombination[] = [];

    constructor(props: P & WithHotKeys) {
      super(props);

      this.mousetrap = require('mousetrap');
    }

    bindHotKey = (key: KeyCombination, callback: (e: ExtendedKeyboardEvent, combo: string) => void): void => {
      this.mousetrap.bind(key, callback);
      this.bindings.push(key);
    }

    unbindHotKey = (key: KeyCombination): void => {
      const index = this.bindings.indexOf(key);

      if (index > -1) {
        this.bindings.splice(index, 1);
      }

      this.mousetrap.unbind(key);
    }

    unbindAllHotKeys() {
      if (this.bindings.length < 1) {
        return;
      }

      this.bindings.forEach((binding) => {
        this.mousetrap.unbind(binding);
      });
      this.bindings.splice(0, this.bindings.length);
    }

    componentWillUnmount() {
      this.unbindAllHotKeys();
    }

    render() {
      return (
        <Component
          {...this.props}
          bindHotKey={this.bindHotKey}
          unbindHotKey={this.unbindHotKey}
        />
      );
    }
  };
}
