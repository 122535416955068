import { AppBar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import HamburgerIcon from '@material-ui/icons/Menu';
import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { StateRoot } from '../../store/interfaces';
import { SidebarMutations } from '../../store/mutations/sidebar.mutations';

export const style = (theme: Theme) => createStyles({
  menuButton: {
    marginLeft: -12,
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  root: {
    backgroundColor: 'white',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    position: 'fixed',
    top: 0,
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 112px)',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1168px',
    },
  },
});

interface Props {
  sideBarOpen: boolean;
}

interface TriggerProps {
  handleSidebarToggle: () => void;
}

const mapStateToProps = (state: StateRoot): Props => ({
  sideBarOpen: state.sideBarOpen,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): TriggerProps => ({
  handleSidebarToggle: () => dispatch(SidebarMutations.toggle()),
});

const ToolBarView = connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(({
  children,
  classes,
  handleSidebarToggle,
}: PropsWithChildren<Props> & TriggerProps & WithStyles) => (
    <Toolbar>
      <IconButton className={classes.menuButton} aria-label="Menu" onClick={handleSidebarToggle}>
        <HamburgerIcon />
      </IconButton>
      {children}
    </Toolbar>
  )));

export const AppBarView = withStyles(style)(({
  children,
  classes,
}: { children?: React.ReactNode } & WithStyles) => (
    <AppBar position="relative" color="default" classes={{ root: classes.root }}>
      {children}
    </AppBar>
  ));

export default ToolBarView;
