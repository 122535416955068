import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Select from 'react-select';
import { BatchFieldSet } from '../../../store/interfaces/batch-dashboard';
import { IndexOf } from '../../../store/types';
import { AscendingIcon, DescendingIcon } from './icons';
import { sortStyle } from './sort-style';

export interface ExternalProps {
  sortItem: SortItem | undefined;
  fields: IndexOf<BatchFieldSet> | undefined;
  index: number;
  excludeOptions: OptionFilter[];
  clearOnSelect: boolean;
  onSelectChange: (index: number, selectedOption: SortItem) => Promise<void>;
  onSelectRemove: (index: number) => Promise<void>;
  onToggleOrder: (index: number, ascending: boolean) => Promise<void>;
}

export interface ViewDataProps {
  sortItem: SortItem | null;
  fields: IndexOf<BatchFieldSet> | undefined;
  excludeOptions: OptionFilter[];
  selectOptions: GroupedOptions[];
  onSelectChange: (selectedOption: SortItem | null) => Promise<void>;
  onSelectRemove: () => Promise<void>;
  onToggleOrder: () => Promise<void>;
}

type ViewProps = ViewDataProps & WithStyles & WithTranslation;

export interface OptionFilter {
  fieldSetId: string | null;
  fieldId: string;
  label: string;
}

export interface SortItem {
  fieldSetId: string | null;
  fieldId: string;
  ascending: boolean;
  label: string;
  value: string;
}

export const defaultSortItem = (value: string): SortItem[] => ([
  {
    ascending: true,
    fieldId: 'created',
    fieldSetId: null,
    label: value,
    value,
  },
]);

export interface GroupedOptions {
  label: string;
  options: SortItem[];
}

type ControllerProps = ExternalProps;

class BatchDashboardSortRowController extends React.PureComponent<ControllerProps> {
  changeSelectHandler = async (selectedOption: SortItem | null) => {
    if (!selectedOption) return;
    await this.props.onSelectChange(this.props.index, selectedOption);
  }

  deleteSortHandler = async () => {
    await this.props.onSelectRemove(this.props.index);
  }

  toggleOrderHandler = async () => {
    if (!this.props.sortItem) return;
    await this.props.onToggleOrder(this.props.index, !this.props.sortItem.ascending);
  }

  render() {
    const fields = this.props.fields;
    if (!fields) return null;

    const selectOptions: GroupedOptions[] = Object.keys(fields).filter((fieldSetId) => {
      return fields[fieldSetId].type === 'PerBatchFieldSet';
    }).map((fieldSetId) => {
      return {
        label: fields[fieldSetId].label,
        options: Object.keys(fields[fieldSetId].fields).map((fieldId) => {
          return {
            ascending: true,
            fieldId: fieldId,
            fieldSetId: fieldSetId,
            label: fields[fieldSetId].fields[fieldId].label,
            value: fieldId,
          };
        }).filter((option) => {
          const fieldSet = fields[fieldSetId];
          return fieldSet.type === 'PerBatchFieldSet'
            && fieldSet.fields[option.fieldId].dashboardVisible
            && !this.props.excludeOptions.find((exclude) =>
              option !== null && exclude.fieldSetId === option.fieldSetId && exclude.fieldId === option.fieldId
            );
        }),
      };
    });

    return (
      <BatchDashboardSortRowView
        fields={this.props.fields}
        onSelectChange={this.changeSelectHandler}
        onSelectRemove={this.deleteSortHandler}
        onToggleOrder={this.toggleOrderHandler}
        sortItem={this.props.sortItem || null}
        excludeOptions={this.props.excludeOptions}
        selectOptions={selectOptions}
      />
    );
  }
}

export const BatchDashboardSortRowView = withStyles(sortStyle)(withTranslation()(({
  classes,
  fields,
  sortItem,
  t,
  onSelectChange,
  excludeOptions,
  onSelectRemove,
  selectOptions,
  onToggleOrder,
}: ViewProps) => {
  if (!fields) return null;
  const selectName = sortItem ? `sortSelect-${sortItem.fieldSetId}-${sortItem.fieldId}` : 'sortSelect-new';

  const batchSortOptions: SortItem[] = [
    {
      ascending: true,
      fieldId: 'created',
      fieldSetId: null,
      label: t('batchWorkflowDashboard.batchSort.created'),
      value: t('batchWorkflowDashboard.batchSort.created'),
    },
    {
      ascending: true,
      fieldId: 'status',
      fieldSetId: null,
      label: t('batchWorkflowDashboard.columns.status'),
      value: t('batchWorkflowDashboard.columns.status'),
    },
    {
      ascending: true,
      fieldId: 'statusChanged',
      fieldSetId: null,
      label: t('batchWorkflowDashboard.batchSort.statusChanged'),
      value: t('batchWorkflowDashboard.batchSort.statusChanged'),
    },
  ];
  selectOptions.unshift({
    label: 'Batch',
    options: batchSortOptions.filter((option) => !excludeOptions.find((exclude) =>
      exclude.label === option.label
    )),
  });

  const formatGroupLabel = (data: GroupedOptions) => (
    <div className={classes.groupStyles}>
      <span>{data.label}</span>
      <span className={classes.groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <Grid container={true} spacing={2} className={classes.sortContainer}>
      <Grid item={true} xs={true}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            value={sortItem}
            onChange={onSelectChange}
            name={selectName}
            options={selectOptions}
            className={classes.select}
            formatGroupLabel={formatGroupLabel}
            placeholder={excludeOptions.length ? 'Then...' : 'Choose a field'}
          />
          {sortItem
            ? <span className={classes.fieldSetNameContainer}>{sortItem.fieldSetId ? fields[sortItem.fieldSetId].label : 'Batch'}</span>
            : null
          }
        </FormControl>
      </Grid>
      {sortItem
        ? <Grid item={true} xs={true}>
          {sortItem.ascending
            ? <IconButton aria-label="Ascending" onClick={onToggleOrder}>
              <AscendingIcon />
            </IconButton>
            : <IconButton aria-label="Descending" onClick={onToggleOrder}>
              <DescendingIcon />
            </IconButton>
          }
          <IconButton aria-label="Delete" onClick={excludeOptions.length === 1 ? undefined : onSelectRemove}>
            <DeleteIcon fontSize="small" color={excludeOptions.length === 1 ? 'disabled' : 'action'} />
          </IconButton>
        </Grid>
        : <Grid item={true} xs={true} />
      }
    </Grid>
  );
}
));

export const BatchDashboardSortRowScene = BatchDashboardSortRowController;
