import { Card, CardHeader, FormControl, InputLabel, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragHandleRoundedIcon from '@material-ui/icons/DragHandleRounded';
import EditIcon from '@material-ui/icons/Edit';
import { WithT } from 'i18next';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import Select from 'react-select';
import { InputTextField } from '../../../components/input-text-field';
import { PerBatchFieldSource } from '../../../domain/batch-workflow/dto/batch-workflows-and-fields';
import { BatchField, BatchFieldSet, WorkflowField, WorkflowFieldData } from '../../../store/interfaces/batch-workflow';
import { BatchFieldSourceList } from './batch-field-source-list';
import { RemovableBatchComponent, SelectFieldItem } from './batch-workflow-admin';
import { BatchWorkflowNewObjectData } from './batch-workflow.new-objects';
import { BatchFieldViewValidation, BatchWorkflowValidation } from './batch-workflow.validation';
import { ChangeFieldDialog } from './change-field';
import { style } from './style';

interface BatchFieldInput {
  batchFieldSet: BatchFieldSet;
  batchField?: BatchField;
  fieldValidation?: BatchFieldViewValidation;
  toggleInputDialog: () => void;
}

const BatchFieldFieldInput: React.FunctionComponent<BatchFieldInput & WithStyles & WithT> = ({
  batchFieldSet,
  batchField,
  fieldValidation,
  toggleInputDialog,
  classes,
  t,
}) => {

  let disabled: string;

  if (batchFieldSet.__typename === 'PerDocumentHeaderFieldSet') {
    disabled = !batchFieldSet.workflowData || !batchFieldSet.workflowData.id
      ? ' ' + classes.disabled
      : '';
  } else {
    disabled = !batchFieldSet.workflowData || !batchFieldSet.workflowData.id || !batchFieldSet.workflowData.fieldSet
      ? ' ' + classes.disabled
      : '';
  }

  const fieldValidationStyle = fieldValidation && fieldValidation.field
    ? classes.batchFieldSetViewValidation
    : undefined;

  return (
    <div className={classes.batchFieldInputDiv}>
      {
        batchField && batchField.field
          ? <EditIcon
            className={`${classes.batchFieldSetInputActionButton}${disabled}`}
            onClick={
              disabled === ''
                ? toggleInputDialog
                : undefined
            }
          />
          : <AddIcon
            className={`${classes.batchFieldSetInputActionButton}${disabled}`}
            onClick={
              disabled === ''
                ? toggleInputDialog
                : undefined
            }
          />
      }
      &nbsp;
      <b className={fieldValidationStyle}>{t('batchWorkflowEdit.general.field')}:</b> &nbsp;
      {
        batchField && batchField.field
          ? batchField.field.name
          : undefined
      }
    </div>
  );
};

interface ControllerProps {
  batchFieldSetIndex: number;
  batchFieldSet: BatchFieldSet;
  batchFieldIndex: number;
  batchField: BatchField;
  newObjectData?: BatchWorkflowNewObjectData;
  validationData?: BatchWorkflowValidation;
  handleBatchFieldChange: (batchField: BatchField, index: number, notImportant?: boolean) => void;
  onDeleteBatchComponent: (removableBatchComponent: RemovableBatchComponent) => void;
}

interface LocalState {
  changeFieldDialogAnchorOpen: boolean;
}

class BatchFieldController extends React.PureComponent<ControllerProps, LocalState> {
  constructor(props: ControllerProps) {
    super(props);

    this.state = {
      changeFieldDialogAnchorOpen: false,
    };
  }

  handleBatchFieldLabelChange = (value: string) => {
    this.props.handleBatchFieldChange({ ...this.props.batchField, label: value }, this.props.batchFieldIndex);
  }

  toggleInputDialog = () => {
    this.setState({ changeFieldDialogAnchorOpen: !this.state.changeFieldDialogAnchorOpen });
  }

  render() {
    const { batchFieldSetIndex, batchFieldSet, batchFieldIndex, batchField, newObjectData, validationData,
      handleBatchFieldChange, onDeleteBatchComponent } = this.props;

    function handleDeleteBatchField(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
      event.preventDefault();
      onDeleteBatchComponent({
        batchFieldIndex: batchFieldIndex,
        batchFieldSetIndex: batchFieldSetIndex,
        type: 'batchField',
      });
    }

    function handleBatchFieldDataTypeChange(selectedOption: SelectFieldItem | null) {
      if (selectedOption === null) return;

      handleBatchFieldChange({ ...batchField, dataType: selectedOption.value }, batchFieldIndex);
    }

    function handleBatchFieldFieldChange(workflowFieldData: WorkflowFieldData) {
      handleBatchFieldChange({ ...batchField, field: workflowFieldData, sources: undefined }, batchFieldIndex);
    }

    function handleBatchFieldSourcesChange(perBatchFieldSources: PerBatchFieldSource[], sourcesOpen?: boolean, notImportant?: boolean) {
      const newBatchField = { ...batchField, field: undefined, sources: perBatchFieldSources };
      if (sourcesOpen !== undefined) {
        newBatchField.sourcesOpen = sourcesOpen;
      }
      handleBatchFieldChange(newBatchField, batchFieldIndex, notImportant);
    }

    return (
      <BatchFieldView
        batchFieldSetIndex={batchFieldSetIndex}
        batchFieldSet={batchFieldSet}
        batchFieldIndex={batchFieldIndex}
        batchField={batchField}
        newObjectData={newObjectData}
        validationData={validationData}
        changeFieldDialogAnchorOpen={this.state.changeFieldDialogAnchorOpen}
        handleBatchFieldLabelChange={this.handleBatchFieldLabelChange}
        handleBatchFieldDataTypeChange={handleBatchFieldDataTypeChange}
        handleBatchFieldFieldChange={handleBatchFieldFieldChange}
        handleBatchFieldSourcesChange={handleBatchFieldSourcesChange}
        handleDeleteBatchField={handleDeleteBatchField}
        onDeleteBatchComponent={onDeleteBatchComponent}
        toggleInputDialog={this.toggleInputDialog}
      />
    );
  }
}

interface ViewProps {
  batchFieldSetIndex: number;
  batchFieldSet: BatchFieldSet;
  batchFieldIndex: number;
  batchField: BatchField;
  newObjectData?: BatchWorkflowNewObjectData;
  validationData?: BatchWorkflowValidation;
  changeFieldDialogAnchorOpen: boolean;
  handleBatchFieldLabelChange: (value: string) => void;
  handleBatchFieldDataTypeChange: (selectedOption: SelectFieldItem | null) => void;
  handleBatchFieldFieldChange: (batchFieldField: WorkflowFieldData) => void;
  handleBatchFieldSourcesChange: (perBatchFieldSources: PerBatchFieldSource[], sourcesOpen?: boolean, notImportant?: boolean) => void;
  handleDeleteBatchField: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onDeleteBatchComponent: (removableBatchComponent: RemovableBatchComponent) => void;
  toggleInputDialog: () => void;
}

type AllViewProps = ViewProps & WithTranslation & WithStyles;

const BatchFieldView = withStyles(style)(withTranslation()(({
  batchFieldSetIndex,
  batchFieldSet,
  batchFieldIndex,
  batchField,
  newObjectData,
  validationData,
  changeFieldDialogAnchorOpen,
  handleBatchFieldLabelChange,
  handleBatchFieldDataTypeChange,
  handleBatchFieldFieldChange,
  handleBatchFieldSourcesChange,
  handleDeleteBatchField,
  onDeleteBatchComponent,
  toggleInputDialog,
  classes,
  t,
}: AllViewProps) => {

  function handleChangeFieldDialogHide(value?: WorkflowField) {
    toggleInputDialog();
    if (value) {
      const workflowFieldData: WorkflowFieldData = {
        id: value.id,
        name: value.name,
      };
      handleBatchFieldFieldChange(workflowFieldData);
    }
  }

  const workflowId = batchFieldSet && batchFieldSet.workflowData
    ? batchFieldSet.workflowData.id
    : undefined;

  const selectedDataType = batchField.dataType ?
    {
      label: batchField.dataType,
      value: batchField.dataType,
    }
    : undefined;

  const dataTypeOptions = [
    {
      label: 'String',
      value: 'String',
    },
    {
      label: 'Text',
      value: 'Text',
    },
    {
      label: 'Number',
      value: 'Number',
    },
  ];

  const batchFieldDivRef = React.useRef<HTMLDivElement>(null);
  const thisBatchFieldError = Boolean(
    validationData &&
    validationData.batchFieldSetValidation &&
    (validationData.batchFieldSetValidation.id === batchFieldSetIndex) &&
    validationData.batchFieldSetValidation.batchFieldValidation &&
    (validationData.batchFieldSetValidation.batchFieldValidation.id === batchFieldIndex) &&
    !validationData.batchFieldSetValidation.batchFieldValidation.batchFieldSourceValidation
  );

  const thisBatchFieldIsNew = Boolean(
    newObjectData &&
    newObjectData.newBatchFieldSet &&
    (newObjectData.newBatchFieldSet.id === batchFieldSetIndex) &&
    newObjectData.newBatchFieldSet.newBatchField &&
    (newObjectData.newBatchFieldSet.newBatchField.id === batchFieldIndex)
  );

  React.useEffect(() => {
    if (batchFieldDivRef.current && (thisBatchFieldError || thisBatchFieldIsNew)) {
      BatchWorkflowValidation.scrollToValidationFailure(batchFieldDivRef.current);
    }
  });

  let batchFieldLabelError: boolean = false;
  let batchFieldLabelErrorMessage: string | undefined;
  let batchFieldViewValidation: BatchFieldViewValidation | undefined;
  if (thisBatchFieldError) {
    const batchFieldValidation = validationData!.batchFieldSetValidation!.batchFieldValidation!;
    const errorMessage = validationData!.errorMessageComponent || undefined;

    if (batchFieldValidation.label) {
      batchFieldLabelError = true;
      batchFieldLabelErrorMessage = errorMessage;
    } else if (batchFieldValidation.field) {
      batchFieldViewValidation = { field: true, errorMessage };
    }
  }
  if (thisBatchFieldIsNew) {
    batchFieldLabelErrorMessage = newObjectData!.messageComponent || undefined;
  }

  return (
    <Draggable draggableId={`batchField-${batchFieldSetIndex}-${batchFieldIndex}`} index={batchFieldIndex}>
      {(draggableProvided) => (
        <Grid item={true} xs={12}>
          <div ref={batchFieldDivRef} />
          <div
            className="draggable"
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
          >
            <Card className={classes.batchField}>
              <CardHeader
                className={classes.fieldSetCardHeader}
                title={
                  <React.Fragment>
                    <span className={classes.batchFieldCardTitle}>
                      {t('batchWorkflowEdit.general.batchField')}
                    </span>
                    &nbsp;&nbsp;
                    <span className={classes.batchFieldId}>{batchField.id}</span>
                    <Tooltip title={t('batchWorkflowEdit.actions.removeBatchField')!}>
                      <DeleteOutlineIcon
                        onClick={handleDeleteBatchField}
                        className={classes.pullRight + ' ' + classes.deleteIconButton}
                      />
                    </Tooltip>
                  </React.Fragment>
                }
              />
              <div className={classes.paddedBatchFieldCard}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={3}>
                    <FormControl
                      variant="outlined"
                      className={classes.fieldSetFormControl}
                    >
                      <InputTextField
                        id={`batchFieldName-${batchFieldSetIndex}-${batchFieldIndex}`}
                        className={classes.textFieldBatchFieldLabel}
                        label="Label"
                        textValue={batchField.label}
                        textValueError={batchFieldLabelError}
                        textValueErrorMessage={batchFieldLabelErrorMessage}
                        onChange={handleBatchFieldLabelChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl variant="outlined" className={classes.formControlBatchFieldDataType}>
                      <InputLabel
                        className={classes.batchFieldSourceSourceLabel}
                        shrink={true}
                        variant={'outlined'}
                      >
                        {t('batchWorkflowEdit.general.dataType')}
                      </InputLabel>
                      <Select
                        value={selectedDataType}
                        onChange={handleBatchFieldDataTypeChange}
                        name={`batchFieldDataType-${batchFieldSetIndex}-${batchFieldIndex}`}
                        options={dataTypeOptions}
                        className={classes.fieldSetTypeSelect}
                        placeholder={t('batchWorkflowEdit.placeHolder.dataType')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={6} className={classes.batchFieldSecondColumn}>
                    {
                      batchFieldSet.__typename === 'PerBatchFieldSet'
                        ? <BatchFieldSourceList
                          batchFieldSetIndex={batchFieldSetIndex}
                          batchFieldIndex={batchFieldIndex}
                          batchField={batchField}
                          batchFieldSources={batchField.sources}
                          validationData={validationData}
                          handleBatchFieldSourcesChange={handleBatchFieldSourcesChange}
                          onDeleteBatchComponent={onDeleteBatchComponent}
                        />
                        : <BatchFieldFieldInput
                          batchFieldSet={batchFieldSet}
                          batchField={batchField}
                          fieldValidation={batchFieldViewValidation}
                          toggleInputDialog={toggleInputDialog}
                          classes={classes}
                          t={t}
                        />
                    }
                  </Grid>
                </Grid>
              </div>
              <div className={classes.dragHandle} {...draggableProvided.dragHandleProps}>
                <Tooltip title={t('batchWorkflowEdit.manipulations.moveBatchField')!}>
                  <div className="circle">
                    <DragHandleRoundedIcon />
                  </div>
                </Tooltip>
              </div>
            </Card>
            {
              workflowId !== undefined
                && changeFieldDialogAnchorOpen
                ? <ChangeFieldDialog
                  onClose={handleChangeFieldDialogHide}
                  open={true}
                  workflowId={workflowId}
                  batchFieldSetIndex={batchFieldSetIndex}
                  batchField={batchField}
                  batchFieldSourceIndex={undefined}
                  batchFieldSource={undefined}
                  batchFieldSetFieldSetId={
                    batchFieldSet.workflowData
                      && batchFieldSet.workflowData.fieldSet
                      ? batchFieldSet.workflowData.fieldSet.id
                      : undefined
                  }
                  type={batchFieldSet.__typename === 'PerDocumentHeaderFieldSet' ? 'header' : 'repeating'}
                />
                : undefined
            }
          </div>
        </Grid>
      )}
    </Draggable>
  );
}));

export const BatchFieldScene = BatchFieldController;
