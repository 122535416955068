import { TFunction } from 'i18next';
import { PerBatchFieldSource } from '../../../domain/batch-workflow/dto/batch-workflows-and-fields';
import { BatchField, BatchFieldSet, BatchWorkflow } from '../../../store/interfaces/batch-workflow';

export interface BatchWorkflowValidation {
  errorMessageComponent?: string;
  errorMessageGlobal?: string;
  generalSettingsValidation?: BatchWorkflowGeneralSettingsValidation;
  batchFieldSetValidation?: BatchFieldSetValidation;
}

export interface BatchWorkflowGeneralSettingsValidation {
  batchWorkflowName?: boolean;
}

export interface BatchFieldSetValidation {
  id: number;
  label?: boolean;
  workflow?: boolean;
  fieldSet?: boolean;
  batchFieldValidation?: BatchFieldValidation;
}

export interface BatchFieldValidation {
  id: number;
  label?: boolean;
  field?: boolean;
  batchFieldSourceValidation?: BatchFieldSourceValidation;
}

export interface BatchFieldSourceValidation {
  id: number;
  workflow?: boolean;
  field?: boolean;
}

export interface BatchFieldSetViewValidation {
  workflow?: boolean;
  fieldSet?: boolean;
  errorMessage?: string;
}

export interface BatchFieldViewValidation {
  field?: boolean;
  errorMessage?: string;
}

export interface BatchFieldSourceViewValidation {
  workflow?: boolean;
  field?: boolean;
  errorMessage?: string;
}

export namespace BatchWorkflowValidation {
  function validateBatchFieldSources(
    batchFieldSetIndex: number,
    batchFieldIndex: number,
    sources: PerBatchFieldSource[] | undefined,
    t: TFunction
  ): BatchWorkflowValidation | undefined {
    if (!sources) {
      return undefined;
    }
    for (let i = 0; i < sources.length; i++) {
      let source = sources[i];
      if (!source.field || !source.field.workflowData || !source.field.workflowData.id) {
        return {
          batchFieldSetValidation: {
            batchFieldValidation: { id: batchFieldIndex, batchFieldSourceValidation: { id: i, workflow: true } },
            id: batchFieldSetIndex,
          },
          errorMessageComponent: t('batchWorkflowEdit.validation.workflow'),
          errorMessageGlobal: t('batchWorkflowEdit.validation.batchFieldSourceWorkflow'),
        };
      }
      if (!source.field.id) {
        return {
          batchFieldSetValidation: {
            batchFieldValidation: { id: batchFieldIndex, batchFieldSourceValidation: { id: i, field: true } },
            id: batchFieldSetIndex,
          },
          errorMessageComponent: t('batchWorkflowEdit.validation.field'),
          errorMessageGlobal: t('batchWorkflowEdit.validation.batchFieldSourceField'),
        };
      }
    }
    return undefined;
  }

  type IndexedLabel = { index: number, label: string };

  function validateBatchFields(
    batchFieldSetIndex: number,
    batchFieldSetType: string,
    batchFields: BatchField[],
    t: TFunction
  ): BatchWorkflowValidation | undefined {
    const ids = new Map<string, IndexedLabel>();
    for (let i = 0; i < batchFields.length; i++) {
      let batchField = batchFields[i];
      if (!batchField.isNew) {
        ids.set(batchField.id.toLowerCase(), { index: i, label: batchField.label });
      }
    }
    for (let i = 0; i < batchFields.length; i++) {
      let batchField = batchFields[i];
      if (batchField.label.trim().length === 0) {
        return {
          batchFieldSetValidation: { id: batchFieldSetIndex, batchFieldValidation: { id: i, label: true } },
          errorMessageComponent: t('batchWorkflowEdit.validation.label'),
          errorMessageGlobal: t('batchWorkflowEdit.validation.batchFieldLabel'),
        };
      }
      if (batchField.isNew) {
        const currentBatchFieldIndex = batchField.id.toLowerCase();
        let indexedLabel: IndexedLabel | undefined = ids.get(currentBatchFieldIndex);
        if (indexedLabel === undefined) {
          for (let j = 0; j < i; j++) {
            let batchFieldTop = batchFields[j];
            if (batchFieldTop.isNew && (batchFieldTop.id.toLowerCase() === currentBatchFieldIndex)) {
              indexedLabel = { index: j, label: batchFieldTop.label };
              break;
            }
          }
        }
        if (indexedLabel !== undefined) {
          return {
            batchFieldSetValidation: { id: batchFieldSetIndex, batchFieldValidation: { id: i, label: true } },
            errorMessageComponent: t('batchWorkflowEdit.validation.duplicateIdentifier'),
            errorMessageGlobal:
              t('batchWorkflowEdit.validation.batchFieldDuplicateIdentifier' + (indexedLabel.index < i ? 'Above' : 'Below'),
                { label: batchField.label, batchField: indexedLabel.label }),
          };
        }
      }
      if (batchFieldSetType !== 'PerBatchFieldSet') {
        if (!batchField.field || !batchField.field.id) {
          return {
            batchFieldSetValidation: { id: batchFieldSetIndex, batchFieldValidation: { id: i, field: true } },
            errorMessageComponent: t('batchWorkflowEdit.validation.field'),
            errorMessageGlobal: t('batchWorkflowEdit.validation.batchFieldField', { batchField: batchField.label }),
          };
        }
      } else {
        let validationResult: BatchWorkflowValidation | undefined = validateBatchFieldSources(batchFieldSetIndex, i, batchField.sources, t);
        if (validationResult) {
          return validationResult;
        }
      }
    }
    return undefined;
  }

  function validateBatchFieldSets(batchFieldSets: BatchFieldSet[], t: TFunction): BatchWorkflowValidation | undefined {
    const ids = new Map<string, IndexedLabel>();
    for (let i = 0; i < batchFieldSets.length; i++) {
      let batchFieldSet = batchFieldSets[i];
      if (!batchFieldSet.isNew) {
        ids.set(batchFieldSet.id.toLowerCase(), { index: i, label: batchFieldSet.label });
      }
    }
    for (let i = 0; i < batchFieldSets.length; i++) {
      let batchFieldSet = batchFieldSets[i];
      if (batchFieldSet.label.trim().length === 0) {
        return {
          batchFieldSetValidation: { id: i, label: true },
          errorMessageComponent: t('batchWorkflowEdit.validation.label'),
          errorMessageGlobal: t('batchWorkflowEdit.validation.batchFieldSetLabel'),
        };
      }
      if (batchFieldSet.isNew) {
        const currentBatchFieldSetIndex = batchFieldSet.id.toLowerCase();
        let indexedLabel: IndexedLabel | undefined = ids.get(currentBatchFieldSetIndex);
        if (indexedLabel === undefined) {
          for (let j = 0; j < i; j++) {
            let batchFieldSetTop = batchFieldSets[j];
            if (batchFieldSetTop.isNew && (batchFieldSetTop.id.toLowerCase() === currentBatchFieldSetIndex)) {
              indexedLabel = { index: j, label: batchFieldSetTop.label };
              break;
            }
          }
        }
        if (indexedLabel !== undefined) {
          return {
            batchFieldSetValidation: { id: i, label: true },
            errorMessageComponent: t('batchWorkflowEdit.validation.duplicateIdentifier'),
            errorMessageGlobal:
              t('batchWorkflowEdit.validation.batchFieldSetDuplicateIdentifier' + (indexedLabel.index < i ? 'Above' : 'Below'),
                { label: batchFieldSet.label, batchFieldSet: indexedLabel.label }),
          };
        }
      }
      if (batchFieldSet.__typename !== 'PerBatchFieldSet') {
        if (!batchFieldSet.workflowData || !batchFieldSet.workflowData.id) {
          return {
            batchFieldSetValidation: { id: i, workflow: true },
            errorMessageComponent: t('batchWorkflowEdit.validation.workflow'),
            errorMessageGlobal: t('batchWorkflowEdit.validation.batchFieldSetWorkflow', { batchFieldSet: batchFieldSet.label }),
          };
        }
        if (batchFieldSet.__typename === 'PerDocumentRepeatingFieldSet') {
          if (!batchFieldSet.workflowData.fieldSet || !batchFieldSet.workflowData.fieldSet.id) {
            return {
              batchFieldSetValidation: { id: i, fieldSet: true },
              errorMessageComponent: t('batchWorkflowEdit.validation.fieldSet'),
              errorMessageGlobal: t('batchWorkflowEdit.validation.batchFieldSetFieldSet', { batchFieldSet: batchFieldSet.label }),
            };
          }
        }
      }
      let validationResult: BatchWorkflowValidation | undefined = validateBatchFields(i, batchFieldSet.__typename, batchFieldSet.fields, t);
      if (validationResult) {
        return validationResult;
      }
    }
    return undefined;
  }

  function validateGeneralSettings(batchWorkflow: BatchWorkflow, t: TFunction): BatchWorkflowValidation | undefined {
    if (batchWorkflow.name.trim().length === 0) {
      return {
        errorMessageComponent: t('batchWorkflowEdit.validation.batchWorkflowNameComponent'),
        errorMessageGlobal: t('batchWorkflowEdit.validation.batchWorkflowNameGlobal'),
        generalSettingsValidation: { batchWorkflowName: true },
      };
    }
    return undefined;
  }

  export function validateBatchWorkflow(batchWorkflow: BatchWorkflow, t: TFunction): BatchWorkflowValidation | undefined {
    let validationResult: BatchWorkflowValidation | undefined;

    validationResult = validateGeneralSettings(batchWorkflow, t);
    if (validationResult) {
      return validationResult;
    }
    validationResult = validateBatchFieldSets(batchWorkflow.fieldSets, t);
    if (validationResult) {
      return validationResult;
    }
    return undefined;
  }

  export function scrollToValidationFailure(div: HTMLDivElement, offset: number = 75) {
    const pos = div.style.position;
    const top = div.style.top;
    div.style.position = 'relative';
    div.style.top = '-' + offset + 'px';
    div.scrollIntoView({ behavior: 'smooth', block: 'start' });
    div.style.top = top;
    div.style.position = pos;
  }

  export function camelize(str: string) {
    // Lower cases the string
    return str.toLowerCase()
      // Replaces any - or _ characters with a space
      .replace(/[-_]+/g, ' ')
      // Removes any non alphanumeric characters
      .replace(/[^\w\s]/g, '')
      // Uppercases the first character in each group immediately following a space (delimited by spaces)
      .replace(/ (.)/g, (match: string) => (match.toUpperCase()))
      // Removes spaces
      .replace(/ /g, '');
  }
}
