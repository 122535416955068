import { UpdateAppButton } from './components/update-app-button';
import store from './store';
import { AppMutations } from './store/mutations/app.mutations';

// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const HOUR = 60 * 60 * 1000;

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  window.setInterval(
    () => {
      console.log('Starting check for app updates'); // tslint:disable-line:no-console
      navigator.serviceWorker.getRegistrations()
        .then((registrations) => {
          registrations.forEach((registration) => {
            console.log('Checking registration for app updates', registration); // tslint:disable-line:no-console
            registration.update();
          });
        })
        .catch(console.error); // tslint:disable-line:no-console
    },
    HOUR
  );
}

export default function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      navigator.serviceWorker
        .register(swUrl)
        .then(registration => {

          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (!installingWorker) {
              return;
            }

            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // At this point, the old content will have been purged and
                  // the fresh content will have been added to the cache.
                  // It's the perfect time to display a "New content is
                  // available; please refresh." message in your web app.
                  console.log('New content is available; please refresh.'); // tslint:disable-line
                  store.dispatch(AppMutations.setSnackbarMessage({
                    action: <UpdateAppButton />,
                    messageTranslationKey: 'app.updateAvailable',
                    variant: 'info',
                  }));
                } else {
                  // At this point, everything has been precached.
                  // It's the perfect time to display a
                  // "Content is cached for offline use." message.
                  console.log('Content is cached for offline use.'); // tslint:disable-line
                }
              }
            };
          };
        })
        .catch(error => {
          console.error('Error during service worker registration:', error); // tslint:disable-line
        });
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
