// tslint:disable:no-any

import cx from 'classnames';
import React from 'react';
import { withRouter, NavLink, RouteComponentProps } from 'react-router-dom';

// @material-ui/core components
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import { sidebarStyle } from './styles';

import { connect } from 'react-redux';
import { StateRoot } from '../../store/interfaces';
import { SidebarMutations } from '../../store/mutations/sidebar.mutations';
import SidebarWrapper from './components/SidebarWrapper';
import Region from './region';
import User from './user';

export type MenuRoute = MenuHeaderRoute | MenuPathRoute;

export interface MenuHeaderRoute {
  type: 'header';
  icon: React.ComponentType<SvgIconProps>;
  name: React.ReactNode;
  redirect?: false;
  state?: any;
  children: SubMenuRoute[];
}

export interface MenuPathRoute {
  type: 'link';
  icon: React.ComponentType<SvgIconProps>;
  matchPath?: RegExp;
  name: React.ReactNode;
  path: string;
}

export interface SubMenuRoute {
  name: React.ReactNode;
  matchPath?: RegExp;
  mini: React.ReactNode;
  redirect?: boolean;
  path: string;
  pathTo?: string;
}

interface Props {
  classes: any;
  logo: string;
  logoText: string;
  miniActive: boolean;
  open?: boolean;
  routes: MenuRoute[];
}

interface StateProps {
  open?: boolean;
}

interface TriggerProps {
  handleDrawerClose: () => void;
  handleDrawerToggle: () => void;
}

interface State {
  miniActive: boolean;
}

class Sidebar extends React.PureComponent<Props & RouteComponentProps & StateProps & TriggerProps, State> {
  constructor(props: Props & RouteComponentProps & StateProps & TriggerProps) {
    super(props);
    this.state = {
      miniActive: true,
    };
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  public activeRoute(route: MenuRoute): boolean {
    switch (route.type) {
      case 'link':
        return route.matchPath
          ? route.matchPath.test(this.props.location.pathname)
          : this.props.location.pathname === route.path;
      case 'header':
        return route.children.some(this.activeSubRoute.bind(this));
      default:
        return false;
    }
  }

  public activeSubRoute(route: SubMenuRoute) {
    return route.matchPath
      ? route.matchPath.test(this.props.location.pathname)
      : this.props.location.pathname === route.path;
  }

  public readonly setMini = (active: boolean) => () => {
    this.setState({ miniActive: active });
  }

  public readonly openCollapse = (collapse: keyof State) => () => {
    // tslint:disable-next-line:no-any
    this.setState({ [collapse]: !this.state[collapse] } as any);
  }

  public render() {
    const {
      classes,
      logo,
      logoText,
      routes,
    } = this.props;

    const miniActive = this.props.miniActive && this.state.miniActive;

    const brand = (mini: boolean) => {
      const logoNormal = cx({
        [classes.logoNormal]: true,
        [classes.logoNormalSidebarMini]: mini,
      });
      const logoMini = classes.logoMini;
      const logoClasses = classes.logo;
      return (
        <div className={logoClasses}>
          <NavLink to="/" className={logoMini}>
            <img src={logo} alt="logo" className={classes.img} />
          </NavLink>
          <NavLink to="/" className={logoNormal}>
            {logoText}
          </NavLink>
        </div>
      );
    };

    const links = (mini: boolean) => {
      return (
        <List className={classes.list}>
          {routes.map((prop: MenuPathRoute | MenuHeaderRoute, key) => {
            if (prop.type === 'header') {
              const navLinkClasses1 = cx({
                [classes.itemLink]: true,
                [classes.collapseActive]: this.activeRoute(prop),
              });
              const itemText2 = cx({
                [classes.itemText]: true,
                [classes.itemTextMini]: mini,
              });
              const collapseItemText2 = cx({
                [classes.collapseItemText]: true,
                [classes.collapseItemTextMini]: mini,
              });
              return (
                <ListItem key={key} className={classes.item}>
                  <NavLink
                    to={'#'}
                    className={navLinkClasses1}
                    onClick={this.openCollapse(prop.state)}
                  >
                    <ListItemIcon className={classes.itemIcon}>
                      <prop.icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={prop.name}
                      secondary={
                        <b
                          className={cx({
                            [classes.caret]: true,
                            [classes.caretActive]: this.state[prop.state],
                          })}
                        />
                      }
                      disableTypography={true}
                      className={itemText2}
                    />
                  </NavLink>
                  <Collapse in={this.state[prop.state]} unmountOnExit={true}>
                    <List className={classes.list + ' ' + classes.collapseList}>
                      {prop.children && prop.children.map((view, viewIndex) => {
                        if (view.redirect) {
                          return null;
                        }
                        const navLinkClasses2 = cx({
                          [classes.collapseItemLink]: true,
                          [classes.cssvar]: this.activeSubRoute(view),
                        });
                        return (
                          <ListItem key={viewIndex} className={classes.collapseItem}>
                            <NavLink
                              to={view.path}
                              className={navLinkClasses2}
                              onClick={this.props.handleDrawerClose}
                            >
                              <span className={classes.collapseItemMini}>
                                {view.mini}
                              </span>
                              <ListItemText
                                primary={view.name}
                                disableTypography={true}
                                className={collapseItemText2}
                              />
                            </NavLink>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </ListItem>
              );
            }
            const navLinkClasses = cx({
              [classes.itemLink]: true,
              [classes.cssvar]: this.activeRoute(prop),
            });
            const itemText1 = cx({
              [classes.itemText]: true,
              [classes.itemTextMini]: mini,
            });
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink
                  to={prop.path}
                  className={navLinkClasses}
                  onClick={this.props.handleDrawerClose}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    disableTypography={true}
                    className={itemText1}
                  />
                </NavLink>
              </ListItem>
            );
          })}
        </List>
      );
    };

    const drawerPaper = cx({
      [classes.drawerPaper]: true,
      [classes.drawerPaperMini]: miniActive,
    });
    const sidebarWrapper = cx({
      [classes.sidebarWrapper]: true,
      [classes.drawerPaperMini]: miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    });
    const sidebarWrapperMobile = cx({
      [classes.sidebarWrapper]: true,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    });
    return (
      <React.Fragment>
        <Hidden mdUp={true} implementation="css">
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={this.props.open}
            classes={{
              paper: classes.drawerPaper + ' ' + classes.varBackground,
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {window.env.REACT_APP_HIDE_BRAND ? <>&nbsp;</> : brand(false)}
            <SidebarWrapper
              className={sidebarWrapperMobile}
              user={<User classes={classes} mini={false} handleDrawerClose={this.props.handleDrawerClose} />}
              headerLinks={<Region classes={classes} mini={false} handleDrawerClose={this.props.handleDrawerClose} />}
              links={links(false)}
            />
          </Drawer>
        </Hidden>
        <Hidden smDown={true} implementation="css">
          <Drawer
            onMouseOver={this.setMini(false)}
            onMouseOut={this.setMini(true)}
            anchor={'left'}
            variant="permanent"
            open={true}
            classes={{
              paper: drawerPaper + ' ' + classes.varBackground,
            }}
          >
            {window.env.REACT_APP_HIDE_BRAND ? <>&nbsp;</> : brand(miniActive)}
            <SidebarWrapper
              className={sidebarWrapper}
              user={<User classes={classes} mini={miniActive} handleDrawerClose={this.props.handleDrawerClose} />}
              headerLinks={<Region classes={classes} mini={miniActive} handleDrawerClose={this.props.handleDrawerClose} />}
              links={links(miniActive)}
            />
          </Drawer>
        </Hidden>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: StateRoot): StateProps => ({
  open: state.sideBarOpen,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): TriggerProps => ({
  handleDrawerClose: () => dispatch(SidebarMutations.close()),
  handleDrawerToggle: () => dispatch(SidebarMutations.toggle()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(sidebarStyle)(Sidebar)));
