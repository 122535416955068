import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ActionIcon from '@material-ui/icons/MoreVert';
import { Draggable } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IconButtonWithTooltip } from '../../../components/icon-button-tooltip';
import { WithMenuClick, WithMenuShow } from '../../../helpers/types';
import { BatchModification } from '../../../store/interfaces/batch-modification';
import { PageList } from './page-list';
import { style } from './style';

interface Props {
  data: BatchModification;
  documentId: string;
  index: number;
  isLast: boolean;
}

const TitleView: React.FunctionComponent<Props & WithTranslation & WithStyles> = ({
  classes,
  data,
  documentId,
  t,
}) => {
  if (!data.documents || !data.workflows) {
    return <div className={classes.documentTitle}>#{documentId}</div>;
  }
  const document = data.documents[documentId];
  return (
    <div className={classes.documentTitle}>
      {data.workflows
        ? (
          <span>
            <strong>{data.workflows[document.workflowId].name}</strong>
            &nbsp;
          </span>
        )
        : null
      }
      {document.id ? <span>#{document.id}</span> : <i>({t('batchModification.newDocument')})</i>}
      <span className={classes.documentStatus}>&nbsp;{t(`dataSourceStatus.${document.status}.userFriendlyName`)}</span>
    </div>
  );
};

// tslint:disable-next-line:no-any
const DragHandleView: React.FunctionComponent<React.ComponentPropsWithRef<any> & WithTranslation & WithStyles> = ({
  classes,
  dragHandleProps,
  t,
}) => {
  return (
    <div className={classes.panelLeft + ' ' + classes.sideButton} {...dragHandleProps}>
      <Tooltip title={t('batchModification.manipulations.move')!}>
        <div className="circle">
          <DragHandleIcon />
        </div>
      </Tooltip>
    </div>
  );
};

const ActionMenuView: React.FunctionComponent<WithMenuClick & WithTranslation & WithStyles> = ({
  onMenuClick,
  classes,
  t,
}) => (
  <div className={classes.panelRight + ' ' + classes.sideButton}>
    <IconButtonWithTooltip onClick={onMenuClick} tooltip={t('batchModification.actionsTooltip')}>
      <ActionIcon />
    </IconButtonWithTooltip>
  </div>
);

const BatchDocumentView: React.FunctionComponent<Props & WithMenuShow<string> & WithTranslation & WithStyles> = (props) => {
  const { classes } = props;

  function handleMenuClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();
    props.onMenuShow(props.documentId, event.currentTarget);
  }

  return (
    <div className={classes.document}>
      <Draggable draggableId={props.documentId} index={props.index}>
        {(draggableProvided) => (
          <div
            className="draggable"
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
          >
            <Grid container={true} direction="column">
              <TitleView {...props} />
              <Grid className={classes.panelHost} container={true} item={true} direction="row">
                <Grid style={{ padding: 2 }} container={true} item={true} direction="row">
                  <PageList {...props} />
                </Grid>
                <DragHandleView
                  classes={classes}
                  dragHandleProps={draggableProvided.dragHandleProps}
                  t={props.t}
                />
                <ActionMenuView
                  onMenuClick={handleMenuClick}
                  {...props}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Draggable>
    </div>
  );
};

export const BatchDocument = withStyles(style)(withTranslation()(BatchDocumentView));
