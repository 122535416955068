import { withStyles, Tooltip, Typography, WithStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import { WithT } from 'i18next';
import React from 'react';
import { style } from './style';

interface ClassDesignViewProps extends WithT, WithStyles {
  aiClass?: number;
  design?: number;
}

const ClassDesignView: React.FunctionComponent<ClassDesignViewProps> = ({
  aiClass,
  classes,
  design,
  t,
}) => {

  return (
    <Tooltip
      title={<React.Fragment>
        <Typography>
          {t('batchPreview.document.class', { aiClass: aiClass ? aiClass : t('batchPreview.document.unclassified') })}
        </Typography>
        <Typography>
          {t('batchPreview.document.design', { design: design || design === 0 ? design : t('batchPreview.document.unclassified') })}
        </Typography>
      </React.Fragment>}
      classes={{ tooltip: classes.tooltip }}
    >
      {aiClass && (design || design === 0)
        ?
        <InfoIcon fontSize="small" className={classes.documentInfoIcon} />
        :
        <WarningIcon fontSize="small" className={classes.documentInfoIcon} />
      }
    </Tooltip>
  );
};

export const ClassDesignComponent = withStyles(style)(ClassDesignView);
