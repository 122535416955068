import Button from '@material-ui/core/Button';
import { withTranslation, WithTranslation } from 'react-i18next';

const updateNow = () => () => window.location.reload(true);
const dashboardRedirect = (url: string) => () => window.location.replace(url);

const UpdateAppButtonView = withTranslation()(({ t }: WithTranslation) => (
  <Button key="upgrade" aria-label="t('app.updateNow')" color="inherit" onClick={updateNow()}>
    {t('app.updateNow')}
  </Button>
));

interface PreviewButtonProps {
  batchWorkflowId: number;
}

const DashboardBackButtonView = withTranslation()(({ batchWorkflowId, t }: PreviewButtonProps & WithTranslation) => (
  <Button
    key="upgrade"
    aria-label="t('terms.back')"
    color="inherit"
    onClick={dashboardRedirect(`/batch-workflow/${batchWorkflowId}/dashboard`)}
  >
    {t('terms.back')}
  </Button>
));

export namespace PreviewButtons {
  export const UpdateAppButton = <UpdateAppButtonView />;
  export function backToDashboard(batchWorkflowId: number) {
    return (
      <DashboardBackButtonView batchWorkflowId={batchWorkflowId} />
    );
  }
}
