import Chip from '@material-ui/core/Chip';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import SortIcon from '@material-ui/icons/SortByAlpha';
import { WithT } from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { BatchDashboardActions } from '../../../actions/batch-dashboard.actions';
import ToolBarView, { AppBarView } from '../../../components/app-bar';
import { IconButtonWithTooltip } from '../../../components/icon-button-tooltip';
import { MenuPopper } from '../../../components/menu-popper';
import { Placeholder } from '../../../components/placeholder';
import { WindowTitle } from '../../../components/window-title';
import { MenuContext } from '../../../helpers/types';
import { withQuery, WithQuery } from '../../../hoc/with-query';
import { StateRoot } from '../../../store/interfaces';
import { BatchDashboardQuery, BatchFieldSet, ExpansionQuery } from '../../../store/interfaces/batch-dashboard';
import { Category } from '../../../store/interfaces/category';
import { IndexOf } from '../../../store/types';
import { defaultFilterItem, BatchDashboardFilterScene, FilterItem } from './filter';
import { FilterBarComponent } from './filter-bar';
import { AscendingIcon, DescendingIcon } from './icons';
import { BatchDashboardSortScene } from './sort';
import { defaultSortItem, SortItem } from './sort-row';
import { style } from './style';

interface ExternalControllerProps extends WithT {
  title: string | undefined;
}

interface StoreProps {
  fieldSets: IndexOf<BatchFieldSet> | undefined;
  categories: Category[] | undefined;
}

interface StoreActions {
  applySort: (sortBy: SortItem[]) => Promise<void>;
  applyFilter: (expansionQuery: ExpansionQuery) => Promise<void>;
  refresh: () => void;
}

interface State {
  currentFilter: FilterItem | null;
  filterAnchor: MenuContext<BatchDashboardQuery> | null;
  sortAnchor: MenuContext<BatchDashboardQuery> | null;
}

type ControllerProps = ExternalControllerProps & WithQuery<BatchDashboardQuery> & StoreProps & StoreActions;

class DashboardAppBarController extends React.PureComponent<ControllerProps, State> {
  readonly state: State;

  constructor(props: ControllerProps) {
    super(props);

    this.state = {
      currentFilter: null,
      filterAnchor: null,
      sortAnchor: null,
    };
  }

  setSortAnchor = (value: MenuContext<BatchDashboardQuery> | null) => {
    this.setState({
      ...this.state,
      filterAnchor: null,
      sortAnchor: value,
    });
  }

  handleSortClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.sortAnchor) {
      this.handleSortHide();
    } else {
      this.handleSortShow(this.props.query, event.currentTarget);
    }
  }

  handleSortShow = (item: BatchDashboardQuery, element: HTMLElement) => {
    this.handleFilterHide();
    this.setSortAnchor({ element, item });
  }

  handleSortHide = () => {
    this.setSortAnchor(null);
  }

  setFilterAnchor = (value: MenuContext<BatchDashboardQuery> | null, filter: FilterItem | null) => {
    this.setState({
      ...this.state,
      currentFilter: filter,
      filterAnchor: value,
      sortAnchor: null,
    });
  }

  handleResetFilterClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    await this.props.applyFilter({
      filters: defaultFilterItem(
        this.props.t('batchWorkflowDashboard.batchFilter.lockedBy'),
        this.props.t('batchWorkflowDashboard.columns.status'),
        this.props.t('batchWorkflowDashboard.batchFilter.operations.equal')
      ),
    });
  }

  handleFilterDialogClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, filter: FilterItem | null) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.filterAnchor) {
      this.handleFilterHide();
    } else {
      this.handleFilterShow(this.props.query, event.currentTarget, filter);
    }
  }

  handleFilterShow = (item: BatchDashboardQuery, element: HTMLElement, filter: FilterItem | null) => {
    this.setFilterAnchor({ element, item }, filter);
  }

  handleFilterHide = () => {
    this.setFilterAnchor(null, null);
  }

  handleRefresh = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.handleSortHide();
    this.handleFilterHide();
    this.props.refresh();
  }

  handleApplySort = async (sortBy: SortItem[]) => {
    this.handleSortHide();
    await this.props.applySort(sortBy);
  }

  handleApplyFilter = async (expansionQuery: ExpansionQuery) => {
    this.handleFilterHide();
    await this.props.applyFilter(expansionQuery);
  }

  handleDeleteFilter = async (index: number) => {
    this.handleFilterHide();
    if (!this.props.query.filters) return;
    this.props.query.filters.splice(index, 1);
    await this.props.applyFilter({
      filters: this.props.query.filters,
    });
  }

  render() {
    const { fieldSets, query, t, title, categories } = this.props;
    const { filterAnchor, sortAnchor } = this.state;
    return (
      <React.Fragment>
        <DashboardAppBarView
          handleRefresh={this.handleRefresh}
          handleSortClick={this.handleSortClick}
          handleResetClick={this.handleResetFilterClick}
          sort={query.sortingInfo}
          t={t}
          title={title}
        >
          <FilterBarComponent
            t={t}
            handleFilterDialogClick={this.handleFilterDialogClick}
            handleFilterDeleteClick={this.handleDeleteFilter}
            filters={{
              fields: query.filters,
            }}
          />
        </DashboardAppBarView>

        <MenuPopper anchor={sortAnchor && sortAnchor.element}>
          <BatchDashboardSortScene
            resetSortBy={defaultSortItem(t('batchWorkflowDashboard.batchSort.created'))}
            initialSortBy={query.sortingInfo}
            onCancel={this.handleSortHide}
            fields={fieldSets}
            onSave={this.handleApplySort}
          />
        </MenuPopper>

        <MenuPopper anchor={filterAnchor && filterAnchor.element}>
          <BatchDashboardFilterScene
            categories={categories}
            currentFilter={this.state.currentFilter}
            expansionQuery={(this.state.filterAnchor && this.state.filterAnchor.item) ? {
              filters: this.state.filterAnchor.item.filters,
            } : {
                filters: query.filters,
              }}
            fields={fieldSets}
            onCancel={this.handleFilterHide}
            onSave={this.handleApplyFilter}
            t={t}
          />
        </MenuPopper>
      </React.Fragment >
    );
  }
}

interface ExternalViewProps {
  handleRefresh: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleSortClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleResetClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;

  children?: React.ReactNode;
  sort: SortItem[];
  title: string | undefined;
}

type ViewProps = ExternalViewProps & WithT & WithStyles;

const DashboardAppBarView = withStyles(style)(({
  children,
  classes,
  handleRefresh,
  handleSortClick,
  handleResetClick,
  sort,
  t,
  title,
}: ViewProps): JSX.Element => (
    <React.Fragment>
      <AppBarView>
        <ToolBarView>
          <WindowTitle title={title || window.env.REACT_APP_HIDE_BRAND ? 'Batch UI' : t('defaultWindowTitle')} />
          <Typography variant="h6" color="inherit" style={{ width: '100%' }}>
            {!title
              ? <Placeholder />
              : title
            }
          </Typography>

          <div className={classes.grow} />

          <Chip
            label={t('batchWorkflowDashboard.batchFilter.reset')}
            icon={<FilterListIcon />}
            onClick={handleResetClick}
            className={classes.chip}
          />
          <Chip
            label={sort && sort.length
              ? sort[0].label
              : t('batchWorkflowDashboard.sort.notSet')}
            icon={sort && sort.length
              ? sort[0].ascending
                ? <AscendingIcon />
                : <DescendingIcon />
              : <SortIcon />
            }
            onClick={handleSortClick}
            className={classes.chip}
          />
          <IconButtonWithTooltip
            onClick={handleRefresh}
            tooltip={t('batchWorkflowDashboard.actions.refresh')}
          >
            <RefreshIcon />
          </IconButtonWithTooltip>
        </ToolBarView>
        {children}
      </AppBarView>
    </React.Fragment>
  ));

const mapStateToProps = (state: StateRoot): StoreProps & WithQuery<BatchDashboardQuery> => ({
  categories: state.batchDashboard.value ? state.batchDashboard.value.categories : undefined,
  fieldSets: state.batchDashboard.value ? state.batchDashboard.value.fieldSets : undefined,
  query: state.batchDashboard.query!,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): StoreActions => ({
  applyFilter: async (expansionQuery: ExpansionQuery) => dispatch(BatchDashboardActions.updateFilter(expansionQuery)),
  applySort: async (sortBy: SortItem[]) => dispatch(BatchDashboardActions.applySort(sortBy)),
  refresh: () => dispatch(BatchDashboardActions.reload()),
});

export const DashboardAppBar = connect(mapStateToProps, mapDispatchToProps)(withQuery(DashboardAppBarController));
