import { Category } from './category';
import { User } from './user';

// tslint:disable-next-line:no-any
export type Fields = { [key: string]: any };

export interface Batch {
  id: string;
  batchWorkflowId: number;
  documents: Document[];
  pages: number;
  fields: Fields;
  currentLock: BatchLock | undefined;
  currentStatus: BatchStatus | undefined;
  currentCategory: BatchCategory | undefined;
}

export interface BatchLock {
  id: string;
  lockedByUserId: number;
  lockedByUser: User | undefined;
  lockedByClientApplicationId: number;
  locked: string;
}

export interface BatchStatus {
  status: string;
}

export class BatchStatus {
  static PROCESSING = 'processing';
  static REPROCESSING = 'reprocessing';
  static READY = 'ready';
  static HOLD = 'onhold';
  static DONE = 'done';
  static DELETED = 'deleted';
}

export interface BatchCategory {
  categoryId: number;
  category: Category | undefined;
  created: string;
  createdByUserId: number;
  createdByUser: User | undefined;
}

export interface Document {
  id: string;
  dataSourceId: number;
  batchId?: string;
  pages: Page[];
  currentDocumentWorkflow: DocumentWorkflow;
  status: string;
  class?: number;
  design?: number;
  regionId: string;
}

export interface DocumentWorkflow {
  workflowId: number;
}

export interface Page {
  id: string;
  documentId: string;
  inputFileId: string;
  ordinal: number;
  thumbnail: Thumbnail;
  fullImage: Thumbnail;
}

export interface Thumbnail {
  url: string;
}
