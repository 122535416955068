export class CodedError extends Error {
  constructor(message: string, public readonly code: string) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, CodedError.prototype);
  }
}

export interface GraphQLError {
  message: string;
  extensions: GraphQLExtension;
}

interface GraphQLExtension {
  code: string;
  exception: GraphQLException;
}

interface GraphQLException {
  // tslint:disable-next-line:no-any
  details?: any;
}
