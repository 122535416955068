import { createAction } from '..';
import { Batch } from '../interfaces/batch';
import { CodedError } from '../interfaces/error';
import { BatchQuery } from '../interfaces/index';

export namespace BatchMutations {
  export const currentQuery = (query: BatchQuery) => createAction((state) => ({
    ...state,
    batch: state.batch.setQuery(query),
  }));

  export const currentError = (err: CodedError) => createAction((state) => ({
    ...state,
    batch: state.batch.setError(err),
  }));

  export const current = (batch: Batch | null) => createAction((state) => ({
    ...state,
    batch: state.batch.setValue(batch),
  }));

  export const currentLoading = () => createAction((state) => ({
    ...state,
    batch: state.batch.setLoading(),
  }));

  export const noCurrent = () => createAction((state) => ({
    ...state,
    batch: state.batch.clearValue(),
  }));
}
