import { createStyles, Theme } from '@material-ui/core/styles';

export const style = (theme: Theme) => createStyles({
  actionButton: {
    '&:hover': {
      cursor: 'pointer',
    },
    background: '#00C088',
    borderRadius: '50%',
    color: '#FFFFFF',
    left: '-24px',
    padding: '4px',
    position: 'absolute',
    top: '7px',
  },
  addGreenButton: {
    '&:hover': {
      backgroundColor: '#00C088',
      color: '#FFFFFF',
    },
    border: '1px solid #00C088',
    color: '#00C088',
    marginBottom: '20px',
    marginTop: '11px',
  },
  batchField: {
    overflow: 'visible',
    paddingLeft: '37px',
    position: 'relative',
  },
  batchFieldCardTitle: {
    fontSize: '16px',
    fontWeight: 510,
    marginRight: theme.spacing(2),
    verticalAlign: 'middle',
  },
  batchFieldId: {
    color: 'grey',
    fontSize: '13px',
    fontStyle: 'italic',
    verticalAlign: 'middle',
  },
  batchFieldInputDiv: {
    '& b': {
      marginLeft: '26px',
    },
    '& span': {
      position: 'relative',
    },
    marginLeft: '10px',
    marginTop: '40px',
    paddingBottom: '29px',
    position: 'relative',
  },
  batchFieldSecondColumn: {
    paddingLeft: '25px !important',
  },
  batchFieldSet: {
    marginBottom: '20px',
    overflow: 'visible',
    paddingBottom: '10px',
    position: 'relative',
  },
  batchFieldSetCardHeader: {
    backgroundColor: '#EEF5FB',
    paddingBottom: '0px',
    paddingLeft: '57px',
  },
  batchFieldSetCardTitle: {
    color: '#0071CE',
    fontSize: '18px',
    fontWeight: 510,
    marginRight: theme.spacing(2),
    verticalAlign: 'middle',
  },
  batchFieldSetId: {
    color: 'grey',
    fontSize: '13px',
    fontStyle: 'italic',
  },
  batchFieldSetInputActionButton: {
    '&:hover': {
      cursor: 'pointer',
    },
    background: '#00C088',
    borderRadius: '50%',
    color: '#FFFFFF',
    padding: '4px',
    position: 'absolute',
    top: '-4px',
  },
  batchFieldSetInputDiv: {
    '& b': {
      marginLeft: '26px',
    },
    '& span': {
      position: 'relative',
    },
    marginLeft: '0px',
    marginTop: '23px',
    position: 'relative',
  },
  batchFieldSetViewValidation: {
    color: 'red',
  },
  batchFieldSetWrapper: {
    '& .draggable': {
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  batchFieldSourceCard: {
    marginBottom: '10px',
    marginTop: '15px',
    overflow: 'visible',
    paddingLeft: '37px',
    position: 'relative',
  },
  batchFieldSourceCollapsableButton: {
    backgroundColor: '#ebebeb',
    border: '1px solid #ebebeb',
    color: '#000000',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  batchFieldSourceCollapsableIcon: {
    background: '#00C088',
    borderRadius: '50%',
    color: '#FFFFFF',
    marginLeft: '32px',
  },
  batchFieldSourceContainer: {
    marginTop: '20px',
  },
  batchFieldSourceSourceLabel: {
    left: '-8px',
    zIndex: 'auto',
  },
  batchWorkflowGeneralSettings: {
    backgroundColor: '#EEF5FB',
    padding: '17px 56px 14px 49px',
  },
  closeIconButton: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: '#999999',
    fontSize: '20px',
    marginTop: '1px',
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  deleteIconButton: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: '#999999',
    fontSize: '23px',
    marginTop: '1px',
  },
  disabled: {
    backgroundColor: '#999999',
  },
  dragHandle: {
    '& .circle': {
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.08);',
      },
      background: 'transparent',
      borderRadius: '50%',
      color: 'rgba(0, 0, 0, 0.54)',
      height: '3rem',
      padding: theme.spacing(1.5),
      width: '3rem',
    },
    left: '2px',
    maxWidth: '5%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  fieldSetCardHeader: {
    paddingBottom: '0px',
  },
  fieldSetFormControl: {
    margin: theme.spacing(),
    marginTop: '0px',
    width: '100%',
  },
  fieldSetTypeFormControl: {
    margin: theme.spacing(),
    marginRight: '0px',
    marginTop: '4px',
    minWidth: '22%',
  },
  fieldSetTypeSelect: {
    marginTop: '9px',
    maxWidth: '225px',
  },
  fieldSetTypeSelectLabel: {
    left: '-12px',
    top: '5px',
  },
  formControl: {
    margin: theme.spacing(),
    maxWidth: '50%',
    minWidth: '23%',
  },
  formControlBatchFieldDataType: {
    margin: theme.spacing(),
    marginTop: '21px',
    width: '100%',
  },
  formControlBatchFieldSetType: {
    margin: theme.spacing(),
    maxWidth: '50%',
    minWidth: '20%',
  },
  formControlBatchFieldSourceSource: {
    margin: theme.spacing(),
    marginTop: '14px',
    width: '100%',
  },
  formControlLabel: {
    margin: '0px 0px 0px 11px',
  },
  grow: {
    flexGrow: 1,
  },
  paddedBatchFieldCard: {
    padding: '0px 56px 10px 10px',
  },
  paddedBatchFieldListCard: {
    padding: '10px 56px 10px 58px',
  },
  paddedBatchFieldSourceCard: {
    padding: '0px 56px 10px 10px',
    position: 'relative',
  },
  paddedBatchFieldSourceItems: {
    marginLeft: '30px',
    paddingBottom: '5px',
    paddingTop: '10px',
    position: 'relative',
  },
  paddedBlueBackground: {
    backgroundColor: '#EEF5FB',
    padding: '10px 56px 4px 49px',
  },
  pullRight: {
    float: 'right',
  },
  selectDialogItemButton: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  selectDialogNameItem: {
    '& span': {
      fontSize: '14px',
    },
    borderTop: '1px solid #000000',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  selectDialogTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  selectDialogTitleContainer: {
    padding: '44px 16px 0px',
    position: 'relative',
  },
  textField: {
    '& input': {
      fontSize: '14px',
      padding: '9px',
    },
    '& label': {
      top: '-9px',
    },
    marginTop: '10px',
    width: '250px',
  },
  textFieldBatchFieldDataType: {
    '& input': {
      fontSize: '14px',
      padding: '9px',
    },
    '& label': {
      top: '-9px',
    },
    marginTop: '31px',
    width: '100%',
  },
  textFieldBatchFieldLabel: {
    '& input': {
      fontSize: '14px',
      padding: '9px',
    },
    '& label': {
      top: '-9px',
    },
    marginTop: '31px',
    width: '100%',
  },
});
