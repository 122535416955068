import DashboardIcon from '@material-ui/icons/Dvr';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import { WithTranslation } from 'react-i18next';
import { MenuRoute } from './components/sidebar';

export interface Props {
  batchWorkflowId?: number;
}

const menu: (props: Props & WithTranslation) => MenuRoute[] = ({ batchWorkflowId, t }) => [
  {
    icon: HomeIcon,
    name: t('home'),
    path: '/',
    type: 'link',
  },
  {
    icon: DashboardIcon,
    matchPath: /^\/batch-workflow\/.+\/dashboard$/,
    name: t('dashboard'),
    path: batchWorkflowId ? `/batch-workflow/${batchWorkflowId}/dashboard` : '/',
    type: 'link',
  },
  {
    children: [
      {
        mini: 'G',
        name: 'General',
        path: '/settings/general',
      },
    ],
    icon: SettingsIcon,
    name: t('settings'),
    type: 'header',
  },
];
export default menu;
