import { withStyles, WithStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { style } from './style';

interface Props {
  error?: boolean;
}

const PlaceholderView: React.FunctionComponent<Props & WithStyles> = ({ classes, error }) => (
  <span className={cx({ [classes.root]: true, [classes.error]: error })} />
);

export const Placeholder = withStyles(style)(PlaceholderView);
