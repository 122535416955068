import { DateTime, Interval } from 'luxon';
import React from 'react';

interface Props {
  date: DateTime;
}

function refreshInterval(date: DateTime) {
  const seconds = Math.abs(Interval.fromDateTimes(date, DateTime.utc()).count('seconds'));
  if (seconds / 2 <= 60) return 1000;
  if (seconds / 2 <= 3600) return 60 * 1000 / 2;
  return 3600 * 1000 / 4;
}

class RelativeDateController extends React.Component<Props> {
  private refreshHandle: number | undefined;

  componentWillUnmount(): void {
    if (this.refreshHandle !== undefined) {
      clearInterval(this.refreshHandle);
    }
  }

  public render() {
    if (this.refreshHandle !== undefined) {
      clearInterval(this.refreshHandle);
    }
    const { date } = this.props;

    this.refreshHandle = window.setInterval(
      () => {
        this.forceUpdate();
      },
      refreshInterval(date)
    );
    return (
      <span>
        {date.toRelative()}
      </span>
    );
  }
}

export const RelativeDate = RelativeDateController;
