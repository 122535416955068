// tslint:disable-next-line:no-any
export function objectEquals(v1: any, v2: any) {

  if (typeof (v1) !== typeof (v2)) {
    return false;
  }

  if (typeof (v1) === 'function') {
    return v1.toString() === v2.toString();
  }

  if (v1 instanceof Object && v2 instanceof Object) {
    if (countProps(v1) !== countProps(v2)) {
      return false;
    }
    var r = true;
    // tslint:disable-next-line:forin
    for (const k in v1) {
      r = objectEquals(v1[k], v2[k]);
      if (!r) {
        return false;
      }
    }
    return true;
  } else {
    return v1 === v2;
  }
}

// tslint:disable-next-line:no-any
function countProps(obj: any) {
  var count = 0;
  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      count++;
    }
  }
  return count;
}
