import { ApolloClient, Observable } from '@apollo/client';
import gql from 'graphql-tag';
import env from '../../environment';
import { BatchFieldSet, BatchFilter, Pagination } from '../../store/interfaces/batch-dashboard';
import { IndexOf } from '../../store/types';
import { clientCache } from '../graphql';
import { SortItem } from './../../scenes/batch-workflow/dashboard/sort-row';
import { BatchWorkflowUpdates } from './../batch-workflow/dto/batch-workflow-updates-subscription';
import { DashboardBatch, ReadableBatchStatus } from './dto/batch-dashboard-data';

const GQL_LOAD_BATCH_IDS = gql`
query (
  $batchWorkflowIds: [Int!]!,
  $batchSort: [BatchSort!]!,
  $pagination: Pagination,
  $batchFilter: [BatchFilter]
  ) {
  batchIds(
    batchWorkflowIds: $batchWorkflowIds,
    batchSort: $batchSort,
    pagination: $pagination,
    batchFilter: $batchFilter
    ) {
    id
  }
}`;

const GQL_LOAD_BATCHES_BY_IDS = gql`
query (
  $batchIds: [String!]!
  ) {
  findByIds(
    ids: $batchIds
    ) {
    id
    batchWorkflowId
    regionId
    currentData {
      id
      created
      fieldSets
    }
    documents {
      currentDocumentWorkflow {
        workflowId
      }
      pages {
        id
      }
    }
    currentStatus {
      status
      created
      createdByUserId
    }
    currentCategory {
      categoryId
      created
      createdByUserId
    }
    currentLock {
      id
      lockedByUserId
      lockedByClientApplicationId
      locked
    }
    initialRevision {
      created
    }
  }
}`;

const GQL_LOAD_BATCH = gql`
  query ($id: String!) {
    batch(id: $id) {
      id
      batchWorkflowId
      regionId
      currentData {
        id
        created
        fieldSets
      }
      documents {
        currentDocumentWorkflow {
          workflowId
        }
        pages {
          id
        }
      }
      currentStatus {
        status
        created
      }
      currentCategory {
        categoryId
        created
        createdByUserId
      }
      currentLock {
        id
        lockedByUserId
        lockedByClientApplicationId
        locked
      }
      initialRevision {
        created
      }
    }
  }`;

const GQL_SUBSCRIBE_BATCH_WORKFLOW_UPDATES = gql`
subscription ($batchWorkflowId: Int!) {
  batchWorkflowUpdates(batchWorkflowId: $batchWorkflowId) {
    __typename
    ... on BatchLockSub {
      batchId
      batchLockId
      lockedByUserId
      lockedByClientApplicationId
    }
    ... on BatchUnlockSub {
      batchId
      batchLockId
      unlockedByUserId
      unlockedByClientApplicationId
    }
    ... on BatchDataUpdate {
      batchId
      batchDataId
      userId
    }
    ... on NewBatch {
      batchId
      status
    }
    ... on BatchStatusUpdate {
      batchId
      batchStatusId
      status
      userId
    }
  }
}`;

export class BatchDashboardRepository {
  public static forRegion(regionId: string): BatchDashboardRepository {
    const url = env.urlFactory.regional(regionId).service('data').resolve('graphql');
    const client = clientCache.getItem(url);
    if (!client) throw new Error('Unable to create GraphQL client');
    return new BatchDashboardRepository(client);
  }

  private static fixBatchData(batch: DashboardBatch, batchFieldSets: IndexOf<BatchFieldSet>): void {
    if (!batch.currentData) return;
    Object.keys(batch.currentData.fieldSets).forEach((fieldSet: string) => {
      const batchFieldSet = batchFieldSets[fieldSet];
      if (!batchFieldSet) return;

      switch (batchFieldSet.type) {
        case 'PerBatchFieldSet':
          batch.currentData.fieldSets[fieldSet].type = 'PerBatchDataFieldSet';
          break;
        case 'PerDocumentHeaderFieldSet':
          batch.currentData.fieldSets[fieldSet].type = 'PerDocumentHeaderDataFieldSet';
          break;
        case 'PerDocumentRepeatingFieldSet':
          batch.currentData.fieldSets[fieldSet].type = 'PerDocumentRepeatingDataFieldSet';
          break;
        default:
          batch.currentData.fieldSets[fieldSet].type = undefined;
          break;
      }
    });
  }

  constructor(
    public readonly client: ApolloClient<{}>, // TODO private
  ) { }

  public async findByIds(batchIds: string[], batchFieldSets: IndexOf<BatchFieldSet>): Promise<DashboardBatch[]> {
    const { data } = await this.client.query<{ findByIds: DashboardBatch[] }>({
      fetchPolicy: 'no-cache',
      query: GQL_LOAD_BATCHES_BY_IDS,
      variables: {
        batchIds,
      },
    });

    const batches = data.findByIds.map((batch: DashboardBatch) => {
      BatchDashboardRepository.fixBatchData(batch, batchFieldSets);
      return {
        ...batch,
        currentStatus: batch.currentStatus
          ? {
            ...batch.currentStatus,
            status: ReadableBatchStatus[batch.currentStatus.status],
          } : undefined,
        pages: batch.documents.reduce((a, b) => a + b.pages.length, 0),
      };
    });

    return batches || [];
  }

  public async findOne(batchId: string, batchFieldSets: IndexOf<BatchFieldSet>): Promise<DashboardBatch> {
    const { data } = await this.client.query<{ batch: DashboardBatch }>({
      fetchPolicy: 'no-cache',
      query: GQL_LOAD_BATCH,
      variables: { id: batchId },
    });

    const batch = {
      ...data.batch,
      currentStatus: data.batch.currentStatus
        ? {
          ...data.batch.currentStatus,
          status: ReadableBatchStatus[data.batch.currentStatus.status],
        } : undefined,
      pages: data.batch.documents.reduce((a, b) => a + b.pages.length, 0),
    };
    BatchDashboardRepository.fixBatchData(batch, batchFieldSets);
    return batch;
  }

  public async findBatchIds(
    batchWorkflowId: number,
    batchSort: SortItem[],
    pagination: Pagination,
    batchFilter?: BatchFilter[],
  ): Promise<string[]> {
    const { data } = await this.client.query<{ batchIds: { id: string }[] }>({
      fetchPolicy: 'no-cache',
      query: GQL_LOAD_BATCH_IDS,
      variables: {
        batchFilter,
        batchSort: batchSort.map((sortItem) => ({
          ascending: sortItem.ascending,
          fieldId: sortItem.fieldId,
          fieldSetId: sortItem.fieldSetId,
        })),
        batchWorkflowIds: [batchWorkflowId],
        pagination,
      },
    });
    return data.batchIds.map((batchId) => batchId.id);
  }

  public async subscribeToBatchWorkflow(batchWorkflowId: number): Promise<Observable<BatchWorkflowUpdates>> {
    return this.client.subscribe<BatchWorkflowUpdates>({
      fetchPolicy: 'no-cache',
      query: GQL_SUBSCRIBE_BATCH_WORKFLOW_UPDATES,
      variables: { batchWorkflowId: batchWorkflowId },
    }) as Observable<BatchWorkflowUpdates>;
  }
}
