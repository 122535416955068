import { cloneDeep } from 'lodash';
import { createAction } from '../index';
import { BatchModification, Page, UndoItem } from '../interfaces/batch-modification';
import { CodedError } from '../interfaces/error';
import { BatchQuery } from '../interfaces/index';
import { ReduxAction } from '../types';

export interface PageLocation {
  documentId: string;
  index: number;
}

export namespace BatchModificationMutations {
  function generateUndo(value: BatchModification | null): BatchModification | null {
    if (!value) return value;

    const lastUndo = value.undoStack.length
      ? value.undoStack[value.undoStack.length - 1]
      : {} as UndoItem;
    const { documentIds, documents, orphanedPages } = value;

    if (lastUndo.documentIds === documentIds && lastUndo.documents === documents && lastUndo.orphanedPages === orphanedPages) return value;

    return {
      ...value,
      undoStack: [...value.undoStack, { documentIds, documents, orphanedPages }],
    };
  }

  function prepareSetValue(action: (prev: BatchModification) => BatchModification | undefined): ReduxAction {
    return createAction((state) => {
      if (!state.batchModification.value) return state;
      const batchModification = action(state.batchModification.value);
      if (!batchModification) return state;

      return {
        ...state,
        batchModification: state.batchModification.setValue(generateUndo(batchModification)),
      };
    });
  }

  export const setQuery = (query: BatchQuery) => createAction((state) => ({
    ...state,
    batchModification: state.batchModification.setQuery(query),
  }));

  export const setError = (err: CodedError) => createAction((state) => ({
    ...state,
    batchModification: state.batchModification.setError(err),
  }));

  export const setValue = (value: BatchModification | null) => createAction((state) => ({
    ...state,
    batchModification: state.batchModification.setValue(generateUndo(value)),
  }));

  export const undo = () => createAction((state) => {
    if (!state.batchModification.value) return state;

    const prev = state.batchModification.value;
    if (prev.undoStack.length <= 1) return state;
    prev.undoStack.pop();

    const restore = prev.undoStack[prev.undoStack.length - 1];

    const value = {
      ...prev,
      documentIds: restore.documentIds,
      documents: restore.documents,
      orphanedPages: restore.orphanedPages,
    };

    // NOTE this doesn't push to the undo stack, therefore can't use prepareSetValue
    return {
      ...state,
      batchModification: state.batchModification.setValue(value),
    };
  });

  export const clearUndo = () => prepareSetValue((state) => {
    if (state.undoStack.length <= 1) return undefined;
    state.undoStack.pop();

    return {
      ...state,
      undoStack: [],
    };
  });

  export const moveDocument = (sourceIndex: number, destinationIndex: number) => prepareSetValue((prev) => {
    if (sourceIndex === destinationIndex) return undefined;

    const ids = Array.from(prev.documentIds);
    const id = ids[sourceIndex];

    ids.splice(sourceIndex, 1);
    ids.splice(destinationIndex, 0, id);

    return {
      ...prev,
      documentIds: ids,
    };
  });

  export const movePage = (source: PageLocation, destination: PageLocation) => prepareSetValue((prev) => {
    if ((destination.documentId === source.documentId) && (destination.index === source.index)) {
      return undefined;
    }

    const documents = { ...prev.documents };
    const pages = { ...prev.pages };

    if (destination.documentId === source.documentId) {
      const ids = Array.from(documents[source.documentId].pageIds);
      const id = ids[source.index];
      ids.splice(source.index, 1);
      ids.splice(destination.index, 0, id);
      documents[source.documentId] = {
        ...documents[source.documentId],
        pageIds: ids,
      };
    } else {
      const fromIds = Array.from(documents[source.documentId].pageIds);
      const id = fromIds[source.index];
      fromIds.splice(source.index, 1);
      documents[source.documentId] = {
        ...documents[source.documentId],
        pageIds: fromIds,
      };

      const toIds = Array.from(documents[destination.documentId].pageIds);
      toIds.splice(destination.index, 0, id);
      documents[destination.documentId] = {
        ...documents[destination.documentId],
        pageIds: toIds,
      };

      pages[id] = {
        ...pages[id],
        documentId: destination.documentId,
      };
    }

    return {
      ...prev,
      documents,
      pages,
    };
  });

  export const reversePageOrder = (documentId: string) => prepareSetValue((prev) => {
    const document = prev.documents[documentId];
    if (!document) return undefined;

    const pageIds = [...document.pageIds];
    pageIds.reverse();

    const documents = {
      ...prev.documents,
      [documentId]: {
        ...document,
        pageIds,
      },
    };

    return {
      ...prev,
      documents,
    };
  });

  export const changeWorkflow = (documentId: string, newWorkflowId: number) => prepareSetValue((prev) => {
    const document = prev.documents[documentId];
    if (!document) return undefined;

    if (document.workflowId === newWorkflowId) {
      return undefined;
    }

    const documents = {
      ...prev.documents,
      [documentId]: {
        ...document,
        workflowId: newWorkflowId,
      },
    };

    return {
      ...prev,
      documents,
    };
  });

  export const removeFromBatch = (documentId: string) => prepareSetValue((prev) => {
    const index = prev.documentIds.indexOf(documentId);
    if (index < 0) return undefined;

    const documentIds: string[] = [...prev.documentIds];
    documentIds.splice(index, 1);

    const documents = { ...prev.documents };
    delete documents[documentId];

    return {
      ...prev,
      documentIds,
      documents,
    };
  });

  export const removePageFromBatch = (pageId: string, documentId: string) => prepareSetValue((prev) => {
    const documents = cloneDeep(prev.documents);
    const index = documents[documentId].pageIds.indexOf(pageId);
    documents[documentId].pageIds.splice(index, 1);
    const orphanedPages = prev.orphanedPages ? [...prev.orphanedPages, { [documentId]: pageId }] : [{ [documentId]: pageId }];

    return {
      ...prev,
      documents,
      lightboxModal: undefined,
      orphanedPages,
    };
  });

  export const joinDocumentToNext = (documentId: string) => prepareSetValue((prev) => {
    const index = prev.documentIds.indexOf(documentId);
    if (index < 0 || index > prev.documentIds.length - 2) return undefined;

    const followingId = prev.documentIds[index + 1];

    // append pages of following document to current
    const documents = {
      ...prev.documents,
      [documentId]: {
        ...prev.documents[documentId],
        pageIds: [
          ...prev.documents[documentId].pageIds,
          ...prev.documents[followingId].pageIds,
        ],
      },
    };

    let documentIds: string[];
    if (prev.documents[followingId].id) {
      // update the following document to have no pages
      documents[followingId] = {
        ...prev.documents[followingId],
        pageIds: [],
      };
      documentIds = prev.documentIds;
    } else {
      // if the following document (now empty) is *new*, then remove it
      delete documents[followingId];
      documentIds = [...prev.documentIds];
      documentIds.splice(index + 1, 1);
    }

    // now moving pages of the following document to current
    const pages = { ...prev.pages };
    for (const pageId of prev.documents[followingId].pageIds) {
      pages[pageId] = {
        ...pages[pageId],
        documentId: documentId,
      };
    }

    return {
      ...prev,
      documentIds,
      documents,
      pages,
    };
  });

  export const splitDocument = (documentId: string, pageIndex: number) => prepareSetValue((prev) => {
    const index = prev.documentIds.indexOf(documentId);
    if (index < 0) return undefined;

    const documents = { ...prev.documents };
    const pages = { ...prev.pages };

    const source = documents[documentId].pageIds;
    documents[documentId] = {
      ...documents[documentId],
      pageIds: source.slice(0, pageIndex + 1),
    };

    const remainingPageIds = source.slice(pageIndex + 1);

    const value = {
      ...prev,
    };

    if (
      index <= prev.documentIds.length - 2
      && prev.documents[prev.documentIds[index + 1]].workflowId === prev.documents[documentId].workflowId
      && !prev.documents[prev.documentIds[index + 1]].pageIds.length
    ) {
      // if following document is empty and from the same workflow, re-use that instead of creating a new document
      const followingId = prev.documentIds[index + 1];

      documents[followingId] = {
        ...documents[followingId],
        pageIds: remainingPageIds,
      };

      for (const pageId of remainingPageIds) {
        pages[pageId] = {
          ...pages[pageId],
          documentId: followingId,
        };
      }
    } else {
      // create a new document
      const placeholderId = `**new** ${Date.now()}`;
      const documentIds = Array.from(value.documentIds);
      documentIds.splice(index + 1, 0, placeholderId);

      documents[placeholderId] = {
        ...documents[documentId],
        id: undefined,
        pageIds: remainingPageIds,
      };
      value.documentIds = documentIds;

      for (const pageId of remainingPageIds) {
        pages[pageId] = {
          ...pages[pageId],
          documentId: placeholderId,
        };
      }
    }

    value.documents = documents;
    value.pages = pages;

    return value;
  });

  export const duplicatePage = (pageId: string, documentId: string) => prepareSetValue((prev) => {
    const pages = { ...prev.pages };
    const documents = { ...prev.documents };

    let duplicatedPageId;
    if (pageId.startsWith('dup_')) {
      duplicatedPageId = pageId.replace(/dup_\d+_/, `dup_${Date.now()}_`);
    } else {
      duplicatedPageId = `dup_${Date.now()}_${pageId}`;
    }

    pages[duplicatedPageId] = {
      ...pages[pageId],
      id: duplicatedPageId,
      ordinal: Object.keys(pages).length,
    };

    const pageIds = Array.from(documents[documentId].pageIds);
    const pageIndex = pageIds.indexOf(pageId);
    pageIds.splice(pageIndex + 1, 0, duplicatedPageId);

    documents[documentId] = {
      ...documents[documentId],
      pageIds: pageIds,
    };

    const value = {
      ...prev,
      documents,
      lightboxModal: undefined,
      pages,
    };

    return value;
  });

  export const setLoading = () => createAction((state) => ({
    ...state,
    batchModification: state.batchModification.setLoading(),
  }));

  export const clear = () => createAction((state) => ({
    ...state,
    batchModification: state.batchModification.clear(),
  }));

  export const setLightboxModal = (page: Page | undefined) => prepareSetValue((prev) => {
    return {
      ...prev,
      lightboxModal: page,
    };
  });

  export const setSubUpdateInfo = (subUpdateInfo: { type: 'deleted' | 'onhold' | 'unlock', by: string }) => createAction((state) => {
    if (!state.batchModification.value) return state;

    return {
      ...state,
      batchModification: state.batchModification.setValue({
        ...state.batchModification.value,
        subUpdateInfo,
      }),
    };
  });

  export const setSubscriber = (value: ZenObservable.Subscription) => createAction((state) => ({
    ...state,
    subscriber: value,
  }));
}
