import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CircularProgressComponent } from '../../../components/circular-progress';
import { RouterProps, WithBatchWorkflowId } from '../../../helpers/router-props';
import { StateRoot } from '../../../store/interfaces';
import { BatchWorkflow, BatchWorkflowModification } from '../../../store/interfaces/batch-workflow';
import { Loadable } from '../../../store/loader';
import { BatchWorkflowAdminScene } from './batch-workflow-admin';

interface Props {
  batchWorkflowModification: Loadable<BatchWorkflowModification, BatchWorkflow>;
}

type ControllerProps = RouteComponentProps<WithBatchWorkflowId> & Props;

export class BatchWorkflowEditController extends React.Component<ControllerProps> {
  componentDidMount() {
    RouterProps.handleBatchWorkflowRoute(this.props);
  }

  render() {
    const { batchWorkflowModification, match } = this.props;
    const batchWorkflowId = match.params.batchWorkflowId
      ? Number(match.params.batchWorkflowId)
      : undefined;

    return (
      batchWorkflowModification.loading || batchWorkflowId === undefined
        ? <CircularProgressComponent />
        : <BatchWorkflowAdminScene batchWorkflowId={batchWorkflowId} />
    );
  }
}

const mapStateToSceneProps = (state: StateRoot): Props => ({
  batchWorkflowModification: state.batchWorkflowModification,
});

export const BatchWorkflowAdminEditScene = withRouter(connect(mapStateToSceneProps)(BatchWorkflowEditController));
