import { createAction } from '..';
import { ActiveDocument, Batch, Document, Workflow } from '../interfaces/batch-learning';
import { CodedError } from '../interfaces/error';
import { IndexOf } from '../types';
import { DocumentStatus, InputFile } from './../interfaces/batch-learning';

export namespace BatchLearningMutations {
  export const setQuery = (batchId: string, batchWorkflowId: number, regionId: string | undefined) => createAction((state) => ({
    ...state,
    batchLearning: state.batchLearning.setQuery({ batchId, batchWorkflowId, regionId }),
  }));

  export const setLoading = () => createAction((state) => ({
    ...state,
    batchLearning: state.batchLearning.setLoading(),
  }));

  export const setError = (err: CodedError) => createAction((state) => ({
    ...state,
    batchLearning: state.batchLearning.setError(err),
  }));

  export const setRegionalData = (
    batch: Batch,
    documents: IndexOf<Document>,
    inputFiles: IndexOf<InputFile>,
    documentIds: string[],
    inputFileIds: string[]
  ) => createAction((state) => ({
    ...state,
    batchLearning: state.batchLearning.setValue({
      batch,
      documentIds,
      documents,
      inputFileIds,
      inputFiles,
      // tslint:disable-next-line:align
    }, false),
  }));

  export const setWorkflows = (workflows: IndexOf<Workflow>) => createAction((state) => {
    if (!state.batchLearning.value) return state;

    return {
      ...state,
      batchLearning: state.batchLearning.setValue({
        ...state.batchLearning.value,
        workflows,
      }),
    };
  });

  export const setActiveDocument = (activeDocument: ActiveDocument) => createAction((state) => {
    if (!state.batchLearning.value) return state;

    return {
      ...state,
      batchLearning: state.batchLearning.setValue({
        ...state.batchLearning.value,
        activeDocument,
      }),
    };
  });

  export const setDocumentStatus = (documentStatus: DocumentStatus) => createAction((state) => {
    if (!state.batchLearning.value) return state;

    const documents = state.batchLearning.value.documents;
    documents[documentStatus.documentId].status = documentStatus.status;

    return {
      ...state,
      batchLearning: state.batchLearning.setValue({
        ...state.batchLearning.value,
        documents,
      }),
    };
  });

  export const clear = () => createAction((state) => ({
    ...state,
    batchLearning: state.batchLearning.clear(),
  }));

  export const setSubUpdateInfo = (subUpdateInfo: { type: 'deleted' | 'onhold' | 'unlock', by: string }) => createAction((state) => {
    if (!state.batchLearning.value) return state;

    return {
      ...state,
      batchLearning: state.batchLearning.setValue({
        ...state.batchLearning.value,
        subUpdateInfo,
      }),
    };
  });

  export const setSubscriber = (value: ZenObservable.Subscription) => createAction((state) => ({
    ...state,
    subscriber: value,
  }));
}
