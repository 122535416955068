import { createStyles, Theme } from '@material-ui/core/styles';

export const style = (theme: Theme) => createStyles({
  error: {
    background: '#fcc!important',
  },
  root: {
    background: '#ddd',
    borderRadius: '0.45rem',
    display: 'inline-block',
    height: '0.9rem',
    width: '30%',
  },
});
