import { WorkflowRepository } from '../domain/workflow/workflow.repository';
import { WorkflowMutations } from '../store/mutations/workflow.mutations';
import { Action } from '../store/types';

const repository = WorkflowRepository.forGlobal();

export namespace WorkflowActions {
  export function loadByClientId(clientId: number): Action {
    return async (dispatch) => {
      const workflows = await repository.findWorkflowsByClientId(clientId);
      dispatch(WorkflowMutations.availableWorkflows(workflows));
    };
  }
}
