import React from 'react';
import { Prompt } from 'react-router-dom';

interface Props {
  allow: boolean;
  message: string;
}

class NavigationCheckController extends React.PureComponent<Props> {
  eventHandler = (e: Event) => {
    if (this.props.allow) return;
    e.preventDefault();
    e.returnValue = false; // Chrome requires returnValue to be set
  }

  componentDidMount(): void {
    window.addEventListener('beforeunload', this.eventHandler, true);
  }

  componentWillUnmount(): void {
    window.removeEventListener('beforeunload', this.eventHandler, true);
  }

  render() {
    return (
      <React.Fragment>
        <Prompt when={!this.props.allow} message={this.props.message} />
        {this.props.children}
      </React.Fragment>
    );
  }
}

export const NavigationCheck = NavigationCheckController;
