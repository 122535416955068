import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import authActions, { AuthProps } from '../../actions/auth.actions';
import { StateRoot } from '../../store/interfaces';
import { sidebarStyle } from './styles';

// @material-ui/core components
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Face from '@material-ui/icons/Face';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AuthStatus } from '../../store/interfaces/auth';

interface Props {
  // handleCollapseOpenAvatar: () => Promise<void>;
  handleDrawerClose: () => void;
  mini: boolean;
}

interface TriggerProps {
  handleSignOut: () => void;
}

export const UserView = withTranslation(['menu'])(({
  auth,
  classes,
  handleDrawerClose,
  handleSignOut,
  mini,
  t,
}: Props & AuthProps & TriggerProps & WithStyles & WithTranslation) => {
  const [collapsed, setCollapsed] = React.useState(true);

  const handleSignOutClick = async () => {
    handleDrawerClose();
    setCollapsed(true);
    await handleSignOut();
  };
  const handleAutoClose = async () => {
    handleDrawerClose();
    setCollapsed(true);
  };

  const name = auth.status === AuthStatus.AUTHENTICATED
    // tslint:disable-next-line:no-any
    ? (auth.tokenSet.idToken.payload as any).name
    : '';

  const itemText = cx({
    [classes.itemText]: true,
    [classes.itemTextMini]: mini,
  });
  const collapseItemText = cx({
    [classes.collapseItemText]: true,
    [classes.collapseItemTextMini]: mini,
  });

  return (
    <List className={classes.list}>
      <ListItem className={classes.item}>
        <NavLink
          to={'#'}
          className={classes.itemLink}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => setCollapsed(!collapsed)}
        >
          <ListItemIcon className={classes.userItemIcon}>
            <Face />
          </ListItemIcon>
          <ListItemText
            primary={name}
            secondary={
              <b
                className={cx({
                  [classes.caret]: true,
                  [classes.caretActive]: !collapsed && !mini,
                })}
              />
            }
            disableTypography={true}
            className={itemText}
          />
        </NavLink>
        <Collapse in={!collapsed && !mini} unmountOnExit={true}>
          <List className={classes.list + ' ' + classes.collapseList}>
            <ListItem className={classes.collapseItem}>
              <NavLink
                to="/profile"
                className={classes.collapseItemLink}
                onClick={handleAutoClose}
              >
                <span className={classes.collapseItemMini}>
                  {t('user.profile.abbrev')}
                </span>
                <ListItemText
                  primary={t('user.profile.text')}
                  disableTypography={true}
                  className={collapseItemText}
                />
              </NavLink>
            </ListItem>
            <ListItem className={classes.collapseItem}>
              <NavLink
                to="#"
                className={classes.collapseItemLink}
                onClick={handleSignOutClick}
              >
                <span className={classes.collapseItemMini}>
                  {t('user.signOut.abbrev')}
                </span>
                <ListItemText
                  primary={t('user.signOut.text')}
                  disableTypography={true}
                  className={collapseItemText}
                />
              </NavLink>
            </ListItem>
          </List>
        </Collapse>
      </ListItem>
    </List>
  );
});

const mapStateToProps = (state: StateRoot): AuthProps => ({
  auth: state.auth,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): TriggerProps => ({
  handleSignOut: () => dispatch(authActions.signOut()),
});

export default connect<AuthProps, TriggerProps, Props, StateRoot>(mapStateToProps, mapDispatchToProps)(withStyles(sidebarStyle)(UserView));
