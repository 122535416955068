import { withStyles, WithStyles } from '@material-ui/core/styles';
import { WithT } from 'i18next';
import { BatchDataFieldSet } from '../../../domain/batch-dashboard/dto/batch-dashboard-data';
import { BatchFieldSet } from '../../../store/interfaces/batch-dashboard';
import { IndexOf } from '../../../store/types';
import { Field } from '../../batch/preview/field';
import { style } from './style';

export interface RowGraphProps {
  fieldSets: { [key: string]: BatchDataFieldSet };
  position: string;
  fieldSetIndex: IndexOf<BatchFieldSet> | null;
}

interface FieldDef {
  fieldSetId: string;
  fieldId: string;
  dataType: string | undefined;
  format: string | undefined;
  label: string;
  value: string | number | undefined;
}

const FieldsView: React.FunctionComponent<RowGraphProps & WithT & WithStyles> = ({
  classes,
  fieldSetIndex,
  fieldSets,
  position,
  t,
}) => {
  if (!fieldSetIndex) return null;

  function getFieldSetValue(fieldSetData: BatchDataFieldSet, fieldId: string) {
    if (!fieldSetData) return undefined;
    if (fieldSetData.type !== 'PerBatchDataFieldSet') return undefined;
    if (!fieldSetData.fields) return undefined;

    const fieldData = fieldSetData.fields[fieldId];
    return fieldData && fieldData.selected ? fieldData.selected.value : undefined;
  }

  const allFields = Object.keys(fieldSetIndex).reduce(
    (acc, fieldSetId) => {
      const fieldSet = fieldSetIndex[fieldSetId];
      if (fieldSet.type !== 'PerBatchFieldSet') return acc;

      return [
        ...acc,
        ...Object.keys(fieldSet.fields).reduce(
          (fieldAcc, fieldId) => {
            const field = fieldSet.fields[fieldId];
            if (!field.dashboardVisible) return fieldAcc;
            const value = getFieldSetValue(fieldSets[fieldSetId], fieldId);
            return [
              ...fieldAcc,
              { fieldSetId, fieldId, ...field, value },
            ];
          },
          [] as FieldDef[]
        ),
      ];
    },
    [] as FieldDef[]
  );
  const fieldsData = (position === 'left') ? allFields.slice(0, 3) : allFields.slice(3, 6);

  return (
    <div className={classes.fieldContainer}>
      {fieldsData.length
        ? fieldsData.map(
          (field, i) => (
            <Field
              key={i}
              format={field.format}
              label={field.label}
              t={t}
              type={field.dataType}
              value={field.value}
            />
          )
        )
        : <div />
      }
    </div>
  );
};

export const Fields = (withStyles(style)(FieldsView));
