import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import env from '../../environment';
import { clientCache } from '../graphql';
import { BatchData, DocumentReExtractDto, ModifyBatchDataResult } from './dto/batch-preview-data';
import { ModifyBatchFieldData } from './dto/batch-preview-modify-data';

const GQL_LOAD_BATCH = gql`
query ($batchId: String!) {
  batch(id: $batchId) {
    id
    batchWorkflowId
    regionId
    ignoredDocuments
    currentData {
      id
      fieldSets
      created
    }
    documents {
      id
      status
      currentDocumentWorkflow {
        id
        workflowId
      }
      pages {
        id
      }
      class
      design
    }
    currentLock {
        id
        lockedByUserId
        lockedByClientApplicationId
        locked
    }
    currentStatus {
        status
        created
    }
    currentCategory {
      categoryId
    }
  }
}`;

const GQL_LOAD_BATCH_DATA = gql`
query ($batchId: String!) {
  batch(id: $batchId) {
    id
    currentData {
      id
      fieldSets
      created
    }
  }
}`;

const GQL_RE_EXTRACT_DOCUMENT = gql`
mutation ($data: String!) {
  reExtractDocument(documentId: $data) {
    id
    status
  }
}`;

const GQL_MODIFY_BATCH = gql`
mutation ($data: ModifyBatchFieldData!) {
  modifyBatchData(data: $data) {
    shouldReloadDocuments
    batch {
      id
      batchWorkflowId
      regionId
      ignoredDocuments
      currentData {
        id
        fieldSets
      }
      documents {
        id
        status
        currentDocumentWorkflow {
          id
          workflowId
        }
        pages {
          id
        }
      }
      currentLock {
        id
        lockedByUserId
        lockedByClientApplicationId
        locked
      }
    }
  }
}`;

export class BatchPreviewRepository {
  public static forRegion(regionId: string): BatchPreviewRepository {
    const url = env.urlFactory.regional(regionId).service('data').resolve('graphql');
    const client = clientCache.getItem(url);
    if (!client) throw new Error('Unable to create GraphQL client');
    return new BatchPreviewRepository(client);
  }

  private static fixBatchData(raw: BatchData | null): void {
    if (!raw) return;

    if (!raw.currentData || !raw.currentData.fieldSets) return;

    Object.keys(raw.currentData.fieldSets).forEach((fieldSetId) => {
      // tslint:disable-next-line:no-any
      const fieldSet: any = raw.currentData.fieldSets[fieldSetId];
      if (fieldSet.fields) {
        fieldSet.type = 'PerBatchDataFieldSet';
      } else if (fieldSet.documents && fieldSet.documents.length) {
        const first = fieldSet.documents[0];
        if (first.fields) {
          fieldSet.type = 'PerDocumentHeaderDataFieldSet';
        } else {
          fieldSet.type = 'PerDocumentRepeatingDataFieldSet';
        }
      }
    });
  }

  constructor(
    public readonly client: ApolloClient<{}>, // TODO private
  ) { }

  public async findOne(batchId: string): Promise<BatchData | null> {
    const { data } = await this.client.query<{ batch: BatchData }>({
      fetchPolicy: 'no-cache',
      query: GQL_LOAD_BATCH,
      variables: { batchId },
    });

    if (data && data.batch) {
      data.batch.pages = data.batch.documents.reduce((a, b) => a + b.pages.length, 0);
      data.batch.ignoredDocuments = data.batch.ignoredDocuments ? data.batch.ignoredDocuments : [];
      BatchPreviewRepository.fixBatchData(data.batch);
      return data.batch;
    }
    return null;
  }

  public async saveData(saveData: ModifyBatchFieldData): Promise<ModifyBatchDataResult | null> {
    const { data } = await this.client.mutate<{ modifyBatchData: ModifyBatchDataResult }>({
      mutation: GQL_MODIFY_BATCH,
      variables: { data: saveData },
    });

    if (!data) {
      return null;
    }

    BatchPreviewRepository.fixBatchData(data.modifyBatchData.batch);
    return data.modifyBatchData;
  }

  public async getData(batchId: string): Promise<BatchData | null> {
    const { data } = await this.client.mutate<{ batch: BatchData }>({
      mutation: GQL_LOAD_BATCH_DATA,
      variables: { batchId },
    });

    if (!data) {
      return null;
    }

    BatchPreviewRepository.fixBatchData(data.batch);
    return data.batch;
  }

  public async reExtract(documentId: string): Promise<DocumentReExtractDto | null> {
    const { data } = await this.client.mutate<{ reExtract: DocumentReExtractDto }>({
      mutation: GQL_RE_EXTRACT_DOCUMENT,
      variables: { data: documentId },
    });

    if (!data) {
      return null;
    }

    return data.reExtract;
  }
}
