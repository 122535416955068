import { applyMiddleware, createStore, PreloadedState, Store as ReduxStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { StateRoot } from './interfaces';
import rootReducer from './reducer';
import { Reducer, ReduxAction } from './types';

export function createAction(reducer: Reducer): ReduxAction {
  return {
    type: reducer,
  };
}

export type Store = ReduxStore<StateRoot, ReduxAction>;

export function storeFactory(initialState?: PreloadedState<StateRoot>): Store {
  return createStore<StateRoot, ReduxAction, {}, {}>(rootReducer, initialState, applyMiddleware(thunk as ThunkMiddleware<StateRoot, ReduxAction>));
}

const store = storeFactory();
// tslint:disable-next-line:no-any
(window as any).store = store;
export default store;
