import { ThunkDispatch } from 'redux-thunk';
import {
  ModifyBatchField,
  ModifyBatchFieldSet,
  ModifyBatchFieldSource,
  ModifyBatchWorkflowData
} from '../domain/batch-workflow/dto/batch-workflow-modify-data';
import { PerBatchFieldSource } from '../domain/batch-workflow/dto/batch-workflows-and-fields';
import { WorkflowRepository } from '../domain/workflow/workflow.repository';
import { AuthStatus } from '../store/interfaces/auth';
import { BatchField, BatchFieldSet, BatchWorkflowModification, WorkflowField } from '../store/interfaces/batch-workflow';
import { CodedError } from '../store/interfaces/error';
import { AppMutations } from '../store/mutations/app.mutations';
import { BatchWorkflowAdminMutations } from '../store/mutations/batch-workflow-admin.mutations';
import { WorkflowMutations } from '../store/mutations/workflow.mutations';
import { Action, IndexOf } from '../store/types';
import { BatchWorkflowRepository } from './../domain/batch-workflow/batch-workflow.repository';
import { parseApolloError } from './../helpers/graphql';
import { BatchWorkflow } from './../store/interfaces/batch-workflow';
import { StateRoot } from './../store/interfaces/index';
import { ReduxAction } from './../store/types';

export namespace BatchWorkflowAdminActions {
  export function initBatchWorkflowAdmin(batchWorkflowId: number | undefined): Action {
    return async (dispatch, getState) => {
      const state = getState();

      if (state.batchWorkflowModification.loading) return;
      let modification: BatchWorkflowModification | undefined = state.batchWorkflowModification.query;

      if (!modification || (modification.batchWorkflowId !== batchWorkflowId)) {
        modification = { batchWorkflowId };
        dispatch(BatchWorkflowAdminMutations.setQuery(modification));
      }
      BatchWorkflowAdminMutations.setLoading();

      await loadBatchWorkflowAdminData(
        dispatch,
        getState,
        modification,
      );
    };
  }

  async function loadBatchWorkflowAdminData(
    dispatch: ThunkDispatch<StateRoot, null, ReduxAction>,
    getState: () => StateRoot,
    query: BatchWorkflowModification,
  ) {
    const state = getState();

    if (!query.batchWorkflowId) {
      const clientId = state.auth.status === AuthStatus.AUTHENTICATED
        ? state.auth.tokenSet.idToken.payload.cli
        : undefined;
      const defaultBatchWorkflow: BatchWorkflow = {
        categories: [],
        clientId,
        favoriteOrdinal: null,
        fieldSets: [],
        id: 0,
        isAutoReleaseEnabled: 0,
        name: '',
        repeatingFieldSets: undefined,
        userPermissions: [],
        workflowFields: undefined,
        workflows: undefined,
      };
      dispatch(BatchWorkflowAdminMutations.setValue(defaultBatchWorkflow));
      return;
    }

    const existingBatchWorkflowAdminData = state.batchWorkflowModification.value;

    if ((existingBatchWorkflowAdminData === undefined) && !state.batchWorkflowModification.error) {
      dispatch(BatchWorkflowAdminMutations.setLoading());
    }

    try {
      const repository = BatchWorkflowRepository.forGlobal();
      const batchWorkflow = await repository.findById(query.batchWorkflowId, false);
      dispatch(BatchWorkflowAdminMutations.setValue(batchWorkflow));
    } catch (err) {
      const errors = parseApolloError(err);
      errors.forEach((error) => {
        dispatch(BatchWorkflowAdminMutations.setError(new CodedError(error.message, 'error')));
        dispatch(AppMutations.setErrorSnackbar(new Error(error.message)));
      });
    }
  }

  export function loadWorkflowsByClientId(clientId: number): Action {
    return async (dispatch, getState) => {
      const state = getState();
      if (!state.batchWorkflowModification.value) return;

      const newState: BatchWorkflow = { ...state.batchWorkflowModification.value, workflows: undefined };
      dispatch(BatchWorkflowAdminMutations.setValue(newState));

      try {
        const repository = WorkflowRepository.forGlobal();
        const workflows = await repository.findWorkflowsByClientId(clientId);
        newState.workflows = WorkflowMutations.drawWorkflowsForState(workflows);
        dispatch(BatchWorkflowAdminMutations.setValue(newState));
      } catch (err) {
        const errors = parseApolloError(err);
        errors.forEach((error) => {
          dispatch(AppMutations.setErrorSnackbar(new Error(error.message)));
        });
      }
    };
  }

  export function loadWorkflowFieldSets(workflowId: number): Action {
    return async (dispatch, getState) => {
      const state = getState();
      if (!state.batchWorkflowModification.value) return;

      const newState: BatchWorkflow = { ...state.batchWorkflowModification.value, repeatingFieldSets: undefined };
      dispatch(BatchWorkflowAdminMutations.setValue(newState));

      try {
        const repository = WorkflowRepository.forGlobal();
        const workflowData = await repository.findWorkflowData(workflowId);
        newState.repeatingFieldSets = WorkflowMutations.drawFieldSetsForState(workflowData);
        dispatch(BatchWorkflowAdminMutations.setValue(newState));
      } catch (err) {
        const errors = parseApolloError(err);
        errors.forEach((error) => {
          dispatch(AppMutations.setErrorSnackbar(new Error(error.message)));
        });
      }
    };
  }

  export function loadWorkflowFields(workflowId: number, type: 'header' | 'repeating', batchFieldSetFieldSetId: number | undefined): Action {
    return async (dispatch, getState) => {
      const state = getState();
      if (!state.batchWorkflowModification.value) return;

      const newState: BatchWorkflow = { ...state.batchWorkflowModification.value, workflowFields: undefined };
      dispatch(BatchWorkflowAdminMutations.setValue(newState));

      try {
        const repository = WorkflowRepository.forGlobal();
        const workflow = await repository.findWorkflowData(workflowId);
        let fieldsForState: IndexOf<WorkflowField> = {};
        workflow.fields.forEach((field) => {
          if (
            ((type === 'header') && (!field.fieldSet || !field.fieldSet.isRepeating)) ||
            ((type === 'repeating') && field.fieldSet && (field.fieldSet.id === batchFieldSetFieldSetId))
          ) {
            fieldsForState[field.displayOrder.toString()] = {
              displayOrder: field.displayOrder,
              id: field.id,
              name: field.name,
            };
          }
        });
        newState.workflowFields = fieldsForState;
        dispatch(BatchWorkflowAdminMutations.setValue(newState));
      } catch (err) {
        const errors = parseApolloError(err);
        errors.forEach((error) => {
          dispatch(AppMutations.setErrorSnackbar(new Error(error.message)));
        });
      }
    };
  }

  function getModifyBatchFieldSources(sources: PerBatchFieldSource[]): ModifyBatchFieldSource[] {
    let sequence = 0;
    const modifyBatchFieldSources: ModifyBatchFieldSource[] = sources.map((source: PerBatchFieldSource) => {
      const modifySource: ModifyBatchFieldSource = {
        fieldId: source.fieldId ? source.fieldId : 0,
        id: source.id,
        priority: (sequence += 10),
        source: source.source ? source.source : '',
      };
      return modifySource;
    });
    return modifyBatchFieldSources;
  }

  function getModifyBatchFields(fields: BatchField[], fieldSetType: string): ModifyBatchField[] {
    let sequence = 0;
    const modifyBatchFields: ModifyBatchField[] = fields.map((field: BatchField) => {
      const modifyField: ModifyBatchField = {
        dataType: field.dataType ? field.dataType : '',
        id: field.id,
        label: field.label,
        ordinal: (sequence += 10),
      };
      if (fieldSetType === 'PerBatch') {
        if (field.sources) {
          const sources: ModifyBatchFieldSource[] = getModifyBatchFieldSources(field.sources);
          modifyField.sources = sources;
        }
      } else {
        modifyField.fieldId = field.field ? field.field.id : undefined;
      }
      return modifyField;
    });
    return modifyBatchFields;
  }

  function getModifyBatchFieldSets(fieldSets: BatchFieldSet[]): ModifyBatchFieldSet[] {
    let sequence = 0;
    const modifyBatchFieldSets: ModifyBatchFieldSet[] = fieldSets.reduce((acc: ModifyBatchFieldSet[], fieldSet: BatchFieldSet) => {
      let index: number = fieldSet.__typename.indexOf('FieldSet');
      if (index < 0) return acc;
      const fieldSetType: string = fieldSet.__typename.substring(0, index);
      if (['PerBatch', 'PerDocumentHeader', 'PerDocumentRepeating'].indexOf(fieldSetType) < 0) return acc;

      const fields: ModifyBatchField[] = getModifyBatchFields(fieldSet.fields, fieldSetType);

      const modifyFieldSet: ModifyBatchFieldSet = {
        fields,
        id: fieldSet.id,
        label: fieldSet.label,
        ordinal: (sequence += 10),
        type: fieldSetType,
      };
      if (fieldSetType !== 'PerBatch') {
        modifyFieldSet.workflowId = fieldSet.workflowData ? fieldSet.workflowData.id : undefined;
        if (fieldSetType === 'PerDocumentRepeating') {
          modifyFieldSet.fieldSetId = fieldSet.workflowData && fieldSet.workflowData.fieldSet ? fieldSet.workflowData.fieldSet.id : undefined;
        }
      }
      acc.push(modifyFieldSet);
      return acc;
      // tslint:disable-next-line:align
    }, []);
    return modifyBatchFieldSets;
  }

  export function save(batchWorkflow: BatchWorkflow): Action {
    return async (dispatch, getState) => {
      const saveData: ModifyBatchWorkflowData = {
        fieldSets: getModifyBatchFieldSets(batchWorkflow.fieldSets),
        id: batchWorkflow.id,
        isAutoReleaseEnabled: batchWorkflow.isAutoReleaseEnabled,
        name: batchWorkflow.name,
      };
      dispatch(BatchWorkflowAdminMutations.setLoading());

      try {
        const repository = BatchWorkflowRepository.forGlobal();
        await repository.saveModifications(saveData);
        // When data is saved, BatchWorkflowAdminController::componentDidMount() is called, where fresh data
        // is loaded to get in the client all IDs generated for new objects on the server; thus, there is
        // no need to load data here, we just need to set some value to stop displaying loading circle:
        dispatch(BatchWorkflowAdminMutations.setValue(null));

        dispatch(AppMutations.setSnackbarMessage({
          autoHideDuration: 5000,
          messageTranslationKey: 'batchWorkflowEdit.general.savedSuccessfully',
          variant: 'success',
        }));
      } catch (err) {
        const errors = parseApolloError(err);
        errors.forEach((error) => {
          dispatch(BatchWorkflowAdminMutations.setError(new CodedError(error.message, 'error')));
          dispatch(AppMutations.setErrorSnackbar(new Error(error.message)));
        });
      }
    };
  }
}
