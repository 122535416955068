import { objectEquals } from '../helpers/object-equals';
import { CodedError } from './interfaces/error';

export type HasChanged<Q, T> = (previous: T | null, query: Q) => boolean;

export type ValueFullyLoaded<T> = (value: T | null) => boolean;

export class Loadable<Q, T> {
  public static initial<Q, T>(
    hasChanged: HasChanged<Q, T>,
    valueFullyLoaded: ValueFullyLoaded<T> = () => true
  ): Loadable<Q, T> {
    return new Loadable<Q, T>(
      undefined,
      undefined,
      false,
      null,
      hasChanged,
      valueFullyLoaded,
    );
  }

  get loadRequired() {
    return !this.loading && (this.query === undefined || this.value === undefined);
  }

  get valuePending() {
    const hasQuery = this.query !== undefined;
    const loaded = !hasQuery || (this.value !== undefined && this.privateValueFullyLoaded(this.value));
    return !loaded;
  }

  constructor(
    public readonly query: Q | undefined,
    public readonly value: T | null | undefined,
    public readonly loading: boolean,
    public readonly error: CodedError | null,
    private readonly privateHasChangedByQuery: HasChanged<Q, T>,
    private readonly privateValueFullyLoaded: ValueFullyLoaded<T>,
  ) { }

  public hasChangedByQuery(query: Q) {
    return this.value === undefined || this.privateHasChangedByQuery(this.value, query);
  }

  public setQuery(query: Q, retainValue: boolean = false): Loadable<Q, T> {
    const changed = this.hasChangedByQuery(query) || !objectEquals(this.query, query);
    return new Loadable<Q, T>(
      query,
      changed && !retainValue ? undefined : this.value,
      false,
      null,
      this.privateHasChangedByQuery,
      this.privateValueFullyLoaded,
    );
  }

  public setLoading(loading: boolean = true): Loadable<Q, T> {
    return new Loadable<Q, T>(
      this.query,
      this.value,
      loading,
      null,
      this.privateHasChangedByQuery,
      this.privateValueFullyLoaded,
    );
  }

  public clearValue(): Loadable<Q, T> {
    return new Loadable<Q, T>(
      this.query,
      undefined,
      false,
      null,
      this.privateHasChangedByQuery,
      this.privateValueFullyLoaded,
    );
  }

  public clear(): Loadable<Q, T> {
    return new Loadable<Q, T>(
      undefined,
      undefined,
      false,
      null,
      this.privateHasChangedByQuery,
      this.privateValueFullyLoaded,
    );
  }

  public setValue(value: T | null, finishedLoading: boolean = true): Loadable<Q, T> {
    return new Loadable<Q, T>(
      this.query,
      value || null,
      !finishedLoading,
      null,
      this.privateHasChangedByQuery,
      this.privateValueFullyLoaded,
    );
  }

  public setError(error: CodedError): Loadable<Q, T> {
    return new Loadable<Q, T>(
      this.query,
      undefined,
      false,
      error,
      this.privateHasChangedByQuery,
      this.privateValueFullyLoaded,
    );
  }
}

export type SimpleLoadable<T> = SimpleLoadableValue<T> | SimpleLoadableError | SimpleLoadableLoading;

export interface SimpleLoadableValue<T> {
  state: 'value';
  value: T;
}

export interface SimpleLoadableError {
  state: 'error';
  error: CodedError;
}

export interface SimpleLoadableLoading {
  state: 'loading';
}
