import { Tooltip } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { WithT } from 'i18next';
import { NavLink } from 'react-router-dom';
import { BatchWorkflow } from '../../../store/interfaces/batch-workflow';

export interface RowProps {
  batchWorkflow: BatchWorkflow;
  isUserBatchWorkflowAdmin: boolean;
  handleEditBatchWorkflow(batchWorkflowId: number): void;
}

const Row: React.FunctionComponent<RowProps & WithStyles & WithT> = ({
  batchWorkflow,
  isUserBatchWorkflowAdmin,
  handleEditBatchWorkflow,
  classes,
  t,
}) => {

  const disabled = !isUserBatchWorkflowAdmin
    ? ' ' + classes.disabled
    : '';

  function onClickEdit(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    event.preventDefault();
    if (disabled === '') {
      handleEditBatchWorkflow(batchWorkflow.id);
    }
  }

  return (
    <NavLink to={`/batch-workflow/${batchWorkflow.id}/dashboard`} className="row">
      <div>
        {batchWorkflow.name}
      </div>
      <div className={'right ' + classes.batchWorkflowListRowActionContainer}>
        <Tooltip title={t('batchWorkflowList.tooltip.view')!}>
          <VisibilityIcon className={classes.batchWorkflowListActionButton} />
        </Tooltip>
        <Tooltip title={t('batchWorkflowList.tooltip.edit')!}>
          <EditIcon onClick={onClickEdit} className={classes.batchWorkflowListActionButton + ' ' + classes.batchWorkflowEditButton + disabled} />
        </Tooltip>
      </div>
    </NavLink>
  );
};

export default Row;
