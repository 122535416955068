import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import { createStyles, Theme } from '@material-ui/core/styles';

export const style = (theme: Theme) => createStyles({
  batchFieldSet: {
    marginBottom: theme.spacing(),
  },
  batchId: {
    color: '#888',
    fontSize: '0.9rem',
    textAlign: 'end',
  },
  batchTitle: {
    fontSize: '1.2rem',
    paddingBottom: '0.4rem',
  },
  colorValidationHard: {
    color: '#b71c1c !important',
  },
  colorValidationSoft: {
    color: '#e65100 !important',
  },
  confidenceGraph: {
    display: 'table',
    height: theme.spacing(),
    width: '100%',
  },
  confidenceGraphBar: {
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    verticalAlign: 'middle',
    width: theme.spacing(20),
  },
  confidenceGraphGrey: {
    backgroundColor: grey[400],
    display: 'table-cell',
  },
  confidenceGraphHigh: {
    backgroundColor: green[600],
    display: 'table-cell',
  },
  confidenceGraphLow: {
    backgroundColor: red[600],
    display: 'table-cell',
  },
  confidenceGraphMid: {
    backgroundColor: yellow[600],
    display: 'table-cell',
  },
  confidenceGraphPaper: {
    '& span:first-child': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderTopLeftRadius: theme.shape.borderRadius,
    },
    '& span:last-child': {
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    display: 'table-row',
  },
  confidenceHigh: {
    color: green[800],
  },
  confidenceLow: {
    color: red[800],
  },
  confidenceMid: {
    color: yellow[800],
  },
  confidenceTotal: {
  },
  controlValidationHard: {
    backgroundColor: '#ef9a9a !important',
    borderColor: '#b71c1c !important',
  },
  controlValidationSoft: {
    backgroundColor: '#ffcc80 !important',
    borderColor: '#e65100 !important',
  },
  documentFieldSet: {
    marginBottom: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  documentInfoIcon: {
    verticalAlign: 'middle',
  },
  documentTitle: {
    marginRight: theme.spacing(2),
    verticalAlign: 'middle',
  },
  expansionContent: {
    margin: 0,
  },
  expansionDetail: {
    display: 'block',
    padding: 0,
  },
  expansionExpanded: {
    margin: '0px !important',
  },
  expansionIcon: {
    top: -theme.spacing(3),
  },
  expansionSummary: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(),
  },
  fieldRoot: {
    paddingRight: theme.spacing(),
    paddingTop: theme.spacing(),
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: '30%',
  },
  grey: {
    color: 'grey',
  },
  greyEm: {
    color: 'grey',
    fontSize: '10px',
  },
  grow: {
    flexGrow: 1,
  },
  includeInOutputCheckBox: {
    position: 'relative',
    right: theme.spacing(),
    top: -theme.spacing(1 / 4),
  },
  inlineBlock: {
    display: 'inline-block',
    flexGrow: 1,
  },
  inlineFlex: {
    display: 'inline-flex',
    verticalAlign: 'top',
    width: '100%',
  },
  moreIconButton: {
    marginTop: -theme.spacing(0.5),
  },
  padded: {
    padding: '4px 56px 4px 24px',
  },
  problemCard: {
    '&:first-child': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    '&:not(:first-child)': {
      borderTopStyle: 'none',
    },
    backgroundColor: '#ef9a9a !important',
    borderColor: '#b71c1c !important',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  pullRight: {
    float: 'right',
  },
  referenceDocumentInfo: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(),
  },
  rowHARDField: {
    backgroundColor: '#ef9a9a',
    color: '#b71c1c',
  },
  rowSOFTField: {
    backgroundColor: '#ffcc80',
    color: '#e65100',
  },
  select: {
    flexGrow: 1,
    minWidth: '250px',
  },
  selectHARD: {
    borderColor: '#b71c1c',
  },
  selectSOFT: {
    borderColor: '#e65100',
  },
  tableCell: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  textField: {
    width: '250px',
  },
  tooltip: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    maxWidth: 350,
  },
});
