import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'react-image-lightbox/style.css';
import { Provider } from 'react-redux';
import App from './App';
import { CircularProgressComponent } from './components/circular-progress';
import './i18n';
import './index.scss';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import { Dispatch } from './store/types';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      popper: {
        zIndex: 5000,
      },
      tooltip: {
        fontSize: '13px',
      },
    },
  },
  palette: {
    primary: { main: '#0071CE' },
    secondary: { main: '#00B188', contrastText: '#FFFFFF' },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <Suspense fallback={<CircularProgressComponent />}>
        <App auth={store.getState().auth} dispatch={store.dispatch as Dispatch} />
      </Suspense>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root') as HTMLElement
);

registerServiceWorker();
