import { createAction } from '..';
import { BatchWorkflow } from '../interfaces/batch-workflow';
import { CodedError } from '../interfaces/error';

export namespace BatchWorkflowMutations {
  export const currentId = (batchWorkflowId: number | undefined) => createAction((state) => ({
    ...state,
    batchWorkflow: batchWorkflowId
      ? state.batchWorkflow.setQuery(batchWorkflowId)
      : state.batchWorkflow.clear(),
  }));

  export const currentError = (err: CodedError) => createAction((state) => ({
    ...state,
    batchWorkflow: state.batchWorkflow.setError(err),
  }));

  export const current = (batchWorkflow: BatchWorkflow | null) => createAction((state) => ({
    ...state,
    batchWorkflow: batchWorkflow && (state.batchWorkflow.query === batchWorkflow.id)
      ? state.batchWorkflow.setValue(batchWorkflow || null)
      : state.batchWorkflow,
  }));

  export const noCurrent = () => createAction((state) => ({
    ...state,
    batchWorkflow: state.batchWorkflow.clearValue(),
  }));

  export const results = (batchWorkflows: BatchWorkflow[]) => createAction((state) => ({
    ...state,
    batchWorkflows: state.batchWorkflows.loaded(batchWorkflows),
  }));

  export const loading = () => createAction((state) => ({
    ...state,
    batchWorkflows: state.batchWorkflows.startLoading(false),
  }));

  export const error = (err: CodedError) => createAction((state) => ({
    ...state,
    batchWorkflows: state.batchWorkflows.loadError(err),
  }));
}
