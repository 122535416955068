import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import env from '../../environment';
import { clientCache } from '../graphql';
import { Page } from './../../store/interfaces/batch-modification';

const GQL_LOAD_INPUT_FILES_BY_IDS = gql`
query ($ids: [String!]!) {
  inputFileByIds(ids: $ids) {
    id
    inputSessionId
    dataSourceId
    workflowId
    pages {
      id
      thumbnail {
        url
      }
    }
  }
}`;

const GQL_GET_LEGACY_ELS_URL = gql`
query ($inputFileId: String!) {
  inputFileLegacyElsUrl(inputFileId: $inputFileId)
}`;

interface InputFile {
  id: string;
  inputSessionId: string;
  pages: Page[];
  dataSourceId: number;
  workflowId: number;
}

export class InputFileRepository {
  public static forRegion(regionId: string): InputFileRepository {
    const url = env.urlFactory.regional(regionId).service('data').resolve('graphql');
    const client = clientCache.getItem(url);
    if (!client) throw new Error('Unable to create GraphQL client');
    return new InputFileRepository(client);
  }

  constructor(
    public readonly client: ApolloClient<{}>, // TODO private
  ) { }

  public async findByIds(inputFileIds: string[]): Promise<InputFile[]> {
    const { data } = await this.client.query<{ inputFileByIds: InputFile[] }>({
      query: GQL_LOAD_INPUT_FILES_BY_IDS,
      variables: { ids: inputFileIds },
    });
    return data.inputFileByIds;
  }

  public async getLegacyElsUrl(inputFileId: string): Promise<string | undefined> {
    const { data } = await this.client.query<{ inputFileLegacyElsUrl: string | undefined }>({
      fetchPolicy: 'no-cache',
      query: GQL_GET_LEGACY_ELS_URL,
      variables: { inputFileId },
    });
    return data.inputFileLegacyElsUrl;
  }
}
