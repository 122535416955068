import { Token, TokenSet } from '../../store/interfaces/token';

export interface Base64TokenSet {
  idToken: string;
  refreshToken: string;
}

const TOKEN_PARTS = 3;
const TOKEN_BODY_INDEX = 1;

export function decodeTokenSet(data: Base64TokenSet): TokenSet | undefined {
  if (!data) return undefined;

  const idToken = decodeToken(data.idToken);
  const refreshToken = decodeToken(data.refreshToken);

  if (!idToken || !refreshToken) return undefined;

  return {
    idToken,
    refreshToken,
  };
}

export function decodeToken(raw: string): Token | null {
  if (!raw) return null;
  const payload = decomposeJwt(raw);
  if (!payload) return null;
  return { payload, raw, expires: payload.exp * 1000 };
}

function decomposeJwt(token: string): { exp: number } | null {
  if (!token || token.split('.').length !== TOKEN_PARTS) {
    return null;
  }

  const base64Url = token.split('.')[TOKEN_BODY_INDEX];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  try {
    return JSON.parse(decodeURIComponent(window.atob(base64)));
  } catch (error) {
    return null;
  }
}
