import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

export const style = (theme: Theme) => createStyles({
  circularProgressContainer: {
    height: '100%',
  },
  progress: {
    marginTop: theme.spacing(4),
  },
});

const CircularProgressView = withStyles(style)(({
  classes,
}: WithStyles) => (
    <Grid className={classes.circularProgressContainer} item={true} container={true} justify="center" alignItems={'center'} xs={12}>
      <CircularProgress className={classes.progress} />
    </Grid>
  ));

export const CircularProgressComponent = CircularProgressView;
