export interface WithRegionId {
  regionId: string;
}

export interface MaybeWithRegionId {
  regionId?: string;
}

export function withRegionId<T>(WrappedComponent: React.ComponentType<T & WithRegionId>) {
  return (props: T & MaybeWithRegionId) => props.regionId ? <WrappedComponent regionId={props.regionId} {...props} /> : null;
}
