import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BatchPreviewProblems } from '../../../actions/batch-preview.helpers';
import { RelativeDate } from '../../../components/relative-date';
import { BatchPreviewData, ConfidenceLevels } from '../../../store/interfaces/batch-preview';
import { ConfidenceBarComponent } from './confidence-bar';
import { Field } from './field';
import { style } from './style';

export interface WithPreview {
  preview: BatchPreviewData;
}

interface WithSummary {
  summary: {
    batchConfidence: ConfidenceLevels | null;
    batchStatus: string | undefined;
    documentCount: number,
    hasProblem: boolean,
    pageCount: number,
    problems: BatchPreviewProblems,
    dataUpdated?: DateTime,
  };
}

class PreviewSummaryController extends React.PureComponent<WithPreview> {
  render() {
    const { preview } = this.props;

    const currentData = preview.batch && preview.batch.currentData
      ? preview.batch.currentData
      : undefined;

    const batchStatus = preview.batch.currentStatus ? preview.batch.currentStatus.status : undefined;

    const documents = preview.documents;
    const summary = {
      batchConfidence: preview.confidenceLevels ? preview.confidenceLevels.confidence : null,
      batchStatus,
      dataUpdated: currentData ? DateTime.fromISO(currentData.created) : undefined,
      documentCount: Object.keys(documents).length,
      hasProblem: Object.keys(preview.problems).length > 0,
      pageCount: Object.keys(documents).reduce(
        (acc, documentId) => {
          const document = documents[documentId];
          return acc + document.pages;
        },
        0
      ),
      problems: preview.problems,
    };

    return (
      <PreviewSummaryView
        summary={summary}
      />
    );
  }
}

const PreviewSummaryView = withStyles(style)(withTranslation()(({
  classes,
  summary,
  t,
}: WithSummary & WithStyles & WithTranslation) => (
    <Card>
      {summary.problems.batchStatus
        ?
        <CardContent className={classes.problemCard}>
          {t('batchPreview.problems.batchStatus')}
        </CardContent>
        : null}
      {summary.problems.batchFieldSetValidation
        ?
        <CardContent className={classes.problemCard}>
          {t('batchPreview.problems.batchFieldSetValidation', { count: Object.keys(summary.problems.batchFieldSetValidation).length })}
        </CardContent>
        : null}
      {summary.problems.documentStatus
        ?
        <CardContent className={classes.problemCard}>
          {t('batchPreview.problems.documentStatus', { count: Object.keys(summary.problems.documentStatus).length })}
        </CardContent>
        : null}
      {summary.problems.documentValidation
        ?
        <CardContent className={classes.problemCard}>
          {t('batchPreview.problems.documentValidation', { count: Object.keys(summary.problems.documentValidation).length })}
        </CardContent>
        : null}
      <CardContent>
        <Grid container={true}>
          <Grid item={true} xs={12} md={4} className={classes.fieldRoot}>
            <Field
              label={t('batchPreview.summaryHeader.dataUpdated')}
              t={t}
              value={summary.dataUpdated ? <RelativeDate date={summary.dataUpdated} /> : undefined}
            />
          </Grid>
          <Grid item={true} xs={12} md={4} className={classes.fieldRoot}>
            <Field
              label={t('batchPreview.summaryHeader.documentCount')}
              t={t}
              value={summary.documentCount}
            />
          </Grid>
          <Grid item={true} xs={12} md={4} className={classes.fieldRoot}>
            <Field
              label={t('batchPreview.summaryHeader.pageCount')}
              t={t}
              value={summary.pageCount}
            />
          </Grid>
        </Grid>
      </CardContent>
      {summary.batchConfidence ?
        <CardContent>
          <ConfidenceBarComponent documentConfidence={summary.batchConfidence} t={t} />
        </CardContent>
        : null}
    </Card>
  )
));

export const PreviewSummary = PreviewSummaryController;
