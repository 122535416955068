import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import UndoIcon from '@material-ui/icons/Undo';
import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { withTranslation, WithTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BatchModificationActions } from '../../../actions/batch-modification.actions';
import { BatchActions } from '../../../actions/batch.actions';
import AppBar, { AppBarView } from '../../../components/app-bar';
import { CircularProgressComponent } from '../../../components/circular-progress';
import {
  BatchLockedComponent,
  BatchReleaseComponent,
  BatchUpdatedComponent,
  ErrorComponent,
  GeneralErrorComponent
} from '../../../components/error';
import { withHotKeys, WithHotKeys } from '../../../components/hot-keys';
import { IconButtonWithTooltip } from '../../../components/icon-button-tooltip';
import { NavigationCheck } from '../../../components/navigation-check';
import PageContainer from '../../../components/page-container';
import SwitchToNewELSButton from '../../../components/switch-new-app-button';
import { VerticalDivider } from '../../../components/vertical-divider';
import { WindowTitle } from '../../../components/window-title';
import { batchHotKeys } from '../../../helpers/hot-keys';
import { RouterProps, WithBatchId } from '../../../helpers/router-props';
import { BatchQuery, StateRoot } from '../../../store/interfaces';
import { AuthenticationState, AuthStatus } from '../../../store/interfaces/auth';
import { BatchStatus } from '../../../store/interfaces/batch';
import { BatchModification } from '../../../store/interfaces/batch-modification';
import { Loadable } from '../../../store/loader';
import { BatchModificationMutations } from '../../../store/mutations/batch-modification.mutations';
import { NotFoundScene } from '../../not-found';
import { DocumentList } from './document-list';
import { style } from './style';

interface Props {
  auth: AuthenticationState;
  data: Loadable<BatchQuery, BatchModification>;

  // include region in here to force reloading of pure component
  regionId?: string;
}

interface Triggers {
  save: () => void;
  undo: () => void;
  unload: () => void;
  closeLightbox: () => void;
  lockBatch: (batchId: string, regionId: string) => void;
  unlockBatch: (batchId: string, regionId: string) => void;
  releaseBatch: (batchId: string, batchWorkflowId: number, regionId: string) => void;
  removePage: (pageId: string, documentId: string) => void;
  subscribe: (regionId: string, batchWorkflowId: number) => void;
  duplicatePage: (pageId: string, documentId: string) => void;
}

type ControllerProps = Props & Triggers & RouteComponentProps<WithBatchId> & WithHotKeys & WithTranslation & WithStyles;

class BatchModificationController extends React.PureComponent<ControllerProps> {
  componentDidMount() {
    RouterProps.handleBatchWorkflowRoute(this.props);
    RouterProps.handleBatchModificationRoute(this.props, true);
    this.props.bindHotKey(['ctrl+z', 'command+z'], this.props.undo);
  }

  componentDidUpdate(prevProps: ControllerProps): void {
    RouterProps.handleBatchWorkflowRoute(prevProps, this.props);
    RouterProps.handleBatchModificationRoute(prevProps);

    if (this.props.data.query && this.props.data.query.batchWorkflowId && this.props.data.query.regionId) {
      this.props.subscribe(this.props.data.query.regionId, this.props.data.query.batchWorkflowId);
    }
  }

  componentWillUnmount(): void {
    this.props.unload();
  }

  render() {
    return <BatchModificationView {...this.props} />;
  }
}

const TitleView: React.FunctionComponent<Props & Triggers & RouteComponentProps<WithBatchId> & WithTranslation & WithStyles> = ({
  classes,
  data,
  save,
  t,
  undo,
  history,
  unlockBatch,
}) => {

  function handleProcessBatch() {
    if (data.query) {
      history.replace({
        pathname: `/batch-workflow/${data.query.batchWorkflowId}/batch/${data.query.batchId}/learning`,
      });
    }
  }

  function handlePreviewBatch() {
    if (data.query) {
      history.replace({
        pathname: `/batch-workflow/${data.query.batchWorkflowId}/batch/${data.query.batchId}/preview`,
      });
    }
  }

  function handleBack() {
    if (data.query) {
      history.goBack();
    }
  }

  function handleExitUnlock() {
    if (data.query && data.query.batchId && data.query.regionId) {
      unlockBatch(data.query.batchId, data.query.regionId);

      history.replace(`/batch-workflow/${data.query.batchWorkflowId}/dashboard`);
    }
  }

  const keyHandler = {
    exit: (event?: KeyboardEvent) => {
      event?.preventDefault();
      handleExitUnlock();
    },
    save: (event?: KeyboardEvent) => {
      event?.preventDefault();
      save();
    },
  };

  let allowUndo = data.value ? data.value.undoStack.length > 1 : false;
  return (
    <GlobalHotKeys keyMap={batchHotKeys} handlers={keyHandler}>
      <AppBarView>
        <AppBar>
          <WindowTitle title={t('batchModification.pageTitle', { batchId: data.query ? data.query.batchId : '' })} />
          <IconButtonWithTooltip
            onClick={handleBack}
            tooltip={t('terms.back')}
          >
            <BackIcon />
          </IconButtonWithTooltip>
          <Tooltip title={t('batchModification.actions.exitUnlock')!}>
            <Button onClick={handleExitUnlock} color="inherit">{t('batchModification.actions.exit')}</Button>
          </Tooltip>
          <VerticalDivider height={'30px'} horizontalMargin={'20px'} />
          <Typography variant="h6" color="inherit" className={classes.grow}>
            {t('batchModification.pageTitle', { batchId: data.query ? data.query.batchId : '' })}
          </Typography>
          {data.value
            ?
            <React.Fragment>
              <SwitchToNewELSButton batchId={data.query?.batchId} batchWorkflowId={data.query?.batchWorkflowId} />
              <Button onClick={handlePreviewBatch} color="inherit">{t('batchModification.actions.previewBatch')}</Button>
              <Button onClick={handleProcessBatch} color="inherit">{t('batchModification.actions.processBatch')}</Button>
              <IconButtonWithTooltip
                onClick={undo}
                disabled={!allowUndo}
                tooltip={t('batchModification.undo')}
              >
                <UndoIcon />
              </IconButtonWithTooltip>
              <Button onClick={save} color="inherit">{t('terms.save')}</Button>
            </React.Fragment>
            : null
          }
        </AppBar>
      </AppBarView>
    </GlobalHotKeys>
  );
};

const BatchModificationView: React.FunctionComponent<Props & Triggers & RouteComponentProps<WithBatchId> & WithTranslation & WithStyles> = (
  props,
) => {
  function handleCancelRelease() {
    if (props.data.query && props.data.query.batchId && props.data.query.regionId) {
      props.history.replace(`/batch-workflow/${props.data.query.batchWorkflowId}/dashboard`);
    } else {
      props.history.goBack();
    }
  }

  function handleReleaseBatch() {
    if (props.data.query && props.data.query.batchId && props.data.query.batchWorkflowId && props.regionId) {
      props.releaseBatch(props.data.query.batchId, props.data.query.batchWorkflowId, props.regionId);
    }
  }

  function handleDeletePage() {
    if (!props.data.value || !props.data.value.lightboxModal) return;
    props.removePage(props.data.value.lightboxModal.id, props.data.value.lightboxModal.documentId);
  }

  function handleDuplicatePage() {
    if (!props.data.value || !props.data.value.lightboxModal) return;
    props.duplicatePage(props.data.value.lightboxModal.id, props.data.value.lightboxModal.documentId);
  }

  if (props.data.error) {
    return <ErrorComponent error={props.data.error} />;
  }

  if (props.data.loading || props.data.valuePending) {
    return <CircularProgressComponent />;
  }

  if (props.data.value) {
    const currentUserId = props.auth.status === AuthStatus.AUTHENTICATED
      ? props.auth.tokenSet.idToken.payload.sub
      : undefined;

    if (props.data.value.batch.currentLock && props.data.value.batch.currentLock.lockedByUser
      && (props.data.value.batch.currentLock.lockedByUserId !== currentUserId)) {
      return <BatchLockedComponent lockedBy={props.data.value.batch.currentLock.lockedByUser.name} />;
    }

    if (!props.data.value.batch.currentStatus) {
      return <GeneralErrorComponent message={props.t('errors.batch-no-status')} />;
    }

    if (props.data.value.batch.currentStatus.status === BatchStatus.HOLD) {
      return (
        <BatchReleaseComponent
          batchId={props.data.value.batch.id}
          handleCancel={handleCancelRelease}
          handleRelease={handleReleaseBatch}
        />);
    }

    if (props.data.value.subUpdateInfo) {
      const subUpdateInfo = props.data.value.subUpdateInfo;
      let body = '';
      if (subUpdateInfo.type === BatchStatus.DELETED || subUpdateInfo.type === BatchStatus.HOLD) {
        body = props.t(`errors.subscription.` + subUpdateInfo.type, { name: props.t(subUpdateInfo.by) });
      } else {
        body = props.t('batchPreview.problems.batchUnlocked', { name: props.t(subUpdateInfo.by) });
      }

      return (
        <BatchUpdatedComponent
          handleUpdate={handleCancelRelease}
          title={props.t('batchPreview.pageTitle', { batchId: props.data.value.batch.id })}
          body={body}
        />
      );
    }

    if (props.data.value.batch.currentStatus.status !== BatchStatus.READY) {
      return <GeneralErrorComponent message={props.t(`errors.batch-in-${props.data.value.batch.currentStatus.status}-status`)} />;
    }

    if (props.data.query && props.data.query.batchId && props.data.query.regionId && !props.data.value.batch.currentLock) {
      props.lockBatch(props.data.query.batchId, props.data.query.regionId);
    }

    return (
      <NavigationCheck
        allow={!props.data.value || (props.data.value.undoStack.length <= 1)}
        message={props.t('terms.navigationCheck')}
      >
        <PageContainer>
          <Grid item={true} xs={12}>
            <TitleView {...props} />
          </Grid>
          <Grid item={true} xs={12}>
            <DocumentList {...props} />
          </Grid>
          {
            props.data.value && props.data.value.lightboxModal && (
              <Lightbox
                mainSrc={props.data.value.lightboxModal.fullImage
                  ? props.data.value.lightboxModal.fullImage.url
                  : props.data.value.lightboxModal.thumbnail.url}
                mainSrcThumbnail={props.data.value.lightboxModal.thumbnail.url}
                onCloseRequest={props.closeLightbox}
                reactModalStyle={{ content: { zIndex: 1300 }, overlay: { zIndex: 1200 } }}
                toolbarButtons={[
                  <IconButtonWithTooltip
                    key={'deletePageButton'}
                    onClick={handleDeletePage}
                    color="inherit"
                    tooltip={props.t('batchModification.manipulations.deletePage')}
                  >
                    <DeleteIcon />
                  </IconButtonWithTooltip>,
                  <IconButtonWithTooltip
                    key={'duplicatePageButton'}
                    onClick={handleDuplicatePage}
                    color="inherit"
                    tooltip={props.t('batchModification.manipulations.duplicate')}
                  >
                    <DuplicateIcon />
                  </IconButtonWithTooltip>,
                ]}
              />
            )
          }
        </PageContainer>
      </NavigationCheck>
    );
  }

  return <NotFoundScene />;
};

const mapStateToProps = (state: StateRoot): Props => ({
  auth: state.auth,
  data: state.batchModification,
  regionId: state.currentRegionId,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): Triggers => ({
  closeLightbox: () => dispatch(BatchModificationMutations.setLightboxModal(undefined)),
  duplicatePage: (pageId: string, documentId: string) => dispatch(BatchModificationMutations.duplicatePage(pageId, documentId)),
  lockBatch: (batchId, regionId) => dispatch(BatchActions.lockBatch(batchId, regionId)),
  releaseBatch: (batchId, batchWorkflowId, regionId) => dispatch(BatchModificationActions.releaseBatch(batchId, batchWorkflowId, regionId)),
  removePage: (pageId, documentId) => dispatch(BatchModificationMutations.removePageFromBatch(pageId, documentId)),
  save: () => dispatch(BatchModificationActions.save()),
  subscribe: (regionId, batchWorkflowId) => dispatch(BatchModificationActions.subscribe(regionId, batchWorkflowId)),
  undo: () => dispatch(BatchModificationMutations.undo()),
  unload: () => dispatch(BatchModificationMutations.clear()),
  unlockBatch: (batchId, regionId) => dispatch(BatchActions.unlockBatch(batchId, regionId)),
});

export const BatchModificationScene = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(withTranslation()(withHotKeys(BatchModificationController))))
);
