import {
  BatchData,
  BatchDataFieldSet,
  DocumentHeaderFields,
  DocumentRepeatingFields,
  FieldValueDetail,
  PerBatchDataFieldSet,
  PerDocumentHeaderDataFieldSet,
  PerDocumentRepeatingDataFieldSet,
} from '../domain/batch-preview/dto/batch-preview-data';
import { BatchFieldSet } from '../domain/batch-workflow/dto/batch-workflows-and-fields';
import { PerBatchField as BatchFieldDto, PerDocumentField as PerDocumentFieldDto } from '../domain/batch-workflow/dto/batch-workflows-and-fields';
import { BatchWorkflowAndWorkflow, Field } from '../domain/batch-workflow/dto/batch-workflows-and-workflows';
import * as Batch from '../store/interfaces/batch';
import { PerBatchFieldSource, PerDocumentField } from '../store/interfaces/batch-dashboard';
import * as BatchPreview from '../store/interfaces/batch-preview';
import { IndexOf } from '../store/types';
import { BatchPreviewConfidence } from './../store/interfaces/batch-preview';

const DEFAULT_CONFIDENCE_RULES: BatchPreview.ConfidenceRule[] = [{
  confidenceTo: 40,
}, {
  confidenceFrom: 40,
  confidenceTo: 80,
}, {
  confidenceFrom: 80,
}];

function getPerBatchFields(fields: BatchFieldDto[]) {
  return fields.reduce(
    (acc, field) => {
      const sources = field.sources.reduce(
        (acc2, source) => {
          if (source.id === undefined) return acc2;
          return {
            ...acc2, [source.id]: {
              fieldId: source.fieldId,
              priority: source.priority,
            },
          };
        },
        {} as IndexOf<PerBatchFieldSource>
      );
      return {
        ...acc, [field.id]: {
          dataType: field.dataType,
          format: field.format,
          label: field.label,
          ordinal: field.ordinal,
          sources,
        },
      };
    },
    {}
  );
}

function getPerDocumentFields(fields: PerDocumentFieldDto[]) {
  return fields.reduce(
    (acc, field) => {
      return {
        ...acc, [field.id]: {
          dataType: field.dataType,
          fieldId: field.fieldId,
          format: field.format,
          label: field.label,
          ordinal: field.ordinal,
        },
      };
    },
    {} as IndexOf<PerDocumentField>
  );
}

function indexFields(fields: Field[]) {
  return fields.reduce(
    (acc, field) => {
      return {
        ...acc, [field.id]: {
          confidenceRule: field.confidenceRule,
        },
      };
    },
    {} as IndexOf<BatchPreview.Field>
  );
}

function applyConfidenceRules(
  batchPreviewConfidence: BatchPreviewConfidence,
  rules: BatchPreview.ConfidenceRule[],
  fields: { confidence: number },
  fieldSetId: string,
  documentId?: string
): void {
  rules.forEach((rule, i) => {
    let { confidenceFrom, confidenceTo } = rule;
    if (!confidenceFrom) confidenceFrom = 0;
    if (!confidenceTo) confidenceTo = 101;
    const level = (i > 0) ? ((i === (rules.length - 1)) ? 'high' : 'mid') : 'low';
    if (fields.confidence >= confidenceFrom && fields.confidence < confidenceTo) {
      batchPreviewConfidence.confidence[level]++;
      if (documentId) {
        batchPreviewConfidence.documentConfidence[documentId].confidence[level]++;
        batchPreviewConfidence.documentConfidence[documentId].fieldSetConfidence[fieldSetId][level]++;
      } else {
        batchPreviewConfidence.fieldSetConfidence[fieldSetId][level]++;
      }
    }
  });
}

function incrementConfidenceTotal(batchPreviewConfidence: BatchPreviewConfidence, fieldSetId: string, documentId?: string, count: number = 1): void {
  batchPreviewConfidence.confidence.total += count;
  if (documentId) {
    batchPreviewConfidence.documentConfidence[documentId].confidence.total += count;
    batchPreviewConfidence.documentConfidence[documentId].fieldSetConfidence[fieldSetId].total += count;
  } else {
    batchPreviewConfidence.fieldSetConfidence[fieldSetId].total += count;
  }
}

function getDocumentFieldConfidence(
  batchPreviewConfidence: BatchPreviewConfidence,
  fields: IndexOf<FieldValueDetail>,
  fieldReference: IndexOf<BatchPreview.PerDocumentField>,
  fieldSetId: string,
  documentId: string,
  workflow: BatchPreview.Workflow
): void {
  Object.keys(fields).forEach((fieldId) => {
    const documentField = fields[fieldId];
    incrementConfidenceTotal(batchPreviewConfidence, fieldSetId, documentId);
    if (documentField && documentField.value && fieldReference[fieldId]) {
      const workflowFieldId = fieldReference[fieldId].fieldId;
      const rules = (workflow.fields && workflow.fields[workflowFieldId] && workflow.fields[workflowFieldId].confidenceRule)
        ? workflow.fields[workflowFieldId].confidenceRule : workflow.confidenceRule;
      applyConfidenceRules(batchPreviewConfidence, rules || DEFAULT_CONFIDENCE_RULES, documentField, fieldSetId, documentId);
    }
  });
}

export function getBatchConfidence(
  batchData: IndexOf<BatchDataFieldSet | undefined>,
  fieldSets: IndexOf<BatchPreview.BatchFieldSet>,
  documents: IndexOf<BatchPreview.Document>,
  workflows: IndexOf<BatchPreview.Workflow>
): BatchPreviewConfidence {
  const batchPreviewConfidence: BatchPreviewConfidence = {
    confidence: { high: 0, low: 0, mid: 0, total: 0 },
    documentConfidence: {},
    fieldSetConfidence: {},
  };

  if (!documents || Object.keys(documents).length < 1
    || !workflows || Object.keys(workflows).length < 1
    || !batchData || Object.keys(batchData).length < 1) {
    return batchPreviewConfidence;
  }

  Object.keys(fieldSets).forEach((fieldSetId) => {
    if (fieldSets[fieldSetId].type !== 'PerBatchFieldSet') return;

    batchPreviewConfidence.fieldSetConfidence[fieldSetId] = { high: 0, low: 0, mid: 0, total: 0 };
    const fieldSetRef = fieldSets[fieldSetId] as BatchPreview.PerBatchFieldSet;
    const fieldSetValues = batchData[fieldSetId] as PerBatchDataFieldSet;

    if (!fieldSetRef || !fieldSetValues || !fieldSetValues.fields) return;

    Object.keys(fieldSetRef.fields).forEach((fieldId) => {
      const fieldRef = fieldSetRef.fields[fieldId].sources;
      const fieldValues = fieldSetValues.fields[fieldId] ? fieldSetValues.fields[fieldId].selected : undefined;
      incrementConfidenceTotal(batchPreviewConfidence, fieldSetId);
      if (fieldValues
        && fieldValues.documentId
        && fieldValues.batchFieldSourceId
        && fieldRef[fieldValues.batchFieldSourceId]
        && documents[fieldValues.documentId]) {
        const document = documents[fieldValues.documentId];
        const workflow = workflows[document.workflowId];
        const rules = (workflow.fields
          && workflow.fields[fieldRef[fieldValues.batchFieldSourceId].fieldId]
          && workflow.fields[fieldRef[fieldValues.batchFieldSourceId].fieldId].confidenceRule
          ? workflow.fields[fieldRef[fieldValues.batchFieldSourceId].fieldId].confidenceRule
          : (workflow.confidenceRule ? workflow.confidenceRule : undefined));
        applyConfidenceRules(batchPreviewConfidence, rules || DEFAULT_CONFIDENCE_RULES, fieldValues, fieldSetId);
      }
    });
  });

  Object.keys(documents).forEach((documentId) => {
    batchPreviewConfidence.documentConfidence[documentId] = {
      confidence: { high: 0, low: 0, mid: 0, total: 0 },
      fieldSetConfidence: {},
    };
    const document = documents[documentId];
    const workflow = workflows[document.workflowId];
    Object.keys(fieldSets).forEach((fieldSetId) => {
      if (fieldSets[fieldSetId].type === 'PerBatchFieldSet') return;

      batchPreviewConfidence.documentConfidence[documentId].fieldSetConfidence[fieldSetId] = { high: 0, low: 0, mid: 0, total: 0 };
      const fieldRef = fieldSets[fieldSetId] as BatchPreview.PerDocumentHeaderFieldSet | BatchPreview.PerDocumentRepeatingFieldSet;
      const fieldValues = batchData[fieldSetId] as PerDocumentHeaderDataFieldSet | PerDocumentRepeatingDataFieldSet | undefined;
      if (fieldRef.workflowId !== workflow.id) return;

      if (fieldValues && fieldRef.type === 'PerDocumentHeaderFieldSet') {
        const fieldSetDocument = (fieldValues.documents as DocumentHeaderFields[]).find((doc) => doc.documentId === documentId);
        if (fieldSetDocument && fieldSetDocument.fields) {
          getDocumentFieldConfidence(batchPreviewConfidence, fieldSetDocument.fields, fieldRef.fields, fieldSetId, documentId, workflow);
        }
      } else if (fieldValues && fieldRef.type === 'PerDocumentRepeatingFieldSet') {
        const fieldSetDocument = (fieldValues.documents as DocumentRepeatingFields[]).find((doc) => doc.documentId === documentId);
        if (fieldSetDocument && fieldSetDocument.rows) {
          fieldSetDocument.rows.forEach((row) => {
            getDocumentFieldConfidence(batchPreviewConfidence, row.fields, fieldRef.fields, fieldSetId, documentId, workflow);
          });
        }
      } else if (!fieldRef.reference) {
        incrementConfidenceTotal(batchPreviewConfidence, fieldSetId, documentId, Object.keys(fieldRef.fields).length);
      }
    });
  });
  return batchPreviewConfidence;
}

export function indexDocuments(batchId: string, documents: Batch.Document[]) {
  return documents.reduce(
    (acc, document) => ({
      ...acc, [document.id]: {
        batchId,
        class: document.class,
        design: document.design,
        id: document.id,
        pages: document.pages.length,
        regionId: document.regionId,
        workflowId: document.currentDocumentWorkflow.workflowId,
      },
    }),
    {} as IndexOf<BatchPreview.Document>
  );
}

export function indexWorkflows(workflows: BatchWorkflowAndWorkflow[]) {
  return workflows.reduce(
    (acc, workflow) => ({
      ...acc, [workflow.id]: {
        clientId: workflow.client.id,
        confidenceRule: workflow.confidenceRule,
        fields: indexFields(workflow.fields),
        id: workflow.id,
        name: workflow.name,
      },
    }),
    {} as IndexOf<BatchPreview.Workflow>
  );
}

export function indexFieldSets(fieldSets: BatchFieldSet[]) {
  return fieldSets.reduce(
    (acc, fieldSet) => {
      switch (fieldSet.__typename) {
        case 'PerBatchFieldSet':
          return {
            ...acc, [fieldSet.id]: {
              fields: getPerBatchFields(fieldSet.fields),
              label: fieldSet.label,
              ordinal: fieldSet.ordinal,
              type: fieldSet.__typename,
            },
          };
        case 'PerDocumentHeaderFieldSet':
          return {
            ...acc, [fieldSet.id]: {
              fields: getPerDocumentFields(fieldSet.fields),
              fieldSetId: fieldSet.fieldSetId,
              label: fieldSet.label,
              ordinal: fieldSet.ordinal,
              reference: fieldSet.reference,
              type: fieldSet.__typename,
              workflowId: fieldSet.workflowId,
            },
          };
        case 'PerDocumentRepeatingFieldSet':
          return {
            ...acc, [fieldSet.id]: {
              fields: getPerDocumentFields(fieldSet.fields),
              fieldSetId: fieldSet.fieldSetId,
              label: fieldSet.label,
              ordinal: fieldSet.ordinal,
              reference: fieldSet.reference,
              type: fieldSet.__typename,
              workflowId: fieldSet.workflowId,
            },
          };
        default:
          return acc;
      }
    },
    {}
  );
}

export function getWorkflowIds(batchData: BatchData): number[] {
  const workflowIdSet = new Set<number>();
  // get workflow ids from documents returned
  batchData.documents.forEach((d) => workflowIdSet.add(d.currentDocumentWorkflow.workflowId));
  // get workflow ids from per batch fields
  if (!batchData.currentData) return Array.from(workflowIdSet);

  Object.keys(batchData.currentData.fieldSets).forEach((fieldSetId) => {
    const fieldSet = batchData.currentData.fieldSets[fieldSetId];
    if (fieldSet.type !== 'PerBatchDataFieldSet') return;

    Object.keys(fieldSet.fields).forEach((fieldId) => {
      const field = fieldSet.fields[fieldId];
      if (field.selected && field.selected.workflowId) {
        workflowIdSet.add(Number(field.selected.workflowId)); // hack because data type in extracted JSON is incorrect
      }
      if (field.alternatives && field.alternatives.length) {
        field.alternatives.forEach((alternative) => {
          if (!alternative.workflowId) return;
          workflowIdSet.add(Number(alternative.workflowId)); // hack because data type in extracted JSON is incorrect
        });
      }
    });
  });
  return Array.from(workflowIdSet);
}

export interface BatchPreviewProblems {
  batchStatus?: true;
  batchFieldSetValidation?: IndexOf<true>;
  documentStatus?: IndexOf<true>;
  documentValidation?: IndexOf<true>;
  test?: true;
}

function toIndexOfTrue(ids: string[]): IndexOf<true> {
  return ids.reduce(
    (acc, fieldSetId) => ({
      ...acc,
      [fieldSetId]: true as true,
    }),
    {} as IndexOf<true>
  );
}

const DOCUMENT_READY_STATUSES = {
  'data-entry-in-progress': true,
  'data-entry-ready': true,
};

export function getProblems(batchData: BatchData) {
  const problems: BatchPreviewProblems = {};

  // check batch status
  const batchStatus = batchData.currentStatus ? batchData.currentStatus.status : undefined;
  if (batchStatus !== 'ready') {
    problems.batchStatus = true;
  }

  // check document status
  const documentsInWrongStatus = batchData.documents
    .filter((document) => !DOCUMENT_READY_STATUSES[document.status])
    .map((document) => document.id);

  if (documentsInWrongStatus.length) {
    problems.documentStatus = toIndexOfTrue(documentsInWrongStatus);
  }

  if (!batchData.currentData) return problems;
  // check batch field hard validation
  const fieldSets = batchData.currentData.fieldSets;

  const batchFieldSetValidation = Object.keys(fieldSets)
    .filter((fieldSetId) => {
      const fieldSet = fieldSets[fieldSetId];
      if (fieldSet.type !== 'PerBatchDataFieldSet') return false;

      const fields = fieldSet.fields;
      return Object.keys(fields).reduce<Boolean>(
        (fieldFailed, fieldId) => {
          const field = fields[fieldId];
          return fieldFailed || (field && field.selected && field.selected.validationSeverity === 'HARD');
        },
        false
      );
    });
  if (batchFieldSetValidation.length) {
    problems.batchFieldSetValidation = toIndexOfTrue(batchFieldSetValidation);
  }

  // check document field validation
  const failedDocuments = new Set<string>();
  const documentHeaderFieldSets = Object.keys(fieldSets)
    .map((fieldSetId) => fieldSets[fieldSetId])
    .filter((fieldSet) => fieldSet.type === 'PerDocumentHeaderDataFieldSet')
    .map((fieldSet) => fieldSet as PerDocumentHeaderDataFieldSet);

  documentHeaderFieldSets.forEach((fieldSet) => {
    fieldSet.documents.forEach((document) => {
      if (batchData.ignoredDocuments && batchData.ignoredDocuments.indexOf(document.documentId) >= 0) return;
      const fields = document.fields;
      const failures = Object.keys(fields)
        .map((fieldId) => fields[fieldId])
        .filter((field) => field && field.validationSeverity === 'HARD');

      if (failures.length > 0) {
        failedDocuments.add(document.documentId);
      }
    });
  });

  const documentRepeatingFieldSets = Object.keys(fieldSets)
    .map((fieldSetId) => fieldSets[fieldSetId])
    .filter((fieldSet) => fieldSet.type === 'PerDocumentRepeatingDataFieldSet')
    .map((fieldSet) => fieldSet as PerDocumentRepeatingDataFieldSet);

  documentRepeatingFieldSets.forEach((fieldSet) => {
    fieldSet.documents.forEach((document) => {
      if (batchData.ignoredDocuments && batchData.ignoredDocuments.indexOf(document.documentId) >= 0) return;
      document.rows.forEach((row) => {
        const fields = row.fields;
        const failures = Object.keys(fields)
          .map((fieldId) => fields[fieldId])
          .filter((field) => field && field.validationSeverity === 'HARD');

        if (failures.length > 0) {
          failedDocuments.add(document.documentId);
        }
      });
    });
  });

  if (failedDocuments.size) {
    problems.documentValidation = toIndexOfTrue(Array.from(failedDocuments));
  }

  return problems;
}
