import { Button, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { BatchWorkflowActions } from '../../../actions/batch-workflow.actions';
import ToolBarView, { AppBarView } from '../../../components/app-bar';
import { NavTable } from '../../../components/nav-table';
import PageContainer from '../../../components/page-container';
import { WindowTitle } from '../../../components/window-title';
import { DataList } from '../../../store/data-list';
import { StateRoot } from '../../../store/interfaces';
import { AuthenticationState } from '../../../store/interfaces/auth';
import { BatchWorkflow } from '../../../store/interfaces/batch-workflow';
import { UserWorkflowPermission } from '../../../store/interfaces/user';
import Row from './row';
import { style } from './style';

interface Props {
  auth: AuthenticationState;
  batchWorkflows: DataList<number, BatchWorkflow>;
}

interface Triggers {
  search: () => void;
}

class BatchWorkflowListController extends React.PureComponent<Props & Triggers & RouteComponentProps & WithStyles & WithTranslation> {
  componentDidMount() {
    this.props.search();
  }

  render() {
    return <BatchWorkflowListView {...this.props} />;
  }
}

const BatchWorkflowListView: React.FunctionComponent<Props & RouteComponentProps & WithStyles & WithTranslation> =
  ({ batchWorkflows, classes, history, t }) => {

  function handleEditBatchWorkflow(batchWorkflowId: number) {
    history.replace(`/batch-workflow/${batchWorkflowId}`);
  }

  function handleAddBatchWorkflow() {
    history.replace(`/batch-workflow/add`);
  }

  return (
    <PageContainer>
      <Grid item={true} xs={12}>
        <AppBarView>
          <ToolBarView>
            <WindowTitle title={window.env.REACT_APP_HIDE_BRAND ? 'Batch UI' : t('defaultWindowTitle')} />
            <Typography variant="h6" color="inherit">{t('batchWorkflowList.pageTitle')}</Typography>
            <div className={classes.grow} />
            {
              // TODO: check if user is current Client's admin then show the "Add Batch Workflow" button
              false
                ? <Tooltip title={t('batchWorkflowList.tooltip.add')!}>
                  <Button color="inherit" variant={'outlined'} onClick={handleAddBatchWorkflow} className={classes.button}>
                    <AddCircleIcon />&nbsp;
                    {t('batchWorkflowList.actions.add')}
                  </Button>
                </Tooltip>
                : null
            }
          </ToolBarView>
        </AppBarView>
      </Grid>
      {batchWorkflows.loading
        ?
        <Grid item={true} container={true} justify="center" xs={12}>
          <CircularProgress className={classes.progress} />
        </Grid>
        :
        <Grid item={true} xs={12}>
          <Paper>
            <NavTable>
              <header>
                <div style={{ width: '100%' }}>{t('batchWorkflowList.columns.name')}</div>
                <div className="right">{t('batchWorkflowList.columns.actions')}</div>
              </header>
              {batchWorkflows.items.map((batchWorkflow, i) =>
                <Row
                  t={t}
                  handleEditBatchWorkflow={handleEditBatchWorkflow}
                  isUserBatchWorkflowAdmin={batchWorkflow.userPermissions.includes(UserWorkflowPermission.isAdmin)}
                  key={i}
                  batchWorkflow={batchWorkflow}
                  classes={classes}
                />
              )}
            </NavTable>
          </Paper>
        </Grid>
      }
    </PageContainer>
  );
};

const mapStateToProps = (state: StateRoot): Props => ({
  auth: state.auth,
  batchWorkflows: state.batchWorkflows,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): Triggers => ({
  search: () => dispatch(BatchWorkflowActions.search()),
});

export const BatchWorkflowListScene = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(withTranslation()(BatchWorkflowListController)))
);
