import { withStyles, Card, CardHeader, FormControl, FormControlLabel, Grid, Switch, TextField, WithStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BatchWorkflow } from '../../../store/interfaces/batch-workflow';
import { BatchWorkflowValidation } from './batch-workflow.validation';
import { style } from './style';

export interface ExternalControllerProps {
  batchWorkflow: BatchWorkflow;
  validationData?: BatchWorkflowValidation;
  onChangeBatchWorkflow: (batchWorkflow: BatchWorkflow) => void;
}

class BatchWorkflowGeneralSettingsController extends React.PureComponent<ExternalControllerProps> {
  onChangeBatchWorkflowName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.props.batchWorkflow) return;
    event.preventDefault();
    this.props.onChangeBatchWorkflow({ ...this.props.batchWorkflow, name: event.target.value });
  }

  onChangeBatchWorkflowAutoRelease = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    const checked = this.props.batchWorkflow.isAutoReleaseEnabled ? 0 : 1;
    this.props.onChangeBatchWorkflow({ ...this.props.batchWorkflow, isAutoReleaseEnabled: checked });
  }

  render() {
    return (
      <BatchWorkflowGeneralSettingsView
        batchWorkflow={this.props.batchWorkflow}
        validationData={this.props.validationData}
        onChangeBatchWorkflowName={this.onChangeBatchWorkflowName}
        onChangeBatchWorkflowAutoRelease={this.onChangeBatchWorkflowAutoRelease}
      />
    );
  }
}

interface ViewProps {
  batchWorkflow: BatchWorkflow;
  validationData?: BatchWorkflowValidation;
  onChangeBatchWorkflowName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeBatchWorkflowAutoRelease: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type AllViewProps = ViewProps & WithTranslation & WithStyles;

const BatchWorkflowGeneralSettingsView = withStyles(style)(withTranslation()(({
  batchWorkflow,
  validationData,
  onChangeBatchWorkflowName,
  onChangeBatchWorkflowAutoRelease,
  classes,
  t,
}: AllViewProps) => {

  const batchWorkflowGeneralSettingsRef = React.useRef<HTMLDivElement>(null);
  const generalSettingsError = Boolean(
    validationData &&
    validationData.generalSettingsValidation
  );

  React.useEffect(() => {
    if (batchWorkflowGeneralSettingsRef.current && generalSettingsError) {
      BatchWorkflowValidation.scrollToValidationFailure(batchWorkflowGeneralSettingsRef.current, 90);
    }
  });

  let batchWorkflowNameError: boolean = false;
  let batchWorkflowNameErrorMessage: string | undefined;
  if (generalSettingsError) {
    const generalSettingsValidation = validationData!.generalSettingsValidation!;
    const errorMessage = validationData!.errorMessageComponent || undefined;

    if (generalSettingsValidation.batchWorkflowName) {
      batchWorkflowNameError = true;
      batchWorkflowNameErrorMessage = errorMessage;
    }
  }

  return (
    <Grid item={true} xs={12}>
      <div ref={batchWorkflowGeneralSettingsRef} />
      <Card>
        <CardHeader
          className={classes.batchFieldSetCardHeader}
          title={
            <React.Fragment>
              <span className={classes.batchFieldSetCardTitle}>
                {t('batchWorkflowEdit.general.generalSettings')}
              </span>
            </React.Fragment>
          }
        />
        <div className={classes.batchWorkflowGeneralSettings}>
          <div>
            <FormControl
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                label="Batch Workflow Name"
                className={classes.textField}
                value={batchWorkflow ? batchWorkflow.name : undefined}
                required={true}
                onChange={onChangeBatchWorkflowName}
                margin="normal"
                variant="outlined"
                error={batchWorkflowNameError}
                helperText={batchWorkflowNameErrorMessage}
              />
            </FormControl>
          </div>
          <div>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Switch
                  checked={Boolean(batchWorkflow.isAutoReleaseEnabled)}
                  onClick={onChangeBatchWorkflowAutoRelease}
                  color="primary"
                />
              }
              label={t('batchWorkflowEdit.general.autoRelease')}
              labelPlacement="start"
            />
          </div>
        </div>
      </Card>
    </Grid>
  );
}));

export const BatchWorkflowGeneralSettings = BatchWorkflowGeneralSettingsController;
