import { initialState, StateRoot } from './interfaces';
import { ReduxAction } from './types';

function rootReducer(state: StateRoot = initialState, action: ReduxAction): StateRoot {
  if (action && typeof action.type === 'function') {
    return action.type(state);
  }
  return state;
}

export default rootReducer;
