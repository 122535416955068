import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import env from '../../environment';
import { clientCache } from '../graphql';
import { Workflow } from './../../store/interfaces/batch-modification';
import { WorkflowField, WorkflowWithFields } from './../../store/interfaces/batch-workflow';

const GQL_LOAD_WORKFLOWS_BY_CLIENT = gql`
query ($clientId: Int!) {
  workflowsByClient(clientId: $clientId) {
    id
    name
    client {
      id
      name
    }
  }
}`;

const GQL_LOAD_WORKFLOWS = gql`
query ($workflowIds: [Int!]!) {
  workflowsByIds(ids: $workflowIds) {
    id
    name
    client {
      id
      name
    }
  }
}`;

const GQL_LOAD_WORKFLOW_DATA = gql`
query ($id: Int!) {
  workflow(id: $id) {
    id
    clientId
    name
    fields {
      __typename
      ... on Field {
        id
        name
        displayOrder
        fieldSet {
          id
          description
          label
          isRepeating
          fieldRepresentation
        }
      }
      ... on FieldSet {
        fields {
          id
          name
          displayOrder
          fieldSet {
            id
            description
            label
            isRepeating
            fieldRepresentation
          }
        }
      }
    }
  }
}`;

export class WorkflowRepository {
  public static forGlobal(): WorkflowRepository {
    const url = env.urlFactory.global().service('data').resolve('graphql');
    const client = clientCache.getItem(url);
    if (!client) throw new Error('Unable to create GraphQL client');
    return new WorkflowRepository(client);
  }

  constructor(
    private readonly client: ApolloClient<{}>,
  ) { }

  public async findWorkflowsByClientId(clientId: number): Promise<Workflow[]> {
    const { data } = await this.client.query<{ workflowsByClient: Workflow[] }>({
      query: GQL_LOAD_WORKFLOWS_BY_CLIENT,
      variables: { clientId },
    });
    return data.workflowsByClient || [];
  }

  public async findWorkflows(workflowIds: number[]): Promise<Workflow[]> {
    const { data } = await this.client.query<{ workflowsByIds: Workflow[] }>({
      query: GQL_LOAD_WORKFLOWS,
      variables: { workflowIds },
    });
    return data.workflowsByIds || [];
  }

  public async findWorkflowData(workflowId: number): Promise<WorkflowWithFields> {
    const { data } = await this.client.query<{ workflow: WorkflowWithFields }>({
      query: GQL_LOAD_WORKFLOW_DATA,
      variables: { id: workflowId },
    });

    // flatten the fields coming from updated backend format
    const flattenFields: WorkflowField[] = [];
    data.workflow.fields?.forEach?.((fieldStructure: WorkflowField | { fields: WorkflowField[] }) => {
      if ('fields' in fieldStructure) {
        fieldStructure.fields.forEach((field: WorkflowField) => flattenFields.push(field));
      } else {
        flattenFields.push(fieldStructure);
      }
    });

    return { ...data.workflow, fields: flattenFields } ;
  }
}
