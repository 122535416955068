import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props {
  batchWorkflowId?: string | number;
  batchId?: string | number;
}

type AllProps = Props & WithTranslation;

const SwitchToNewELSBtn: React.FC<AllProps> = ({ t, batchWorkflowId, batchId }) => {
  if (!window.env.REACT_APP_NEW_ELS_URL || !batchWorkflowId || !batchId) return null;

  return <Tooltip title={t('batchLearning.actions.switchToNewELSApp')!}>
    <a href={`${window.env.REACT_APP_NEW_ELS_URL}/engine-learning-screen/batch/${batchWorkflowId}/${batchId}`}>
      <Button variant={'contained'} style={{
        backgroundColor: 'white',
        marginRight: 8,
        minWidth: 32,
        padding: 8,
      }}>
        <CompareArrowsIcon color={'action'} />
      </Button>
    </a>
  </Tooltip>;
};

export const SwitchToNewELSButton = withTranslation()(SwitchToNewELSBtn);

export default SwitchToNewELSButton;
