import { createAction } from '..';
import { BatchWorkflowModification } from '../interfaces/batch-workflow';
import { CodedError } from '../interfaces/error';
import { BatchWorkflow } from './../interfaces/batch-workflow';

export namespace BatchWorkflowAdminMutations {
  export const setQuery = (modification: BatchWorkflowModification) => createAction((state) => ({
    ...state,
    batchWorkflowModification: state.batchWorkflowModification.setQuery(modification),
  }));

  export const setError = (err: CodedError) => createAction((state) => ({
    ...state,
    batchWorkflowModification: state.batchWorkflowModification.setError(err),
  }));

  export const setValue = (value: BatchWorkflow | null) => createAction((state) => ({
    ...state,
    batchWorkflowModification: state.batchWorkflowModification.setValue(value),
  }));

  export const setLoading = () => createAction((state) => ({
    ...state,
    batchWorkflowModification: state.batchWorkflowModification.setLoading(),
  }));

  export const setNoValue = () => createAction((state) => ({
    ...state,
    batchWorkflowModification: state.batchWorkflowModification.clearValue(),
  }));
}
