import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import LockIcon from '@material-ui/icons/LockOutlined';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CodedError } from '../../store/interfaces/error';

export const style = createStyles({
  container: {
    height: '100%',
    textAlign: 'center',
  },
  errorIcon: {
    color: 'red',
    fontSize: 200,
    opacity: 0.3,
  },
  errorMessage: {
    maxWidth: '640px',
    opacity: 0.3,
  },
});

interface ErrorViewProps {
  error: CodedError;
}

const ErrorView = withStyles(style)(withTranslation()(({
  classes,
  error,
  t,
}: ErrorViewProps & WithTranslation & WithStyles) => (
  <Grid container={true} justify={'center'} alignItems={'center'} className={classes.container}>
    <Grid item={true}>
      <ErrorIcon className={classes.errorIcon} />
      <Typography variant={'h4'} className={classes.errorMessage}>
        {t(`errors.${error.code || error.name}`, { message: error.message })}
      </Typography>
    </Grid>
  </Grid>
)));

export const ErrorComponent = ErrorView;

interface GeneralErrorViewProps {
  message: string;
}

const GeneralErrorView = withStyles(style)(({
  classes,
  message,
}: GeneralErrorViewProps & WithStyles) => (
  <Grid container={true} justify={'center'} alignItems={'center'} className={classes.container}>
    <Grid item={true}>
      <ErrorIcon className={classes.errorIcon} />
      <Typography variant={'h4'} className={classes.errorMessage}>
        {message}
      </Typography>
    </Grid>
  </Grid>
));

export const GeneralErrorComponent = GeneralErrorView;

interface LockedViewProps {
  lockedBy: string;
}

const BatchLockedView = withStyles(style)(withTranslation()(({
  classes,
  lockedBy,
  t,
}: LockedViewProps & WithTranslation & WithStyles) => (
  <Grid container={true} justify={'center'} alignItems={'center'} className={classes.container}>
    <Grid item={true}>
      <LockIcon className={classes.errorIcon} />
      <Typography variant={'h4'} className={classes.errorMessage}>
        {t('errors.batch-locked')} <strong> {lockedBy} </strong>
      </Typography>
    </Grid>
  </Grid>
)));

export const BatchLockedComponent = BatchLockedView;

interface ReleaseViewProps {
  batchId: string;
  handleCancel: () => void;
  handleRelease: () => void;
}

const BatchReleaseView = withStyles(style)(withTranslation()(({
  classes,
  batchId,
  handleCancel,
  handleRelease,
  t,
}: ReleaseViewProps & WithTranslation & WithStyles) => (
  <Grid container={true} justify={'center'} alignItems={'center'} className={classes.container}>
    <Grid item={true}>
      <Dialog
        open={true}
        maxWidth="xs"
        aria-labelledby="release-popup-title"
      >
        <DialogTitle id="release-popup-title">
          {t('batchWorkflowDashboard.releasePopup.title', { batchId })}
        </DialogTitle>
        <DialogContent>
          <Typography>{t('batchWorkflowDashboard.releasePopup.body')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={handleCancel}>{t('batchWorkflowDashboard.actions.cancel')}</Button>
          <Button variant="contained" color="primary" onClick={handleRelease} autoFocus={true}>
            {t('batchWorkflowDashboard.actions.release')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  </Grid>
)));

export const BatchReleaseComponent = BatchReleaseView;

interface BatchUpdatedViewProps {
  title: string;
  body: string;
  handleUpdate: () => void;
}

const BatchUpdatedView = withStyles(style)(withTranslation()(({
  classes,
  title,
  body,
  handleUpdate,
  t,
}: BatchUpdatedViewProps & WithTranslation & WithStyles) => (
  <Grid container={true} justify={'center'} alignItems={'center'} className={classes.container}>
    <Grid item={true}>
      <Dialog
        open={true}
        maxWidth="xs"
        aria-labelledby="release-popup-title"
      >
        <DialogTitle id="release-popup-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography>{body}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleUpdate} autoFocus={true}>
            OK
            </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  </Grid>
)));

export const BatchUpdatedComponent = BatchUpdatedView;
