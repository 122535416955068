import { createAction } from '..';
import { DashboardBatch } from '../../domain/batch-dashboard/dto/batch-dashboard-data';
import { BatchDashboardData, BatchDashboardQuery } from '../interfaces/batch-dashboard';
import { CodedError } from '../interfaces/error';
import { dashboardQueryStorage } from './batch-dashboard.storage';

export namespace BatchDashboardMutations {
  export const setQuery = (query: BatchDashboardQuery, retainValue: boolean = false) => createAction((state) => {
    dashboardQueryStorage.set(query);
    return ({
      ...state,
      batchDashboard: state.batchDashboard.setQuery(query, retainValue),
    });
  });

  export const setValue = (value: BatchDashboardData | null) => createAction((state) => ({
    ...state,
    batchDashboard: state.batchDashboard.setValue(value),
  }));

  export const patchValue = (value: Partial<BatchDashboardData>) => createAction((state) => (state.batchDashboard.value
    ? {
      ...state,
      batchDashboard: state.batchDashboard.setValue({
        ...state.batchDashboard.value,
        ...value,
      }),
    }
    : state
  ));

  export const setLoading = () => createAction((state) => ({
    ...state,
    batchDashboard: state.batchDashboard.setLoading(),
  }));

  export const setError = (err: CodedError) => createAction((state) => ({
    ...state,
    batchDashboard: state.batchDashboard.setError(err),
  }));

  export const reloadOneBatch = (newBatchData: DashboardBatch) => createAction((state) => {
    if (!state.batchDashboard.value) return state;

    const allBatchData = state.batchDashboard.value.batches.map(batchData => batchData.id === newBatchData.id ? newBatchData : batchData);

    return {
      ...state,
      batchDashboard: state.batchDashboard.setValue({
        ...state.batchDashboard.value,
        batches: allBatchData,
      }),
    };
  });

  export const removeBatchFromList = (batchId: string) => createAction((state) => {
    if (!state.batchDashboard.value) return state;

    const allBatchData = state.batchDashboard.value
      .batches
      .filter((batchData) => batchData.id !== batchId);

    return {
      ...state,
      batchDashboard: state.batchDashboard.setValue({
        ...state.batchDashboard.value,
        batches: allBatchData,
      }),
    };
  });

  export const deleteBatch = (batchId: string) => createAction((state) => {
    if (!state.batchDashboard.value) return state;

    const allBatchData = state.batchDashboard.value.batches.filter(batchData => batchData.id !== batchId);

    return {
      ...state,
      batchDashboard: state.batchDashboard.setValue({
        ...state.batchDashboard.value,
        batches: allBatchData,
      }),
    };
  });

  export const setSubscriber = (value: ZenObservable.Subscription) => createAction((state) => ({
    ...state,
    subscriber: value,
  }));
}
