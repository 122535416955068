import { WithStyles } from '@material-ui/core/styles';
import { BatchDocumentWorkflowData } from '../../../domain/batch-dashboard/dto/batch-dashboard-data';

export interface RowGraphProps {
  documentWorkflowData: BatchDocumentWorkflowData;
  index: number;
}

export const RowGraph: React.FunctionComponent<RowGraphProps & WithStyles> = ({ documentWorkflowData, index, classes }) => {
  return (
    <div className={classes.rowGraph}>
      <div>
        <span
          title={`${documentWorkflowData.name}: ${documentWorkflowData.count}`}
          style={{ width: documentWorkflowData.percentage }}
        >
          {documentWorkflowData.name}: {documentWorkflowData.count}
        </span>
      </div>
    </div>
  );
};
