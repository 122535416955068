import { FabProps } from '@material-ui/core/Fab';
import React from 'react';

export const submit = (handler: () => Promise<void>) => async (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  await handler();
};

export const SubmitButton = React.forwardRef<HTMLButtonElement, FabProps>(
  (props: FabProps, ref: React.ForwardedRef<HTMLButtonElement>) => <button {...props} ref={ref} type="submit" />
  );
