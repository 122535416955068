import SvgIcon from '@material-ui/core/SvgIcon';

// tslint:disable-next-line:no-any
const Add = (props: any) => (
  <SvgIcon {...props} >
    <rect x="11" y="0" width="2" height="24" />
  </SvgIcon>
);

export default Add;
