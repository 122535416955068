// tslint:disable:no-console
import { KeyStore, Store } from './store';

export enum Validity {
  Valid,
  Expiring,
  Invalid,
}

export declare type Validator<T> = (value: T | undefined) => Validity;
export declare type ValidatorSync<T> = (value: T | undefined) => boolean;
export declare type Callback<T> = (error?: Error, result?: T) => void;
export declare type Loader<T> = (current: T | undefined, done: Callback<T>) => void;
export declare type KeyLoader<T> = (key: string, current: T | undefined, done: Callback<T>) => void;
export declare type KeyLoaderSync<T> = (key: string) => T;
export declare type OnChanged<T> = (value: T | undefined) => void;
export declare type OnKeyChanged<T> = (key: string, value: T | undefined) => void;

/**
 * Read-through single item cache with item validation and read aggregation.
 */
export class Cache<T> {
  private loading?: Promise<T>;

  constructor(
    private readonly store: Store<T>,
    private readonly validator: Validator<T>,
    private readonly loader: Loader<T>,
    private readonly onChanged: OnChanged<T>,
  ) { }

  public init() {
    const item = this.store.getItem();
    this.onChanged(item);

    const refreshRequired = item && this.validator(item) !== Validity.Valid;
    if (refreshRequired) {
      this.getItem()
        .catch((err) => console.error('Error refreshing cache', err));
    }
  }

  /**
   * Get item from cache or load a new one if the currently cached item is invalid.
   * If loading is currently in progress from another call, this call will wait and
   * return the result from the existing load operation
   */
  public async getItem(acceptExpiring: boolean = true): Promise<T | undefined> {
    const item = this.store.getItem();
    const validity = this.validator(item);

    // if there is already an valid item, then us that
    if (validity === Validity.Valid) return item;

    const loading = this.loading;
    // if an expiring item is acceptable, then use it as long as a new one is already being loaded
    if (acceptExpiring && validity === Validity.Expiring && loading) return item;
    // otherwise if an item is being loaded, then return it's promise
    if (loading) return loading;

    // start loading a new item
    this.loading = new Promise((resolve, reject) => {
      this.loader(item, (error, result) => {
        if (error) {
          reject(error);
        } else if (result) {
          this.setItem(result);
          resolve(result);
        }
        this.loading = undefined;
      });
    });

    // now that we have kicked off loading, it is ok to return the expiring item if acceptable
    if (acceptExpiring && validity === Validity.Expiring) return item;
    // or return the promise of the loaded item
    return this.loading;
  }

  public getItemSync(acceptExpiring: boolean = true): T | undefined {
    const item = this.store.getItem();
    const validity = this.validator(item);

    return acceptExpiring || (validity === Validity.Valid) ? item : undefined;
  }

  /**
   * Update the store and send changed notification
   * @param item new item
   */
  public setItem(item: T | undefined) {
    this.store.setItem(item);
    this.onChanged(item);
  }
}

/**
 * Read-through multi item cache with item validation and read aggregation.
 */
export class KeyCache<T> {
  private readonly loading = new Map<string, Promise<T> | undefined>();

  constructor(
    private readonly store: KeyStore<T>,
    private readonly validator: Validator<T>,
    private readonly loader: KeyLoader<T>,
    private readonly onChanged?: OnKeyChanged<T>,
  ) { }

  /**
   * Get item from cache or load a new one if the currently cached item is invalid.
   * If loading is currently in progress from another call, this call will wait and
   * return the result from the existing load operation
   */
  public async getItem(key: string, acceptExpiring: boolean = true): Promise<T | undefined> {
    const item = this.store.getItem(key);
    const validity = this.validator(item);

    // if there is already an valid item, then us that
    if (validity === Validity.Valid) return item;

    const loading = this.loading.get(key);
    // if an expiring item is acceptable, then use it as long as a new one is already being loaded
    if (acceptExpiring && validity === Validity.Expiring && loading) return item;
    // otherwise if an item is being loaded, then return it's promise
    if (loading) return loading;

    // start loading a new item
    this.loading.set(key, new Promise((resolve, reject) => {
      this.loader(key, item, (error, result) => {
        if (error) {
          reject(error);
        } else if (result) {
          this.setItem(key, result);
          resolve(result);
        }
        this.loading.delete(key);
      });
    }));

    // now that we have kicked off loading, it is ok to return the expiring item if acceptable
    if (acceptExpiring && validity === Validity.Expiring) return item;
    // or return the promise of the loaded item
    return this.loading.get(key);
  }

  /**
   * Update the store and send changed notification
   * @param key key of the item being stored
   * @param item new item
   */
  public setItem(key: string, item: T | undefined) {
    this.store.setItem(key, item);
    if (this.onChanged) {
      this.onChanged(key, item);
    }
  }
}

/**
 * Read-through multi item cache with item validation and read aggregation.
 */
export class KeyCacheSync<T> {
  constructor(
    protected readonly store: KeyStore<T>,
    protected readonly validator: ValidatorSync<T>,
    protected readonly loader: KeyLoaderSync<T>,
    protected readonly onChanged?: OnKeyChanged<T>,
  ) { }

  /**
   * Get item from cache or load a new one if the currently cached item is invalid.
   * If loading is currently in progress from another call, this call will wait and
   * return the result from the existing load operation
   */
  public getItem(key: string): T | undefined {
    const item = this.store.getItem(key);
    const valid = this.validator(item);

    // if there is already an valid item, then us that
    if (valid) return item;

    // load a new item
    const result = this.loader(key);
    this.setItem(key, result);

    // or return the promise of the loaded item
    return result;
  }

  /**
   * Update the store and send changed notification
   * @param key key of the item being stored
   * @param item new item
   */
  public setItem(key: string, item: T | undefined) {
    this.store.setItem(key, item);
    if (this.onChanged) {
      this.onChanged(key, item);
    }
  }

  /**
   * Remove all items from the store
   */
  public clear() {
    this.store.clear();
  }
}
