import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import env from '../../environment';
import { Batch } from '../../store/interfaces/batch';
import { clientCache } from '../graphql';
import { ModifyBatchData } from './dto/batch-modify-data';

const GQL_LOAD_BATCH = gql`
query ($id: String!) {
  batch(id: $id) {
    id
    batchWorkflowId
    regionId
    documents {
      id
      dataSourceId
      currentDocumentWorkflow {
        id
        workflowId
      }
      pages {
        id
        inputFileId
        ordinal
        thumbnail {
          url
        }
        fullImage {
          url
        }
      }
      regionId
      status
    }
    currentStatus {
        status
    }
    currentLock {
      id
      lockedByUserId
      lockedByClientApplicationId
      locked
    }
  }
}`;

const GQL_MODIFY_BATCH = gql`
mutation ($data: ModifyBatchData!) {
  modifyBatch(data: $data)
}`;

const GQL_DELETE_BATCH = gql`
mutation ($batchId: String!) {
  deleteBatch(batchId: $batchId)
}`;

const GQL_LOCK_BATCH = gql`
mutation ($batchId: String!) {
  lockBatch(batchId: $batchId)
}`;

const GQL_UNLOCK_BATCH = gql`
mutation ($batchId: String!) {
  unlockBatch(batchId: $batchId)
}`;

const GQL_HOLD_BATCH = gql`
mutation ($batchId: String!) {
  holdBatch(batchId: $batchId)
}`;

const GQL_RELEASE_BATCH = gql`
mutation ($batchId: String!) {
  releaseBatch(batchId: $batchId)
}`;

export class BatchRepository {
  public static forRegion(regionId: string): BatchRepository {
    const url = env.urlFactory.regional(regionId).service('data').resolve('graphql');
    const client = clientCache.getItem(url);
    if (!client) throw new Error('Unable to create GraphQL client');
    return new BatchRepository(client);
  }

  constructor(
    public readonly client: ApolloClient<{}>, // TODO private
  ) { }

  public async findById(batchWorkflowId: number, batchId: string): Promise<Batch | null> {
    const { data } = await this.client.query<{ batch: Batch }>({
      fetchPolicy: 'no-cache',
      query: GQL_LOAD_BATCH,
      variables: { id: batchId },
    });
    return data.batch;
  }

  public async saveModifications(modifyData: ModifyBatchData): Promise<boolean> {
    const { data } = await this.client.mutate<{ modifyBatch: boolean }>({
      mutation: GQL_MODIFY_BATCH,
      variables: { data: modifyData },
    });

    return data?.modifyBatch ?? false;
  }

  public async deleteBatch(batchId: string): Promise<boolean> {
    const { data } = await this.client.mutate<{ deleteBatch: boolean }>({
      mutation: GQL_DELETE_BATCH,
      variables: { batchId: batchId },
    });

    if (!data) {
      return false;
    }

    return data.deleteBatch;
  }

  public async lockBatch(batchId: string): Promise<boolean> {
    const { data } = await this.client.mutate<{ lockBatch: boolean }>({
      mutation: GQL_LOCK_BATCH,
      variables: { batchId: batchId },
    });

    if (!data) {
      return false;
    }

    return data.lockBatch;
  }

  public async unlockBatch(batchId: string): Promise<boolean> {
    const { data } = await this.client.mutate<{ unlockBatch: boolean }>({
      mutation: GQL_UNLOCK_BATCH,
      variables: { batchId: batchId },
    });

    if (!data) {
      return false;
    }

    return data.unlockBatch;
  }

  public async holdBatch(batchId: string): Promise<boolean> {
    const { data } = await this.client.mutate<{ holdBatch: boolean }>({
      mutation: GQL_HOLD_BATCH,
      variables: { batchId },
    });

    if (!data) {
      return false;
    }

    return data.holdBatch;
  }

  public async releaseBatch(batchId: string): Promise<boolean> {
    const { data } = await this.client.mutate<{ releaseBatch: boolean }>({
      mutation: GQL_RELEASE_BATCH,
      variables: { batchId: batchId },
    });

    if (!data) {
      return false;
    }

    return data.releaseBatch;
  }
}
