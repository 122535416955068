import { CombinedUrlFactory } from './url';

// add environment variables to this interface as well as to /public/env.js file
interface EnvironmentVariables {
  REACT_APP_ENV: string | undefined;
  REACT_APP_GLOBAL_BASE_URL: string | undefined;
  REACT_APP_GLOBAL_AUTH_URL: string | undefined;
  REACT_APP_GLOBAL_DATA_URL: string | undefined;
  REACT_APP_REGIONAL_BASE_URL: string | undefined;
  REACT_APP_REGIONAL_DATA_URL: string | undefined;
  REACT_APP_HIDE_BRAND: boolean | undefined;
  REACT_APP_NEW_ELS_URL: string | undefined;
  REACT_APP_LEGACY_ELS_URL: string | undefined;
}

// extended Window interface to avoid TypeScript error
declare global {
  interface Window {
    env: EnvironmentVariables;
  }
}

interface Env {
  urlFactory: CombinedUrlFactory;
}

let env: Env;
if ((window.env.REACT_APP_ENV === 'development') || (process.env.NODE_ENV === 'development')) {
  env = require('./development.environment');
} else {
  env = require('./default.environment');
}

export default env;
