import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BatchLearningActions } from '../../../actions/batch-learning.actions';
import { BatchActions } from '../../../actions/batch.actions';
import { CircularProgressComponent } from '../../../components/circular-progress';
import {
  BatchLockedComponent,
  BatchReleaseComponent,
  BatchUpdatedComponent,
  ErrorComponent,
  GeneralErrorComponent
} from '../../../components/error';
import { IconButtonWithTooltip } from '../../../components/icon-button-tooltip';
import SwitchToNewELSButton from '../../../components/switch-new-app-button';
import { VerticalDivider } from '../../../components/vertical-divider';
import { WindowTitle } from '../../../components/window-title';
import { batchHotKeys } from '../../../helpers/hot-keys';
import { RouterProps, WithBatchId, WithBatchWorkflowId, WithDocumentId } from '../../../helpers/router-props';
import { BatchQuery, StateRoot } from '../../../store/interfaces';
import { AuthenticationState, AuthStatus } from '../../../store/interfaces/auth';
import { BatchStatus } from '../../../store/interfaces/batch';
import { BatchLearning, SetDocumentStatus } from '../../../store/interfaces/batch-learning';
import { Loadable } from '../../../store/loader';
import { BatchLearningMutations } from '../../../store/mutations/batch-learning.mutations';
import { NotFoundScene } from '../../not-found';
import { DocumentCard } from './document-card';
import { InputFileCard } from './input-file-card';
import { LegacyElsIframe } from './legacy-els-iframe';
import { style } from './style';

interface Props {
  auth: AuthenticationState;
  data: Loadable<BatchQuery, BatchLearning>;
  regionId?: string;
}

interface Triggers {
  load: (batchId: string, batchWorkflowId: number, regionId: string | undefined, documentId?: string | undefined) => void;
  loadLegacyEls: (documentId: string, regionId: string, type: 'Document' | 'InputFile') => () => void;
  setDocumentStatus: SetDocumentStatus;
  lockBatch: (batchId: string, regionId: string) => void;
  subscribe: (regionId: string, batchWorkflowId: number) => void;
  unload: () => void;
  unlockBatch: (batchId: string, regionId: string) => void;
  releaseBatch: (batchId: string, batchWorkflowId: number, regionId: string) => void;
}

type ControllerProps = Props &
  Triggers &
  RouteComponentProps<WithDocumentId> &
  WithTranslation &
  WithStyles;

class BatchLearningController extends React.PureComponent<ControllerProps> {
  componentDidMount() {
    const batchId = RouterProps.getBatchId(this.props);
    const batchWorkflowId = RouterProps.handleBatchWorkflowRoute(this.props);
    const regionId = this.props.regionId;
    const documentId = RouterProps.getDocumentId(this.props);

    if (!batchWorkflowId || !batchId) throw new Error('batchId or batchWorkflowId is undefined');

    this.props.load(batchId, batchWorkflowId, regionId, documentId);
  }

  componentDidUpdate(prevProps: Props & Triggers & RouteComponentProps<WithBatchWorkflowId & WithBatchId & WithDocumentId>): void {
    const currentBatchId = RouterProps.getBatchId(this.props);
    const previousBatchId = RouterProps.getBatchId(prevProps);
    const currentBatchWorkflowId = RouterProps.handleBatchWorkflowRoute(this.props, prevProps);
    const previousBatchWorkflowId = RouterProps.getBatchWorkflowId(prevProps);
    const currentRegionId = this.props.regionId;
    const previousRegionId = prevProps.regionId;
    const documentId = RouterProps.getDocumentId(this.props);

    if (!currentBatchId || !currentBatchWorkflowId) throw new Error('batchId or batchWorkflowId is undefined');

    if (currentBatchId !== previousBatchId || currentBatchWorkflowId !== previousBatchWorkflowId || currentRegionId !== previousRegionId) {
      this.props.load(currentBatchId, currentBatchWorkflowId, currentRegionId, documentId);
    }
    if (currentRegionId) {
      this.props.subscribe(currentRegionId, currentBatchWorkflowId);
    }
  }

  componentWillUnmount(): void {
    this.props.unload();
  }

  render() {
    return <BatchLearningView
      {...this.props}
      batchWorkflowId={RouterProps.handleBatchWorkflowRoute(this.props)}
    />;
  }
}

const BatchLearningView = withStyles(style)(withTranslation()(({
  classes,
  data,
  loadLegacyEls,
  setDocumentStatus,
  regionId,
  t,
  history,
  auth,
  lockBatch,
  unlockBatch,
  releaseBatch,
  batchWorkflowId,
}: Props & Triggers & RouteComponentProps & WithTranslation & WithStyles & { batchWorkflowId?: number }) => {

  function handlePreviewBatch() {
    if (data.query) {
      history.replace({
        pathname: `/batch-workflow/${data.query.batchWorkflowId}/batch/${data.query.batchId}/preview`,
      });
    }
  }

  function handleModifyBatch() {
    if (data.query) {
      history.replace({
        pathname: `/batch-workflow/${data.query.batchWorkflowId}/batch/${data.query.batchId}/modify`,
      });
    }
  }

  function handleReleaseBatch() {
    if (data.query && data.query.batchId && data.query.batchWorkflowId && regionId) {
      releaseBatch(data.query.batchId, data.query.batchWorkflowId, regionId);
    }
  }

  function handleCancelRelease() {
    if (data.query && data.query.batchId && data.query.regionId) {
      history.replace(`/batch-workflow/${data.query.batchWorkflowId}/dashboard`);
    } else {
      history.goBack();
    }
  }

  function handleBack() {
    if (data.query) {
      history.goBack();
    }
  }

  function handleExitUnlock() {
    if (data.query && data.query.batchId && data.query.regionId) {
      unlockBatch(data.query.batchId, data.query.regionId);

      history.replace(`/batch-workflow/${data.query.batchWorkflowId}/dashboard`);
    }
  }

  if (data.error) {
    return <ErrorComponent error={data.error} />;
  }

  if (data.loading || data.valuePending) {
    return <CircularProgressComponent />;
  }

  if (data.value) {
    const currentUserId = auth.status === AuthStatus.AUTHENTICATED
      ? auth.tokenSet.idToken.payload.sub
      : undefined;

    if (data.value.batch.currentLock
      && data.value.batch.currentLock.lockedByUser
      && (data.value.batch.currentLock.lockedByUserId !== currentUserId)) {
      return <BatchLockedComponent lockedBy={data.value.batch.currentLock.lockedByUser.name} />;
    }

    if (!data.value.batch.currentStatus) {
      return <GeneralErrorComponent message={t('errors.batch-no-status')} />;
    }

    if (data.value.batch.currentStatus.status === BatchStatus.HOLD) {
      return (
        <BatchReleaseComponent
          batchId={data.value.batch.id}
          handleCancel={handleCancelRelease}
          handleRelease={handleReleaseBatch}
        />);
    }

    if (data.value.subUpdateInfo) {
      const subUpdateInfo = data.value.subUpdateInfo;
      let body = '';
      if (subUpdateInfo.type === BatchStatus.DELETED || subUpdateInfo.type === BatchStatus.HOLD) {
        body = t(`errors.subscription.` + subUpdateInfo.type, { name: t(subUpdateInfo.by) });
      } else {
        body = t('batchPreview.problems.batchUnlocked', { name: t(subUpdateInfo.by) });
      }

      return (
        <BatchUpdatedComponent
          handleUpdate={handleCancelRelease}
          title={t('batchPreview.pageTitle', { batchId: data.value.batch.id })}
          body={body}
        />
      );
    }

    if (data.value.batch.currentStatus.status !== BatchStatus.READY) {
      return <GeneralErrorComponent message={t(`errors.batch-in-${data.value.batch.currentStatus.status}-status`)} />;
    }

    if (data.query && data.query.batchId && data.query.regionId && !data.value.batch.currentLock) {
      lockBatch(data.query.batchId, data.query.regionId);
    }

    const keyHandler = {
      exit: (event?: KeyboardEvent) => {
        event?.preventDefault();
        handleExitUnlock();
      },
    };

    const batchId = data.query ? data.query.batchId : '';

    return (
      <GlobalHotKeys keyMap={batchHotKeys} handlers={keyHandler}>
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <AppBar position={'relative'} color={'default'}>
              <Toolbar>
                <WindowTitle title={t('batchLearning.pageTitle', { batchId })} />
                <IconButtonWithTooltip
                  onClick={handleBack}
                  tooltip={t('terms.back')}
                >
                  <BackIcon />
                </IconButtonWithTooltip>
                <Tooltip title={t('batchLearning.actions.exitUnlock')!}>
                  <Button onClick={handleExitUnlock} color="inherit">{t('batchLearning.actions.exit')}</Button>
                </Tooltip>
                <VerticalDivider height={'30px'} horizontalMargin={'20px'} />
                <Typography variant="h6" color="inherit" className={classes.grow}>
                  {t('batchLearning.pageTitle', { batchId })}
                </Typography>

                <React.Fragment>
                  <SwitchToNewELSButton batchId={batchId} batchWorkflowId={batchWorkflowId} />
                  <Button onClick={handlePreviewBatch} color="inherit">{t('batchLearning.actions.preview')}</Button>
                  <Button onClick={handleModifyBatch} color="inherit">{t('batchLearning.actions.modify')}</Button>
                </React.Fragment>
              </Toolbar>
            </AppBar>
          </Grid>
          <Grid item={true} className={classes.documentCardList}>
            {
              data.value.inputFileIds.map((id, i) =>
                data.value && regionId ?
                  <InputFileCard
                    key={id}
                    documentId={id}
                    data={data.value}
                    onClick={loadLegacyEls(id, regionId, 'InputFile')}
                    isActive={(data.value.activeDocument && data.value.activeDocument.id === id) ? true : false}
                  />
                  : null
              )
            }
            {
              data.value.documentIds.map((id, i) =>
                data.value && regionId ?
                  <DocumentCard
                    key={id}
                    documentId={id}
                    data={data.value}
                    onClick={loadLegacyEls(id, regionId, 'Document')}
                    isActive={(data.value.activeDocument && data.value.activeDocument.id === id) ? true : false}
                  />
                  : null
              )
            }

          </Grid>
          <Grid item={true} className={classes.grow}>
            {
              data.value.activeDocument
                ?
                <LegacyElsIframe
                  documentId={data.value.activeDocument.id}
                  regionId={data.value.activeDocument.regionId}
                  type={data.value.activeDocument.type}
                  onGetDocumentStatus={setDocumentStatus}
                />
                : null
            }
          </Grid>
        </Grid>
      </GlobalHotKeys>
    );
  }

  return <NotFoundScene />;
}));

const mapStateToProps = (state: StateRoot): Props => ({
  auth: state.auth,
  data: state.batchLearning,
  regionId: state.currentRegionId,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): Triggers => ({
  load: (
    batchId: string,
    batchWorkflowId: number,
    regionId: string | undefined,
    documentId?: string | undefined,
  ) => dispatch(BatchLearningActions.load(batchId, batchWorkflowId, regionId, documentId)),
  loadLegacyEls: (id, regionId, type) => () => dispatch(BatchLearningMutations.setActiveDocument({ id, regionId, type })),
  lockBatch: (batchId, regionId) => dispatch(BatchActions.lockBatch(batchId, regionId)),
  releaseBatch: (batchId, batchWorkflowId, regionId) => dispatch(BatchLearningActions.releaseBatch(batchId, batchWorkflowId, regionId)),
  setDocumentStatus: (documentId, status) => dispatch(BatchLearningMutations.setDocumentStatus({ documentId, status })),
  subscribe: (regionId, batchWorkflowId) => dispatch(BatchLearningActions.subscribe(regionId, batchWorkflowId)),
  unload: () => dispatch(BatchLearningMutations.clear()),
  unlockBatch: (batchId, regionId) => dispatch(BatchActions.unlockBatch(batchId, regionId)),
});

export const BatchLearningScene = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BatchLearningController)
);
