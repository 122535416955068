import { CombinedUrlFactoryImpl } from './url';

export const urlFactory = new CombinedUrlFactoryImpl({
  global: {
    '@': 'http://localhost:8080',
    'auth': 'http://localhost:3001',
    'data': 'http://localhost:4001',
  },
  regional: (regionId: string) => ({
    '@': `http://localhost:8081`,
    'data': 'http://localhost:5001',
  }),
});
