import { IndexOf } from '../types';
import { BatchLock, BatchStatus } from './batch';

export interface Batch {
  id: string;
  regionId: string;
  currentLock: BatchLock | undefined;
  currentStatus: BatchStatus | undefined;
}

export interface Document {
  id: string;
  batchId: string;
  workflowId: number;
  thumbnail: Thumbnail | undefined;
  pageCount: number;
  status: string;
}

export interface InputFile {
  batchId: string;
  id: string;
  thumbnail: Thumbnail | undefined;
  dataSourceId: number;
  workflowId: number;
}

export interface SimpleDocumentStatus {
  status: string;
}

export interface DocumentStatus {
  documentId: string;
  status: string;
}

export type SetDocumentStatus = (documentId: string, status: string) => void;

export interface ActiveDocument {
  id: string;
  regionId: string;
  type: 'Document' | 'InputFile';
}

export interface Thumbnail {
  url: string;
}

export interface Workflow {
  id: number;
  name: string;
}

export interface BatchLearning {
  batch: Batch;
  activeDocument?: ActiveDocument;
  documents: IndexOf<Document>;
  documentIds: string[];
  inputFileIds: string[];
  inputFiles: IndexOf<InputFile>;
  workflows?: IndexOf<Workflow>;
  subUpdateInfo?: {
    type: 'deleted' | 'onhold' | 'unlock';
    by: string;
  };
}

export function isFullyLoaded(value: BatchLearning): boolean {
  return Boolean(
    value
    && value.batch
    && value.documents
    && value.documentIds
    && value.workflows
  );
}
