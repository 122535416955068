import Button from '@material-ui/core/Button';
import { withTranslation, WithTranslation } from 'react-i18next';

const updateNow = () => () => window.location.reload(true);

const UpdateAppButtonView = withTranslation()(({ t }: WithTranslation) => (
  <Button key="upgrade" aria-label="t('app.updateNow')" color="inherit" onClick={updateNow()}>
    {t('app.updateNow')}
  </Button>
));

export const UpdateAppButton = UpdateAppButtonView;
