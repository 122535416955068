import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { WithT } from 'i18next';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BatchDashboardActions } from '../../../actions/batch-dashboard.actions';
import { BatchActions } from '../../../actions/batch.actions';
import { CircularProgressComponent } from '../../../components/circular-progress';
import { GeneralErrorComponent } from '../../../components/error';
import { NavTable } from '../../../components/nav-table';
import PageContainer from '../../../components/page-container';
import { Pager } from '../../../components/pager';
import { ReadableBatchStatus } from '../../../domain/batch-dashboard/dto/batch-dashboard-data';
import { MenuContext } from '../../../helpers/types';
import { StateRoot } from '../../../store/interfaces';
import { AuthenticationState, AuthStatus } from '../../../store/interfaces/auth';
import { Batch } from '../../../store/interfaces/batch';
import { BatchDashboardData, BatchDashboardQuery, ExpansionQuery } from '../../../store/interfaces/batch-dashboard';
import { BatchWorkflow } from '../../../store/interfaces/batch-workflow';
import { Loadable } from '../../../store/loader';
import { WithHistory } from '../../../store/types';
import { DashboardAppBar } from './dashboard-app-bar';
import { BatchDashboardDeleteScene } from './delete-btn';
import { defaultFilterItem, FilterItem } from './filter';
import { LoadingRow, Row } from './row';
import { defaultSortItem, SortItem } from './sort-row';
import { style } from './style';

interface Props {
  auth: AuthenticationState;
  batchDashboard: Loadable<BatchDashboardQuery, BatchDashboardData>;
  batchWorkflow: Loadable<number, BatchWorkflow>;
  subscriber: ZenObservable.Subscription | undefined;
}

interface ExternalProps {
  batchWorkflowId: number;
  regionId: string;
}

interface ViewTriggers {
}

interface ControllerTriggers extends ViewTriggers {
  reload: () => void;
  subscribe: (regionId: string, batchWorkflowId: number) => void;
  deleteBatch: (batchId: string, regionId: string) => void;
  lockBatch: (batchId: string, regionId: string) => void;
  unlockBatch: (batchId: string, regionId: string) => void;
  applySort: (sortBy: SortItem[]) => Promise<void>;
  updateFilter: (expansionQuery: ExpansionQuery) => Promise<void>;
  initDashboard: (
    regionId: string | undefined,
    batchWorkflowId: number | undefined,
    defaultBatchSort: SortItem[],
    defaultBatchFilter: FilterItem[]
  ) => void;
  loadMore: (startIndex: number, endIndex: number) => void;
  handlePageChanged: (page: number) => void;
}

type ControllerProps = Props & ExternalProps & ControllerTriggers & WithHistory & WithTranslation;

interface LocalState {
  currentUserId: number | undefined;
  loadedRowCount: number;
  loadedRowsMap: number[];
  loadingRowCount: number;
  menuAnchor: MenuContext<Batch> | null;
}

class BatchDashboardController extends React.Component<ControllerProps, LocalState> {
  state: LocalState;

  componentDidMount() {
    this.props.initDashboard(
      this.props.regionId,
      this.props.batchWorkflowId,
      defaultSortItem(this.props.t('batchWorkflowDashboard.batchSort.created')),
      defaultFilterItem(
        this.props.t('batchWorkflowDashboard.batchFilter.lockedBy'),
        this.props.t('batchWorkflowDashboard.columns.status'),
        this.props.t('batchWorkflowDashboard.batchFilter.operations.equal')
      ),
    );
    this.props.subscribe(this.props.regionId, this.props.batchWorkflowId);
  }

  componentWillUnmount() {
    if (!this.props.subscriber) return;
    this.props.subscriber.unsubscribe();
  }

  constructor(props: ControllerProps) {
    super(props);

    this.state = {
      currentUserId: this.props.auth.status === AuthStatus.AUTHENTICATED
        ? this.props.auth.tokenSet.idToken.payload.sub as number
        : undefined,
      loadedRowCount: this.props.batchDashboard.value
        ? Object.keys(this.props.batchDashboard.value.indexOfDashboardBatch).length
        : 0,
      loadedRowsMap: [],
      loadingRowCount: 0,
      menuAnchor: null,
    };
  }

  setMenuAnchor = (value: MenuContext<Batch> | null) => {
    this.setState({
      ...this.state,
      menuAnchor: value,
    });
  }

  handleMenuShow = (item: Batch, element: HTMLElement) => {
    this.setMenuAnchor({ element, item });
  }

  handleMenuHide = () => {
    this.setMenuAnchor(null);
  }

  handleModifyBatch = () => {
    if (!this.state.menuAnchor) return;
    this.props.history.push(`/batch-workflow/${this.state.menuAnchor.item.batchWorkflowId}/batch/${this.state.menuAnchor.item.id}/modify`);
  }

  handleProcessBatch = () => {
    if (!this.state.menuAnchor) return;
    this.props.history.push(`/batch-workflow/${this.state.menuAnchor.item.batchWorkflowId}/batch/${this.state.menuAnchor.item.id}/learning`);
  }

  handleToggleLock = () => {
    if (!this.state.menuAnchor || !this.props.regionId) return;

    if (this.state.menuAnchor.item.currentLock) {
      this.props.unlockBatch(this.state.menuAnchor.item.id, this.props.regionId);
    } else {
      this.props.lockBatch(this.state.menuAnchor.item.id, this.props.regionId);
    }
    this.handleMenuHide();
  }

  handleDeleteBatch = (batchId: string) => {
    this.props.deleteBatch(batchId, this.props.regionId);
    this.handleMenuHide();
  }

  isNotLockedBySomeoneElse = (batch: Batch): boolean => {
    return (!batch.currentLock) || (batch.currentLock && batch.currentLock.lockedByUserId === this.state.currentUserId);
  }

  isNotOnHold = (batch: Batch): boolean => {
    return (!batch.currentStatus) || (batch.currentStatus && batch.currentStatus.status !== ReadableBatchStatus.onhold);
  }

  render() {
    const query = this.props.batchDashboard.query;
    if (!query || query.batchWorkflowId !== this.props.batchWorkflowId) {
      return null;
    }

    return (
      <BatchDashboardView
        auth={this.props.auth}
        batchDashboard={this.props.batchDashboard}
        batchWorkflow={this.props.batchWorkflow}
        currentUserId={this.state.currentUserId}
        handleDeleteBatch={this.handleDeleteBatch}
        handleMenuHide={this.handleMenuHide}
        handleMenuShow={this.handleMenuShow}
        handleModifyBatch={this.handleModifyBatch}
        handlePageChanged={this.props.handlePageChanged}
        handleProcessBatch={this.handleProcessBatch}
        handleToggleLock={this.handleToggleLock}
        history={this.props.history}
        isNotLockedBySomeoneElse={this.isNotLockedBySomeoneElse}
        isNotOnHold={this.isNotOnHold}
        menuAnchor={this.state.menuAnchor}
        t={this.props.t}
        subscriber={this.props.subscriber}
      />
    );
  }
}

interface ExternalViewProps {
  currentUserId: number | undefined;
  handleMenuShow: (item: Batch, element: HTMLElement) => void;
  handleMenuHide: () => void;
  handleModifyBatch: () => void;
  handlePageChanged: (page: number) => void;
  handleProcessBatch: () => void;
  handleToggleLock: () => void;
  handleDeleteBatch: (batchId: string) => void;
  isNotLockedBySomeoneElse: (batch: Batch) => boolean;
  isNotOnHold: (batch: Batch) => boolean;
  menuAnchor: MenuContext<Batch> | null;
}

type ViewProps = Props & ViewTriggers & WithHistory & ExternalViewProps & WithT & WithStyles;

const BatchDashboardView = withStyles(style)(({
  batchDashboard,
  batchWorkflow,
  classes,
  currentUserId,
  t,
  handleMenuHide,
  handleMenuShow,
  handleModifyBatch,
  handlePageChanged,
  handleProcessBatch,
  handleToggleLock,
  handleDeleteBatch,
  isNotLockedBySomeoneElse,
  isNotOnHold,
  menuAnchor,
}: ViewProps) => {
  if (batchWorkflow.error) {
    return (
      <GeneralErrorComponent message={batchWorkflow.error.message} />
    );
  }
  const query = batchDashboard.query;
  return (
    <PageContainer>
      <Grid item={true} xs={12}>
        <DashboardAppBar
          t={t}
          title={batchWorkflow.value ? batchWorkflow.value.name : undefined}
        />
      </Grid>
      {batchDashboard.loading
        ?
        <CircularProgressComponent classes={{ circularProgressContainer: classes.circularProgressContainer, progress: classes.progress }} />
        :
        <Grid item={true} xs={12} className={classes.list}>
          <Paper>
            <NavTable>
              <header>
                <div>{t('batchWorkflowDashboard.columns.batch')}</div>
                <div>{t('batchWorkflowDashboard.columns.status')}</div>
                <div>{t('batchWorkflowDashboard.columns.category')}</div>
                <div>{t('batchWorkflowDashboard.columns.type')}</div>
                <div>{t('batchWorkflowDashboard.columns.fields')}</div>
                <div />
                <div className="right">{t('batchWorkflowDashboard.columns.actions')}</div>
              </header>
              {query && batchDashboard.value && batchDashboard.value.batchIds.length
                ? batchDashboard.value.batchIds
                  .slice(query.pagination.offset, query.pagination.offset + query.pagination.limit)
                  .map((batchId) => (batchDashboard.value && batchDashboard.value.indexOfDashboardBatch[batchId]
                    ? (
                      <Row
                        key={batchId}
                        batch={batchDashboard.value.indexOfDashboardBatch[batchId]}
                        currentUserId={currentUserId}
                        fieldSets={batchDashboard.value.fieldSets}
                        onMenuShow={handleMenuShow}
                        t={t}
                      />
                    )
                    : <LoadingRow key={batchId} batchId={batchId} t={t} />))
                : null
              }
              <footer />
            </NavTable>
            {query && batchDashboard.value
              ?
              <Pager
                currentPage={Math.ceil(query.pagination.offset / query.pagination.limit) + 1}
                handlePageChanged={handlePageChanged}
                pages={Math.ceil(batchDashboard.value.batchIds.length / query.pagination.limit)}
                t={t}
              />
              : null
            }
          </Paper>
        </Grid>
      }

      {menuAnchor
        ? <Menu anchorEl={menuAnchor.element} open={Boolean(menuAnchor)} onClose={handleMenuHide}>
          {isNotLockedBySomeoneElse(menuAnchor.item)
            ? <MenuItem onClick={handleModifyBatch}>{t('batchWorkflowDashboard.actions.modify')}</MenuItem>
            : null
          }
          {isNotLockedBySomeoneElse(menuAnchor.item)
            ? <MenuItem onClick={handleProcessBatch}>{t('batchWorkflowDashboard.actions.process')}</MenuItem>
            : null
          }
          {isNotOnHold(menuAnchor.item)
            ? <MenuItem onClick={handleToggleLock}>{menuAnchor.item.currentLock
              ? t('batchWorkflowDashboard.actions.unlock')
              : t('batchWorkflowDashboard.actions.lock')}
            </MenuItem>
            : null}
          {isNotLockedBySomeoneElse(menuAnchor.item) && isNotOnHold(menuAnchor.item)
            ? <BatchDashboardDeleteScene
              handleDeleteBatch={handleDeleteBatch}
              batch={menuAnchor.item}
            />
            : null
          }
        </Menu>
        : null
      }
    </PageContainer>
  );
});

const mapStateToProps = (state: StateRoot): Props => ({
  auth: state.auth,
  batchDashboard: state.batchDashboard,
  batchWorkflow: state.batchWorkflow,
  subscriber: state.subscriber,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): ControllerTriggers => ({
  applySort: async (sortBy: SortItem[]) => dispatch(BatchDashboardActions.applySort(sortBy)),
  deleteBatch: (batchId, regionId) => dispatch(BatchActions.deleteBatch(batchId, regionId)),
  handlePageChanged: (page: number) => dispatch(BatchDashboardActions.changePage(page)),
  initDashboard: (
    regionId: string | undefined,
    batchWorkflowId: number | undefined,
    defaultBatchSort: SortItem[],
    defaultBatchFilter: FilterItem[],
  ) => dispatch(BatchDashboardActions.initDashboard(
    regionId,
    batchWorkflowId,
    defaultBatchSort,
    { offset: 0, limit: 50 },
    defaultBatchFilter
  )),
  loadMore: (startIndex: number, endIndex: number) => dispatch(BatchDashboardActions.loadRange(startIndex, endIndex)),
  lockBatch: (batchId, regionId) => dispatch(BatchActions.lockBatch(batchId, regionId)),
  reload: () => dispatch(BatchDashboardActions.reload()),
  subscribe: (regionId: string, batchWorkflowId: number) => dispatch(BatchDashboardActions.subscribe(regionId, batchWorkflowId)),
  unlockBatch: (batchId, regionId) => dispatch(BatchActions.unlockBatch(batchId, regionId)),
  updateFilter: async (expansionQuery: ExpansionQuery) => dispatch(BatchDashboardActions.updateFilter(expansionQuery)),
});

export const BatchDashboard = connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(BatchDashboardController)
);
