import { Card, CardHeader, FormControl, Grid, InputLabel, Tooltip } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragHandleRoundedIcon from '@material-ui/icons/DragHandleRounded';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import Select from 'react-select';
import { Field, PerBatchFieldSource } from '../../../domain/batch-workflow/dto/batch-workflows-and-fields';
import { Workflow } from '../../../domain/batch-workflow/dto/batch-workflows-and-fields';
import { WorkflowAndFieldSetData, WorkflowField } from '../../../store/interfaces/batch-workflow';
import { SelectFieldItem } from './batch-workflow-admin';
import { BatchFieldSourceViewValidation, BatchWorkflowValidation } from './batch-workflow.validation';
import { ChangeFieldDialog } from './change-field';
import { ChangeWorkflowDialog } from './change-workflow';
import { style } from './style';

interface ControllerProps {
  batchFieldSetIndex: number;
  batchFieldIndex: number;
  batchFieldSourceIndex: number;
  batchFieldSource: PerBatchFieldSource;
  validationData?: BatchWorkflowValidation;
  handleBatchFieldSourceChange: (batchFieldSource: PerBatchFieldSource, index: number) => void;
  handleDeleteBatchFieldSource: (batchFieldSourceIndex: number) => void;
}

interface LocalState {
  changeWorkflowDialogAnchorOpen: boolean;
  changeFieldDialogAnchorOpen: boolean;
}

type AllProps = ControllerProps & WithStyles & WithTranslation;

export class BatchFieldSourceListController extends React.PureComponent<AllProps, LocalState> {
  state: Readonly<LocalState>;

  constructor(props: AllProps) {
    super(props);

    this.state = {
      changeFieldDialogAnchorOpen: false,
      changeWorkflowDialogAnchorOpen: false,
    };
  }

  toggleInputDialog = (dialogAnchor: string) => {
    if (dialogAnchor === 'workflow') {
      this.setState({ changeWorkflowDialogAnchorOpen: !this.state.changeWorkflowDialogAnchorOpen });
    } else if (dialogAnchor === 'field') {
      this.setState({ changeFieldDialogAnchorOpen: !this.state.changeFieldDialogAnchorOpen });
    }
  }

  render() {
    const {
      batchFieldSetIndex,
      batchFieldIndex,
      batchFieldSourceIndex,
      batchFieldSource,
      validationData,
      handleBatchFieldSourceChange,
      handleDeleteBatchFieldSource,
    } = this.props;

    return (
      <BatchFieldSourceView
        batchFieldSetIndex={batchFieldSetIndex}
        batchFieldIndex={batchFieldIndex}
        batchFieldSourceIndex={batchFieldSourceIndex}
        batchFieldSource={batchFieldSource}
        validationData={validationData}
        changeWorkflowDialogAnchorOpen={this.state.changeWorkflowDialogAnchorOpen}
        changeFieldDialogAnchorOpen={this.state.changeFieldDialogAnchorOpen}
        toggleInputDialog={this.toggleInputDialog}
        handleBatchFieldSourceChange={handleBatchFieldSourceChange}
        handleDeleteBatchFieldSource={handleDeleteBatchFieldSource}
      />
    );
  }
}

interface ViewProps {
  batchFieldSetIndex: number;
  batchFieldIndex: number;
  batchFieldSourceIndex: number;
  batchFieldSource: PerBatchFieldSource;
  validationData?: BatchWorkflowValidation;
  changeWorkflowDialogAnchorOpen: boolean;
  changeFieldDialogAnchorOpen: boolean;
  toggleInputDialog: (dialogAnchor: string) => void;
  handleBatchFieldSourceChange: (batchFieldSource: PerBatchFieldSource, index: number) => void;
  handleDeleteBatchFieldSource: (batchFieldSourceIndex: number) => void;
}

type AllViewProps = ViewProps & WithStyles & WithTranslation;

const BatchFieldSourceView = withStyles(style)(withTranslation()(({
  batchFieldSetIndex,
  batchFieldIndex,
  batchFieldSourceIndex,
  batchFieldSource,
  validationData,
  changeWorkflowDialogAnchorOpen,
  changeFieldDialogAnchorOpen,
  toggleInputDialog,
  handleBatchFieldSourceChange,
  handleDeleteBatchFieldSource,
  classes,
  t,
}: AllViewProps) => {

  function onDelete(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    event.preventDefault();
    handleDeleteBatchFieldSource(batchFieldSourceIndex);
  }

  function onToggleWorkflowDialog(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    event.preventDefault();
    toggleInputDialog('workflow');
  }

  function onToggleFieldDialog(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    event.preventDefault();
    toggleInputDialog('field');
  }

  function handleChangeWorkflowDialogHide(value?: Workflow) {
    toggleInputDialog('workflow');
    if (value) {
      const field: Field = { id: undefined, name: undefined, workflowData: value };
      handleBatchFieldSourceChange({ ...batchFieldSource, field: field, fieldId: undefined }, batchFieldSourceIndex);
    }
  }

  function handleChangeFieldDialogHide(value?: WorkflowField) {
    toggleInputDialog('field');
    if (value && batchFieldSource.field) {
      const field: Field = { ...batchFieldSource.field, id: value.id, name: value.name };
      handleBatchFieldSourceChange({ ...batchFieldSource, field: field, fieldId: value.id }, batchFieldSourceIndex);
    }
  }

  function handleBatchFieldSourceSourceChange(selectedOption: SelectFieldItem | null) {
    if (selectedOption === null) return;

    handleBatchFieldSourceChange({ ...batchFieldSource, source: selectedOption.value }, batchFieldSourceIndex);
  }

  const currentBatchFieldSourceWorkflow: WorkflowAndFieldSetData | undefined = batchFieldSource.field
    ? { id: batchFieldSource.field.workflowData.id, name: batchFieldSource.field.workflowData.name }
    : undefined;

  const selectedSource = batchFieldSource.source ?
    {
      label: batchFieldSource.source.charAt(0) + batchFieldSource.source.replace('_', ' ').slice(1).toLowerCase(),
      value: batchFieldSource.source,
    }
    : undefined;

  const sourceOptions = [
    {
      label: 'Document',
      value: 'DOCUMENT',
    },
    {
      label: 'Input file',
      value: 'INPUT_FILE',
    },
  ];

  const changeFieldDisabled: string = !batchFieldSource.field || !batchFieldSource.field.workflowData || !batchFieldSource.field.workflowData.id
    ? ' ' + classes.disabled
    : '';

  const batchFieldSourceDivRef = React.useRef<HTMLDivElement>(null);
  const thisBatchFieldSourceError = Boolean(
    validationData &&
    validationData.batchFieldSetValidation &&
    (validationData.batchFieldSetValidation.id === batchFieldSetIndex) &&
    validationData.batchFieldSetValidation.batchFieldValidation &&
    (validationData.batchFieldSetValidation.batchFieldValidation.id === batchFieldIndex) &&
    validationData.batchFieldSetValidation.batchFieldValidation.batchFieldSourceValidation &&
    (validationData.batchFieldSetValidation.batchFieldValidation.batchFieldSourceValidation.id === batchFieldSourceIndex)
  );

  React.useEffect(() => {
    if (batchFieldSourceDivRef.current && thisBatchFieldSourceError) {
      BatchWorkflowValidation.scrollToValidationFailure(batchFieldSourceDivRef.current);
    }
  });

  let batchFieldSourceViewValidation: BatchFieldSourceViewValidation | undefined;
  if (thisBatchFieldSourceError) {
    const batchFieldSourceValidation = validationData!.batchFieldSetValidation!.batchFieldValidation!.batchFieldSourceValidation!;
    const errorMessage = validationData!.errorMessageComponent || undefined;

    if (batchFieldSourceValidation.workflow) {
      batchFieldSourceViewValidation = { workflow: true, errorMessage };
    } else if (batchFieldSourceValidation.field) {
      batchFieldSourceViewValidation = { field: true, errorMessage };
    }
  }

  const workflowValidationStyle = batchFieldSourceViewValidation && batchFieldSourceViewValidation.workflow
    ? classes.batchFieldSetViewValidation
    : undefined;

  const fieldValidationStyle = batchFieldSourceViewValidation && batchFieldSourceViewValidation.field
    ? classes.batchFieldSetViewValidation
    : undefined;

  return (
    <Grid item={true} xs={12}>
      <Draggable draggableId={`batchFieldSource-${batchFieldSetIndex}-${batchFieldIndex}-${batchFieldSourceIndex}`} index={batchFieldSourceIndex}>
        {(draggableProvided) => (
          <Grid item={true} xs={12}>
            <div ref={batchFieldSourceDivRef} />
            <div
              className="draggable"
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
            >
              <Card className={classes.batchFieldSourceCard}>
                <CardHeader
                  className={classes.fieldSetCardHeader}
                  title={
                    <React.Fragment>
                      <span className={classes.batchFieldCardTitle}>
                        {t('batchWorkflowEdit.general.batchFieldSource')}
                      </span>
                      <Tooltip title={t('batchWorkflowEdit.actions.removeBatchFieldSource')!}>
                        <DeleteOutlineIcon
                          onClick={onDelete}
                          className={classes.pullRight + ' ' + classes.deleteIconButton}
                        />
                      </Tooltip>
                    </React.Fragment>
                  }
                />
                <div className={classes.paddedBatchFieldSourceCard}>
                  <div className={classes.paddedBatchFieldSourceItems}>
                    {
                      batchFieldSource.field
                        && batchFieldSource.field.workflowData
                        ? <EditIcon
                          className={classes.actionButton}
                          onClick={onToggleWorkflowDialog}
                        />
                        : <AddIcon
                          className={classes.actionButton}
                          onClick={onToggleWorkflowDialog}
                        />
                    }
                    &nbsp;
                    <b className={workflowValidationStyle}>{t('batchWorkflowEdit.general.workflow')}:</b> &nbsp;
                    {
                      batchFieldSource.field
                        && batchFieldSource.field.workflowData
                        ? batchFieldSource.field.workflowData.name
                        : undefined
                    }
                  </div>
                  <div className={classes.paddedBatchFieldSourceItems}>
                    {
                      batchFieldSource.fieldId
                        ? <EditIcon
                          className={`${classes.actionButton}${changeFieldDisabled}`}
                          onClick={
                            changeFieldDisabled === ''
                              ? onToggleFieldDialog
                              : undefined
                          }
                        />
                        : <AddIcon
                          className={`${classes.actionButton}${changeFieldDisabled}`}
                          onClick={
                            changeFieldDisabled === ''
                              ? onToggleFieldDialog
                              : undefined
                          }
                        />
                    }
                    &nbsp;
                    <b className={fieldValidationStyle}>{t('batchWorkflowEdit.general.field')}:</b> &nbsp;
                    {
                      batchFieldSource.field
                        ? batchFieldSource.field.name
                        : undefined
                    }
                  </div>
                  <div>
                    <FormControl variant="outlined" className={classes.formControlBatchFieldSourceSource}>
                      <InputLabel
                        className={classes.batchFieldSourceSourceLabel}
                        shrink={true}
                        variant={'outlined'}
                      >
                        {t('batchWorkflowEdit.general.source')}
                      </InputLabel>
                      <Select
                        value={selectedSource}
                        onChange={handleBatchFieldSourceSourceChange}
                        name={`batchFieldSourceSource-${batchFieldSetIndex}-${batchFieldIndex}-${batchFieldSourceIndex}`}
                        options={sourceOptions}
                        className={classes.fieldSetTypeSelect}
                        placeholder={t('batchWorkflowEdit.placeHolder.source')}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className={classes.dragHandle} {...draggableProvided.dragHandleProps}>
                  <Tooltip title={t('batchWorkflowEdit.manipulations.moveBatchFieldSource')!}>
                    <div className="circle">
                      <DragHandleRoundedIcon />
                    </div>
                  </Tooltip>
                </div>
              </Card>
            </div>
          </Grid>
        )}
      </Draggable>
      {
        changeWorkflowDialogAnchorOpen
          ? <ChangeWorkflowDialog
            open={true}
            onClose={handleChangeWorkflowDialogHide}
            batchFieldSetIndex={batchFieldSetIndex}
            batchFieldSet={undefined}
            batchFieldSourceIndex={batchFieldSourceIndex}
            currentBatchFieldSourceWorkflow={currentBatchFieldSourceWorkflow}
          />
          : undefined
      }
      {
        changeFieldDialogAnchorOpen
          ? <ChangeFieldDialog
            open={true}
            onClose={handleChangeFieldDialogHide}
            batchFieldSetIndex={batchFieldSetIndex}
            batchField={undefined}
            batchFieldSourceIndex={batchFieldSourceIndex}
            batchFieldSource={batchFieldSource}
            workflowId={currentBatchFieldSourceWorkflow ? currentBatchFieldSourceWorkflow.id : undefined}
            batchFieldSetFieldSetId={undefined}
            type={'header'}
          />
          : undefined
      }
    </Grid>
  );
}));

export const BatchFieldSourceScene = withStyles(style)(withTranslation()(BatchFieldSourceListController));
