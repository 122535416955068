export interface UrlFactory {
  globalUrls: { [key: string]: string };
  regionalUrls: (regionId: string) => { [key: string]: string };
}

export const URL_FACTORY = 'URL_FACTORY';

export type Url = string;

// tslint:disable-next-line:no-any
export function escape(literals: TemplateStringsArray, ...placeholders: any[]) {
  let result = '';

  // interleave the literals with the placeholders
  for (let i = 0; i < placeholders.length; i++) {
    result += literals[i];
    result += encodeURIComponent(placeholders[i]);
  }

  // add the last literal
  result += literals[literals.length - 1];
  return result;
}

export interface UrlMap {
  ['@']: Url;
  [key: string]: Url | UrlMap;
}

export interface CombinedUrlFactory {
  global(): UrlFactory;
  regional(regionId: string): UrlFactory;
}

export class CombinedUrlFactoryImpl implements CombinedUrlFactory {
  private readonly globalFactory: UrlFactory;
  private readonly regionalFactories = new Map<string, UrlFactory>();

  constructor(private readonly options: { global: UrlMap, regional(regionId: string): UrlMap }) {
    this.globalFactory = UrlFactory.from(options.global);
  }

  public global(): UrlFactory {
    return this.globalFactory;
  }

  public regional(regionId: string): UrlFactory {
    let factory = this.regionalFactories.get(regionId);
    if (factory) return factory;
    factory = UrlFactory.from(this.options.regional(regionId));
    this.regionalFactories.set(regionId, factory);
    return factory;
  }
}

export class UrlFactory {
  private readonly baseUrl: string;

  public static from(map: UrlMap) {
    return new UrlFactory(map['@'], map);
  }

  private static removeLeadingSlash(s: string) {
    return s.startsWith('/') ? s.slice(1) : s;
  }

  private static removeTrailingSlash(s: string) {
    return s.endsWith('/') ? s.slice(0, -1) : s;
  }

  private static tidyPathSegment(segment: string) {
    return UrlFactory.removeTrailingSlash(UrlFactory.removeLeadingSlash(segment));
  }

  constructor(
    base: Url,
    private readonly map?: UrlMap,
  ) {
    this.baseUrl = UrlFactory.removeTrailingSlash(base);
  }

  public resolve(...path: string[]): Url {
    if (!path.length) {
      return this.baseUrl;
    }

    const parts = [
      this.baseUrl,
      ...path.slice(0, -1).map(UrlFactory.tidyPathSegment),
      UrlFactory.removeLeadingSlash(path[path.length - 1]),
    ];
    return parts.join('/');
  }

  public service(service: string): UrlFactory {
    const map = this.map && this.map[service];
    const override = (map instanceof Object) ? map['@'] : map;

    const base = override || `${this.baseUrl}/${service}/`;
    if (map instanceof Object) {
      return new UrlFactory(base, {
        '@': base,
        ...(map as object),
      });
    }
    return new UrlFactory(base);
  }
}
