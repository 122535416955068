import GridListTile from '@material-ui/core/GridListTile';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IconButtonWithTooltip } from '../../../components/icon-button-tooltip';
import Zoom from '../../../components/zoom';
import { BatchModification, Page } from '../../../store/interfaces/batch-modification';
import SplitIcon from './split-icon';
import { style } from './style';

interface Props {
  data: BatchModification;
  documentId: string;
  draggable: boolean;
  pageId: string;
  handleShowLightbox: (page: Page | undefined) => () => void;
  handleSplitDocument: (documentId: string, pageIndex: number) => () => void;
  index: number;
  isFirst: boolean;
  isLast: boolean;
}

const DocumentPageView: React.FunctionComponent<Props & WithTranslation & WithStyles> = ({
  classes,
  data,
  documentId,
  handleShowLightbox,
  handleSplitDocument,
  isLast,
  pageId,
  index,
  t,
}) => {
  const page = data.pages[pageId];
  return (
    <React.Fragment>
      <div className={classes.pageContainer} onClick={handleShowLightbox(page)}>
        <GridListTile className={classes.page}>
          <Zoom img={page.thumbnail.url} width={150} height={150} zoomScale={4} transitionTime={0.25} />
          <div style={{ fontSize: '80%', color: '#444', width: 150, textAlign: 'center', zIndex: 999 }}>
            <span>{page.inputFileId}</span>
            &nbsp;
            &nbsp;
            &nbsp;
              <strong>{page.ordinal + 1}</strong>{
              data.inputFiles
                ? <span>/<span>{data.inputFiles[page.inputFileId].pageCount}</span></span>
                : null
            }
          </div>
        </GridListTile>
      </div>
      {!isLast
        ?
        <IconButtonWithTooltip
          className={classes.splitButton}
          onClick={handleSplitDocument(documentId, index)}
          tooltip={t('batchModification.manipulations.split')}
        >
          <SplitIcon />
        </IconButtonWithTooltip>
        :
        <div className={classes.splitButtonPlaceholder} />
      }
    </React.Fragment>
  );
};

const DocumentPageContainerView: React.FunctionComponent<Props & WithTranslation & WithStyles> = (props) => {
  const { classes, draggable, index, pageId, documentId } = props;

  if (!draggable) {
    return <DocumentPageView {...props} />;
  }
  return (
    <Draggable draggableId={`${documentId}/${pageId}`} index={index}>
      {(provided) => (
        <div
          className={classes.pageContainer}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <DocumentPageView {...props} />
        </div>
      )}
    </Draggable>
  );
};

export const DocumentPage = withStyles(style)(withTranslation()(DocumentPageContainerView));
