import { createAction } from '..';
import { Region } from '../interfaces/region';

export namespace RegionMutations {
  export const current = (regionId: string) => createAction((state) => ({
    ...state,
    currentRegionId: regionId,
  }));

  export const available = (regions: Region[]) => createAction((state) => ({
    ...state,
    regions: state.regions.loaded(regions),
  }));

  export const refreshing = (region: Region) => createAction((state) => ({
    ...state,
    regions: state.regions.patchItem(region.id, { refreshing: true }),
  }));

  export const replace = (region: Region) => createAction((state) => ({
    ...state,
    regions: state.regions.replaceItem(region),
  }));
}
