import { createStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const style = (theme: Theme) => createStyles({
  'root': {
    '& a.row,& .row': {
      '&:hover': {
        backgroundColor: fade(theme.palette.common.black, 0.05),
      },
      '&:not(:last-child)': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
      '&>div': {
        '&.right': {
          padding: '4px 24px 4px 8px',
          textAlign: 'end',
        },
        display: 'table-cell',
        padding: '4px 48px 4px 24px',
        verticalAlign: 'middle',
      },
      color: theme.palette.text.primary,
      display: 'table-row',
      height: '3rem',
      textDecoration: 'none',
    },
    '&>header': {
      '&>div': {
        '&.right': {
          padding: '4px 24px 4px 8px',
          textAlign: 'end',
        },
        color: 'rgba(0, 0, 0, 0.54)',
        display: 'table-cell',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        padding: '4px 48px 4px 24px',
        verticalAlign: 'middle',
      },
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      display: 'table-row',
      height: '3rem',
    },
    borderCollapse: 'collapse',
    display: 'table',
    lineHeight: '1.35rem',
    width: '100%',
  },
});
