import { withStyles, Button, Grid, Tooltip, WithStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BatchField, BatchFieldSet } from '../../../store/interfaces/batch-workflow';
import { BatchFieldScene } from './batch-field';
import { RemovableBatchComponent } from './batch-workflow-admin';
import { BatchWorkflowNewObjectData } from './batch-workflow.new-objects';
import { BatchWorkflowValidation } from './batch-workflow.validation';
import { style } from './style';

interface ControllerProps {
  batchFieldSetIndex: number;
  batchFieldSet: BatchFieldSet;
  newObjectData?: BatchWorkflowNewObjectData;
  validationData?: BatchWorkflowValidation;
  addBatchField: () => void;
  handleBatchFieldChange: (batchField: BatchField, index: number, notImportant?: boolean) => void;
  onDeleteBatchComponent: (removableBatchComponent: RemovableBatchComponent) => void;
}

class BatchFieldListController extends React.PureComponent<ControllerProps> {
  render() {
    const { batchFieldSetIndex, batchFieldSet, newObjectData, validationData,
      addBatchField, handleBatchFieldChange, onDeleteBatchComponent } = this.props;
    return (
      <BatchFieldListView
        batchFieldSetIndex={batchFieldSetIndex}
        batchFieldSet={batchFieldSet}
        newObjectData={newObjectData}
        validationData={validationData}
        addBatchField={addBatchField}
        onDeleteBatchComponent={onDeleteBatchComponent}
        handleBatchFieldChange={handleBatchFieldChange}
      />
    );
  }
}

interface ViewProps {
  batchFieldSetIndex: number;
  batchFieldSet: BatchFieldSet;
  newObjectData?: BatchWorkflowNewObjectData;
  validationData?: BatchWorkflowValidation;
  addBatchField: () => void;
  handleBatchFieldChange: (batchField: BatchField, index: number, notImportant?: boolean) => void;
  onDeleteBatchComponent: (removableBatchComponent: RemovableBatchComponent) => void;
}

type AllViewProps = ViewProps & WithTranslation & WithStyles;

const BatchFieldListView = withStyles(style)(withTranslation()(({
  batchFieldSetIndex,
  batchFieldSet,
  newObjectData,
  validationData,
  addBatchField,
  handleBatchFieldChange,
  onDeleteBatchComponent,
  classes,
  t,
}: AllViewProps) => {

  return (
    <div className={classes.paddedBatchFieldListCard}>
      <Tooltip title={t('batchWorkflowEdit.tooltip.addBatchWorkflowField')!}>
        <Button variant="outlined" className={classes.addGreenButton} onClick={addBatchField}>
          <AddCircleIcon />&nbsp;
          {t('batchWorkflowEdit.actions.addBatchField')}
        </Button>
      </Tooltip>
      {
        batchFieldSet.fields.length ?
          <Grid item={true} xs={12}>
            <Droppable droppableId={`batchFields-${batchFieldSetIndex}`} type="batchField" direction="vertical">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <Grid container={true} spacing={2}>
                    {
                      batchFieldSet.fields.map((batchField, index) =>
                        <BatchFieldScene
                          key={index}
                          batchFieldSetIndex={batchFieldSetIndex}
                          batchFieldSet={batchFieldSet}
                          batchFieldIndex={index}
                          batchField={batchField}
                          newObjectData={newObjectData}
                          validationData={validationData}
                          handleBatchFieldChange={handleBatchFieldChange}
                          onDeleteBatchComponent={onDeleteBatchComponent}
                        />
                      )
                    }
                  </Grid>
                </div>
              )}
            </Droppable>
          </Grid>
          : undefined
      }
    </div>
  );
}));

export const BatchFieldList = BatchFieldListController;
