import { PerBatchFieldSource } from '../../domain/batch-workflow/dto/batch-workflows-and-fields';
import { IndexOf } from '../types';
import { Workflow } from './batch-modification';
import { Category } from './category';
import { UserWorkflowPermission } from './user';

export interface BatchWorkflow {
  id: number;
  clientId: number;
  name: string;
  favoriteOrdinal: number | null;
  isAutoReleaseEnabled: number;
  fieldSets: BatchFieldSet[];
  workflows: IndexOf<Workflow> | undefined;
  workflowFields: IndexOf<WorkflowField> | undefined;
  repeatingFieldSets: IndexOf<WorkflowFieldSet> | undefined;
  categories: Category[];
  userPermissions: UserWorkflowPermission[];
}

export interface BatchFieldSet {
  __typename: string;
  id: string;
  label: string;
  ordinal: number;
  workflowData?: WorkflowAndFieldSetData;
  fields: BatchField[];
  isNew?: boolean;
}

export interface BatchField {
  id: string;
  label: string;
  ordinal: number;
  dataType?: string;
  field?: WorkflowFieldData;
  sources?: PerBatchFieldSource[];
  sourcesOpen?: boolean;
  isNew?: boolean;
}

export interface WorkflowWithFields {
  id: number;
  clientId: number;
  name: string;
  fields: WorkflowField[];
}

export interface WorkflowField {
  id: number;
  name: string;
  displayOrder: number;
  fieldSet?: WorkflowFieldSet;
}

export interface WorkflowFieldSet {
  id: number;
  description: string;
  label: string;
  isRepeating: number;
  fieldRepresentation: string;
}

export interface WorkflowAndFieldSetData {
  id: number | undefined;
  name: string | undefined;
  fieldSet?: WorkflowFieldSetSimple;
}

export interface WorkflowFieldData {
  id: number | undefined;
  name: string | undefined;
}

export interface WorkflowFieldSetSimple {
  id: number;
  label: string | undefined;
  description: string | undefined;
}

export interface RefreshingBatchWorkflow extends BatchWorkflow {
  refreshing: true | undefined;
}

export interface BatchWorkflowModification {
  batchWorkflowId?: number;
}

export function compareBatchWorkflows(a: BatchWorkflow, b: BatchWorkflow) {
  if (a.favoriteOrdinal === b.favoriteOrdinal) {
    if (a.name === b.name) {
      return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
    }
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  }
  if (a.favoriteOrdinal === null) return 1;
  if (b.favoriteOrdinal === null) return -1;
  return a.favoriteOrdinal! < b.favoriteOrdinal! ? 1 : -1;
}
