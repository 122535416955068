import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBar from '../../components/app-bar';
import PageContainer from '../../components/page-container';

const SettingsView: React.FunctionComponent<{}> = () => (
  <PageContainer>
    <Grid item={true} xs={12}>
      <AppBar>
        <Typography variant="h6" color="inherit">
          Settings
        </Typography>
      </AppBar>
    </Grid>
    <Grid item={true}>
      Hello
    </Grid>
  </PageContainer>
);

export const SettingsScene = SettingsView;
