import React from 'react';
import { BatchWorkflowAdminScene } from './batch-workflow-admin';

export class BatchWorkflowAddController extends React.Component<{}> {
  render() {
    return (<BatchWorkflowAdminScene batchWorkflowId={undefined} />);
  }
}

export const BatchWorkflowAdminAddScene = BatchWorkflowAddController;
