import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation, WithTranslation } from 'react-i18next';
import PageContainer from '../../components/page-container';
import { WindowTitle } from '../../components/window-title';

const NotFoundView: React.FunctionComponent<WithTranslation> = ({ t }) => (
  <PageContainer>
    <WindowTitle title={window.env.REACT_APP_HIDE_BRAND ? 'Batch UI' : t('defaultWindowTitle')} />
    <Grid item={true} xs={12}>
      <Typography variant="h1" align="center">
        404
      </Typography>
      <Typography variant="h4" align="center">
        {t('notFound.text')}
      </Typography>
    </Grid>
  </PageContainer>
);

export const NotFoundScene = withTranslation()(NotFoundView);
