import React from 'react';

interface Props {
  title?: string;
}

class WindowTitleController extends React.PureComponent<Props> {
  componentDidMount() {
    const title = this.props.title;
    if (title) {
      document.title = title;
    }
  }

  componentDidUpdate(props: Props): void {
    if (props.title && props.title !== this.props.title) {
      document.title = props.title;
    }
  }

  render() {
    return (null);
  }
}

export const WindowTitle = WindowTitleController;
