import * as H from 'history';
import { BatchRepository } from '../domain/batch/batch.repository';
import { AppMutations } from '../store/mutations/app.mutations';
import { BatchDashboardMutations } from '../store/mutations/batch-dashboard.mutations';
import { Action } from '../store/types';
import { BatchDashboardActions } from './batch-dashboard.actions';

export namespace BatchActions {
  export function deleteBatch(batchId: string, regionId: string | undefined): Action {
    return async (dispatch) => {
      if (!batchId || !regionId) return;

      const repository = BatchRepository.forRegion(regionId);

      const batchDeleted = await repository.deleteBatch(batchId).catch((err) => {
        dispatch(AppMutations.setSnackbarMessage({
          autoHideDuration: 5000,
          messageTranslationKey: 'errors.batch-delete-failed',
          rawMessage: err.message,
          variant: 'error',
        }));
      });

      if (batchDeleted) {
        dispatch(BatchDashboardMutations.deleteBatch(batchId));
      }
    };
  }

  export function lockBatch(batchId: string, regionId: string | undefined): Action {
    return async (dispatch) => {
      if (!batchId || !regionId) return;

      const repository = BatchRepository.forRegion(regionId);

      const batchLocked = await repository.lockBatch(batchId).catch((err) => {
        dispatch(AppMutations.setSnackbarMessage({
          autoHideDuration: 5000,
          messageTranslationKey: 'errors.batch-lock-failed',
          rawMessage: err.message,
          variant: 'error',
        }));
      }
      );

      if (batchLocked) {
        dispatch(BatchDashboardActions.reloadOneBatch(batchId));
      }
    };
  }

  export function unlockBatch(batchId: string, regionId: string | undefined): Action {
    return async (dispatch) => {
      if (!batchId || !regionId) return;

      const repository = BatchRepository.forRegion(regionId);

      const batchUnlocked = await repository.unlockBatch(batchId).catch((err) => {
        dispatch(AppMutations.setSnackbarMessage({
          autoHideDuration: 5000,
          messageTranslationKey: 'errors.batch-unlock-failed',
          rawMessage: err.message,
          variant: 'error',
        }));
      }
      );

      if (batchUnlocked) {
        dispatch(BatchDashboardActions.reloadOneBatch(batchId));
      }
    };
  }

  export function holdBatch(batchId: string, regionId: string | undefined, history: H.History): Action {
    return async (dispatch, getState) => {
      if (!batchId || !regionId) return;
      const repository = BatchRepository.forRegion(regionId);
      const batchHeld = await repository.holdBatch(batchId).catch((err) => {
        dispatch(AppMutations.setSnackbarMessage({
          autoHideDuration: 5000,
          messageTranslationKey: 'errors.batch-hold-failed',
          rawMessage: err.message,
          variant: 'error',
        }));
      });

      if (batchHeld) {
        await dispatch(BatchActions.unlockBatch(batchId, regionId));
        const state = getState();
        const query = state.batchPreview.query;
        if (query) {
          history.replace(`/batch-workflow/${query.batchWorkflowId}/dashboard`);
        }
      }
    };
  }

  export function releaseBatch(batchId: string, batchWorkflowId: number, regionId: string | undefined): Action {
    return async (dispatch) => {
      if (!batchWorkflowId || !batchId || !regionId) return;
      const repository = BatchRepository.forRegion(regionId);
      await repository.releaseBatch(batchId).catch((err) => {
        dispatch(AppMutations.setSnackbarMessage({
          autoHideDuration: 5000,
          messageTranslationKey: 'errors.batch-release-failed',
          rawMessage: err.message,
          variant: 'error',
        }));
      });
    };
  }

  export function exit(history: H.History, batchWorkflowId: number): Action {
    return async () => {
      history.push(`/batch-workflow/${batchWorkflowId}/dashboard`);
    };
  }
}
