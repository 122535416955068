import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isScrolledIntoView } from '../../../helpers/scroll';
import { BatchLearning } from '../../../store/interfaces/batch-learning';
import { style } from './style';

interface Props {
  documentId: string;
  data: BatchLearning;
  isActive: boolean;
  onClick: () => void;
}

class InputFileController extends React.PureComponent<Props> {
  activeDivRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.activeDivRef = React.createRef<HTMLDivElement>();
  }

  componentDidUpdate() {
    if (this.activeDivRef.current && this.props.isActive && !isScrolledIntoView(this.activeDivRef.current)) {
      this.activeDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render(): React.ReactNode {
    return (
      <div ref={this.activeDivRef}>
        <InputFileView
          documentId={this.props.documentId}
          data={this.props.data}
          onClick={this.props.onClick}
          isActive={this.props.isActive}
        />
      </div>
    );
  }
}

const InputFileView = withStyles(style)(withTranslation()(({
  classes,
  documentId,
  data,
  isActive,
  onClick,
  t,
}: Props & WithTranslation & WithStyles) => {
  let workflow = undefined;
  const document = data.inputFiles[documentId];

  if (document) {
    workflow = data.workflows ? data.workflows[document.workflowId] : undefined;
  }

  return (
    <Grid
      item={true}
      container={true}
      className={classes.documentCardContainer}
      onClick={onClick}
    >
      <Card className={`${classes.card} ${classes.documentCard} ${isActive ? classes.documentCardActive : null}`}>
        <CardActionArea>
          <CardContent>
            <Grid item={true}>
              <Typography
                gutterBottom={true}
                noWrap={true}
                title={workflow ? workflow.name : undefined}
              >
                {
                  workflow
                    ? workflow.name
                    : null
                }
              </Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}));

export const InputFileCard = InputFileController;
