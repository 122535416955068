import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import React from 'react';
import MaskedInput from 'react-text-mask';

// tslint:disable-next-line:no-any
const TextMaskCustom = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]}
      placeholderChar={'-'}
      showMask={true}
    />
  );
};

interface State {
  text: string;
}

class FormattedInputs extends React.PureComponent<TextFieldProps, State> {
  constructor(props: TextFieldProps) {
    super(props);

    this.state = { text: String(props.value) };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      text: event.target.value,
    });
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  render() {
    return (
      <TextField
        autoComplete="off"
        autoCorrect={this.props.autoCorrect}
        autoFocus={this.props.autoFocus}
        fullWidth={this.props.fullWidth}
        id={this.props.id}
        label={this.props.label}
        margin={this.props.margin}
        name={this.props.name}
        type={this.props.type}
        value={this.props.value}
        variant={this.props.variant as 'outlined'}
        InputProps={{
          inputComponent: TextMaskCustom,
          onChange: this.handleChange,
          value: this.state.text,
        }}
      />
    );
  }
}

export default FormattedInputs;
