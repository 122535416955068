import { Card, CardHeader, FormControl, InputLabel, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragHandleRoundedIcon from '@material-ui/icons/DragHandleRounded';
import EditIcon from '@material-ui/icons/Edit';
import { WithT } from 'i18next';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import Select from 'react-select';
import { InputTextField } from '../../../components/input-text-field';
import { Workflow } from '../../../domain/batch-workflow/dto/batch-workflows-and-fields';
import {
  BatchField,
  BatchFieldSet,
  WorkflowAndFieldSetData,
  WorkflowFieldSet,
  WorkflowFieldSetSimple
} from '../../../store/interfaces/batch-workflow';
import { BatchFieldList } from './batch-field-list';
import { RemovableBatchComponent } from './batch-workflow-admin';
import { BatchWorkflowNewObjectData } from './batch-workflow.new-objects';
import { BatchFieldSetViewValidation, BatchWorkflowValidation } from './batch-workflow.validation';
import { ChangeFieldSetDialog } from './change-fieldset';
import { ChangeWorkflowDialog } from './change-workflow';
import { style } from './style';

export interface GroupedOptions {
  label: string;
  options: FieldSetTypeItem[];
}

export interface FieldSetTypeItem {
  label: string;
  value: string;
}

interface ExternalProps {
  batchFieldSetIndex: number;
  batchFieldSet: BatchFieldSet;
  newObjectData?: BatchWorkflowNewObjectData;
  validationData?: BatchWorkflowValidation;
  handleBatchFieldSetChange: (batchFieldSet: BatchFieldSet, index: number, notImportant?: boolean, newBatchFieldIndex?: number) => void;
  onDeleteBatchComponent: (removableBatchComponent: RemovableBatchComponent) => void;
}

interface LocalState {
  changeWorkflowDialogAnchorOpen: boolean;
  changeFieldSetDialogAnchorOpen: boolean;
}

interface BatchFieldSetInputProps {
  batchFieldSet?: BatchFieldSet;
  workflowFieldSetValidation?: BatchFieldSetViewValidation;
  toggleInputDialog: (dialogAnchor: string) => void;
}

const BatchFieldSetWorkflowInput: React.FunctionComponent<BatchFieldSetInputProps & WithStyles & WithT> = ({
  batchFieldSet,
  workflowFieldSetValidation,
  toggleInputDialog,
  classes,
  t,
}) => {
  function onClickWorkflow(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    event.preventDefault();
    toggleInputDialog('workflow');
  }

  const workflowValidationStyle = workflowFieldSetValidation && workflowFieldSetValidation.workflow
    ? classes.batchFieldSetViewValidation
    : undefined;

  return (
    <div className={classes.batchFieldSetInputDiv}>
      {
        batchFieldSet && batchFieldSet.workflowData
          ? <EditIcon
            className={classes.batchFieldSetInputActionButton}
            onClick={onClickWorkflow}
          />
          : <AddIcon
            className={classes.batchFieldSetInputActionButton}
            onClick={onClickWorkflow}
          />
      }
      &nbsp;
      <b className={workflowValidationStyle}>{t('batchWorkflowEdit.general.workflow')}:</b> &nbsp;
      {
        batchFieldSet && batchFieldSet.workflowData
          ? batchFieldSet.workflowData.name
          : undefined
      }
      &nbsp;&nbsp;&nbsp;&nbsp;
      {
        batchFieldSet && batchFieldSet.__typename === 'PerDocumentRepeatingFieldSet'
          ? <BatchFieldSetFieldSetInput
            batchFieldSet={batchFieldSet}
            workflowFieldSetValidation={workflowFieldSetValidation}
            toggleInputDialog={toggleInputDialog}
            classes={classes}
            t={t}
          />
          : undefined
      }
    </div>
  );
};

const BatchFieldSetFieldSetInput: React.FunctionComponent<BatchFieldSetInputProps & WithStyles & WithT> = ({
  batchFieldSet,
  workflowFieldSetValidation,
  toggleInputDialog,
  classes,
  t,
}) => {
  function onClickFieldSet(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    event.preventDefault();
    toggleInputDialog('fieldSet');
  }

  if (!batchFieldSet) {
    return null;
  }

  const disabled: string = !batchFieldSet.workflowData || !batchFieldSet.workflowData.id
    ? ' ' + classes.disabled
    : '';

  const fieldSetValidationStyle = workflowFieldSetValidation && workflowFieldSetValidation.fieldSet
    ? classes.batchFieldSetViewValidation
    : undefined;

  return (
    <span>
      {
        batchFieldSet.workflowData && batchFieldSet.workflowData.fieldSet
          ? <EditIcon
            className={`${classes.batchFieldSetInputActionButton}${disabled}`}
            onClick={
              disabled === ''
                ? onClickFieldSet
                : undefined
            }
          />
          : <AddIcon
            className={`${classes.batchFieldSetInputActionButton}${disabled}`}
            onClick={
              disabled === ''
                ? onClickFieldSet
                : undefined
            }
          />
      }
      &nbsp;
      <b className={fieldSetValidationStyle}>{t('batchWorkflowEdit.general.fieldSet')}:</b> &nbsp;
      {
        batchFieldSet && batchFieldSet.workflowData && batchFieldSet.workflowData.fieldSet
          ? batchFieldSet.workflowData.fieldSet.label
          : undefined
      }
    </span>
  );
};

type ControllerProps = ExternalProps;

class BatchFieldSetController extends React.PureComponent<ControllerProps, LocalState> {
  state: Readonly<LocalState>;

  constructor(props: ControllerProps & WithStyles & WithTranslation) {
    super(props);

    this.state = {
      changeFieldSetDialogAnchorOpen: false,
      changeWorkflowDialogAnchorOpen: false,
    };
  }

  onChangeBatchField = (batchField: BatchField, index: number, notImportant?: boolean) => {
    const batchFields = [...this.props.batchFieldSet.fields];

    if (!batchFields[index]) return;

    batchFields[index] = batchField;
    const batchFieldSet = { ...this.props.batchFieldSet, fields: batchFields };
    this.props.handleBatchFieldSetChange(batchFieldSet, this.props.batchFieldSetIndex, notImportant);
  }

  addBatchField = () => {
    if (!this.props.batchFieldSet) return;

    const batchFields = [...this.props.batchFieldSet.fields];

    const newBatchField: BatchField = { id: '', label: '', ordinal: 0, isNew: true };
    const newBatchFieldIndex: number = batchFields.push(newBatchField) - 1;

    const batchFieldSet: BatchFieldSet = { ...this.props.batchFieldSet, fields: batchFields };
    this.props.handleBatchFieldSetChange(batchFieldSet, this.props.batchFieldSetIndex, false, newBatchFieldIndex);
  }

  handleBatchFieldSetLabelChange = (value: string) => {
    this.props.handleBatchFieldSetChange({ ...this.props.batchFieldSet, label: value }, this.props.batchFieldSetIndex);
  }

  toggleInputDialog = (dialogAnchor: string) => {
    if (dialogAnchor === 'workflow') {
      this.setState({ changeWorkflowDialogAnchorOpen: !this.state.changeWorkflowDialogAnchorOpen });
    } else if (dialogAnchor === 'fieldSet') {
      this.setState({ changeFieldSetDialogAnchorOpen: !this.state.changeFieldSetDialogAnchorOpen });
    }
  }

  render() {
    const {
      batchFieldSet,
      batchFieldSetIndex,
      newObjectData,
      validationData,
      handleBatchFieldSetChange,
      onDeleteBatchComponent,
    } = this.props;

    function handleBatchFieldSetDelete(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
      event.preventDefault();
      onDeleteBatchComponent({
        batchFieldSetIndex: batchFieldSetIndex,
        type: 'batchFieldSet',
      });
    }

    function resetBatchFieldFields(): BatchField[] {
      return batchFieldSet.fields.map((batchField) => ({
        ...batchField,
        field: undefined,
        sources: undefined,
      }));
    }

    function handleBatchFieldSetTypeChange(selectedOption: FieldSetTypeItem | null) {
      if (selectedOption === null) return;

      let workflowData: WorkflowAndFieldSetData | undefined;
      if (batchFieldSet.workflowData) {
        if ((batchFieldSet.__typename !== 'PerBatchFieldSet') && (selectedOption.value !== 'PerBatchFieldSet')) {
          workflowData = { ...batchFieldSet.workflowData, fieldSet: undefined };
        }
      }
      handleBatchFieldSetChange(
        {
          ...batchFieldSet,
          __typename: selectedOption.value,
          fields: resetBatchFieldFields(),
          workflowData,
        },
        batchFieldSetIndex
      );
    }

    function handleBatchFieldSetWorkflowChange(workflowData: WorkflowAndFieldSetData) {
      handleBatchFieldSetChange(
        {
          ...batchFieldSet,
          fields: resetBatchFieldFields(),
          workflowData,
        },
        batchFieldSetIndex
      );
    }

    function handleBatchFieldSetFieldSetChange(fieldSet: WorkflowFieldSetSimple) {
      if (batchFieldSet.workflowData) {
        handleBatchFieldSetChange(
          {
            ...batchFieldSet,
            fields: resetBatchFieldFields(),
            workflowData: { ...batchFieldSet.workflowData, fieldSet: fieldSet },
          },
          batchFieldSetIndex
        );
      }
    }

    return (
      <BatchFieldSetView
        batchFieldSet={batchFieldSet}
        batchFieldSetIndex={batchFieldSetIndex}
        newObjectData={newObjectData}
        validationData={validationData}
        changeWorkflowDialogAnchorOpen={this.state.changeWorkflowDialogAnchorOpen}
        changeFieldSetDialogAnchorOpen={this.state.changeFieldSetDialogAnchorOpen}
        handleBatchFieldSetLabelChange={this.handleBatchFieldSetLabelChange}
        handleBatchFieldSetTypeChange={handleBatchFieldSetTypeChange}
        handleBatchFieldSetWorkflowChange={handleBatchFieldSetWorkflowChange}
        handleBatchFieldSetFieldSetChange={handleBatchFieldSetFieldSetChange}
        handleBatchFieldSetDelete={handleBatchFieldSetDelete}
        toggleInputDialog={this.toggleInputDialog}
        onChangeBatchField={this.onChangeBatchField}
        addBatchField={this.addBatchField}
        onDeleteBatchComponent={onDeleteBatchComponent}
      />
    );
  }
}

interface ViewProps {
  batchFieldSet: BatchFieldSet;
  batchFieldSetIndex: number;
  newObjectData?: BatchWorkflowNewObjectData;
  validationData?: BatchWorkflowValidation;
  changeWorkflowDialogAnchorOpen: boolean;
  changeFieldSetDialogAnchorOpen: boolean;
  handleBatchFieldSetLabelChange: (value: string) => void;
  handleBatchFieldSetTypeChange: (selectedOption: FieldSetTypeItem | null) => void;
  handleBatchFieldSetWorkflowChange: (workflowData: WorkflowAndFieldSetData) => void;
  handleBatchFieldSetFieldSetChange: (fieldSet: WorkflowFieldSetSimple) => void;
  handleBatchFieldSetDelete: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  toggleInputDialog: (dialogAnchor: string) => void;
  onChangeBatchField: (batchField: BatchField, index: number, notImportant?: boolean) => void;
  addBatchField: () => void;
  onDeleteBatchComponent: (removableBatchComponent: RemovableBatchComponent) => void;
}

type AllViewProps = ViewProps & WithTranslation & WithStyles;

const BatchFieldSetView = withStyles(style)(withTranslation()(({
  batchFieldSet,
  batchFieldSetIndex,
  newObjectData,
  validationData,
  changeWorkflowDialogAnchorOpen,
  changeFieldSetDialogAnchorOpen,
  handleBatchFieldSetLabelChange,
  handleBatchFieldSetTypeChange,
  handleBatchFieldSetWorkflowChange,
  handleBatchFieldSetFieldSetChange,
  handleBatchFieldSetDelete,
  toggleInputDialog,
  onChangeBatchField,
  addBatchField,
  onDeleteBatchComponent,
  classes,
  t,
}: AllViewProps) => {

  const formatGroupLabel = (data: GroupedOptions) => (
    <div className={classes.groupStyles}>
      <span>{data.label}</span>
    </div>
  );

  const batchFieldSetTypeOptions = [
    {
      label: t('batchWorkflowEdit.general.perBatchFieldSetType'),
      options: [
        {
          label: 'Batch',
          value: 'PerBatchFieldSet',
        },
      ],
    },
    {
      label: t('batchWorkflowEdit.general.perDocumentHeaderFieldSetType'),
      options: [
        {
          label: 'Document Header',
          value: 'PerDocumentHeaderFieldSet',
        },
      ],
    },
    {
      label: t('batchWorkflowEdit.general.perDocumentRepeatingFieldSetType'),
      options: [
        {
          label: 'Document Repeating',
          value: 'PerDocumentRepeatingFieldSet',
        },
      ],
    },
  ];

  let selectedItem: FieldSetTypeItem | null;

  switch (batchFieldSet.__typename) {
    case 'PerBatchFieldSet': {
      selectedItem = {
        label: 'Batch',
        value: 'PerBatchFieldSet',
      };
      break;
    }

    case 'PerDocumentHeaderFieldSet': {
      selectedItem = {
        label: 'Document Header',
        value: 'PerDocumentHeaderFieldSet',
      };
      break;
    }

    case 'PerDocumentRepeatingFieldSet': {
      selectedItem = {
        label: 'Document Repeating',
        value: 'PerDocumentRepeatingFieldSet',
      };
      break;
    }

    default:
      selectedItem = null;
      break;
  }

  function handleChangeWorkflowDialogHide(value?: Workflow) {
    toggleInputDialog('workflow');
    if (value) {
      handleBatchFieldSetWorkflowChange({
        fieldSet: undefined,
        id: value.id,
        name: value.name,
      });
    }
  }

  function handleChangeFieldSetDialogHide(value?: WorkflowFieldSet) {
    toggleInputDialog('fieldSet');
    if (value) {
      handleBatchFieldSetFieldSetChange({
        description: value.description,
        id: value.id,
        label: value.label,
      });
    }
  }

  const batchFieldSetDivRef = React.useRef<HTMLDivElement>(null);
  const thisBatchFieldSetError = Boolean(
    validationData &&
    validationData.batchFieldSetValidation &&
    (validationData.batchFieldSetValidation.id === batchFieldSetIndex) &&
    !validationData.batchFieldSetValidation.batchFieldValidation
  );
  const thisBatchFieldSetIsNew = Boolean(
    newObjectData &&
    newObjectData.newBatchFieldSet &&
    (newObjectData.newBatchFieldSet.id === batchFieldSetIndex) &&
    !newObjectData.newBatchFieldSet.newBatchField
  );

  React.useEffect(() => {
    if (batchFieldSetDivRef.current && (thisBatchFieldSetError || thisBatchFieldSetIsNew)) {
      BatchWorkflowValidation.scrollToValidationFailure(batchFieldSetDivRef.current);
    }
  });

  let batchFieldSetLabelError: boolean = false;
  let batchFieldSetLabelErrorMessage: string | undefined;
  let batchFieldSetViewValidation: BatchFieldSetViewValidation | undefined;
  if (thisBatchFieldSetError) {
    const batchFieldSetValidation = validationData!.batchFieldSetValidation!;
    const errorMessage = validationData!.errorMessageComponent || undefined;

    if (batchFieldSetValidation.label) {
      batchFieldSetLabelError = true;
      batchFieldSetLabelErrorMessage = errorMessage;
    } else if (batchFieldSetValidation.workflow) {
      batchFieldSetViewValidation = { workflow: true, errorMessage };
    } else if (batchFieldSetValidation.fieldSet) {
      batchFieldSetViewValidation = { fieldSet: true, errorMessage };
    }
  }
  if (thisBatchFieldSetIsNew) {
    batchFieldSetLabelErrorMessage = newObjectData!.messageComponent || undefined;
  }

  return (
    <div className={classes.batchFieldSetWrapper} ref={batchFieldSetDivRef}>
      <Draggable draggableId={`batchFieldSet-${batchFieldSetIndex}`} index={batchFieldSetIndex}>
        {(draggableProvided) => (
          <div
            className="draggable"
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
          >
            <Grid item={true} xs={12}>
              <Card className={classes.batchFieldSet}>
                <CardHeader
                  className={classes.batchFieldSetCardHeader}
                  title={
                    <React.Fragment>
                      <span className={classes.batchFieldSetCardTitle}>
                        {t('batchWorkflowEdit.general.batchFieldSet')}
                      </span>
                      &nbsp;&nbsp;
                      <span className={classes.batchFieldSetId}>{batchFieldSet.id}</span>
                      <Tooltip title={t('batchWorkflowEdit.actions.removeBatchFieldSet')!}>
                        <DeleteOutlineIcon
                          className={classes.pullRight + ' ' + classes.deleteIconButton}
                          onClick={handleBatchFieldSetDelete}
                        />
                      </Tooltip>
                    </React.Fragment>
                  }
                />
                <div className={classes.paddedBlueBackground}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputTextField
                      id={batchFieldSetIndex + '-batchFieldSetName'}
                      className={classes.textField}
                      label="Label"
                      textValue={batchFieldSet.label}
                      textValueError={batchFieldSetLabelError}
                      textValueErrorMessage={batchFieldSetLabelErrorMessage}
                      onChange={handleBatchFieldSetLabelChange}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControlBatchFieldSetType}
                  >
                    <InputLabel
                      className={classes.batchFieldSourceSourceLabel}
                      shrink={true}
                      variant={'outlined'}
                    >
                      {t('batchWorkflowEdit.general.type')}
                    </InputLabel>
                    <Select
                      value={selectedItem}
                      onChange={handleBatchFieldSetTypeChange}
                      name={batchFieldSetIndex + '-batchFieldSetType'}
                      options={batchFieldSetTypeOptions}
                      className={classes.fieldSetTypeSelect}
                      formatGroupLabel={formatGroupLabel}
                      placeholder={'batchWorkflowEdit.placeHolder.batchFieldSetType'}
                    />
                  </FormControl>
                  {
                    batchFieldSet.__typename !== 'PerBatchFieldSet'
                      ? <FormControl
                        variant="outlined"
                        className={classes.fieldSetTypeFormControl}
                      >
                        <BatchFieldSetWorkflowInput
                          batchFieldSet={batchFieldSet}
                          workflowFieldSetValidation={batchFieldSetViewValidation}
                          toggleInputDialog={toggleInputDialog}
                          classes={classes}
                          t={t}
                        />
                      </FormControl>
                      : undefined
                  }
                </div>
                <BatchFieldList
                  batchFieldSetIndex={batchFieldSetIndex}
                  batchFieldSet={batchFieldSet}
                  newObjectData={newObjectData}
                  validationData={validationData}
                  addBatchField={addBatchField}
                  handleBatchFieldChange={onChangeBatchField}
                  onDeleteBatchComponent={onDeleteBatchComponent}
                />
                <div className={classes.dragHandle} {...draggableProvided.dragHandleProps}>
                  <Tooltip title={t('batchWorkflowEdit.manipulations.moveBatchFieldSet')!}>
                    <div className="circle">
                      <DragHandleRoundedIcon />
                    </div>
                  </Tooltip>
                </div>
              </Card>
            </Grid>
          </div>
        )}
      </Draggable>
      {
        changeWorkflowDialogAnchorOpen
          ? <ChangeWorkflowDialog
            onClose={handleChangeWorkflowDialogHide}
            open={true}
            batchFieldSetIndex={batchFieldSetIndex}
            batchFieldSet={batchFieldSet}
            batchFieldSourceIndex={undefined}
            currentBatchFieldSourceWorkflow={undefined}
          />
          : undefined
      }
      {
        batchFieldSet.workflowData
          && batchFieldSet.workflowData.id
          && changeFieldSetDialogAnchorOpen
          ? <ChangeFieldSetDialog
            onClose={handleChangeFieldSetDialogHide}
            open={true}
            batchFieldSetIndex={batchFieldSetIndex}
            batchFieldSet={batchFieldSet}
            workflowId={batchFieldSet.workflowData.id}
          />
          : undefined
      }
    </div>
  );
}));

export const BatchFieldSetScene = BatchFieldSetController;
