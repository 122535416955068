import { RegionRepository } from '../domain/region/region.repository';
import { AuthStatus } from '../store/interfaces/auth';
import { compareRegions, Region } from '../store/interfaces/region';
import { RegionMutations } from '../store/mutations/region.mutations';
import { Action } from '../store/types';

export namespace RegionActions {
  export function ensureLoaded(): Action {
    return async (dispatch, getState) => {
      const state = getState();
      if (!state.regions.loading) return;

      if (state.auth.status !== AuthStatus.AUTHENTICATED) {
        dispatch(RegionMutations.available([]));
        return;
      }

      const repository = RegionRepository.forGlobal();
      const regionsResult = await repository.findAll();
      const regions = [...regionsResult]; // regionsResult is frozen so we can't call .sort() function on it
      dispatch(RegionMutations.available(regions));

      if (regions.length && !state.currentRegionId) {
        const sorted = regions.sort(compareRegions);
        dispatch(RegionMutations.current(sorted[0].id));
      }
    };
  }

  export function toggleFavorite(region: Region): Action {
    return async (dispatch) => {
      dispatch(RegionMutations.refreshing(region));
      const updated = { ...region };

      const repository = RegionRepository.forGlobal();
      if (region.favoriteOrdinal !== null) {
        // un-favorite
        await repository.unfavorite(region.id);
        updated.favoriteOrdinal = null;
      } else {
        // favorite
        updated.favoriteOrdinal = await repository.favorite(region.id);
      }
      updated.refreshing = undefined;
      dispatch(RegionMutations.replace(updated));
    };
  }
}
