import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreIcon from '@material-ui/icons/MoreVert';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { WithT } from 'i18next';
import React from 'react';

import {
  BatchDataFieldSet,
  PerDocumentFields,
  PerDocumentHeaderDataFieldSet,
  PerDocumentRepeatingDataFieldSet,
  RowField,
} from '../../../domain/batch-preview/dto/batch-preview-data';
import {
  BatchFieldSet as SortedBatchFieldSet,
  PerDocumentHeaderFieldSet,
  PerDocumentRepeatingFieldSet
} from '../../../domain/batch-workflow/dto/batch-workflows-and-fields';
import {
  BatchDocumentConfidence,
  BatchFieldSet,
  ConfidenceLevels,
  Document,
  Workflow,
} from '../../../store/interfaces/batch-preview';
import { IndexOf } from '../../../store/types';
import { ClassDesignComponent } from './class-design-view';
import { ConfidenceBarComponent, ConfidenceTooltipComponent } from './confidence-bar';
import { CellField, Field, Validation } from './field';
import { style } from './style';

interface DocumentHeaderProps extends WithT, WithStyles {
  document?: PerDocumentFields;
  documentId: string;
  fieldSetId: string;
  fieldSet: BatchFieldSet;
  fieldSetConfidence: ConfidenceLevels | null;
}

interface DocumentRepeatingProps extends WithT, WithStyles {
  document?: PerDocumentFields;
  documentId: string;
  fieldSetId: string;
  fieldSet: BatchFieldSet;
  fieldSetConfidence: ConfidenceLevels | null;
}

interface DocumentFieldSetListProps {
  batchDocuments: IndexOf<Document>;
  batchId: string;
  batchWorkflowId: number;
  fieldSets: IndexOf<BatchDataFieldSet>;
  handleIgnoreDocument: (documentId: string) => void;
  handleProcessBatch: (documentId: string) => () => void;
  onMenuShow: (item: Document, element: HTMLElement) => void;
  ignoredDocuments: string[];
  problemDocuments: IndexOf<true>;
  refFieldSets: IndexOf<BatchFieldSet>;
  sortedFieldSets?: SortedBatchFieldSet[];
  workflows?: IndexOf<Workflow>;
  documentConfidence: IndexOf<BatchDocumentConfidence> | null;
}

interface DocumentFieldSetProps extends WithT, WithStyles {
  fieldSets: IndexOf<BatchDataFieldSet>;
  documentConfidence: BatchDocumentConfidence | null;
  document: Document;
  documentId: string;
  handleIgnoreDocument: (documentId: string) => void;
  handleProcessBatch: () => void;
  onMenuShow: (item: Document, element: HTMLElement) => void;
  hasProblem: boolean;
  isIgnored: boolean;
  refFieldSets: IndexOf<BatchFieldSet>;
  sortedFieldSets?: SortedBatchFieldSet[];
  workflows?: IndexOf<Workflow>;
}

const DocumentFieldSetView = withStyles(style)((
  {
    classes,
    document,
    documentId,
    fieldSets,
    handleIgnoreDocument,
    handleProcessBatch,
    onMenuShow,
    hasProblem,
    isIgnored,
    refFieldSets,
    sortedFieldSets,
    t,
    workflows,
    documentConfidence,
  }: DocumentFieldSetProps
) => {
  const workflowFieldSets = sortedFieldSets
    ? sortedFieldSets.filter(
      (fs: SortedBatchFieldSet) => (fs as PerDocumentHeaderFieldSet | PerDocumentRepeatingFieldSet).workflowId === document.workflowId)
    : [];

  let isReference: boolean = true;

  const documentData = Object.keys(fieldSets).reduce(
    (acc, fieldSetId) => {
      if (!workflowFieldSets.find(
        (fs: SortedBatchFieldSet) => (fs as PerDocumentHeaderFieldSet | PerDocumentRepeatingFieldSet).id === fieldSetId)) return acc;
      if (!fieldSets[fieldSetId]) return acc;
      // tslint:disable-next-line:no-any
      isReference = isReference && !!((refFieldSets[fieldSetId] as any) as (PerDocumentHeaderFieldSet | PerDocumentRepeatingFieldSet)).reference;
      const docData = ((fieldSets[fieldSetId] as PerDocumentHeaderDataFieldSet | PerDocumentRepeatingDataFieldSet)
        .documents as PerDocumentFields[]);

      return {
        ...acc,
        [fieldSetId]: docData.filter((doc: PerDocumentFields) => doc.documentId === documentId).shift(),
      };
    },
    {} as PerDocumentHeaderDataFieldSet | PerDocumentRepeatingDataFieldSet
  );

  const isCollapsed = isReference || isIgnored;
  const [checked, setChecked] = React.useState(!isIgnored);
  function handleChange() {
    handleIgnoreDocument(documentId);
    setChecked(prev => !prev);
  }

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.stopPropagation();
  }

  function handleMenuClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.stopPropagation();
    event.preventDefault();
    onMenuShow(document, event.currentTarget);
  }

  return (
    <Grid item={true} xs={12}>
      <Accordion key={'doc_' + documentId} defaultExpanded={!isCollapsed} className={classes.documentFieldSet}>
        <AccordionSummary
          className={(hasProblem ? classes.problemCard : '') + ' ' + classes.expansionSummary}
          expandIcon={<ExpandMoreIcon />}
          classes={{
            content: classes.expansionContent,
            expanded: classes.expansionExpanded,
            expandIcon: classes.expansionIcon,
          }}
        >
          <Grid container={true} spacing={0}>
            <Grid item={true} xs={7}>
              <Typography variant="h5">
                <span className={classes.documentTitle}>
                  {t('batchPreview.documentTitle', {
                    documentId: documentId,
                    workflowName: (workflows) ? workflows[document.workflowId].name : '',
                  })}
                </span>

                <ClassDesignComponent
                  aiClass={document.class}
                  design={document.design}
                  classes={classes}
                  t={t}
                />
              </Typography>
              <Typography variant={'subtitle1'}>{t('batchPreview.documentPages', { pages: document.pages })}</Typography>
            </Grid>
            <Grid item={true} xs={5}>
              <IconButton
                onClick={handleMenuClick}
                color="inherit"
                className={classes.pullRight + ' ' + classes.moreIconButton}
              >
                <MoreIcon fontSize="small" />
              </IconButton>
              <Button
                onClick={handleProcessBatch}
                color="inherit"
                className={classes.pullRight}
              >
                {t('batchPreview.actions.process')}
              </Button>
              {!isReference ?
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  className={classes.pullRight}
                  title={<Typography>{t('batchPreview.document.includeInOutputTooltip')}</Typography>}
                >
                  <FormControlLabel
                    label={t('batchPreview.document.includeInOutputLabel')}
                    onClick={handleClick}
                    className={classes.includeInOutputCheckBox}
                    control={<Checkbox
                      checked={checked}
                      onChange={handleChange}
                      className={classes.pullRight}
                    />}
                  />
                </Tooltip>
                : <div className={classes.pullRight + ' ' + classes.referenceDocumentInfo}>{t('batchPreview.document.referenceDocument')}</div>}
            </Grid>
            {documentConfidence ?
              <Grid item={true} xs={12}>
                <ConfidenceBarComponent
                  classes={classes}
                  documentConfidence={documentConfidence.confidence}
                  t={t}
                />
              </Grid>
              : null}
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionDetail}>
          {workflowFieldSets.length > 0 ?
            workflowFieldSets.map((fs: SortedBatchFieldSet) => {
              switch (fs.__typename) {
                case 'PerDocumentRepeatingFieldSet':
                  return <RepeatingFieldSetView
                    key={'pdrsf_' + documentId + '_' + fs.id}
                    documentId={documentId}
                    document={documentData ? documentData[fs.id] : undefined}
                    fieldSet={refFieldSets[fs.id]}
                    fieldSetId={fs.id}
                    t={t}
                    fieldSetConfidence={documentConfidence ? documentConfidence.fieldSetConfidence[fs.id] : null}
                  />;
                case 'PerDocumentHeaderFieldSet':
                  return <HeaderFieldSetView
                    key={'pdhfs_' + documentId + '_' + fs.id}
                    documentId={documentId}
                    document={documentData ? documentData[fs.id] : undefined}
                    fieldSet={refFieldSets[fs.id]}
                    fieldSetId={fs.id}
                    t={t}
                    fieldSetConfidence={documentConfidence ? documentConfidence.fieldSetConfidence[fs.id] : null}
                  />;
                default:
                  return null;
              }
            })
            : <CardContent>{t('batchPreview.noFieldSetsForDocument')}</CardContent>}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
});

class DocumentFieldSetListController extends React.PureComponent<DocumentFieldSetListProps & WithT> {
  render() {
    const {
      batchDocuments,
      fieldSets,
      handleProcessBatch,
      handleIgnoreDocument,
      onMenuShow,
      ignoredDocuments,
      problemDocuments,
      refFieldSets,
      sortedFieldSets,
      t,
      workflows,
      documentConfidence,
    } = this.props;

    return (
      <React.Fragment>
        {batchDocuments
          ?
          Object.keys(batchDocuments).map((documentId) => {
            return (
              <DocumentFieldSetView
                key={'dfs_' + documentId}
                document={batchDocuments[documentId]}
                documentId={documentId}
                fieldSets={fieldSets}
                handleProcessBatch={handleProcessBatch(documentId)}
                handleIgnoreDocument={handleIgnoreDocument}
                onMenuShow={onMenuShow}
                hasProblem={problemDocuments[documentId] || false}
                isIgnored={(ignoredDocuments && ignoredDocuments.indexOf(documentId) >= 0)}
                refFieldSets={refFieldSets}
                sortedFieldSets={sortedFieldSets}
                t={t}
                workflows={workflows}
                documentConfidence={documentConfidence ? documentConfidence[documentId] : null}
              />
            );
          })
          : null
        }
      </React.Fragment>
    );
  }
}

const HeaderFieldSetView = withStyles(style)((
  { documentId, document, fieldSet, fieldSetId, fieldSetConfidence, classes, t }: DocumentHeaderProps
) => {
  return fieldSet ? (
    <CardContent id={fieldSetId + documentId}>
      <Typography variant="h5" color="inherit" className={classes.grey}>
        <span className={classes.documentTitle}>
          {t('batchPreview.documentFieldSetTitle', { fieldSetId: fieldSet.label })}
        </span>
        {fieldSetConfidence ?
          <ConfidenceTooltipComponent documentConfidence={fieldSetConfidence} t={t} />
          : null}
        {
          // tslint:disable-next-line:no-any
          ((fieldSet as any) as PerDocumentHeaderFieldSet).reference ?
            <Tooltip
              title={<React.Fragment>
                <Typography>{t('batchPreview.document.reference')}</Typography>
              </React.Fragment>}
              classes={{ tooltip: classes.tooltip }}
            >
              <RemoveCircleOutlineIcon fontSize="small" className={classes.documentInfoIcon} />
            </Tooltip>
            : null}
      </Typography>
      <Grid container={true} className={classes.grow}>
        {fieldSet.fields
          ? Object.keys(fieldSet.fields).map((fieldId) => {
            const documentField = (document && document.fields) ? document.fields[fieldId] : undefined;
            const field = fieldSet.fields[fieldId];
            const validationSeverity = documentField ? documentField.validationSeverity : undefined;
            const validation: Validation | undefined = validationSeverity && documentField
              ? {
                message: documentField.rejectReason,
                severity: validationSeverity === 'HARD' ? 'HARD' : 'SOFT',
              }
              : undefined;

            return (
              <Grid item={true} xs={12} md={6} key={'hfs_' + fieldId} className={classes.fieldRoot}>
                <Field
                  label={field.label}
                  t={t}
                  value={documentField ? documentField.value : undefined}
                  validation={validation}
                />
              </Grid>
            );
          })
          : <Grid item={true}>{t('batchPreview.noFields')}</Grid>}
      </Grid>
    </CardContent>
  ) : null;
});

const RepeatingFieldSetView = withStyles(style)((
  { documentId, document, fieldSet, fieldSetId, fieldSetConfidence, classes, t }: DocumentRepeatingProps
) => {
  const dataRow = document ? document.rows : undefined;
  let columns = 0;
  return fieldSet && fieldSet.fields ? (
    <React.Fragment>
      <CardContent id={fieldSetId + documentId}>
        <Typography variant="h5" color="inherit" className={classes.grey}>
          <span className={classes.documentTitle}>
            {t('batchPreview.documentFieldSetTitle', { fieldSetId: fieldSet.label })}
          </span>
          {fieldSetConfidence ?
            <ConfidenceTooltipComponent documentConfidence={fieldSetConfidence} t={t} />
            : null}
          {// tslint:disable-next-line:no-any
            ((fieldSet as any) as PerDocumentHeaderFieldSet).reference ?
              <Tooltip
                title={<React.Fragment>
                  <Typography>{t('batchPreview.document.reference')}</Typography>
                </React.Fragment>}
                classes={{ tooltip: classes.tooltip }}
              >
                <RemoveCircleOutlineIcon fontSize="small" className={classes.documentInfoIcon} />
              </Tooltip>
              : null}
        </Typography>
      </CardContent>

      <div className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(fieldSet.fields).map((fieldId) => {
                const field = fieldSet.fields[fieldId];
                columns++;
                return (
                  <TableCell
                    key={'head_' + fieldId + '_' + documentId}
                    className={classes.tableCell}
                  >
                    {field.label}
                  </TableCell>
                );
              })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {(dataRow && dataRow.length > 0)
              ? dataRow.map((rowField: RowField, id: number) => {
                return (
                  <TableRow key={'row_' + fieldSetId + '_' + documentId + '_' + id}>
                    {Object.keys(fieldSet.fields).map((fieldId) => {
                      const field = rowField.fields[fieldId];
                      if (!field) {
                        return (
                          <CellField
                            key={'cell_' + fieldId + '_' + documentId}
                            value={undefined}
                            emptyText="-"
                            validation={undefined}
                            t={t}
                          />
                        );
                      }

                      const validationSeverity = field.validationSeverity;
                      const validation: Validation | undefined = validationSeverity
                        ? {
                          message: field.rejectReason,
                          severity: validationSeverity === 'HARD' ? 'HARD' : 'SOFT',
                        }
                        : undefined;

                      return (
                        <CellField
                          key={'cell_' + fieldId + '_' + documentId}
                          value={field.value}
                          emptyText="-"
                          validation={validation}
                          t={t}
                        />
                      );
                    })}
                  </TableRow>
                );
              })
              : (
                <TableRow>
                  <TableCell colSpan={columns} align="center">{t('batchPreview.noLinesAvailable')}</TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  ) : null;
});

export const DocumentFieldSetScene = DocumentFieldSetListController;
