import { BatchDashboardQuery } from '../interfaces/batch-dashboard';

class DashboardQueryStorage {
  private readonly db: Promise<IDBDatabase>;

  constructor() {
    const dbName = 'persistance';

    this.db = new Promise<IDBDatabase>((resolve, reject) => {
      const request = indexedDB.open(dbName, 2);
      request.onerror = () => {
        reject(new Error('Unable to create database'));
      };
      request.onupgradeneeded = (event) => {
        // tslint:disable-next-line:no-any
        const db: IDBDatabase = (event.target as any).result;
        db.createObjectStore('dashboardQueries', { keyPath: 'batchWorkflowId' });
      };
      request.onsuccess = function () {
        resolve(request.result);
      };
    });
  }

  async get(batchWorkflowId: number): Promise<BatchDashboardQuery> {
    const db = await this.db;
    return new Promise<BatchDashboardQuery>((resolve, reject) => {
      const tx = db.transaction('dashboardQueries', 'readonly');
      const store = tx.objectStore('dashboardQueries');
      const result = store.get(batchWorkflowId);
      result.onerror = () => {
        reject(new Error('Unable to update data'));
      };
      result.onsuccess = () => {
        resolve(result.result);
      };
    });
  }

  async set(value: BatchDashboardQuery) {
    const { regionId, pagination, ...storedValue } = value;
    const db = await this.db;
    return new Promise<void>((resolve, reject) => {
      const tx = db.transaction('dashboardQueries', 'readwrite');
      const store = tx.objectStore('dashboardQueries');
      const result = store.put(storedValue);
      result.onerror = () => {
        reject(new Error('Unable to update data'));
      };
      result.onsuccess = () => {
        resolve();
      };
    });
  }
}

const instance = new DashboardQueryStorage();

export const dashboardQueryStorage = instance;
