// tslint:disable:no-console
import { Token, TokenSet } from '../../store/interfaces/token';
import { Store } from '../store';
import { decodeToken } from './token';

export interface StoreFactory {
  createStore(key: string): Store<TokenSet>;
}

export class LocalStorageStoreFactory implements StoreFactory {
  public createStore(key: string): Store<TokenSet> {
    return new LocalStorageStore(key);
  }
}

class LocalStorageStore implements Store<TokenSet> {
  private ssoToken: Token | null;

  constructor(private readonly key: string) {
    if (!('localStorage' in window) || window.localStorage === null) {
      throw new Error('localStorage is not supported');
    }
  }

  public getItem() {
    // check for SSO token in URL query
    const params = new URLSearchParams(window.location.search.slice(1));
    const token = params.get('token');
    if (token) {
      params.delete('token');
      const modifiedParams = params.toString();
      const path = modifiedParams
        ? `${window.location.pathname}?${modifiedParams}${window.location.hash}`
        : `${window.location.pathname}${window.location.hash}`;
      window.history.replaceState(undefined, document.title, path);

      this.ssoToken = decodeToken(token);
    }
    if (this.ssoToken) {
      return {
        idToken: this.ssoToken,
      } as TokenSet;
    }

    const value = window.localStorage.getItem(this.key);
    return value ? JSON.parse(value) : null;
  }

  public setItem(value: TokenSet | undefined): void {
    if (value) {
      window.localStorage.setItem(this.key, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(this.key);
    }
  }

  public clear(): void {
    window.localStorage.clear();
  }
}
