import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { RouterProps, WithBatchWorkflowId } from '../../../helpers/router-props';
import { MaybeWithRegionId } from '../../../hoc/with-region-id';
import { StateRoot } from '../../../store/interfaces';
import { BatchDashboard } from './dashboard';

export class BatchDashboardSceneController extends React.Component<RouteComponentProps<WithBatchWorkflowId> & MaybeWithRegionId> {
  componentDidMount() {
    RouterProps.handleBatchWorkflowRoute(this.props);
  }

  render() {
    const { history, match, regionId } = this.props;
    const batchWorkflowId = match.params.batchWorkflowId
      ? Number(match.params.batchWorkflowId)
      : undefined;

    if (!batchWorkflowId || !regionId) {
      return null;
    }

    return (
      <BatchDashboard
        batchWorkflowId={batchWorkflowId}
        history={history}
        regionId={regionId}
      />
    );
  }
}

const mapStateToSceneProps = (state: StateRoot): MaybeWithRegionId => ({
  regionId: state.currentRegionId,
});

export const BatchDashboardScene = withRouter(
  connect(mapStateToSceneProps)(BatchDashboardSceneController)
);
