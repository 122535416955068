import cx from 'classnames';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { StateRoot } from '../../store/interfaces';
import { sidebarStyle } from './styles';

// @material-ui/core components
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Place from '@material-ui/icons/Place';
import StarBorderIcon from '@material-ui/icons/StarBorderRounded';
import StarIcon from '@material-ui/icons/StarRounded';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RegionActions } from '../../actions/region.actions';
import { DataList } from '../../store/data-list';
import { Region } from '../../store/interfaces/region';
import { RegionMutations } from '../../store/mutations/region.mutations';

interface Props {
  handleDrawerClose: () => void;
  mini: boolean;
}

interface StateProps {
  currentRegionId?: string;
  regions: DataList<string, Region>;
}

interface TriggerProps {
  setCurrent: (region: Region) => void;
  toggleFavorite: (region: Region) => () => void;
}

export const RegionView = withTranslation()(({
  classes,
  currentRegionId,
  handleDrawerClose,
  mini,
  regions,
  setCurrent,
  t,
  toggleFavorite,
}: Props & StateProps & TriggerProps & WithStyles & WithTranslation) => {
  const [collapsed, setCollapsed] = React.useState(true);

  const handleSelect = (region: Region) => async () => {
    await setCurrent(region);
    handleDrawerClose();
    setCollapsed(true);
  };

  const itemText = cx({
    [classes.itemText]: true,
    [classes.itemTextMini]: mini,
  });
  const collapseItemText = cx({
    [classes.collapseItemText]: true,
    [classes.collapseItemTextMini]: mini,
  });

  return (
    <List className={classes.list}>
      <ListItem className={classes.item}>
        <NavLink
          to={'#'}
          className={classes.itemLink}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => setCollapsed(!collapsed)}
        >
          {currentRegionId ? (
            <span className={classes.itemMini}>
              {t(`regions.${currentRegionId}.abbrev`)}
            </span>
          ) : (
              <ListItemIcon className={classes.itemIcon}>
                <Place />
              </ListItemIcon>
            )}
          <ListItemText
            primary={currentRegionId
              ? t(`regions.${currentRegionId}.text`)
              : t('terms.selectRegion')
            }
            secondary={
              <b
                className={cx({
                  [classes.caret]: true,
                  [classes.caretActive]: !collapsed && !mini,
                })}
              />
            }
            disableTypography={true}
            className={itemText}
          />
        </NavLink>
        <Collapse in={!collapsed && !mini} unmountOnExit={true}>
          <List className={classes.list + ' ' + classes.collapseList}>
            {regions.items.map((region) => (
              <ListItem key={region.id} className={classes.collapseItem}>
                <NavLink
                  to="#"
                  className={classes.collapseItemLink}
                  onClick={handleSelect(region)}
                >
                  <ListItemIcon
                    className={classes.itemIcon}
                    // disabled={region.refreshing}
                    onClick={toggleFavorite(region)}
                    aria-label={t('terms.favorite')}
                  >
                    {region.refreshing
                      ? <CircularProgress size={24} />
                      : region.favoriteOrdinal !== null
                        ? <StarIcon htmlColor="#ffc107" />
                        : <StarBorderIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(`regions.${region.id}.text`)}
                    disableTypography={true}
                    className={collapseItemText}
                  />
                </NavLink>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </ListItem>
    </List>
  );
});

const mapStateToProps = (state: StateRoot): StateProps => ({
  currentRegionId: state.currentRegionId,
  regions: state.regions,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): TriggerProps => ({
  setCurrent: (region: Region) => {
    dispatch(RegionMutations.current(region.id));
  },
  toggleFavorite: (region: Region) => () => dispatch(RegionActions.toggleFavorite(region)),
});

export default connect<StateProps, TriggerProps, Props, StateRoot>(mapStateToProps, mapDispatchToProps)(withStyles(sidebarStyle)(RegionView));
