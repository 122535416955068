import { ThunkDispatch } from 'redux-thunk';
import { BatchDashboardRepository } from '../domain/batch-dashboard/batch-dashboard.repository';
import { BatchDocumentWorkflowData, DashboardBatch } from '../domain/batch-dashboard/dto/batch-dashboard-data';
import { BatchWorkflowRepository } from '../domain/batch-workflow/batch-workflow.repository';
import { PerBatchField as BatchFieldDto, PerDocumentField as PerDocumentFieldDto } from '../domain/batch-workflow/dto/batch-workflows-and-fields';
import { CategoryRepository } from '../domain/category/category.repository';
import { UserRepository } from '../domain/user/user.repository';
import { WorkflowRepository } from '../domain/workflow/workflow.repository';
import { parseApolloError } from '../helpers/graphql';
import { FilterItem } from '../scenes/batch-workflow/dashboard/filter';
import { SortItem } from '../scenes/batch-workflow/dashboard/sort-row';
import { StateRoot } from '../store/interfaces';
import {
  BatchDashboardData,
  BatchDashboardQuery,
  BatchFieldSet,
  BatchFilter,
  ExpansionQuery,
  Pagination,
  PerBatchField,
  PerBatchFieldSource,
  PerDocumentField,
} from '../store/interfaces/batch-dashboard';
import { Workflow } from '../store/interfaces/batch-modification';
import { CodedError } from '../store/interfaces/error';
import { AppMutations } from '../store/mutations/app.mutations';
import { BatchDashboardMutations } from '../store/mutations/batch-dashboard.mutations';
import { dashboardQueryStorage } from '../store/mutations/batch-dashboard.storage';
import { Action, IndexOf, ReduxAction } from '../store/types';
import { BatchWorkflowUpdates } from './../domain/batch-workflow/dto/batch-workflow-updates-subscription';

export namespace BatchDashboardActions {
  function areSame(a: BatchDashboardQuery | undefined, b: BatchDashboardQuery | undefined): boolean {
    if (!a && !b) return true;
    if (!a || !b) return false;

    return a.batchWorkflowId === b.batchWorkflowId
      && a.filters === b.filters
      && a.regionId === b.regionId
      && a.sortingInfo === b.sortingInfo;
  }

  function getPerBatchFields(fields: BatchFieldDto[]) {
    return fields.reduce(
      (acc, field) => {
        const sources = field.sources.reduce(
          (acc2, source) => {
            if (source.id === undefined) return acc2;
            return {
              ...acc2, [source.id]: {
                fieldId: source.fieldId,
                priority: source.priority,
              },
            };
          },
          {} as IndexOf<PerBatchFieldSource>
        );
        return {
          ...acc, [field.id]: {
            dashboardVisible: field.dashboardVisible,
            dataType: field.dataType,
            format: field.format,
            label: field.label,
            ordinal: field.ordinal,
            sources,
          },
        };
      },
      {} as IndexOf<PerBatchField>
    );
  }

  function getPerDocumentFields(fields: PerDocumentFieldDto[]) {
    return fields.reduce(
      (acc, field) => {
        return {
          ...acc, [field.id]: {
            dataType: field.dataType,
            fieldId: field.fieldId,
            format: field.format,
            label: field.label,
            ordinal: field.ordinal,
          },
        };
      },
      {} as IndexOf<PerDocumentField>
    );
  }

  function sortBatchDocumentWorkflowData(a: BatchDocumentWorkflowData, b: BatchDocumentWorkflowData) {
    if (a.count === b.count) {
      return a.name < b.name ? -1 : 1;
    }

    return a.count > b.count ? -1 : 1;
  }

  function convertFilters(filters: FilterItem[]): BatchFilter[] {
    return filters.reduce((acc, filter) => {
      if (!filter.field || !filter.operation || (filter.field.fieldId !== 'lockedBy' && !filter.value.value)) return acc;
      const condition = {
        operator: filter.operation.value,
        value: filter.value.value,
      };
      const existing = acc.find((accFilter) => !!filter.field && accFilter.fieldId === filter.field.fieldId);
      if (existing) {
        const index = acc.indexOf(existing);
        existing.condition.push(condition);
        acc[index] = existing;
        return acc;
      }
      return [
        ...acc,
        {
          condition: [condition],
          dataType: filter.field.dataType ? filter.field.dataType : 'string',
          fieldId: filter.field.fieldId,
          fieldSetId: filter.field.fieldSetId,
        }];
      // tslint:disable-next-line: align
    }, [] as BatchFilter[]);
  }

  async function drawWorkflowData(workflowIds: number[], allWorkflows: IndexOf<Workflow>): Promise<BatchDocumentWorkflowData[]> {
    // count number of occurrences of each workflowId
    const countByWorkflowId = workflowIds.reduce(
      (acc, workflowId) => {
        acc.set(workflowId, (acc.get(workflowId) || 0) + 1);
        return acc;
      },
      new Map<number, number>()
    );

    // make up result
    const result = Array.from(countByWorkflowId.keys()).map((workflowId) => ({
      count: countByWorkflowId.get(workflowId) || 0,
      name: (allWorkflows[workflowId] && allWorkflows[workflowId].name) || '?',
      percentage: 0,
    }));
    result.sort(sortBatchDocumentWorkflowData);

    // set percentage
    const maxCount = result.reduce((max, item) => item.count > max ? item.count : max, 0);
    result.forEach((item) => {
      item.percentage = item.count / maxCount * 100;
    });

    return result;
  }

  async function loadIndexOfWorkflows(workflowIds: number[]): Promise<IndexOf<Workflow>> {
    if (!workflowIds || !workflowIds.length) return {};

    const workflowRepository = WorkflowRepository.forGlobal();
    const workflowResult = await workflowRepository.findWorkflows(workflowIds);
    return workflowResult.reduce(
      (acc, workflow) => ({
        ...acc,
        [workflow.id]: workflow,
      }),
      {} as IndexOf<Workflow>
    );
  }

  export function initDashboard(
    regionId: string | undefined,
    batchWorkflowId: number | undefined,
    defaultBatchSort: SortItem[],
    defaultPagination: Pagination,
    defaultBatchFilter: FilterItem[]
  ): Action {
    return async (dispatch, getState) => {
      const state = getState();
      if (!regionId || !batchWorkflowId) {
        if (state.batchDashboard.value !== undefined) {
          dispatch(BatchDashboardMutations.setValue(null));
        }
        return;
      }
      if (state.batchDashboard.loading) return;

      const restoredQuery = await dashboardQueryStorage.get(batchWorkflowId);
      const query: BatchDashboardQuery = {
        batchWorkflowId,
        filters: (restoredQuery && restoredQuery.filters) ? restoredQuery.filters : defaultBatchFilter,
        pagination: (restoredQuery && restoredQuery.pagination) || defaultPagination,
        regionId,
        sortingInfo: restoredQuery ? restoredQuery.sortingInfo : defaultBatchSort,
      };
      dispatch(BatchDashboardMutations.setQuery(query));
      await loadDashboardData(
        dispatch,
        getState,
        query,
      );
    };
  }

  export function loadDashboard(
    regionId: string | undefined,
    batchWorkflowId: number | undefined,
    batchSortFilter: SortItem[],
    pagination: Pagination,
    filters: FilterItem[],
  ): Action {
    return async (dispatch, getState) => {
      const state = getState();
      if (!regionId || !batchWorkflowId) {
        if (state.batchDashboard.value !== undefined) {
          dispatch(BatchDashboardMutations.setValue(null));
        }
        return;
      }
      if (state.batchDashboard.loading) return;

      const query: BatchDashboardQuery = {
        batchWorkflowId,
        filters,
        pagination,
        regionId,
        sortingInfo: batchSortFilter,
      };
      await loadDashboardData(
        dispatch,
        getState,
        query,
      );
    };
  }

  async function loadDashboardData(
    dispatch: ThunkDispatch<StateRoot, null, ReduxAction>,
    getState: () => StateRoot,
    query: BatchDashboardQuery,
  ) {
    const state = getState();
    const existingDashboardData = state.batchDashboard.value;

    if (!query.regionId || !query.batchWorkflowId) {
      if (existingDashboardData !== undefined) {
        dispatch(BatchDashboardMutations.setValue(null));
      }
      return;
    }

    if (!existingDashboardData || !existingDashboardData.batchIds.length) {
      dispatch(BatchDashboardMutations.setLoading());
    }

    try {
      const batchWorkflowRepository = BatchWorkflowRepository.forGlobal();
      const batchWorkflow = await batchWorkflowRepository.findBatchWorkflowAndFields(query.batchWorkflowId);
      const repository = BatchDashboardRepository.forRegion(query.regionId);

      const fieldSets = batchWorkflow.fieldSets.reduce(
        (acc, fieldSet) => {
          switch (fieldSet.__typename) {
            case 'PerBatchFieldSet':
              return {
                ...acc, [fieldSet.id]: {
                  fields: getPerBatchFields(fieldSet.fields),
                  label: fieldSet.label,
                  ordinal: fieldSet.ordinal,
                  type: fieldSet.__typename,
                },
              };
            case 'PerDocumentHeaderFieldSet':
              return {
                ...acc, [fieldSet.id]: {
                  fields: getPerDocumentFields(fieldSet.fields),
                  fieldSetId: fieldSet.fieldSetId,
                  label: fieldSet.label,
                  ordinal: fieldSet.ordinal,
                  reference: fieldSet.reference,
                  type: fieldSet.__typename,
                  workflowId: fieldSet.workflowId,
                },
              };
            case 'PerDocumentRepeatingFieldSet':
              return {
                ...acc, [fieldSet.id]: {
                  fields: getPerDocumentFields(fieldSet.fields),
                  fieldSetId: fieldSet.fieldSetId,
                  label: fieldSet.label,
                  ordinal: fieldSet.ordinal,
                  reference: fieldSet.reference,
                  type: fieldSet.__typename,
                  workflowId: fieldSet.workflowId,
                },
              };
            default:
              return acc;
          }
        },
        {} as IndexOf<BatchFieldSet>
      );

      const pagination = query.pagination;
      // load initial set up batches
      const batchIds = await repository.findBatchIds(
        query.batchWorkflowId,
        query.sortingInfo,
        { offset: 0, limit: pagination.limit * 4 },
        query.filters ? convertFilters(query.filters) : undefined,
      );

      const dashboardData: BatchDashboardData = {
        allWorkflows: {},
        batches: [],
        indexOfDashboardBatch: {},
        ...existingDashboardData,
        batchIds: existingDashboardData && existingDashboardData.batchIds.length
          ? existingDashboardData.batchIds.splice(0, batchIds.length, ...batchIds)
          : batchIds,
        batchWorkflow,
        categories: batchWorkflow.categories,
        fieldSets,
      };

      dispatch(BatchDashboardMutations.setValue(dashboardData));

      // load the rest of the batches in the background
      await repository.findBatchIds(
        query.batchWorkflowId,
        query.sortingInfo,
        { offset: 0, limit: pagination.limit * 10 },
        query.filters ? convertFilters(query.filters) : undefined,
      )
        .then((allBatchIds) => {
          // protect against updating the wrong data
          const newState = getState();
          if (!areSame(newState.batchDashboard.query, query)) {
            return;
          }
          // update batchIds
          dispatch(BatchDashboardMutations.patchValue({
            batchIds: allBatchIds,
          }));
        })
        .catch((err) => {
          const errors = parseApolloError(err);
          errors.forEach((error) => {
            dispatch(BatchDashboardMutations.setError(new CodedError(error.message, 'error')));
            dispatch(AppMutations.setErrorSnackbar(new Error(error.message)));
          });
        });

      dispatch(loadRange(pagination.offset, pagination.offset + pagination.limit));
    } catch (err) {
      const errors = parseApolloError(err);
      errors.forEach((error) => {
        dispatch(BatchDashboardMutations.setError(new CodedError(error.message, 'error')));
        dispatch(AppMutations.setErrorSnackbar(new Error(error.message)));
      });
    }
  }

  export function loadRange(startIndex: number, endIndex: number): Action {
    return async (dispatch, getState) => {
      const { batchDashboard } = getState();
      if (!batchDashboard.query || !batchDashboard.value) {
        dispatch(BatchDashboardMutations.setValue(null));
        return;
      }

      const { regionId, batchWorkflowId } = batchDashboard.query;
      const { batchIds } = batchDashboard.value;

      if (!regionId || !batchWorkflowId) {
        dispatch(BatchDashboardMutations.setValue(null));
        return;
      }

      try {
        const repository = BatchDashboardRepository.forRegion(regionId);
        const requiredIds = batchIds.slice(startIndex, endIndex);
        const batchData = await repository.findByIds(requiredIds, batchDashboard.value.fieldSets);

        // workflows referenced by loaded batches
        const workflowIds = batchData.reduce(
          (set, batch) => {
            batch.documents.forEach((document) => set.add(document.currentDocumentWorkflow.workflowId));
            return set;
          },
          new Set<number>()
        );
        // remove workflows already loaded
        Object.keys(batchDashboard.value.allWorkflows).forEach((loadedId) => {
          workflowIds.delete(Number(loadedId));
        });
        // load newly required workflows
        const allWorkflows = {
          ...batchDashboard.value.allWorkflows,
          ...await loadIndexOfWorkflows(Array.from(workflowIds)),
        };

        const batchPromises = batchData.map(async (batch) => {
          return await populateDashboardBatch(batch, allWorkflows);
        });

        const batches = await Promise.all(batchPromises);
        const indexOfDashboardBatch = batches.reduce(
          (acc, batch) => {
            return {
              ...acc, [batch.id]: batch,
            };
          },
          batchDashboard.value.indexOfDashboardBatch as IndexOf<DashboardBatch>
        );

        const dashboardData = {
          allWorkflows,
          batches: [
            ...batchDashboard.value.batches,
            ...batches,
          ],
          indexOfDashboardBatch,
        };

        dispatch(BatchDashboardMutations.patchValue(dashboardData));
      } catch (err) {
        const errors = parseApolloError(err);
        errors.forEach((error) => {
          dispatch(BatchDashboardMutations.setError(new CodedError(error.message, 'error')));
          dispatch(AppMutations.setErrorSnackbar(new Error(error.message)));
        });
      }
    };
  }

  export function changePage(page: number): Action {
    return async (dispatch, getState) => {
      const state = getState();
      const query = state.batchDashboard.query;
      if (!query) return;

      const pagination = {
        limit: query.pagination.limit,
        offset: page ? query.pagination.limit * (page - 1) : 0,
      };
      dispatch(BatchDashboardMutations.setQuery(
        {
          ...query,
          pagination,
        },
        true
      ));
      window.scrollTo({ top: 0 });
      dispatch(loadRange(pagination.offset, pagination.offset + pagination.limit));
    };
  }

  export function reload(): Action {
    return async (dispatch, getState) => {
      const state = getState();
      if (!state.batchDashboard.value || !state.batchDashboard.query || state.batchDashboard.loading) return;
      const data = state.batchDashboard.value;
      const query = state.batchDashboard.query;
      const regionId = state.currentRegionId;
      const batchWorkflowId = data.batchWorkflow.id;
      if (!regionId || !batchWorkflowId) return;
      dispatch(BatchDashboardActions.loadDashboard(
        query.regionId,
        query.batchWorkflowId,
        query.sortingInfo,
        query.pagination,
        query.filters,
      ));
    };
  }

  export function subscribe(regionId: string, batchWorkflowId: number): Action {
    return async (dispatch, getState) => {
      const batchDashboardRepository = BatchDashboardRepository.forRegion(regionId);
      const batchWorkflowSubClient = await batchDashboardRepository.subscribeToBatchWorkflow(batchWorkflowId);
      const subscriptionClient = batchWorkflowSubClient.subscribe({
        // tslint:disable-next-line:no-any
        next(data: any) {
          const batchWorkflowUpdates: BatchWorkflowUpdates = data.data.batchWorkflowUpdates;
          if (!batchWorkflowUpdates) return;
          switch (batchWorkflowUpdates.__typename) {
            case 'NewBatch':
              pushOneBatch(batchWorkflowUpdates.batchId);
              break;
            case 'BatchStatusUpdate':
              if (batchWorkflowUpdates.status === 'deleted') {
                dispatch(BatchDashboardActions.reload());
              } else {
                dispatch(BatchDashboardActions.reloadOneBatch(batchWorkflowUpdates.batchId));
              }
              break;
            default:
              dispatch(BatchDashboardActions.reloadOneBatch(batchWorkflowUpdates.batchId));
          }
        },
      });
      dispatch(BatchDashboardMutations.setSubscriber(subscriptionClient));
    };
  }

  export function reloadOneBatch(batchId: string): Action {
    return async (dispatch, getState) => {
      const state = getState();

      const dashboard = state.batchDashboard.value;
      if (!dashboard || !state.currentRegionId) return;

      try {
        const repository = BatchDashboardRepository.forRegion(state.currentRegionId);

        let reloadedBatch = await repository.findOne(batchId, dashboard.fieldSets);
        reloadedBatch = await populateDashboardBatch(reloadedBatch, dashboard.allWorkflows);

        const indexOfDashboardBatch = dashboard.indexOfDashboardBatch;
        const allBatchData: DashboardBatch[] = [];
        Object.keys(indexOfDashboardBatch).forEach((dashboardBatchIndex: string) => {
          if (reloadedBatch.id === dashboardBatchIndex) {
            indexOfDashboardBatch[dashboardBatchIndex] = reloadedBatch;
          }
          allBatchData.push(indexOfDashboardBatch[dashboardBatchIndex]);
        });

        const dashboardData = {
          batches: allBatchData,
          indexOfDashboardBatch,
        };
        dispatch(BatchDashboardMutations.patchValue(dashboardData));
      } catch (err) {
        const errors = parseApolloError(err);
        errors.forEach((error) => {
          dispatch(BatchDashboardMutations.setError(new CodedError(error.message, 'error')));
          dispatch(AppMutations.setErrorSnackbar(new Error(error.message)));
        });
      }
    };
  }

  function pushOneBatch(batchId: string): Action {
    return async (dispatch, getState) => {
      const state = getState();

      const dashboard = state.batchDashboard.value;
      if (!dashboard || !state.currentRegionId) return;

      try {
        const repository = BatchDashboardRepository.forRegion(state.currentRegionId);

        let pushedBatch = await repository.findOne(batchId, dashboard.fieldSets);
        pushedBatch = await populateDashboardBatch(pushedBatch, dashboard.allWorkflows);

        if (dashboard.indexOfDashboardBatch[pushedBatch.id]) dashboard.indexOfDashboardBatch[pushedBatch.id] = pushedBatch;
        dashboard.batches.unshift(pushedBatch);

        dispatch(BatchDashboardMutations.setValue(dashboard));
      } catch (err) {
        const errors = parseApolloError(err);
        errors.forEach((error) => dispatch(BatchDashboardMutations.setError(new CodedError(error.message, 'error'))));
      }
    };
  }

  async function populateDashboardBatch(batch: DashboardBatch, allWorkflows: IndexOf<Workflow>): Promise<DashboardBatch> {
    const userRepository = UserRepository.forGlobal();
    const categoryRepository = CategoryRepository.forGlobal();

    return {
      ...batch,
      currentCategory: batch.currentCategory
        ? {
          ...batch.currentCategory,
          category: (batch.currentCategory.categoryId !== null)
            ? await categoryRepository.findById(batch.currentCategory.categoryId)
            : undefined,
          createdByUser: (batch.currentCategory.createdByUserId)
            ? await userRepository.findById(batch.currentCategory.createdByUserId)
            : undefined,
        }
        : undefined,
      currentLock: batch.currentLock
        ? {
          ...batch.currentLock,
          lockedByUser: (batch.currentLock.lockedByUserId)
            ? await userRepository.findById(batch.currentLock.lockedByUserId)
            : undefined,
        }
        : undefined,
      currentStatus: batch.currentStatus
        ? {
          ...batch.currentStatus,
          createdByUser: (batch.currentStatus.createdByUserId)
            ? await userRepository.findById(batch.currentStatus.createdByUserId)
            : undefined,
        }
        : undefined,
      documentWorkflowData: await drawWorkflowData(
        batch.documents.map((document) => document.currentDocumentWorkflow.workflowId),
        allWorkflows
      ),
    };
  }

  export function updateBatchCompleted(batchId: string): Action {
    return async (dispatch, getState) => {
      dispatch(BatchDashboardMutations.removeBatchFromList(batchId));
    };
  }

  export function applySort(sortBy: SortItem[]): Action {
    return async (dispatch, getState) => {
      const state = getState();
      const query = state.batchDashboard.query;
      if (!query) return;
      dispatch(BatchDashboardMutations.setQuery({
        ...query,
        sortingInfo: sortBy,
      }));
      const newState = getState();
      const data = newState.batchDashboard;
      if (data.query && data.loadRequired && !data.error) {
        dispatch(BatchDashboardActions.loadDashboard(
          query.regionId,
          query.batchWorkflowId,
          sortBy,
          query.pagination,
          query.filters,
        ));
      }
    };
  }

  // Accept and save filter queries
  export function updateFilter(expansionQuery: ExpansionQuery): Action {
    return async (dispatch, getState) => {
      const state = getState();
      const query = state.batchDashboard.query;
      if (!query) return;
      dispatch(BatchDashboardMutations.setQuery({
        ...query,
        filters: expansionQuery.filters,
      }));
      const newState = getState();
      const data = newState.batchDashboard;
      if (data.query && !data.error) {
        dispatch(BatchDashboardActions.loadDashboard(
          query.regionId,
          query.batchWorkflowId,
          query.sortingInfo,
          query.pagination,
          expansionQuery.filters,
        ));
      }
    };
  }
}
