import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BatchWorkflowAdminActions } from '../../../actions/batch-workflow-admin.actions';
import { PerBatchFieldSource } from '../../../domain/batch-workflow/dto/batch-workflows-and-fields';
import { StateRoot } from '../../../store/interfaces';
import {
  BatchField,
  BatchFieldSet,
  BatchWorkflow,
  BatchWorkflowModification,
  WorkflowField,
  WorkflowFieldData
} from '../../../store/interfaces/batch-workflow';
import { Loadable } from '../../../store/loader';
import { style } from './style';

interface Props {
  open: boolean;
  batchFieldSetIndex: number | undefined;
  batchField: BatchField | undefined;
  batchFieldSourceIndex: number | undefined;
  batchFieldSource: PerBatchFieldSource | undefined;
  workflowId: number | undefined;
  batchFieldSetFieldSetId: number | undefined;
  type: 'header' | 'repeating';
  onClose: (value?: WorkflowField) => void;
}

interface StateProps {
  data: Loadable<BatchWorkflowModification, BatchWorkflow>;
}

interface ViewProps {
  handleClose: () => void;
}

interface ContentViewProps {
  batchFieldSourceIndex: number | undefined;
  batchFieldLabel: string | undefined;
  currentField: WorkflowFieldData | undefined;
  workflowFields: WorkflowField[];
  handleSelect: (field: WorkflowField) => () => void;
  handleClose: () => void;
}

interface Triggers {
  loadFields: (workflowId: number, type: 'header' | 'repeating', batchFieldSetFieldSetId: number | undefined) => void;
}

class ChangeFieldDialogController extends React.Component<Props & StateProps & Triggers> {
  componentDidMount() {
    const batchWorkflow = this.props.data.value;

    if (this.props.workflowId) {
      this.props.loadFields(this.props.workflowId, this.props.type, this.props.batchFieldSetFieldSetId);
    } else if (batchWorkflow && batchWorkflow.fieldSets && (this.props.batchFieldSetIndex != null)) {
      const batchFieldSet: BatchFieldSet = batchWorkflow.fieldSets[this.props.batchFieldSetIndex];
      if (batchFieldSet.workflowData && batchFieldSet.workflowData.id) {
        this.props.loadFields(batchFieldSet.workflowData.id, this.props.type, this.props.batchFieldSetFieldSetId);
      }
    }
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleListItemClick = (value: WorkflowField) => () => {
    this.props.onClose(value);
  }

  render() {
    const { open, batchFieldSetIndex, batchField, batchFieldSourceIndex, batchFieldSource, data } = this.props;

    if (!data.value || (batchFieldSetIndex == null)) {
      return (
        <Dialog open={open} onClose={this.handleClose}>
          <ChangeFieldErrorView handleClose={this.handleClose} />
        </Dialog>
      );
    }

    const batchWorkflow: BatchWorkflow = data.value;
    const workflowFields: WorkflowField[] = [];

    let batchFieldLabel: string | undefined;
    let currentField: WorkflowFieldData | undefined;

    if (batchField) {
      batchFieldLabel = batchField.label;
      currentField = batchField.field;
    } else if (batchFieldSource && batchFieldSource.field) {
      currentField = { id: batchFieldSource.field.id, name: batchFieldSource.field.name };
    }

    if (batchWorkflow.workflowFields) {
      for (const fieldIndex in batchWorkflow.workflowFields) {
        if (batchWorkflow.workflowFields.hasOwnProperty(fieldIndex)
          && (!currentField || (currentField.id !== batchWorkflow.workflowFields[fieldIndex].id))) {
          workflowFields.push(batchWorkflow.workflowFields[fieldIndex]);
        }
      }
    }

    return (
      <Dialog open={open} onClose={this.handleClose}>
        <ChangeFieldContentView
          batchFieldLabel={batchFieldLabel}
          currentField={currentField}
          workflowFields={workflowFields}
          batchFieldSourceIndex={batchFieldSourceIndex}
          handleSelect={this.handleListItemClick}
          handleClose={this.handleClose}
        />
      </Dialog>
    );
  }
}

const ChangeFieldErrorView = withTranslation()(({
  handleClose,
  t,
}: ViewProps & WithTranslation) => (
  <React.Fragment>
    <DialogTitle>{t('batchWorkflowEdit.general.unableToLoad')}</DialogTitle>
    <Button onClick={handleClose} color="primary" autoFocus={true}>{t('terms.close')}</Button>
  </React.Fragment>
)
);

const ChangeFieldContentView = withStyles(style)(withTranslation()(({
  batchFieldLabel,
  currentField,
  workflowFields,
  batchFieldSourceIndex,
  handleSelect,
  handleClose,
  classes,
  t,
}: ContentViewProps & WithStyles & WithTranslation) => (
  <React.Fragment>
    <DialogTitle className={classes.selectDialogTitleContainer}>
      <CloseIcon onClick={handleClose} className={classes.closeIconButton} />
      {
        batchFieldSourceIndex !== undefined
          ? <Typography className={classes.selectDialogTitle}>
            {t('batchWorkflowEdit.general.batchFieldSource')} : Field
            </Typography>
          : <Typography className={classes.selectDialogTitle}>
            {t('batchWorkflowEdit.general.batchField')} : Field
            </Typography>
      }
      {currentField
        ?
        <Typography>
          {t('batchWorkflowEdit.selectField.labelCurrent')}<strong>{currentField.name}</strong>
        </Typography>
        : undefined
      }
      {
        batchFieldSourceIndex !== undefined
          ? <Typography>
            {t('batchWorkflowEdit.selectField.title')}{t('batchWorkflowEdit.general.batchFieldSource')} <strong>#{batchFieldSourceIndex + 1}</strong>
          </Typography>
          : <Typography>
            {t('batchWorkflowEdit.selectField.title')}<strong>{batchFieldLabel}</strong>
          </Typography>
      }
      <br />
    </DialogTitle>
    <List className={classes.changeWorkflowList}>
      {workflowFields.map(field => (
        <ListItem button={true} key={field.id} onClick={handleSelect(field)} className={classes.selectDialogItemButton}>
          <ListItemText primary={field.name} className={classes.selectDialogNameItem} />
        </ListItem>
      ))}
    </List>
  </React.Fragment>
)));

const mapStateToProps = (state: StateRoot): StateProps => ({
  data: state.batchWorkflowModification,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): Triggers => ({
  loadFields: (
    workflowId: number,
    type: 'header' | 'repeating',
    batchFieldSetFieldSetId: number | undefined
  ) => dispatch(BatchWorkflowAdminActions.loadWorkflowFields(workflowId, type, batchFieldSetFieldSetId)),
});

export const ChangeFieldDialog = connect(mapStateToProps, mapDispatchToProps)(ChangeFieldDialogController);
