import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import logo from '../../../../images/logo.svg';

export interface Props {
  // handleSubmit: (email: string) => Promise<void>;
  handleBack: () => Promise<void>;
}

export interface State {
  email: string;
  loading: boolean;
}

class AuthForgotPassword extends React.PureComponent<Props, State> {
  public onInputChanged = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // tslint:disable-next-line:no-any
    const change: any = { [prop]: event.target.value };
    this.setState(change);
  }

  public handleSubmit = async () => {
    this.setState({ loading: true });
    this.setState({ loading: false });
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
    };
  }

  public render() {
    return (
      <AuthForgotPasswordView
        {...this.props}
        loading={this.state.loading}
        onInputChanged={this.onInputChanged}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

interface ViewProps {
  loading: boolean;
  onInputChanged: (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => Promise<void>;
  handleBack: () => Promise<void>;
}

const AuthForgotPasswordView = withTranslation(['auth'])(
  ({
    t,
    loading,
    onInputChanged,
    handleSubmit,
    handleBack,
  }: ViewProps & WithTranslation) => (
    <Paper className="card">
      {/* <form className="forgot-password-form" noValidate={true} autoComplete="off"> */}
      <Grid item={true} container={true} direction="column" alignItems="stretch" spacing={2}>
        <Grid className="logo" container={true} alignItems="stretch" justify="center">
          {window.env.REACT_APP_HIDE_BRAND ? <Typography variant={'h3'}>Welcome</Typography> : <img alt={'Xtracta Logo'} src={logo} />}
        </Grid>
        <Grid item={true} container={true}>
          <Typography variant="h5" gutterBottom={true}>
            {t('forgotPassword.title')}
          </Typography>
          <Typography variant="body2" gutterBottom={true}>
            {t('forgotPassword.text')}
          </Typography>
        </Grid>
        <Grid item={true} container={true} alignItems="stretch">
          <TextField
            id="email-input"
            label={t('forgotPassword.inputs.email.label')}
            type="email"
            name="email"
            autoComplete="email"
            margin="normal"
            variant="outlined"
            onChange={onInputChanged('email')}
            fullWidth={true}
            disabled={loading}
          />
        </Grid>
        <Grid item={true} container={true} justify="center">
          <Fab
            className="primary-button"
            variant="extended"
            color="secondary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {t('forgotPassword.buttons.submit')!}
          </Fab>
        </Grid>
        <Grid item={true} />
        <Grid item={true} />
        <Grid item={true} container={true} justify="center">
          <Link onClick={handleBack}>
            {t('forgotPassword.buttons.back')}
          </Link>
        </Grid>
      </Grid>
      {/* </form> */}
    </Paper>
  )
);

export default AuthForgotPassword;
