import { BatchWorkflowAndFields } from '../../../domain/batch-workflow/dto/batch-workflows-and-fields';
import { Batch } from '../../../store/interfaces/batch';
import { User } from '../../../store/interfaces/user';

export interface BatchDashboardData {
  batches: DashboardBatch[];
  batchWorkflowId: number | undefined;
  batchWorkflow: BatchWorkflowAndFields;
}

export interface DashboardBatch extends Batch {
  currentStatus: BatchStatus | undefined;
  documentWorkflowData: BatchDocumentWorkflowData[] | undefined;
  currentData: BatchData;
  initialRevision: BatchRevision;
}

export interface BatchData {
  id: string;
  fieldSets: { [key: string]: BatchDataFieldSet };
  created: Date;
}

export type BatchDataFieldSet = PerBatchDataFieldSet | PerDocumentHeaderDataFieldSet | PerDocumentRepeatingDataFieldSet;

export interface PerBatchDataFieldSet {
  type: 'PerBatchDataFieldSet' | undefined;
  fields: { [key: string]: FieldData };
}

export interface FieldData {
  selected: FieldDataOption;
  alternatives: FieldDataOption[];
  confirmed: {
    when: number;
    userId: number;
  };
}

export interface FieldDataOption {
  value: string | number;
  batchFieldSourceId: number;
  documentId: number;
}

export interface PerDocumentHeaderDataFieldSet {
  type: 'PerDocumentHeaderDataFieldSet' | undefined;
  documents: DocumentHeaderFields[];
}

export interface DocumentHeaderFields {
  documentId: string;
  fields: FieldValue;
}

export interface FieldValue {
  [key: string]: string | number;
}

export interface PerDocumentRepeatingDataFieldSet {
  type: 'PerDocumentRepeatingDataFieldSet' | undefined;
  documents: DocumentRepeatingFields[];
}

export interface DocumentRepeatingFields {
  documentId: string;
  rows: RowField[];
}

export interface RowField {
  fields: FieldValue;
}

export interface BatchStatus {
  status: string;
  created: string;
  createdByUserId: number | null;
  createdByUser: User | undefined;
}

export interface BatchRevision {
  created: string;
}

export interface BatchDocumentWorkflowData {
  count: number;
  name: string;
  percentage: number;
}

export const ReadableBatchStatus = {
  deleted: 'Deleted',
  done: 'Done',
  onhold: 'On Hold',
  processing: 'Processing',
  ready: 'Ready',
  reprocessing: 'Re-processing',
};
