import { createAction } from '..';

export namespace SidebarMutations {
  export const close = () => createAction((state) => ({
    ...state,
    sideBarOpen: false,
  }));

  export const open = () => createAction((state) => ({
    ...state,
    sideBarOpen: false,
  }));

  export const toggle = () => createAction((state) => ({
    ...state,
    sideBarOpen: !state.sideBarOpen,
  }));
}
