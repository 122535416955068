import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

interface MenuPopperProps {
  anchor: HTMLElement | null;
  children?: React.ReactNode;
}

export const MenuPopper = ({ anchor, children }: MenuPopperProps) => (
  <Popper
    style={{ zIndex: 1200 }}
    anchorEl={anchor}
    open={Boolean(anchor)}
    disablePortal={false}
    placement="bottom-start"
    modifiers={{
      flip: {
        enabled: true,
      },
      preventOverflow: {
        boundariesElement: 'scrollParent',
        enabled: true,
      },
    }}
  >
    <Paper style={{ zIndex: 1200 }}>
      {children}
    </Paper>
  </Popper>
);
