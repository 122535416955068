import { CombinedUrlFactoryImpl, UrlMap } from './url';

const host = window.location.host;
const base = host.slice(host.indexOf('.') + 1);

function getGlobalUrls() {
  let global: UrlMap = {
    '@': window.env.REACT_APP_GLOBAL_BASE_URL ? window.env.REACT_APP_GLOBAL_BASE_URL : `https://global.${base}`,
  };

  if (window.env.REACT_APP_GLOBAL_AUTH_URL) {
    global.auth = window.env.REACT_APP_GLOBAL_AUTH_URL;
  }
  if (window.env.REACT_APP_GLOBAL_DATA_URL) {
    global.data = window.env.REACT_APP_GLOBAL_DATA_URL;
  }

  return global;
}

function getRegionalUrls(regionId: string) {
  let regional: UrlMap = {
    '@': window.env.REACT_APP_REGIONAL_BASE_URL ? window.env.REACT_APP_REGIONAL_BASE_URL : `https://region-${regionId}.${base}`,
  };

  if (window.env.REACT_APP_REGIONAL_DATA_URL) {
    regional.data = window.env.REACT_APP_REGIONAL_DATA_URL;
  }

  return regional;
}

export const urlFactory = new CombinedUrlFactoryImpl({
  global: getGlobalUrls(),
  regional: (regionId: string) => getRegionalUrls(regionId),
});
