import { ApolloError } from '@apollo/client';

export interface SimpleError {
  code: number;
  message: string;
}

export const parseApolloError = (error: ApolloError): SimpleError[] => {
  const simpleErrors: SimpleError[] = [];

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    error.graphQLErrors.forEach((e) => {
      if (e.extensions && e.extensions.exception && e.extensions.exception.response) {
        simpleErrors.push({
          code: e.extensions.exception.response.statusCode || 500,
          message: e.extensions.exception.response.message,
        });
      } else if (typeof e.message === 'string') {
        simpleErrors.push({
          code: 500,
          message: e.message,
        });
      } else {
        simpleErrors.push({
          // tslint:disable-next-line:no-any
          code: (e.message as any).statusCode,
          // tslint:disable-next-line:no-any
          message: (e.message as any).message,
        });
      }
    });
  } else if (!!error.networkError) {
    simpleErrors.push({
      code: 500,
      message: error.networkError.message,
    });
  } else {
    simpleErrors.push({
      code: 500,
      message: error.message,
    });
  }

  return simpleErrors;
};
