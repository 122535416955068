import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { StateRoot } from '../../store/interfaces';
import { SnackbarMessage, SnackbarTriggers } from '../../store/interfaces/app';
import { AppMutations } from '../../store/mutations/app.mutations';

export const style = (theme: Theme) => createStyles({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    marginRight: theme.spacing(2),
    opacity: 0.9,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  margin: {
    margin: theme.spacing(2),
  },
  message: {
    alignItems: 'center',
    display: 'flex',
  },
  success: {
    backgroundColor: green[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
});

const variantIcon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
};

interface SnackbarProps {
  snackbarMessage?: SnackbarMessage;
}

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): SnackbarTriggers => ({
  hideSnackbar: () => dispatch(AppMutations.setSnackbarMessage(undefined)),
  showSnackbar: snackbarMessage => dispatch(AppMutations.setSnackbarMessage(snackbarMessage)),
});

const mapStateToProps = (state: StateRoot): SnackbarProps => ({
  snackbarMessage: state.snackbarMessage,
});

const SnackbarView = connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(withTranslation()(({
  classes,
  hideSnackbar,
  snackbarMessage,
  t,
}: SnackbarProps & WithTranslation & WithStyles & SnackbarTriggers) => {
  if (!snackbarMessage) return null;

  const Icon = variantIcon[snackbarMessage.variant];

  return (
    <Snackbar
      open={true}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      className={classes.margin}
      autoHideDuration={snackbarMessage.autoHideDuration}
      onClose={hideSnackbar}
    >
      <SnackbarContent
        className={classes[snackbarMessage.variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={`${classes.icon}, ${classes.iconVariant}`} />
            {
              t(
                snackbarMessage.messageTranslationKey,
                snackbarMessage.messageTranslationVariable
              )
            } &nbsp;<small>{snackbarMessage.rawMessage}</small>
          </span>}
        action={snackbarMessage.action || (
          <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        )}
      />
    </Snackbar>
  );
})));

export const SnackbarComponent = SnackbarView;
