import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BatchWorkflowAdminActions } from '../../../actions/batch-workflow-admin.actions';
import { StateRoot } from '../../../store/interfaces';
import { Workflow } from '../../../store/interfaces/batch-modification';
import { BatchFieldSet, BatchWorkflow, BatchWorkflowModification, WorkflowAndFieldSetData } from '../../../store/interfaces/batch-workflow';
import { Loadable } from '../../../store/loader';
import { style } from './style';

interface Props {
  open: boolean;
  batchFieldSetIndex: number | undefined;
  batchFieldSet: BatchFieldSet | undefined;
  batchFieldSourceIndex: number | undefined;
  currentBatchFieldSourceWorkflow: WorkflowAndFieldSetData | undefined;
  onClose: (value?: Workflow) => void;
}

interface StateProps {
  data: Loadable<BatchWorkflowModification, BatchWorkflow>;
}

interface ViewProps {
  handleClose: () => void;
}

interface ContentViewProps {
  batchFieldSetLabel: string | undefined;
  batchFieldSourceIndex: number | undefined;
  currentWorkflow: WorkflowAndFieldSetData | undefined;
  workflows: Workflow[];
  handleSelect: (workflow: Workflow) => () => void;
  handleClose: () => void;
}

interface Triggers {
  loadWorkflows: (clientId: number) => void;
}

class ChangeWorkflowDialogController extends React.Component<Props & StateProps & Triggers> {
  componentDidMount() {
    const batchWorkflow = this.props.data.value;
    if (batchWorkflow && batchWorkflow.clientId) {
      this.props.loadWorkflows(batchWorkflow.clientId);
    }
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleListItemClick = (value: Workflow) => () => {
    this.props.onClose(value);
  }

  render() {
    const {
      open,
      batchFieldSetIndex,
      batchFieldSet,
      batchFieldSourceIndex,
      currentBatchFieldSourceWorkflow,
      data,
    } = this.props;

    if (!data.value || (batchFieldSetIndex == null)) {
      return (
        <Dialog open={open} onClose={this.handleClose}>
          <ChangeWorkflowErrorView handleClose={this.handleClose} />
        </Dialog>
      );
    }

    const batchWorkflow: BatchWorkflow = data.value;
    const batchFieldSetData: BatchFieldSet = batchFieldSet ? batchFieldSet : batchWorkflow.fieldSets[batchFieldSetIndex];
    const workflows: Workflow[] = [];

    let batchFieldSetLabel: string | undefined;
    let currentWorkflow: WorkflowAndFieldSetData | undefined;

    if (batchFieldSourceIndex !== undefined) {
      // we should compare like this, not "if (batchFieldSourceIndex)", otherwise we will not enter this block when batchFieldSourceIndex is 0
      currentWorkflow = currentBatchFieldSourceWorkflow;
    } else if (batchFieldSetData) {
      batchFieldSetLabel = batchFieldSetData.label;
      currentWorkflow = batchFieldSetData.workflowData;
    }

    if (batchWorkflow.workflows) {
      for (const workflowIndex in batchWorkflow.workflows) {
        if (batchWorkflow.workflows.hasOwnProperty(workflowIndex)
          && (!currentWorkflow || (currentWorkflow.id !== Number(workflowIndex)))) {
          workflows.push(batchWorkflow.workflows[workflowIndex]);
        }
      }
    }

    return (
      <Dialog open={open} onClose={this.handleClose} maxWidth={'md'}>
        <ChangeWorkflowContentView
          batchFieldSetLabel={batchFieldSetLabel}
          batchFieldSourceIndex={batchFieldSourceIndex}
          currentWorkflow={currentWorkflow}
          workflows={workflows}
          handleClose={this.handleClose}
          handleSelect={this.handleListItemClick}
        />
      </Dialog>
    );
  }
}

const ChangeWorkflowErrorView = withTranslation()(({
  handleClose,
  t,
}: ViewProps & WithTranslation) => (
  <React.Fragment>
    <DialogTitle>{t('batchWorkflowEdit.general.unableToLoad')}</DialogTitle>
    <Button onClick={handleClose} color="primary" autoFocus={true}>{t('terms.close')}</Button>
  </React.Fragment>
));

const ChangeWorkflowContentView = withStyles(style)(withTranslation()(({
  batchFieldSetLabel,
  batchFieldSourceIndex,
  currentWorkflow,
  workflows,
  handleSelect,
  handleClose,
  classes,
  t,
}: ContentViewProps & WithStyles & WithTranslation) => (
  <React.Fragment>
    <DialogTitle className={classes.selectDialogTitleContainer}>
      <CloseIcon onClick={handleClose} className={classes.closeIconButton} />
      {
        batchFieldSourceIndex !== undefined
          ? <Typography className={classes.selectDialogTitle}>
            {t('batchWorkflowEdit.general.batchFieldSource')} : Workflow
            </Typography>
          : <Typography className={classes.selectDialogTitle}>
            {t('batchWorkflowEdit.general.batchFieldSet')} : Workflow
            </Typography>
      }
      {currentWorkflow
        ?
        <Typography>
          {t('batchWorkflowEdit.selectWorkflow.labelCurrent')}<strong>{currentWorkflow.name}</strong> ({'ID : ' + currentWorkflow.id})
        </Typography>
        : undefined
      }
      {batchFieldSourceIndex !== undefined
        ?
        <Typography>
          {t('batchWorkflowEdit.selectWorkflow.title')}{t('batchWorkflowEdit.general.batchFieldSource')} <strong>#{batchFieldSourceIndex + 1}</strong>
        </Typography>
        :
        <Typography>
          {t('batchWorkflowEdit.selectWorkflow.title')}<strong>{batchFieldSetLabel}</strong>
        </Typography>
      }
      <br />
    </DialogTitle>
    <List>
      {workflows.map(workflow => (
        <ListItem button={true} key={workflow.id} onClick={handleSelect(workflow)} className={classes.selectDialogItemButton}>
          <ListItemText primary={workflow.name} className={classes.selectDialogNameItem} />
        </ListItem>
      ))}
    </List>
  </React.Fragment>
)));

const mapStateToProps = (state: StateRoot): StateProps => ({
  data: state.batchWorkflowModification,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): Triggers => ({
  loadWorkflows: (clientId: number) => dispatch(BatchWorkflowAdminActions.loadWorkflowsByClientId(clientId)),
});

export const ChangeWorkflowDialog = connect(mapStateToProps, mapDispatchToProps)(ChangeWorkflowDialogController);
