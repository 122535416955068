import { CodedError } from './error';
import { TokenSet } from './token';

export enum AuthStatus {
  UNKNOWN,
  NOT_AUTHENTICATED,
  IN_PROGRESS_MFA,
  AUTHENTICATED,
}

export type AuthenticationState = UnknownState | AuthenticatedState | NotAuthenticatedState | NotAuthenticatedMfaState;

export interface UnknownState {
  status: AuthStatus.UNKNOWN;
}

export interface AuthenticatedState {
  status: AuthStatus.AUTHENTICATED;
  loading: boolean;
  tokenSet: TokenSet;
  error?: CodedError;
}

export interface NotAuthenticatedState {
  status: AuthStatus.NOT_AUTHENTICATED;
  loading: boolean;
  error?: CodedError;
}

export interface NotAuthenticatedMfaState {
  status: AuthStatus.IN_PROGRESS_MFA;
  loading: boolean;
  sessionToken: string;
  mfaType: string;
  error?: CodedError;
}
