// tslint:disable:object-literal-sort-keys
import { createStyles, Theme } from '@material-ui/core/styles';

// ##############################
// // // Sidebar styles
// #############################

import {
  boxShadow,
  defaultFont,
  drawerMiniWidth,
  drawerWidth,
  transition,
} from './copy';

export const sidebarStyle = (theme: Theme) => createStyles({
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: 2032,
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    // overflow: 'auto',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      left: '0',
      right: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: 3,
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0',
    },
  },
  varBackground: {
    color: 'var(--menu-color)',
    '&:after': {
      background: 'var(--menu-background)',
    },
  },
  whiteAfter: {
    '&:after': {
      backgroundColor: 'hsla(0,0%,71%,.3) !important',
    },
  },
  drawerPaperMini: {
    width: drawerMiniWidth + 'px!important',
  },
  logo: {
    padding: '15px 0px',
    margin: '0',
    display: 'block',
    position: 'relative',
    zIndex: 4,
    '& a': {
      textDecoration: 'none',
    },
  },
  logoMini: {
    transition: 'all 300ms linear',
    opacity: 1,
    float: 'left',
    textAlign: 'center',
    width: '30px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '22px',
    marginRight: '18px',
    marginTop: '7px',
    color: 'inherit',
  },
  logoNormal: {
    ...defaultFont,
    transition: 'all 300ms linear',
    display: 'block',
    opacity: 1,
    transform: 'translate3d(0px, 0, 0)',
    textTransform: 'uppercase',
    padding: '8px 0px',
    fontSize: '28px',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    lineHeight: '30px',
    overflow: 'hidden',
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
    textAlign: 'left',
  },
  logoNormalSidebarMini: {
    opacity: 0,
    transform: 'translate3d(-25px, 0, 0)',
  },
  img: {
    width: '35px',
    verticalAlign: 'middle',
    border: '0',
  },
  background: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 300ms linear',
  },
  list: {
    marginTop: '8px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    color: 'inherit',
    '&:after': {
      clear: 'both',
    },
  },
  item: {
    color: 'inherit',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: '0',
  },
  // userItem: {
  //   '&:last-child': {
  //     paddingBottom: '0px',
  //   },
  // },
  itemLink: {
    paddingLeft: '10px',
    paddingRight: '10px',
    transition: 'all 300ms linear',
    margin: '10px 15px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    backgroundColor: 'transparent',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  itemMini: {
    color: 'inherit',
    ...defaultFont,
    textTransform: 'uppercase',
    width: '30px',
    lineHeight: '30px',
    marginRight: '15px',
    textAlign: 'center',
    letterSpacing: '1px',
    position: 'relative',
    float: 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
    fontWeight: 700,
  },
  itemIcon: {
    color: 'inherit',
    width: '30px',
    height: '24px',
    float: 'left',
    position: 'inherit',
    top: '3px',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    '& svg': {
      width: '30px!important',
    },
  },
  itemText: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    lineHeight: '30px',
    fontSize: '14px',
    transform: 'translate3d(0px, 0, 0)',
    opacity: 1,
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap',
  },
  // userItemText: {
  //   lineHeight: '22px',
  // },
  itemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: 0,
  },
  collapseList: {
    marginTop: '0',
  },
  collapseItem: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: 0,
    padding: '0',
  },
  collapseActive: {
    outline: 'none',
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    boxShadow: 'none',
  },
  collapseItemLink: {
    transition: 'all 300ms linear',
    margin: '0 15px',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px',
    backgroundColor: 'transparent',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  collapseItemMini: {
    color: 'inherit',
    ...defaultFont,
    textTransform: 'uppercase',
    width: '30px',
    lineHeight: '30px',
    marginRight: '15px',
    textAlign: 'center',
    letterSpacing: '1px',
    position: 'relative',
    float: 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
    fontWeight: 700,
  },
  collapseItemText: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    position: 'relative',
    transform: 'translateX(0px)',
    opacity: 1,
    whiteSpace: 'nowrap',
    display: 'block',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
    lineHeight: '30px',
  },
  collapseItemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: 0,
  },
  caret: {
    marginTop: '13px',
    position: 'absolute',
    right: '8px',
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },
  // userCaret: {
  //   marginTop: '10px',
  //   right: '18px',
  // },
  caretActive: {
    transform: 'rotate(180deg)',
  },
  cssvar: {
    '&,&:hover,&:focus': {
      color: 'var(--menu-selected-color)',
      backgroundColor: 'var(--menu-selected-background)',
      boxShadow:
        '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(60,72,88,.4)',
    },
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '260px',
    zIndex: 4,
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    paddingBottom: '30px',
    '& a': {
      textDecoration: 'none',
    },
  },
  sidebarWrapperWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
  // user: {
  //   paddingBottom: '0px',
  //   margin: '20px auto 0',
  //   position: 'relative',
  // },
  photo: {
    transition: 'all 300ms linear',
    width: '34px',
    height: '34px',
    overflow: 'hidden',
    float: 'left',
    zIndex: 5,
    marginRight: '11px',
    borderRadius: '50%',
    marginLeft: '23px',
    ...boxShadow,
  },
  avatarImg: {
    width: '100%',
    verticalAlign: 'middle',
    border: '0',
  },
  // userCollapseButton: {
  //   margin: '0',
  //   marginBottom: '8px',
  //   padding: '6px 15px',
  //   // '&:hover': {
  //   //   background: 'none',
  //   // },
  // },
  // userCollapseLinks: {
  //   marginTop: '-4px',
  //   '&:hover,&:focus': {
  //     color: '#FFFFFF',
  //   },
  // },
  userItemIcon: {
    color: 'inherit',
    width: '30px',
    height: '24px',
    float: 'left',
    position: 'inherit',
    top: '3px',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    '& svg': {
      fontSize: 36,
      width: '36px!important',
      height: '36px!important',
      transform: 'translate(-3px,-6px)',
    },
  },
  dividerBefore: {
    backgroundColor: 'hsla(0,0%,100%,.3)',
    marginLeft: '16px',
    marginRight: '16px',
  },
  dividerAfter: {
    backgroundColor: 'hsla(0,0%,100%,.3)',
    marginTop: '8px',
    marginLeft: '16px',
    marginRight: '16px',
  },
});

export default sidebarStyle;
