import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import RootRef from '@material-ui/core/RootRef';
import Select, { SelectProps } from '@material-ui/core/Select';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { WithT } from 'i18next';
import React from 'react';
import { BatchDataFieldSet, FieldData, FieldDataOption, PerBatchDataFieldSet } from '../../../domain/batch-preview/dto/batch-preview-data';
import { Workflow } from '../../../store/interfaces/batch-modification';
import { ConfidenceLevels, Document } from '../../../store/interfaces/batch-preview';
import { BatchField, BatchFieldSet } from '../../../store/interfaces/batch-workflow';
import { IndexOf } from '../../../store/types';
import { ConfidenceTooltipComponent } from './confidence-bar';
import { FieldValue, Validation } from './field';
import { style } from './style';

interface FieldSetProps {
  id: string;
  fieldSets: IndexOf<BatchDataFieldSet>;
  fieldSet: BatchFieldSet;
  documents?: IndexOf<Document>;
  hasProblem: boolean;
  workflows?: IndexOf<Workflow>;
  fieldSetConfidence: ConfidenceLevels | null;
}

interface BatchSelectProps {
  fieldId: string;
  fieldLabel: string;
  fieldData: FieldData | null;
  documents?: IndexOf<Document>;
  handleChanged: (value: FieldDataOption | undefined) => void;
  initialValue: FieldDataOption | undefined;
  workflows?: IndexOf<Workflow>;
}

interface State {
  labelWidth: number;
  helper: { workflowName: string | undefined, documentId: string | undefined };
  validation: Validation | undefined;
}

class BatchSelectField extends React.PureComponent<SelectProps & BatchSelectProps & WithT & WithStyles, State> {
  fieldOptions: FieldDataOption[];
  indexOfOptions = new Map<string, FieldDataOption>();
  inputLabelRef: React.RefObject<HTMLLabelElement>;

  constructor(props: SelectProps & BatchSelectProps & WithT & WithStyles) {
    super(props);

    this.state = {
      helper: { workflowName: undefined, documentId: undefined },
      labelWidth: 0,
      validation: undefined,
    };
    this.inputLabelRef = React.createRef<HTMLLabelElement>();
  }

  componentDidMount() {
    if (!this.fieldOptions && this.props.fieldData) {
      const alternatives = this.props.fieldData.alternatives ? this.props.fieldData.alternatives : [];
      const selected = this.props.fieldData.selected;

      if (selected && !alternatives.find((opt) =>
        (opt.value === selected.value && opt.documentId === selected.documentId)
      )) {
        alternatives.unshift(selected);
      }
      this.fieldOptions = alternatives;

      this.indexOfOptions = alternatives.reduce(
        (acc, option) => {
          acc.set(option.batchFieldSourceId, option);
          return acc;
        },
        new Map<string, FieldDataOption>()
      );

      this.setHelper(selected);
    }
    this.setState({
      labelWidth: this.inputLabelRef.current ? this.inputLabelRef.current.offsetWidth : 150,
    });
  }

  setHelper(option: FieldDataOption | undefined) {
    if (option && this.props.documents && this.props.workflows) {
      this.setState({
        helper: {
          documentId: option.documentId,
          workflowName: this.findWorkflowNameByOption(option),
        },
      });
    }

    const validationSeverity = option ? option.validationSeverity : undefined;
    this.setState({
      validation: validationSeverity && option
        ? {
          message: option.rejectReason,
          severity: validationSeverity === 'HARD' ? 'HARD' : 'SOFT',
        }
        : undefined,
    });
  }

  findWorkflowNameByOption(option: FieldDataOption): string | undefined {
    if (!option || !this.props.documents || !this.props.workflows) return undefined;

    const documentWorkflow = option.documentId
      && this.props.documents[option.documentId]
      && this.props.workflows[this.props.documents[option.documentId].workflowId];

    const workflow = documentWorkflow
      || (option.workflowId && this.props.workflows[option.workflowId]);

    return workflow ? workflow.name : undefined;
  }

  // tslint:disable-next-line:no-any
  handleChange = (event: React.ChangeEvent<any>) => {
    const option = this.indexOfOptions.get(event.target.value);
    this.props.handleChanged(option);
    this.setHelper(option);
  }

  // tslint:disable-next-line:no-any
  handleRenderValue = (value: any) => {
    if (!value) return null;

    const option = this.indexOfOptions.get(value);
    if (!option) return null;

    return <FieldValue value={option.value} emptyText=" " t={this.props.t} />;
  }

  getFormHelperText() {
    if (!this.props.initialValue) return null;
    if (!this.props.initialValue.documentId || !this.state.helper.workflowName) {
      return this.state.helper.workflowName ? this.state.helper.workflowName : null;
    }

    return this.props.t('batchPreview.documentTitle', {
      documentId: this.props.initialValue.documentId,
      workflowName: this.state.helper.workflowName,
    });
  }

  render() {
    const { t, classes } = this.props;
    const validation = this.state.validation;
    const labelClass = cx({
      [classes.colorValidationHard]: validation && validation.severity === 'HARD',
      [classes.colorValidationSoft]: validation && validation.severity === 'SOFT',
    });
    const controlClass = cx({
      [classes.controlValidationHard]: validation && validation.severity === 'HARD',
      [classes.controlValidationSoft]: validation && validation.severity === 'SOFT',
    });
    return (
      <FormControl
        variant="outlined"
        className={classes.formControl}
      >
        <RootRef rootRef={this.inputLabelRef}>
          <InputLabel className={labelClass} htmlFor={this.props.fieldId + '-helper'}>
            {this.props.fieldLabel}
          </InputLabel>
        </RootRef>
        <Select
          input={<OutlinedInput
            classes={{ notchedOutline: controlClass }}
            labelWidth={this.state.labelWidth}
            name={this.props.fieldId}
            id={this.props.fieldId + '-helper'}
          />}
          value={(this.props.initialValue && this.props.initialValue.batchFieldSourceId) || ''}
          variant={this.props.variant}
          displayEmpty={this.props.displayEmpty}
          onChange={this.handleChange}
          className={classes.select}
          renderValue={this.handleRenderValue}
        >
          {(this.fieldOptions && this.fieldOptions.length > 0)
            ? this.fieldOptions.map((option: FieldDataOption) => {
              const workflowName = this.findWorkflowNameByOption(option);
              const menuItemClass = cx({
                [classes.controlValidationHard]: option.validationSeverity === 'HARD',
                [classes.controlValidationSoft]: option.validationSeverity === 'SOFT',
              });
              return (
                <MenuItem
                  key={`doc=${option.documentId},wf=${option.workflowId}`}
                  value={option.batchFieldSourceId}
                  className={menuItemClass}
                >
                  <FieldValue value={option.value} t={t} />
                  <em className={classes.greyEm}>
                    - {option.documentId && workflowName
                      ? t('batchPreview.documentTitle', { workflowName, documentId: option.documentId })
                      : workflowName ? workflowName : null
                    }
                  </em>
                </MenuItem>
              );
            })
            : <MenuItem
              key={Math.random()}
              value=""
            >
              {t('batchPreview.noOptionsAvailable')}
            </MenuItem>}
        </Select>
        <FormHelperText className={labelClass}>
          {this.getFormHelperText()}
        </FormHelperText>
      </FormControl>
    );
  }
}

type BatchFieldSetViewProps = FieldSetProps & WithT & WithStyles & {
  updateField: (
    fieldSetId: string,
    fieldId: string,
    value: FieldDataOption | undefined
  ) => void;
};

const BatchFieldSetView: React.FunctionComponent<BatchFieldSetViewProps> = (props) => {
  const { fieldSet, classes, hasProblem, id, t, updateField, fieldSetConfidence } = props;
  const data = props.fieldSets[id] as PerBatchDataFieldSet;
  const formRef = React.createRef<HTMLFormElement>();

  return (
    <Grid item={true} xs={12}>
      <Card className={classes.batchFieldSet}>
        <CardHeader
          className={hasProblem ? classes.problemCard : ''}
          title={
            <React.Fragment>
              <span className={classes.documentTitle}>
                {t('batchPreview.batchFieldSetTitle', { fieldSetId: fieldSet.label })}
              </span>
              {fieldSetConfidence ?
                <ConfidenceTooltipComponent documentConfidence={fieldSetConfidence} t={t} />
                : null}
            </React.Fragment>
          }
        />
        {(fieldSet && fieldSet.fields && data)
          ?
          <div className={classes.padded}>
            <form ref={formRef}>
              {fieldSet.fields.map((field: BatchField) => {
                const fieldData = (data.fields && data.fields[field.id]) || null;
                const handleChanged = (value: FieldDataOption | undefined) => {
                  updateField(fieldSet.id, field.id, value);
                };
                return (
                  <BatchSelectField
                    key={field.id}
                    fieldId={field.id}
                    fieldLabel={field.label}
                    fieldData={fieldData}
                    initialValue={fieldData ? fieldData.selected : undefined}
                    variant="outlined"
                    displayEmpty={true}
                    handleChanged={handleChanged}
                    {...props}
                  />
                );
              })}
            </form>
          </div>
          : <CardContent>{t('batchPreview.noDataForFieldSet')}</CardContent>}
      </Card>
    </Grid>
  );
};

export const BatchFieldSetScene = withStyles(style)(BatchFieldSetView);
