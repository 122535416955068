import { IndexOf } from '../types';
import { BatchLock, BatchStatus } from './batch';
import { BatchWorkflow as BaseBatchWorkflow } from './batch-workflow';

export interface Batch {
  id: string;
  batchWorkflowId: number;
  regionId: string;
  currentLock: BatchLock | undefined;
  currentStatus: BatchStatus | undefined;
}

export interface Document {
  id: string | undefined;
  batchId: string | undefined;
  pageIds: string[];
  workflowId: number;
  regionId: string;
  status: string;
}

export interface InputFile {
  id: string;
  inputSessionId: string;
  pageCount: number;
  dataSourceId?: number;
  workflowId?: number;
}

export interface InputSessions {
  id: string;
  inputFileIds: string[];
}

export interface Page {
  id: string;
  documentId: string;
  inputFileId: string;
  ordinal: number;
  thumbnail: Thumbnail;
  fullImage: Thumbnail;
}

export interface Thumbnail {
  url: string;
}

export interface Workflow {
  id: number;
  clientId: number;
  name: string;
  client: Client;
}

export interface Client {
  id: number;
  name: string;
}

export interface BatchWorkflow extends BaseBatchWorkflow {
  clientId: number;
}

export interface UndoItem {
  documents: IndexOf<Document>;
  documentIds: string[];
  orphanedPages: IndexOf<string>[];
}

export interface BatchModification {
  batch: Batch;
  batchWorkflows: IndexOf<BatchWorkflow> | undefined;
  clients: IndexOf<Client> | undefined;
  documents: IndexOf<Document>;
  documentIds: string[];
  inputFiles: IndexOf<InputFile> | undefined;
  inputSessions: IndexOf<InputSessions> | undefined;
  pages: IndexOf<Page>;
  undoStack: UndoItem[];
  workflows: IndexOf<Workflow> | undefined;
  lightboxModal: Page | undefined;
  orphanedPages: IndexOf<string>[];
  subUpdateInfo?: {
    type: 'deleted' | 'onhold' | 'unlock';
    by: string;
  };
}

export function isFullyLoaded(value: BatchModification): boolean {
  return Boolean(
    value
    && value.batch
    && value.batchWorkflows
    && value.clients
    && value.documents
    && value.documentIds
    && value.inputFiles
    && value.inputSessions
    && value.pages
    && value.workflows
  );
}
