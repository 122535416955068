import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { WithT } from 'i18next';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Batch } from '../../../store/interfaces/batch';
import { style } from './style';

export interface ControllerProps {
  batch: Batch;
  handleDeleteBatch: (batchId: string) => void;
}

interface LocalState {
  confirmDeleteAnchor: boolean;
}

class BatchDashboardDeleteController extends React.PureComponent<ControllerProps, LocalState> {
  state: Readonly<LocalState>;

  constructor(props: ControllerProps & WithStyles & WithTranslation) {
    super(props);

    this.state = {
      confirmDeleteAnchor: false,
    };
  }

  handleConfirmDeleteShow = async () => {
    await this.setState({
      ...this.state,
      confirmDeleteAnchor: true,
    });
  }

  handleConfirmDeleteHide = async () => {
    await this.setState({
      ...this.state,
      confirmDeleteAnchor: false,
    });
  }

  handleDeleteBatch = async () => {
    const { id } = this.props.batch;
    await this.handleConfirmDeleteHide();
    await this.props.handleDeleteBatch(id);
  }

  render() {
    const { confirmDeleteAnchor } = this.state;
    return (
      <BatchDashboardDeleteView
        handleConfirmDeleteShow={this.handleConfirmDeleteShow}
        handleConfirmDeleteHide={this.handleConfirmDeleteHide}
        handleDeleteBatch={this.handleDeleteBatch}
        confirmDeleteAnchor={confirmDeleteAnchor}
        batch={this.props.batch}
      />
    );
  }
}

type ViewProps = WithStyles & WithTranslation & WithT & WithStyles & {
  handleConfirmDeleteShow: () => Promise<void>;
  handleConfirmDeleteHide: () => Promise<void>;
  handleDeleteBatch: () => Promise<void>;
  confirmDeleteAnchor: boolean;
  batch: Batch;
};

const BatchDashboardDeleteView = withStyles(style)(withTranslation()(({
  t,
  handleConfirmDeleteShow,
  handleConfirmDeleteHide,
  handleDeleteBatch,
  confirmDeleteAnchor,
  batch,
}: ViewProps) => (
  <React.Fragment>
    <MenuItem onClick={handleConfirmDeleteShow}>{t('batchWorkflowDashboard.actions.delete')}</MenuItem>
    <Dialog
      open={confirmDeleteAnchor}
      onClose={handleConfirmDeleteHide}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('batchWorkflowDashboard.releasePopup.confirmDelete', { batchId: batch.id })}</DialogTitle>
      <DialogActions>
        <Button onClick={handleConfirmDeleteHide} color="primary">
          Cancel
          </Button>
        <Button onClick={handleDeleteBatch} color="primary" autoFocus={true}>
          Delete
          </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
)));

export const BatchDashboardDeleteScene = BatchDashboardDeleteController;
