import { FormControl, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { WithT } from 'i18next';
import React from 'react';
import Select from 'react-select';
import { ReadableBatchStatus } from '../../../domain/batch-dashboard/dto/batch-dashboard-data';
import { BatchFieldSet, ExpansionQuery } from '../../../store/interfaces/batch-dashboard';
import { Category } from '../../../store/interfaces/category';
import { IndexOf } from '../../../store/types';
import { filterStyle } from './filter-style';

export interface ExternalControllerProps {
  categories?: Category[];
  expansionQuery: ExpansionQuery;
  fields?: IndexOf<BatchFieldSet>;
  currentFilter: FilterItem | null;
  // TODO: Pass filters on save
  onSave: (expansionQuery: ExpansionQuery) => Promise<void>;
  onCancel: () => void;
}

export interface InternalControllerProps {
}

interface LocalState {
  expansionQuery: ExpansionQuery;
  field: FilterItemField | null;
  operation: FilterItemOption | null;
  value: FilterItemOption;
}

export interface FilterItem {
  field: FilterItemField | null;
  operation: FilterItemOption | null;
  value: FilterItemOption;
}

export function defaultFilterItem(lock: string, status: string, op: string): FilterItem[] {
  return [
    {
      field: {
        dataType: 'string',
        fieldId: 'lockedBy',
        label: lock,
        value: 'lockedBy',
      },
      operation: {
        label: op,
        value: 'is',
      },
      value: {
        label: '',
        value: '',
      },
    },
    {
      field: {
        dataType: 'string',
        fieldId: 'status',
        label: status,
        value: 'status',
      },
      operation: {
        label: op,
        value: 'is',
      },
      value: {
        label: ReadableBatchStatus.ready,
        value: 'ready',
      },
    },
    {
      field: {
        dataType: 'string',
        fieldId: 'status',
        label: status,
        value: 'status',
      },
      operation: {
        label: op,
        value: 'is',
      },
      value: {
        label: ReadableBatchStatus.onhold,
        value: 'onhold',
      },
    },
  ];
}

interface GroupedOptions {
  label: string;
  options: FilterItemField[];
}

interface FilterItemField {
  dataType?: string;
  fieldSetId?: string;
  fieldId: string;
  label: string;
  value: string;
}

export interface FilterItemOption {
  label: string;
  value: string;
}

type ControllerProps = ExternalControllerProps & InternalControllerProps & WithT;

class BatchDashboardFilterController extends React.PureComponent<ControllerProps, LocalState> {
  state: Readonly<LocalState>;

  constructor(props: ControllerProps & WithStyles) {
    super(props);

    this.state = {
      expansionQuery: props.expansionQuery,
      field: props.currentFilter ? props.currentFilter.field : null,
      operation: props.currentFilter ? props.currentFilter.operation : null,
      value: props.currentFilter ? props.currentFilter.value : { label: '', value: '' },
    };
  }

  saveHandler = async () => {
    if (!this.state.field || !this.state.operation || (this.state.field.value !== 'lockedBy' && !this.state.value)) return;
    const filter: FilterItem = {
      field: this.state.field,
      operation: this.state.operation,
      value: this.state.value,
    };
    const expansionQuery = this.state.expansionQuery;
    if (this.props.currentFilter) {
      const indexOfFilter = expansionQuery.filters.indexOf(this.props.currentFilter);
      expansionQuery.filters[indexOfFilter] = filter;
    } else {
      expansionQuery.filters.push(filter);
    }
    await this.props.onSave(expansionQuery);
  }

  setFilterField = async (value: FilterItemField | null) => {
    if (!value) return;
    this.setState({
      ...this.state,
      field: value,
    });
    if (value.fieldId === 'lockedBy') {
      this.setState({
        ...this.state,
        field: value,
        operation: { label: 'is', value: 'is' },
        value: { label: '', value: '' },
      });
    }
  }

  setFilterOperation = async (value: FilterItemOption | null) => {
    if (!value) return;
    this.setState({
      ...this.state,
      operation: value,
    });
  }

  setFilterValue = async (value: FilterItemOption | null) => {
    if (!value) return;
    this.setState({
      ...this.state,
      value: value,
    });
  }

  render() {
    const { fields, categories } = this.props;
    let selectOptions: GroupedOptions[] = [];
    if (fields) {
      selectOptions = Object.keys(fields).filter((fieldSetId) => {
        return fields[fieldSetId].type === 'PerBatchFieldSet';
      }).map((fieldSetId) => {
        return {
          label: fields[fieldSetId].label,
          options: Object.keys(fields[fieldSetId].fields).map((fieldId) => {
            return {
              dataType: fields[fieldSetId].fields[fieldId].dataType,
              fieldId: fieldId,
              fieldSetId,
              label: fields[fieldSetId].fields[fieldId].label,
              value: fieldId,
            };
          }).filter((option) => {
            const fieldSet = fields[fieldSetId];
            return fieldSet.type === 'PerBatchFieldSet'
              && fieldSet.fields[option.fieldId].dashboardVisible;
          }),
        };
      });
    }

    let categoryOptions: FilterItemOption[] = [];
    if (categories) {
      categoryOptions = categories.map((category) => {
        return { label: category.name, value: category.id.toString() };
      });
    }

    return (
      <BatchDashboardFilterView
        categoryOptions={categoryOptions}
        filters={this.state.expansionQuery.filters}
        selectOptions={selectOptions}
        currentFilter={{
          field: this.state.field,
          operation: this.state.operation,
          value: this.state.value,
        }}
        onSave={this.saveHandler}
        onCancel={this.props.onCancel}
        onFieldChange={this.setFilterField}
        onOperationChange={this.setFilterOperation}
        onValueChange={this.setFilterValue}
        t={this.props.t}
      />
    );
  }
}

type ViewProps = WithStyles & {
  categoryOptions: FilterItemOption[];
  currentFilter: FilterItem;
  filters: FilterItem[];
  selectOptions: GroupedOptions[];
  onSave: () => Promise<void>;
  onCancel: () => void;
  onFieldChange: (value: FilterItemField | null) => Promise<void>;
  onOperationChange: (value: FilterItemOption | null) => Promise<void>;
  onValueChange: (value: FilterItemOption | null) => Promise<void>;
};

const BatchDashboardFilterView = withStyles(filterStyle)(({
  categoryOptions,
  classes,
  currentFilter,
  filters,
  selectOptions,
  onSave,
  onCancel,
  onFieldChange,
  onOperationChange,
  onValueChange,
  t,
}: ViewProps & WithT) => {
  function valueChange(event: React.ChangeEvent<HTMLInputElement>) {
    onValueChange({ label: '', value: event.target.value });
  }

  function statusValueChange(option: FilterItemOption | null) {
    onValueChange(option);
  }

  function categoryValueChange(option: FilterItemOption | null) {
    onValueChange(option);
  }

  const lockedByFilter: FilterItemField = {
    dataType: 'string',
    fieldId: 'lockedBy',
    label: t('batchWorkflowDashboard.batchFilter.lockedBy'),
    value: 'lockedBy',
  };
  const batchFilterOptions: FilterItemField[] = [
    lockedByFilter,
    {
      dataType: 'string',
      fieldId: 'batchId',
      label: 'Id',
      value: 'batchId',
    },
    {
      dataType: 'string',
      fieldId: 'status',
      label: t('batchWorkflowDashboard.columns.status'),
      value: 'status',
    },
    {
      dataType: 'string',
      fieldId: 'category',
      label: t('batchWorkflowDashboard.columns.category'),
      value: 'category',
    },
  ];
  if (!currentFilter.field || currentFilter.field.fieldId !== 'lockedBy') {
    const lockedBy = filters.find((value) => !!value.field && value.field.fieldId === lockedByFilter.fieldId);
    if (lockedBy) {
      batchFilterOptions.shift();
    }
  }
  selectOptions.unshift({
    label: 'Batch',
    options: batchFilterOptions,
  });

  const formatGroupLabel = (data: GroupedOptions) => (
    <div className={classes.groupStyles}>
      <span>{data.label}</span>
    </div>
  );

  const statusOperations: FilterItemOption[] = [
    { label: t('batchWorkflowDashboard.batchFilter.operations.equal'), value: 'is' },
    { label: t('batchWorkflowDashboard.batchFilter.operations.notEqual'), value: 'is not' },
  ];

  const operations: FilterItemOption[] = [
    ...statusOperations,
    { label: t('batchWorkflowDashboard.batchFilter.operations.contains'), value: 'contains' },
  ];

  const statusOptions: FilterItemOption[] = Object.keys(ReadableBatchStatus).map((status) => {
    return { label: ReadableBatchStatus[status], value: status };
  });

  const statusValue = statusOptions.find((option) => option.value === currentFilter.value.value);
  const categoryValue = categoryOptions.find((option) => option.value === currentFilter.value.value);

  return (
    <div className={classes.root}>
      <div>
        <div>Filter</div>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            options={selectOptions}
            className={classes.select}
            formatGroupLabel={formatGroupLabel}
            onChange={onFieldChange}
            defaultValue={currentFilter ? currentFilter.field : undefined}
          />
          {!currentFilter.field || currentFilter.field.fieldId !== 'lockedBy' ?
            <React.Fragment>
              <Select
                options={currentFilter.field && (['status', 'category'].indexOf(currentFilter.field.fieldId) > -1) ? statusOperations : operations}
                className={classes.select}
                onChange={onOperationChange}
                defaultValue={currentFilter.operation}
              />
              {currentFilter.field && currentFilter.field.fieldId === 'status'
                ? <Select options={statusOptions} className={classes.select} onChange={statusValueChange} defaultValue={statusValue} />
                : currentFilter.field && currentFilter.field.fieldId === 'category'
                  ? <Select options={categoryOptions} className={classes.select} onChange={categoryValueChange} defaultValue={categoryValue} />
                  : <TextField
                    InputProps={{ classes: { input: classes.textInputField } }}
                    variant="outlined"
                    defaultValue={currentFilter.value.value || undefined}
                    onChange={valueChange}
                  />
              }
            </React.Fragment>
            : null}
        </FormControl>

        <Button variant="contained" color="primary" className={classes.button} onClick={onSave}>
          {currentFilter.field ? t('batchWorkflowDashboard.batchFilter.buttons.update') : t('batchWorkflowDashboard.batchFilter.buttons.add')}
        </Button>
        <Button className={classes.button} onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  );
}
);

export const BatchDashboardFilterScene = BatchDashboardFilterController;
