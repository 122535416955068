import {
  withStyles,
  Grid,
  Tooltip,
  Typography,
  WithStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import BackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BatchWorkflowAdminActions } from '../../../actions/batch-workflow-admin.actions';
import AppBar, { AppBarView } from '../../../components/app-bar';
import { CircularProgressComponent } from '../../../components/circular-progress';
import { ErrorComponent } from '../../../components/error';
import { IconButtonWithTooltip } from '../../../components/icon-button-tooltip';
import { NavigationCheck } from '../../../components/navigation-check';
import PageContainer from '../../../components/page-container';
import { VerticalDivider } from '../../../components/vertical-divider';
import { WindowTitle } from '../../../components/window-title';
import { WithBatchWorkflowId } from '../../../helpers/router-props';
import { StateRoot } from '../../../store/interfaces';
import { BatchField, BatchFieldSet, BatchWorkflow, BatchWorkflowModification } from '../../../store/interfaces/batch-workflow';
import { CodedError } from '../../../store/interfaces/error';
import { UserWorkflowPermission } from '../../../store/interfaces/user';
import { Loadable } from '../../../store/loader';
import { AppMutations } from '../../../store/mutations/app.mutations';
import { BatchFieldSetList } from './batch-field-set-list';
import { BatchWorkflowNewObjectData } from './batch-workflow.new-objects';
import { BatchWorkflowValidation } from './batch-workflow.validation';
import { BatchWorkflowGeneralSettings } from './general-settings';
import { style } from './style';

interface Props {
  batchWorkflowModification: Loadable<BatchWorkflowModification, BatchWorkflow>;
}

interface ExternalProps {
  batchWorkflowId: number | undefined;
}

interface ControllerTriggers {
  initBatchWorkflowAdmin: (batchWorkflowId: number | undefined) => void;
  validate: (errorMessage: string) => void;
  save: (batchWorkflow: BatchWorkflow) => void;
}

export interface RemovableBatchComponent {
  batchFieldSetIndex: number;
  batchFieldIndex?: number;
  batchFieldSourceIndex?: number;
  type: 'batchFieldSet' | 'batchField' | 'batchFieldSource';
}

export interface SelectFieldItem {
  label: string;
  value: string;
}

interface LocalState {
  batchWorkflow: BatchWorkflow | undefined;
  hasChanged: boolean;
  newObjectData?: BatchWorkflowNewObjectData;
  validationData?: BatchWorkflowValidation;
}

type ControllerProps = Props & ExternalProps & ControllerTriggers & RouteComponentProps<WithBatchWorkflowId> & WithStyles & WithTranslation;

export class BatchWorkflowAdminController extends React.PureComponent<ControllerProps, LocalState> {
  state: Readonly<LocalState>;

  constructor(props: ControllerProps) {
    super(props);

    this.state = {
      batchWorkflow: undefined,
      hasChanged: false,
      newObjectData: undefined,
      validationData: undefined,
    };
  }

  componentDidMount() {
    this.props.initBatchWorkflowAdmin(this.props.batchWorkflowId);
  }

  componentDidUpdate() {
    if (!this.state.batchWorkflow && this.props.batchWorkflowModification.value) {
      this.setState({ batchWorkflow: this.props.batchWorkflowModification.value });
    }
  }

  setBatchWorkflowState(batchWorkflow: BatchWorkflow, notImportant?: boolean) {
    // this function was called as something had been changed, thus let's clear validation data - it will be set again if necessary
    this.setState({ batchWorkflow, newObjectData: undefined, validationData: undefined });
    if (!Boolean(notImportant)) {
      this.setState({ hasChanged: true });
    }
  }

  onDragEnd = (result: DropResult) => {
    if (!this.state.batchWorkflow || !this.state.batchWorkflow.fieldSets) return;

    const { destination, source, reason } = result;

    if (!destination || (reason !== 'DROP')) return;
    if ((destination.droppableId === source.droppableId) && (destination.index === source.index)) return;

    switch (result.type) {
      case 'batchFieldSet': {
        if (!this.state.batchWorkflow.fieldSets[source.index] || !this.state.batchWorkflow.fieldSets[destination.index]) return;

        let batchFieldSets = [...this.state.batchWorkflow.fieldSets];
        const movingBatchFieldSet = batchFieldSets[source.index];

        batchFieldSets.splice(source.index, 1);
        batchFieldSets.splice(destination.index, 0, movingBatchFieldSet);

        const batchWorkflow: BatchWorkflow = { ...this.state.batchWorkflow, fieldSets: batchFieldSets };
        this.setBatchWorkflowState(batchWorkflow);
        break;
      }

      case 'batchField': {
        const batchFieldSetIndex = result.draggableId.split('-')[1];
        if (!batchFieldSetIndex) return;
        if (!this.state.batchWorkflow.fieldSets[batchFieldSetIndex]) return;
        if (
          !this.state.batchWorkflow.fieldSets[batchFieldSetIndex].fields[source.index]
          || !this.state.batchWorkflow.fieldSets[batchFieldSetIndex].fields[destination.index]
        ) return;

        let batchFieldSets = [...this.state.batchWorkflow.fieldSets];
        let batchFields = [...batchFieldSets[batchFieldSetIndex].fields];
        const movingBatchField = batchFields[source.index];

        batchFields.splice(source.index, 1);
        batchFields.splice(destination.index, 0, movingBatchField);

        batchFieldSets[batchFieldSetIndex] = { ...batchFieldSets[batchFieldSetIndex], fields: batchFields };

        const batchWorkflow: BatchWorkflow = { ...this.state.batchWorkflow, fieldSets: batchFieldSets };
        this.setBatchWorkflowState(batchWorkflow);
        break;
      }

      case 'batchFieldSource': {
        const componentIndexes = result.draggableId.split('-');

        const batchFieldSetIndex = Number(componentIndexes[1]);
        if (!this.state.batchWorkflow.fieldSets[batchFieldSetIndex]) return;

        const batchFieldIndex = Number(componentIndexes[2]);
        if (!this.state.batchWorkflow.fieldSets[batchFieldSetIndex].fields[batchFieldIndex]) return;

        let batchFieldSources = this.state.batchWorkflow.fieldSets[batchFieldSetIndex].fields[batchFieldIndex].sources;
        if (!batchFieldSources || !batchFieldSources[source.index] || !batchFieldSources[destination.index]) return;

        let batchFieldSets = [...this.state.batchWorkflow.fieldSets];
        let batchFields = [...batchFieldSets[batchFieldSetIndex].fields];
        batchFieldSources = [...batchFieldSources];
        const movingBatchFieldSource = batchFieldSources[source.index];

        batchFieldSources.splice(source.index, 1);
        batchFieldSources.splice(destination.index, 0, movingBatchFieldSource);

        batchFields[batchFieldIndex] = { ...batchFields[batchFieldIndex], sources: batchFieldSources };
        batchFieldSets[batchFieldSetIndex] = { ...batchFieldSets[batchFieldSetIndex], fields: batchFields };

        const batchWorkflow: BatchWorkflow = { ...this.state.batchWorkflow, fieldSets: batchFieldSets };
        this.setBatchWorkflowState(batchWorkflow);
        break;
      }

      default: {
        return;
      }
    }
  }

  onDeleteBatchComponent = (removableBatchComponent: RemovableBatchComponent) => {
    if (!this.state.batchWorkflow || !this.state.batchWorkflow.fieldSets) return;

    switch (removableBatchComponent.type) {
      case 'batchFieldSet': {
        const batchFieldSets = [...this.state.batchWorkflow.fieldSets];
        batchFieldSets.splice(removableBatchComponent.batchFieldSetIndex, 1);

        const batchWorkflow: BatchWorkflow = { ...this.state.batchWorkflow, fieldSets: batchFieldSets };
        this.setBatchWorkflowState(batchWorkflow);
        break;
      }

      case 'batchField': {
        if (removableBatchComponent.batchFieldIndex === undefined) return;
        const batchFieldSets = [...this.state.batchWorkflow.fieldSets];
        const batchFields = [...batchFieldSets[removableBatchComponent.batchFieldSetIndex].fields];
        batchFields.splice(removableBatchComponent.batchFieldIndex, 1);

        batchFieldSets[removableBatchComponent.batchFieldSetIndex] = {
          ...batchFieldSets[removableBatchComponent.batchFieldSetIndex],
          fields: batchFields,
        };

        const batchWorkflow: BatchWorkflow = { ...this.state.batchWorkflow, fieldSets: batchFieldSets };
        this.setBatchWorkflowState(batchWorkflow);
        break;
      }

      case 'batchFieldSource': {
        if ((removableBatchComponent.batchFieldIndex === undefined) || (removableBatchComponent.batchFieldSourceIndex === undefined)) return;

        const batchFieldSets = [...this.state.batchWorkflow.fieldSets];
        const batchFields = [...batchFieldSets[removableBatchComponent.batchFieldSetIndex].fields];
        const batchFieldSources = batchFields[removableBatchComponent.batchFieldIndex].sources;

        if (!batchFieldSources) return;

        batchFieldSources.splice(removableBatchComponent.batchFieldSourceIndex, 1);

        batchFields[removableBatchComponent.batchFieldIndex] = {
          ...batchFields[removableBatchComponent.batchFieldIndex],
          sources: batchFieldSources,
        };

        batchFieldSets[removableBatchComponent.batchFieldSetIndex] = {
          ...batchFieldSets[removableBatchComponent.batchFieldSetIndex],
          fields: batchFields,
        };

        const batchWorkflow: BatchWorkflow = { ...this.state.batchWorkflow, fieldSets: batchFieldSets };
        this.setBatchWorkflowState(batchWorkflow);
        break;
      }

      default: {
        return;
      }
    }
  }

  onChangeBatchWorkflow = (updatedBatchWorkflow: BatchWorkflow) => {
    this.setBatchWorkflowState(updatedBatchWorkflow);
  }

  onChangeBatchFieldSet = (batchFieldSet: BatchFieldSet, index: number, notImportant?: boolean, newBatchFieldIndex?: number) => {
    if (!this.state.batchWorkflow || !this.state.batchWorkflow.fieldSets) return;

    const batchFieldSets = [...this.state.batchWorkflow.fieldSets];

    if (!batchFieldSets[index]) return;

    batchFieldSets[index] = batchFieldSet;

    const batchWorkflow: BatchWorkflow = { ...this.state.batchWorkflow, fieldSets: batchFieldSets };
    this.setBatchWorkflowState(batchWorkflow, notImportant);

    if (newBatchFieldIndex !== undefined) {
      const newObjectData: BatchWorkflowNewObjectData = {
        messageComponent: this.props.t('batchWorkflowEdit.general.enterLabel'),
        newBatchFieldSet: { id: index, newBatchField: { id: newBatchFieldIndex } },
      };
      this.setState({ newObjectData });
    }
  }

  addBatchFieldSet = () => {
    if (!this.state.batchWorkflow) return;

    const batchFieldSets = [...this.state.batchWorkflow.fieldSets];

    const newBatchFieldSet: BatchFieldSet = { __typename: 'PerBatchFieldSet', id: '', label: '', ordinal: 0, fields: [], isNew: true };
    const newBatchFieldSetIndex: number = batchFieldSets.push(newBatchFieldSet) - 1;

    const batchWorkflow: BatchWorkflow = { ...this.state.batchWorkflow, fieldSets: batchFieldSets };
    this.setBatchWorkflowState(batchWorkflow);

    const newObjectData: BatchWorkflowNewObjectData = {
      messageComponent: this.props.t('batchWorkflowEdit.general.enterLabel'),
      newBatchFieldSet: { id: newBatchFieldSetIndex },
    };
    this.setState({ newObjectData });
  }

  setValidationData(validationData: BatchWorkflowValidation) {
    if (
      validationData.batchFieldSetValidation &&
      validationData.batchFieldSetValidation.batchFieldValidation &&
      validationData.batchFieldSetValidation.batchFieldValidation.batchFieldSourceValidation
    ) { // failure in Batch Field Sources, we may need to open them to be able to show the validation failure to the user
      const batchWorkflow = this.state.batchWorkflow!;
      const batchFieldSetIndex = validationData.batchFieldSetValidation.id;
      const batchFieldIndex = validationData.batchFieldSetValidation.batchFieldValidation.id;

      const sourcesOpen = batchWorkflow.fieldSets[batchFieldSetIndex].fields[batchFieldIndex].sourcesOpen;

      if (!sourcesOpen) { // let's open Batch Field Sources
        const batchFieldSets = [...batchWorkflow.fieldSets];
        const batchFields = [...batchFieldSets[batchFieldSetIndex].fields];
        batchFields[batchFieldIndex] = { ...batchFields[batchFieldIndex], sourcesOpen: true };
        batchFieldSets[batchFieldSetIndex] = { ...batchFieldSets[batchFieldSetIndex], fields: batchFields };
        this.setState({ batchWorkflow: { ...batchWorkflow, fieldSets: batchFieldSets } });
      }
    }
    this.setState({ newObjectData: undefined, validationData });
  }

  handleSave = () => {
    let batchWorkflow = this.state.batchWorkflow;
    if (!batchWorkflow) return;

    let batchFieldSets = [...batchWorkflow.fieldSets];
    for (let i = 0; i < batchFieldSets.length; i++) {
      const batchFieldSet: BatchFieldSet = batchFieldSets[i];
      if (batchFieldSet.isNew) {
        batchFieldSets[i] = { ...batchFieldSet, id: BatchWorkflowValidation.camelize(batchFieldSet.label) };
      }
      let batchFields = [...batchFieldSet.fields];
      for (let j = 0; j < batchFields.length; j++) {
        const batchField: BatchField = batchFields[j];
        if (batchField.isNew) {
          batchFields[j] = { ...batchField, id: BatchWorkflowValidation.camelize(batchField.label) };
        }
      }
      batchFieldSets[i] = { ...batchFieldSets[i], fields: batchFields };
    }
    batchWorkflow = { ...batchWorkflow, fieldSets: batchFieldSets };
    this.setState({ batchWorkflow });

    const validationData: BatchWorkflowValidation | undefined = BatchWorkflowValidation.validateBatchWorkflow(batchWorkflow, this.props.t);
    if (!validationData) {
      this.props.save(batchWorkflow);
    } else {
      this.setValidationData(validationData);
      this.props.validate(validationData.errorMessageGlobal || '');
    }
  }

  render() {
    const { batchWorkflowModification, classes, history, t } = this.props;

    if (batchWorkflowModification.error) {
      return <ErrorComponent error={batchWorkflowModification.error} />;
    }

    if (!this.state.batchWorkflow) {
      return <CircularProgressComponent />;
    }

    const batchWorkflow = this.state.batchWorkflow;
    const isAdmin = batchWorkflow.userPermissions.includes(UserWorkflowPermission.isAdmin);

    if (!isAdmin) {
      const codedError = new CodedError(t('batchWorkflowEdit.validation.permissionDenied'), 'error');
      return <ErrorComponent error={codedError} />;
    }

    function handleBack() {
      history.goBack();
    }

    function handleExit() {
      history.replace('/');
    }

    return (
      <PageContainer>
        <Grid item={true} xs={12}>
          <AppBarView>
            <AppBar>
              <WindowTitle title={t('batchWorkflowEdit.windowTitle')} />
              <NavigationCheck
                allow={!this.state.hasChanged}
                message={t('terms.navigationCheck')}
              >
                <IconButtonWithTooltip
                  onClick={handleBack}
                  tooltip={t('terms.back')}
                >
                  <BackIcon />
                </IconButtonWithTooltip>
                <Tooltip title={t('batchWorkflowEdit.tooltip.exitBatchWorkflowAdminPage')!}>
                  <Button onClick={handleExit} color="inherit">{t('batchWorkflowEdit.actions.exit')}</Button>
                </Tooltip>
              </NavigationCheck>
              <VerticalDivider height={'30px'} horizontalMargin={'20px'} />
              <Typography variant={'h6'} color={'inherit'}>
                {
                  batchWorkflow.id > 0
                    ? t('batchWorkflowEdit.editPageTitle', { batchWorkflowId: batchWorkflow.id, batchWorkflowName: batchWorkflow.name })
                    : t('batchWorkflowEdit.addPageTitle')
                }
              </Typography>
              <div className={classes.grow} />
              <Tooltip title={t('batchWorkflowEdit.tooltip.addBatchWorkflowFieldSet')!}>
                <Button color="inherit" variant={'outlined'} onClick={this.addBatchFieldSet} className={classes.button}>
                  <AddCircleIcon />&nbsp;
                  {t('batchWorkflowEdit.actions.addBatchFieldSet')}
                </Button>
              </Tooltip>
              <Button
                onClick={this.handleSave}
                variant={'contained'}
                color={'primary'}
                style={{ marginLeft: '8px' }}
                disabled={!this.state.hasChanged}
              >
                {t('batchWorkflowEdit.actions.save')}
              </Button>
            </AppBar>
          </AppBarView>
        </Grid>
        <BatchWorkflowGeneralSettings
          batchWorkflow={batchWorkflow}
          validationData={this.state.validationData}
          onChangeBatchWorkflow={this.onChangeBatchWorkflow}
        />
        {
          batchWorkflow
            ? <BatchFieldSetList
              batchFieldSets={batchWorkflow.fieldSets}
              newObjectData={this.state.newObjectData}
              validationData={this.state.validationData}
              onChangeBatchFieldSet={this.onChangeBatchFieldSet}
              onDragEnd={this.onDragEnd}
              onDeleteBatchComponent={this.onDeleteBatchComponent}
            />
            : undefined
        }
      </PageContainer>
    );
  }
}

const mapStateToSceneProps = (state: StateRoot): Props => ({
  batchWorkflowModification: state.batchWorkflowModification,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): ControllerTriggers => ({
  initBatchWorkflowAdmin: (batchWorkflowId: number | undefined) => dispatch(BatchWorkflowAdminActions.initBatchWorkflowAdmin(batchWorkflowId)),
  save: (batchWorkflow: BatchWorkflow) => dispatch(BatchWorkflowAdminActions.save(batchWorkflow)),
  validate: (errorMessage: string) => dispatch(AppMutations.setErrorSnackbar(new Error(errorMessage), 10000)),
});

export const BatchWorkflowAdminScene = withRouter(
  connect(mapStateToSceneProps, mapDispatchToProps)(withStyles(style)(withTranslation()(BatchWorkflowAdminController)))
);
