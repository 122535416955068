import { createStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const style = (theme: Theme) => createStyles({
  appBarRoot: {
    backgroundColor: 'white',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    position: 'fixed',
    top: 0,
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 112px)',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1168px',
    },
  },
  batchId: {
    color: '#888',
    fontSize: '0.9rem',
    textAlign: 'end',
  },
  batchTitle: {
    fontSize: '1.2rem',
    paddingBottom: '0.4rem',
  },
  chip: {
    '& svg': {
      color: '#616161',
      marginLeft: theme.spacing(),
    },
    margin: theme.spacing(),
  },
  fieldContainer: {
    '&>div strong': {
      fontFamily: [
        'Source Code Pro',
        'monospace',
      ].join(','),
    },
  },
  grow: {
    flexGrow: 1,
  },
  inputInput: {
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(),
    paddingTop: theme.spacing(),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  list: {
    '& .priority-high td.fields1': {
      backgroundColor: '#fcc',
    },
    '& .status-locked': {
      backgroundColor: theme.palette.grey[100],
    },
    '& .status-processing': {
      backgroundColor: theme.palette.grey[100],
    },
    '& .status-ready': {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
        borderLeftColor: theme.palette.secondary.main,
        borderLeftStyle: 'solid',
        borderLeftWidth: 8,
      },
      cursor: 'pointer',
    },
    marginTop: theme.spacing(4),
  },
  progress: {
    marginTop: theme.spacing(4),
  },
  row: {
    '&>div': {
      paddingBottom: '0.75rem!important',
      paddingTop: '0.75rem!important',
    },
  },
  rowDisabled: {
    '&>div': {
      paddingBottom: '0.75rem!important',
      paddingTop: '0.75rem!important',
    },
    '&>div:not(.buttonContainer)': {
      opacity: 0.4,
    },
    cursor: 'auto',
  },
  rowGraph: {
    '&>div': {
      '&>span': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'inline-block',
        fontSize: 8,
        height: '100%',
        lineHeight: 1,
        overflow: 'hidden',
        padding: 2,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      height: '12px',
      width: '100%',
    },
    height: 14,
    marginBottom: 2,
    width: 100,
  },
  search: {
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.05),
    },
    backgroundColor: fade(theme.palette.common.black, 0.10),
    borderRadius: theme.shape.borderRadius,
    display: 'none',
    margin: theme.spacing(),
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    width: theme.spacing(7),
  },
});
