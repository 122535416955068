import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';

export const style = (theme: Theme) => createStyles({
  container: {
    alignSelf: 'flex-start',
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
    paddingBottom: theme.spacing(2),
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 32 + theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1200px',
    },
  },
});

interface Props { }

const PageContainerView: React.FunctionComponent<Props & WithStyles> = ({ children, classes }) => (
  <Grid container={true} classes={classes} spacing={3}>
    {children}
  </Grid>
);

const PageContainer = withStyles(style)(PageContainerView);
export default PageContainer;
