import { createAction } from '..';
import { AuthStatus } from '../interfaces/auth';
import { CodedError } from '../interfaces/error';
import { TokenSet } from '../interfaces/token';

export namespace AuthMutations {
  export const error = (message: string, code: string, tokenSet?: TokenSet) => createAction((state) => ({
    ...state,
    auth: tokenSet
      ? {
        error: new CodedError(message, code),
        loading: false,
        status: AuthStatus.AUTHENTICATED,
        tokenSet,
      }
      : {
        error: new CodedError(message, code),
        loading: false,
        status: AuthStatus.NOT_AUTHENTICATED,
      },
  }));

  export const notAuthenticated = (loading: boolean = false) => createAction((state) => ({
    ...state,
    auth: { status: AuthStatus.NOT_AUTHENTICATED, loading },
  }));

  export const authenticated = (tokenSet: TokenSet, loading: boolean = false) => createAction((state) => ({
    ...state,
    auth: { status: AuthStatus.AUTHENTICATED, loading, tokenSet },
  }));

  export const mfa = (mfaType: string, sessionToken: string) => createAction((state) => ({
    ...state,
    auth: {
      loading: false,
      mfaType,
      sessionToken,
      status: AuthStatus.IN_PROGRESS_MFA,
    },
  }));
}
