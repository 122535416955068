import { BatchWorkflowRepository } from '../domain/batch-workflow/batch-workflow.repository';
import { parseApolloError } from '../helpers/graphql';
import store from '../store';
import { CodedError } from '../store/interfaces/error';
import { BatchWorkflowMutations } from '../store/mutations/batch-workflow.mutations';
import { Action } from '../store/types';

export namespace BatchWorkflowActions {
  export function search(): Action {
    return async (dispatch) => {
      dispatch(BatchWorkflowMutations.loading());
      try {
        const repository = BatchWorkflowRepository.forGlobal();
        const results = await repository.findAll();
        dispatch(BatchWorkflowMutations.results(results));
      } catch (err) {
        const errors = parseApolloError(err);
        errors.forEach((error) => dispatch(BatchWorkflowMutations.error(new CodedError(error.message, 'error'))));
      }
    };
  }

  export async function load(batchWorkflowId: number | undefined) {
    if (!batchWorkflowId) {
      store.dispatch(BatchWorkflowMutations.noCurrent());
      return;
    }

    store.dispatch(BatchWorkflowMutations.loading());
    try {
      const repository = BatchWorkflowRepository.forGlobal();
      const result = await repository.findById(batchWorkflowId);
      store.dispatch(BatchWorkflowMutations.current(result));
    } catch (err) {
      const errors = parseApolloError(err);
      store.dispatch(BatchWorkflowMutations.currentError(new CodedError(errors[0].message, 'error')));
    }
  }
}
