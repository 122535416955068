import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import env from '../../environment';
import { Region } from '../../store/interfaces/region';
import { clientCache } from '../graphql';

const GQL_LOAD_REGIONS = gql`{
  regions {
    id
    favoriteOrdinal
  }
}`;

const GQL_FAVORITE_REGION = gql`
mutation Favorite($id: String!) {
  regionFavorite(id: $id) {
    ordinal
  }
}`;

const GQL_UNFAVORITE_REGION = gql`
mutation Unfavorite($id: String!) {
  regionUnfavorite(id: $id)
}`;

export class RegionRepository {
  public static forGlobal(): RegionRepository {
    const url = env.urlFactory.global().service('data').resolve('graphql');
    const client = clientCache.getItem(url);
    if (!client) throw new Error('Unable to create GraphQL client');
    return new RegionRepository(client);
  }

  constructor(
    private readonly client: ApolloClient<{}>,
  ) { }

  public async findAll(): Promise<Region[]> {
    const { data } = await this.client.query<{ regions: Region[] }>({
      query: GQL_LOAD_REGIONS,
    });
    return data.regions || [];
  }

  public async favorite(regionId: string): Promise<number | null> {
    const { data } = await this.client.mutate<{ favorite: { ordinal: number } }>({
      mutation: GQL_FAVORITE_REGION,
      variables: { id: regionId },
    });

    if (!data) {
      return null;
    }

    return data.favorite.ordinal;
  }

  public async unfavorite(regionId: string): Promise<void> {
    await this.client.mutate<{ favorite: { ordinal: number } }>({
      mutation: GQL_UNFAVORITE_REGION,
      variables: { id: regionId },
    });
  }
}
