import { createStyles, Theme } from '@material-ui/core/styles';

export const filterStyle = (theme: Theme) => createStyles({
  button: {
    float: 'right',
    margin: '10px 5px 0px',
  },
  formControl: {
    marginTop: theme.spacing(),
    width: '100%',
  },
  popperStyle: {
    zIndex: 1200,
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    marginTop: '15px',
    padding: theme.spacing(2),
    width: '100%',
  },
  select: {
    marginBottom: theme.spacing(),
    minWidth: '170px',
  },
  textInputField: {
    padding: theme.spacing(),
  },
});
