import { CodedError } from './interfaces/error';

export type Comparer<T> = (a: T, b: T) => number;

export class DataList<I, T extends { id: I }> {
  public static empty<I, T extends { id: I }>(comparer?: Comparer<T>): DataList<I, T> {
    return new DataList<I, T>(
      new Map<I, T>(),
      comparer,
      [],
      true,
      undefined
    );
  }

  get items(): T[] {
    return this.sorted.map((id) => this.store.get(id)).filter((item) => !!item) as T[];
  }

  constructor(
    private readonly store: Map<I, T>,
    private readonly comparer: Comparer<T> | undefined,
    private readonly sorted: I[],
    public readonly loading: boolean,
    public readonly error?: CodedError,
  ) { }

  public get(id: I): T | undefined {
    return this.store.get(id);
  }

  public startLoading(clearExisting: boolean): DataList<I, T> {
    return new DataList(
      clearExisting ? new Map<I, T>() : this.store,
      this.comparer,
      clearExisting ? [] : this.sorted,
      true);
  }

  public loaded(items: T[]): DataList<I, T> {
    const unfrozenItems = [...items];

    const sorted = this.comparer
      ? unfrozenItems.sort(this.comparer).map((item) => item.id)
      : unfrozenItems.map((item) => item.id);

    return new DataList(
      unfrozenItems.reduce(
        (store, item) => store.set(item.id, item),
        new Map<I, T>()
      ),
      this.comparer,
      sorted,
      false
    );
  }

  public loadError(error: CodedError): DataList<I, T> {
    return new DataList(
      new Map<I, T>(),
      this.comparer,
      [],
      false,
      error
    );
  }

  public replaceItem(newItem: T): DataList<I, T> {
    if (!this.store.has(newItem.id)) return this;
    const clone = new Map(this.store);
    clone.set(newItem.id, newItem);
    const sorted = this.comparer
      ? Array.from(clone, (item) => item[1]).sort(this.comparer).map((item) => item.id)
      : this.sorted;
    return new DataList(
      clone,
      this.comparer,
      sorted,
      this.loading,
      this.error
    );
  }

  public patchItem(id: I, patch: Partial<T>): DataList<I, T> {
    const item = this.store.get(id);
    if (!item) return this;
    return this.replaceItem(Object.assign(item, patch));
  }
}
