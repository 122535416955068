import { Button, Grid, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import * as H from 'history';
import { WithT } from 'i18next';
import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BatchPreviewActions } from '../../../actions/batch-preview.actions';
import { BatchActions } from '../../../actions/batch.actions';
import AppBar, { AppBarView } from '../../../components/app-bar';
import { CircularProgressComponent } from '../../../components/circular-progress';
import { BatchLockedComponent, BatchReleaseComponent, BatchUpdatedComponent, ErrorComponent, GeneralErrorComponent } from '../../../components/error';
import { IconButtonWithTooltip } from '../../../components/icon-button-tooltip';
import PageContainer from '../../../components/page-container';
import SwitchToNewELSButton from '../../../components/switch-new-app-button';
import { VerticalDivider } from '../../../components/vertical-divider';
import { WindowTitle } from '../../../components/window-title';
import { FieldDataOption } from '../../../domain/batch-preview/dto/batch-preview-data';
import {
  BatchDataFieldSet,
  PerBatchDataFieldSet,
  PerDocumentHeaderDataFieldSet,
  PerDocumentRepeatingDataFieldSet
} from '../../../domain/batch-preview/dto/batch-preview-modify-data';
import { BatchFieldSet } from '../../../domain/batch-workflow/dto/batch-workflows-and-fields';
import { batchHotKeys } from '../../../helpers/hot-keys';
import { RouterProps, WithBatchId } from '../../../helpers/router-props';
import { MenuContext } from '../../../helpers/types';
import { withRegionId, MaybeWithRegionId, WithRegionId } from '../../../hoc/with-region-id';
import { StateRoot } from '../../../store/interfaces';
import { AuthenticationState, AuthStatus } from '../../../store/interfaces/auth';
import { BatchStatus } from '../../../store/interfaces/batch';
import { BatchPreviewData, BatchPreviewQuery, Document } from '../../../store/interfaces/batch-preview';
import { BatchWorkflow } from '../../../store/interfaces/batch-workflow';
import { Loadable } from '../../../store/loader';
import { BatchPreviewMutations } from '../../../store/mutations/batch-preview.mutations';
import { IndexOf } from '../../../store/types';
import { BatchFieldSetScene } from './batch-field-set-view';
import { DocumentFieldSetScene } from './document-field-set-view';
import { style } from './style';
import { PreviewSummary, WithPreview } from './summary';

interface Props {
  auth: AuthenticationState;
  batchPreview: Loadable<BatchPreviewQuery, BatchPreviewData>;
  batchWorkflow: Loadable<number, BatchWorkflow>;
  subscriber: ZenObservable.Subscription | undefined;
}

interface WithUpdateField {
  updateField: (
    fieldSetId: string,
    fieldId: string,
    value: FieldDataOption | undefined
  ) => void;
}

interface LocalState {
  menuAnchor: MenuContext<Document> | null;
}

interface Triggers extends WithUpdateField {
  lockAndLoad: (
    regionId: string | undefined,
    batchWorkflowId: number | undefined,
    batchId: string | undefined
  ) => void;
  validate: () => void;
  holdBatch: (batchId: string, regionId: string, history: H.History) => void;
  reExtract: (documentId: string) => void;
  unload: () => void;
  save: (data: IndexOf<BatchDataFieldSet>, ignoredDocuments: string[], completeFlag?: boolean) => void;
  subscribe: (regionId: string, batchWorkflowId: number) => void;
  unlockBatch: (batchId: string, regionId: string) => void;
  releaseBatch: (batchId: string, batchWorkflowId: number, regionId: string) => void;
}

type ControllerProps = Props & Triggers & RouteComponentProps<WithBatchId> & WithRegionId & WithTranslation;

class BatchPreviewController extends React.PureComponent<ControllerProps, LocalState> {
  state: LocalState;

  constructor(props: ControllerProps) {
    super(props);
    this.state = { menuAnchor: null };
  }

  setMenuAnchor = (value: MenuContext<Document> | null) => {
    this.setState({
      ...this.state,
      menuAnchor: value,
    });
  }

  handleMenuShow = (item: Document, element: HTMLElement) => {
    this.setMenuAnchor({ element, item });
  }

  handleMenuHide = () => {
    this.setMenuAnchor(null);
  }

  componentDidMount() {
    RouterProps.handleBatchWorkflowRoute(this.props);
    this.props.lockAndLoad(
      this.props.regionId,
      this.props.match.params.batchWorkflowId
        ? Number(this.props.match.params.batchWorkflowId)
        : undefined,
      this.props.match.params.batchId,
    );
    if (!this.props.match.params.batchWorkflowId) return;
    this.props.subscribe(this.props.regionId, Number(this.props.match.params.batchWorkflowId));
  }

  componentDidUpdate(props: RouteComponentProps<WithBatchId>): void {
    RouterProps.handleBatchWorkflowRoute(props, this.props);
  }

  componentWillUnmount(): void {
    this.props.unload();
    if (!this.props.subscriber) return;
    this.props.subscriber.unsubscribe();
  }

  render() {
    const {
      auth, batchPreview, history, save, t, unlockBatch, holdBatch, updateField, validate, reExtract, releaseBatch,
    } = this.props;

    if (batchPreview.error) {
      return <ErrorComponent error={batchPreview.error} />;
    }

    if (batchPreview && batchPreview.valuePending) return null;

    const preview = batchPreview ? batchPreview.value : undefined;
    if (!preview) {
      return <CircularProgressComponent />;
    }

    const currentData = preview.batch && preview.batch.currentData
      ? preview.batch.currentData
      : undefined;

    if (batchPreview.loading || batchPreview.valuePending) {
      return <CircularProgressComponent />;
    }

    const ignoredDocuments = preview.batch.ignoredDocuments;

    function handleBack() {
      if (batchPreview.query) {
        history.goBack();
      }
    }

    function handleModifyBatch() {
      if (batchPreview.query) {
        history.replace({
          pathname: `/batch-workflow/${batchPreview.query.batchWorkflowId}/batch/${batchPreview.query.batchId}/modify`,
        });
      }
    }

    function handleProcessBatch() {
      if (batchPreview.query) {
        history.replace({
          pathname: `/batch-workflow/${batchPreview.query.batchWorkflowId}/batch/${batchPreview.query.batchId}/learning`,
        });
      }
    }

    function handleExitUnlock() {
      if (batchPreview.query && batchPreview.query.batchId && batchPreview.query.regionId) {
        unlockBatch(batchPreview.query.batchId, batchPreview.query.regionId);

        history.replace(`/batch-workflow/${batchPreview.query.batchWorkflowId}/dashboard`);
      }
    }

    async function handleCompleteBatch() {
      const newData = getData();

      if (!newData) return;
      await save(newData, ignoredDocuments, true);

      if (!batchPreview.query) return;
      if (history.length === 1) {
        window.open('about:blank', '_parent'); // Browsers don't allow closing of tabs/windows not opened by itself
        window.close();
      }
      history.replace(`/batch-workflow/${batchPreview.query.batchWorkflowId}/dashboard`);
    }

    function handleSaveBatch() {
      const newData = getData();
      if (!newData) return;
      save(newData, ignoredDocuments, false);
    }

    function handleIgnoreDocument(documentId: string) {
      const index = ignoredDocuments.indexOf(documentId);
      if (index < 0) {
        ignoredDocuments.push(documentId);
      } else {
        ignoredDocuments.splice(index, 1);
      }
      validate();
    }

    function handleReleaseBatch() {
      releaseBatch(batch.id, batch.batchWorkflowId, batch.regionId);
    }

    function handleCancelRelease() {
      history.push(`/batch-workflow/${batch.batchWorkflowId}/dashboard`);
    }

    const handleReExtractDocument = (documentId: string | undefined) => () => {
      if (documentId) {
        reExtract(documentId);
      }
      this.handleMenuHide();
    };

    function handleHoldBatch() {
      if (batchPreview.query && batchPreview.query.batchId && batchPreview.query.regionId) {
        holdBatch(batchPreview.query.batchId, batchPreview.query.regionId, history);
      }
    }

    const handleProcessBatchDocument = (documentId: string) => () => {
      if (!batchPreview.query) return;
      history.push({
        pathname: `/batch-workflow/${batchPreview.query.batchWorkflowId}/batch/${batchPreview.query.batchId}/document/${documentId}/learning`,
        state: {
          from: `/batch-workflow/${batchPreview.query.batchWorkflowId}/batch/${batchPreview.query.batchId}/preview`,
        },
      });
    };

    const currentUserId = auth.status === AuthStatus.AUTHENTICATED
      ? auth.tokenSet.idToken.payload.sub
      : undefined;

    function getData() {
      if (!currentData) return undefined;

      const fieldSets = currentData.fieldSets;
      return Object.keys(fieldSets).reduce(
        (acc, fieldSetId) => {
          const fieldSetDefinitions = preview ? preview.fieldSets : null;
          const fieldSetDefinition = fieldSetDefinitions ? fieldSetDefinitions[fieldSetId] : undefined;
          const fieldSetData = currentData.fieldSets[fieldSetId];
          if (!fieldSetDefinition || !fieldSetData) return acc;
          if (fieldSetData.type === 'PerBatchDataFieldSet' && fieldSetDefinition.type === 'PerBatchFieldSet') {
            return {
              ...acc,
              [fieldSetId]: ({
                fields: Object.keys(fieldSetData.fields).reduce(
                  (fieldAcc, fieldId) => {
                    if (!fieldSetData.fields[fieldId]) return acc;
                    const fieldData = fieldSetData.fields[fieldId];
                    const currentSelected = fieldData.selected;
                    if (currentSelected) {
                      fieldData.alternatives = fieldData.alternatives.filter(
                        (alts) =>
                          !(alts.batchFieldSourceId === currentSelected.batchFieldSourceId && alts.documentId === currentSelected.documentId)
                      );
                      fieldData.confirmed = {
                        userId: currentUserId,
                        when: Date.now(),
                      };
                    }
                    return {
                      ...fieldAcc,
                      [fieldId]: fieldData,
                    };
                  },
                  {}
                ),
              } as PerBatchDataFieldSet),
            };
          }
          delete fieldSetData.type;
          return {
            ...acc,
            [fieldSetId]: (fieldSetData as PerDocumentHeaderDataFieldSet | PerDocumentRepeatingDataFieldSet),
          };
        },
        {} as IndexOf<BatchDataFieldSet>
      );
    }

    const batch = preview.batch;

    if (!batch.currentStatus) {
      return <GeneralErrorComponent message={t('errors.batch-no-status')} />;
    }

    if (preview.subUpdateInfo) {
      const subUpdateInfo = preview.subUpdateInfo;
      let body = '';
      if (subUpdateInfo.type === BatchStatus.DELETED || subUpdateInfo.type === BatchStatus.HOLD) {
        body = t(`errors.subscription.` + subUpdateInfo.type, { name: t(subUpdateInfo.by) });
      } else {
        body = t('batchPreview.problems.batchUnlocked', { name: t(subUpdateInfo.by) });
      }

      return (
        <BatchUpdatedComponent
          handleUpdate={handleCancelRelease}
          title={t('batchPreview.pageTitle', { batchId: batch.id })}
          body={body}
        />
      );
    }

    if (batch.currentStatus.status === BatchStatus.HOLD) {
      return <BatchReleaseComponent batchId={batch.id} handleCancel={handleCancelRelease} handleRelease={handleReleaseBatch} />;
    }

    if (batch.currentStatus.status !== BatchStatus.READY) {
      return <GeneralErrorComponent message={t(`errors.batch-in-${batch.currentStatus.status}-status`)} />;
    }

    if (batch.currentLock && batch.currentLock.lockedByUser && (batch.currentLock.lockedByUserId !== currentUserId)) {
      return <BatchLockedComponent lockedBy={batch.currentLock.lockedByUser.name} />;
    }

    return (
      <BatchPreviewView
        batchId={batch.id}
        batchWorkflowId={batchPreview.query?.batchWorkflowId}
        handleBack={handleBack}
        handleCompleteBatch={handleCompleteBatch}
        handleExitUnlock={handleExitUnlock}
        handleMenuHide={this.handleMenuHide}
        handleMenuShow={this.handleMenuShow}
        handleHoldBatch={handleHoldBatch}
        handleModifyBatch={handleModifyBatch}
        handleProcessBatch={handleProcessBatch}
        handleProcessBatchDocument={handleProcessBatchDocument}
        handleReExtractDocument={handleReExtractDocument}
        handleSaveBatch={handleSaveBatch}
        preview={preview}
        updateField={updateField}
        handleIgnoreDocument={handleIgnoreDocument}
        menuAnchor={this.state.menuAnchor}
      />
    );
  }
}

interface FieldSetProps extends WithUpdateField {
  handleProcessBatchDocument: (documentId: string) => () => void;
  onMenuShow: (item: Document, element: HTMLElement) => void;
  handleIgnoreDocument: (documentId: string) => void;
}

const FieldSetView: React.FunctionComponent<FieldSetProps & WithPreview & WithT> = (
  { handleProcessBatchDocument, handleIgnoreDocument, onMenuShow, preview, t, updateField }
) => {
  const fieldSets = (
    preview.batch &&
    preview.batch.currentData &&
    preview.batch.currentData.fieldSets
  ) ? preview.batch.currentData.fieldSets : null;

  const rawFieldSets = preview.batchWorkflow ? preview.batchWorkflow.fieldSets : null;
  const perBatchFS = rawFieldSets ? rawFieldSets.filter((fs: BatchFieldSet) => (fs.__typename === 'PerBatchFieldSet')) : null;
  const perDocumentFS = rawFieldSets ? rawFieldSets.filter((fs: BatchFieldSet) => (fs.__typename !== 'PerBatchFieldSet')) : null;

  return (
    <Grid container={true} spacing={2}>
      {(perBatchFS && fieldSets)
        ? perBatchFS.map((fieldSet: BatchFieldSet) => {
          return (
            <BatchFieldSetScene
              key={fieldSet.id}
              documents={preview.documents}
              fieldSet={fieldSet}
              fieldSets={fieldSets}
              id={fieldSet.id}
              hasProblem={(preview.problems.batchFieldSetValidation && preview.problems.batchFieldSetValidation[fieldSet.id]) || false}
              updateField={updateField}
              workflows={preview.workflows}
              t={t}
              fieldSetConfidence={preview.confidenceLevels ? preview.confidenceLevels.fieldSetConfidence[fieldSet.id] : null}
            />
          );
        })
        : null
      }
      {(preview.batch.documents && perDocumentFS && fieldSets)
        ?
        <DocumentFieldSetScene
          batchDocuments={preview.documents}
          batchId={preview.batch.id}
          batchWorkflowId={preview.batch.batchWorkflowId}
          fieldSets={fieldSets}
          handleProcessBatch={handleProcessBatchDocument}
          handleIgnoreDocument={handleIgnoreDocument}
          onMenuShow={onMenuShow}
          problemDocuments={{ ...preview.problems.documentStatus, ...preview.problems.documentValidation }}
          refFieldSets={preview.fieldSets}
          sortedFieldSets={perDocumentFS}
          t={t}
          workflows={preview.workflows}
          documentConfidence={preview.confidenceLevels ? preview.confidenceLevels.documentConfidence : null}
          ignoredDocuments={preview.batch.ignoredDocuments}
        />
        : null
      }
    </Grid>
  );
};

interface CompleteBatchButtonProps {
  handleCompleteBatch: () => void;
}

const CompleteBatchButton: React.FunctionComponent<CompleteBatchButtonProps & ButtonProps & WithT> = (props) => {
  const { children, t, handleCompleteBatch, ...otherProps } = props;

  const handleCategorySelection = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleCompleteBatch();
  };

  return <Button onClick={handleCategorySelection} {...otherProps}>{children}</Button>;
};

interface BatchPreviewProps extends WithUpdateField {
  batchId: string;
  batchWorkflowId?: number;
  handleBack: () => void;
  handleCompleteBatch: () => void;
  handleExitUnlock: () => void;
  handleMenuShow: (item: Document, element: HTMLElement) => void;
  handleHoldBatch: () => void;
  handleMenuHide: () => void;
  handleModifyBatch: () => void;
  handleProcessBatch: () => void;
  handleProcessBatchDocument: (documentId: string) => () => void;
  handleIgnoreDocument: (documentId: string) => void;
  handleReExtractDocument: (documentId: string | undefined) => () => void;
  handleSaveBatch: () => void;
  preview: BatchPreviewData;
  menuAnchor: MenuContext<Document> | null;
}

const BatchPreviewView = withStyles(style)(withTranslation()(({
  batchId,
  batchWorkflowId,
  classes,
  handleBack,
  handleCompleteBatch,
  handleExitUnlock,
  handleHoldBatch,
  handleMenuHide,
  handleMenuShow,
  handleModifyBatch,
  handleIgnoreDocument,
  handleProcessBatch,
  handleProcessBatchDocument,
  handleReExtractDocument,
  handleSaveBatch,
  preview,
  t,
  updateField,
  menuAnchor,
}: BatchPreviewProps & WithTranslation & WithStyles) => {
  const keyHandler = {
    exit: (event?: KeyboardEvent) => {
      event?.preventDefault();
      handleExitUnlock();
    },
    save: (event?: KeyboardEvent) => {
      event?.preventDefault();
      handleSaveBatch();
    },
  };

  return (
    <GlobalHotKeys keyMap={batchHotKeys} handlers={keyHandler}>
      <PageContainer>
        <Grid item={true} xs={12}>
          <AppBarView>
            <AppBar>
              <WindowTitle title={t('batchPreview.pageTitle', { batchId })} />
              <IconButtonWithTooltip
                onClick={handleBack}
                tooltip={t('terms.back')}
              >
                <BackIcon />
              </IconButtonWithTooltip>
              <Tooltip title={t('batchPreview.actions.exitUnlock')!}>
                <Button onClick={handleExitUnlock} color="inherit">{t('batchPreview.actions.exit')}</Button>
              </Tooltip>
              <Tooltip title={t('batchPreview.actions.holdUnlock')!}>
                <Button onClick={handleHoldBatch} color="inherit">{t('batchPreview.actions.hold')}</Button>
              </Tooltip>
              <VerticalDivider height={'30px'} horizontalMargin={'20px'} />
              <Typography variant={'h6'} color={'inherit'}>
                {t('batchPreview.pageTitle', { batchId: batchId })}
              </Typography>
              <div className={classes.grow} />
              <SwitchToNewELSButton batchWorkflowId={batchWorkflowId} batchId={batchId} />
              <Button onClick={handleModifyBatch} color="inherit">{t('batchPreview.actions.modify')}</Button>
              <Button onClick={handleProcessBatch} color="inherit">{t('batchPreview.actions.process')}</Button>
              <Button onClick={handleSaveBatch} color="inherit">{t('batchPreview.actions.saveBatch')}</Button>
              <CompleteBatchButton
                handleCompleteBatch={handleCompleteBatch}
                variant={'contained'}
                color={'primary'}
                style={{ marginLeft: '8px' }}
                disabled={Object.keys(preview.problems).length > 0}
                t={t}
              >
                {t('batchPreview.actions.completeBatch')}
              </CompleteBatchButton>
            </AppBar>
          </AppBarView>
        </Grid>
        <Grid item={true} xs={12}>
          <PreviewSummary preview={preview} />
        </Grid>
        <Grid item={true} xs={12}>
          <FieldSetView
            handleProcessBatchDocument={handleProcessBatchDocument}
            handleIgnoreDocument={handleIgnoreDocument}
            onMenuShow={handleMenuShow}
            preview={preview}
            t={t}
            updateField={updateField}
          />
        </Grid>

        {menuAnchor
          ? <Menu anchorEl={menuAnchor.element} open={Boolean(menuAnchor)} onClose={handleMenuHide}>
            <MenuItem onClick={handleReExtractDocument(menuAnchor.item.id)}>{t('batchPreview.actions.reExtract')}</MenuItem>
          </Menu>
          : null
        }
      </PageContainer>
    </GlobalHotKeys>
  );
}
));

const mapStateToProps = (state: StateRoot): Props & MaybeWithRegionId => ({
  auth: state.auth,
  batchPreview: state.batchPreview,
  batchWorkflow: state.batchWorkflow,
  regionId: state.currentRegionId,
  subscriber: state.subscriber,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): Triggers => ({
  holdBatch: (batchId, regionId, history) => dispatch(BatchActions.holdBatch(batchId, regionId, history)),
  lockAndLoad: (regionId, batchWorkflowId, batchId) => dispatch(BatchPreviewActions.lockAndLoad(regionId, batchWorkflowId, batchId)),
  reExtract: (documentId) => dispatch(BatchPreviewActions.reExtract(documentId)),
  releaseBatch: (batchId, batchWorkflowId, regionId) => dispatch(BatchPreviewActions.releaseBatch(batchId, batchWorkflowId, regionId)),
  save: (data, ignoredDocuments, completeFlag) => dispatch(BatchPreviewActions.save(data, ignoredDocuments, completeFlag)),
  subscribe: (
    regionId: string,
    batchWorkflowId: number,
  ) => dispatch(BatchPreviewActions.subscribe(regionId, batchWorkflowId)),
  unload: () => dispatch(BatchPreviewMutations.clear()),
  unlockBatch: (batchId, regionId) => dispatch(BatchActions.unlockBatch(batchId, regionId)),
  updateField: (fieldSetId, fieldId, value) => dispatch(BatchPreviewMutations.updateField(fieldSetId, fieldId, value)),
  validate: () => dispatch(BatchPreviewMutations.validate()),
});

export const BatchPreviewScene = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(withRegionId(BatchPreviewController))
  )
);
