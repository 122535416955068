import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import authActions, { AuthProps } from '../../actions/auth.actions';
import { StateRoot } from '../../store/interfaces';
import { AuthStatus } from '../../store/interfaces/auth';
import AuthSignIn from './components/sign-in';
import AuthTotp from './components/totp';
import './style.scss';

interface TriggerProps {
  handleSignIn: (email: string, password: string) => void;
  handleTotp: (code: string) => void;
  handleReset: () => void;
}

const AuthView = (props: AuthProps & TriggerProps) => (
  <Grid className="auth-root fill" container={true} justify="center" alignItems="center" alignContent="center">
    {function () {
      switch (props.auth.status) {
        case AuthStatus.IN_PROGRESS_MFA: return (<AuthTotp
          auth={props.auth}
          handleSubmit={props.handleTotp}
          handleReset={props.handleReset}
        />);
        case AuthStatus.NOT_AUTHENTICATED: return (<AuthSignIn
          auth={props.auth}
          handleSubmit={props.handleSignIn}
        />);
        default: return (<div>unknown</div>); // TODO
      }
    }()}
  </Grid>
);

const mapStateToProps = (state: StateRoot): AuthProps => ({
  auth: state.auth,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): TriggerProps => ({
  handleReset: () => dispatch(authActions.reset()),
  handleSignIn: (email: string, password: string) => dispatch(authActions.signIn(email, password)),
  handleTotp: (code: string) => dispatch(authActions.processTotp(code)),
});

export const AuthScene = connect(mapStateToProps, mapDispatchToProps)(AuthView);
