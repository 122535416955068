import Button from '@material-ui/core/Button';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IconButtonWithTooltip } from '../../../components/icon-button-tooltip';
import { BatchFieldSet } from '../../../store/interfaces/batch-dashboard';
import { IndexOf } from '../../../store/types';
import { BatchDashboardSortRowScene, SortItem } from './sort-row';
import { sortStyle } from './sort-style';

export interface ExternalControllerProps {
  resetSortBy: SortItem[];
  initialSortBy: SortItem[];
  onSave: (sortBy: SortItem[]) => Promise<void>;
  onCancel: () => void;
}

export interface InternalControllerProps {
  fields: IndexOf<BatchFieldSet> | undefined;
}

interface LocalState {
  sortBy: SortItem[];
}

type ControllerProps = ExternalControllerProps & InternalControllerProps;

class BatchDashboardSortController extends React.PureComponent<ControllerProps, LocalState> {
  state: Readonly<LocalState>;

  constructor(props: ControllerProps & WithStyles & WithTranslation) {
    super(props);

    this.state = {
      sortBy: props.initialSortBy,
    };
  }

  saveHandler = async () => {
    // get sort items from local state
    await this.props.onSave(this.state.sortBy);
  }

  changeSelectHandler = async (index: number, selectedOption: SortItem) => {
    const sortBy = [...this.state.sortBy];
    if (index >= sortBy.length) {
      sortBy.push(selectedOption);
    } else {
      sortBy[index] = selectedOption;
    }
    await this.setState({ sortBy });
  }

  deleteSortHandler = async (index: number) => {
    const sortBy = [...this.state.sortBy];
    sortBy.splice(index, 1);
    await this.setState({ sortBy });
  }

  resetHandler = async () => {
    const sortBy = this.props.resetSortBy;
    await this.setState({ sortBy });
  }

  toggleOrderHandler = async (index: number, ascending: boolean) => {
    const sortBy = [...this.state.sortBy];
    sortBy[index] = {
      ...sortBy[index],
      ascending: ascending,
    };
    await this.setState({ sortBy });
  }

  render() {
    const { fields } = this.props;
    if (!fields) return null;

    return (
      <BatchDashboardSortView
        fields={fields}
        onSave={this.saveHandler}
        onCancel={this.props.onCancel}
        onReset={this.resetHandler}
        onSelectChange={this.changeSelectHandler}
        onSelectRemove={this.deleteSortHandler}
        onToggleOrder={this.toggleOrderHandler}
        sortBy={this.state.sortBy}
      />
    );
  }
}

type ViewProps = WithStyles & WithTranslation & {
  fields: IndexOf<BatchFieldSet> | undefined;
  sortBy: SortItem[];
  onReset: () => Promise<void>;
  onSave: () => Promise<void>;
  onCancel: () => void;
  onSelectChange: (index: number, selectedOption: SortItem) => Promise<void>;
  onSelectRemove: (index: number) => Promise<void>;
  onToggleOrder: (index: number, ascending: boolean) => Promise<void>;
};

const BatchDashboardSortView = withStyles(sortStyle)(withTranslation()(({
  classes,
  fields,
  sortBy,
  t,
  onSelectChange,
  onSelectRemove,
  onToggleOrder,
  onReset,
  onSave,
  onCancel,
}: ViewProps) => (
    <div className={classes.root}>
      <div>
        <div>Sort by</div>
        {sortBy.length
          ?
          sortBy.map((item, index) =>
            <BatchDashboardSortRowScene
              key={index}
              index={index}
              fields={fields}
              sortItem={item}
              clearOnSelect={false}
              onSelectChange={onSelectChange}
              onSelectRemove={onSelectRemove}
              onToggleOrder={onToggleOrder}
              excludeOptions={sortBy}
            />
          )
          :
          null
        }
        <BatchDashboardSortRowScene
          index={sortBy.length}
          fields={fields}
          sortItem={undefined}
          clearOnSelect={true}
          onSelectChange={onSelectChange}
          onSelectRemove={onSelectRemove}
          onToggleOrder={onToggleOrder}
          excludeOptions={sortBy}
        />
        {sortBy.length
          ? <Button variant="contained" color="primary" className={classes.button} onClick={onSave}>
            Apply
          </Button>
          : null
        }
        {sortBy.length
          ? <Button className={classes.button} onClick={onCancel}>Cancel</Button>
          : null
        }
        {sortBy.length
          ? <IconButtonWithTooltip
            className={classes.resetButton}
            onClick={onReset}
            tooltip={t('batchWorkflowDashboard.actions.reset')}
          >
            <RefreshIcon fontSize="small" color="action" />
          </IconButtonWithTooltip>
          : null
        }
      </div>
    </div>
  )));

export const BatchDashboardSortScene = BatchDashboardSortController;
