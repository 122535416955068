import { createStyles, Theme } from '@material-ui/core/styles';

export const style = (theme: Theme) => createStyles({
  changeWorkflowList: {
    overflowY: 'scroll',
  },
  changeWorkflowTitle: {
    fontSize: '1em',
    marginBottom: theme.spacing(),
  },
  document: {
    '& .draggable': {
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  documentStatus: {
    fontStyle: 'italic',
  },
  documentTitle: {
    padding: '4px 56px 4px 24px',
  },
  grow: {
    flexGrow: 1,
  },
  joinButton: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  noPages: {
    alignItems: 'center',
    background: theme.palette.background.paper,
    display: 'inline-flex',
    height: 150,
    justifyContent: 'center',
    margin: 1,
    minWidth: 150,
  },
  page: {
    alignItems: 'center',
    background: theme.palette.background.paper,
    border: '1px solid rgba(224, 224, 224, 1)',
    display: 'inline-flex',
    height: 150,
    justifyContent: 'center',
    margin: 1,
    minWidth: 150,
  },
  pageContainer: {
    alignItems: 'center',
    display: 'inline-flex',
    height: 150,
    justifyContent: 'center',
  },
  pageList: {
    '&>.droppable': {
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'start',
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    flexGrow: 1,
    overflowX: 'scroll',
    paddingTop: theme.spacing(),
  },
  panelHost: {
    position: 'relative',
  },
  panelLeft: {
    background: 'linear-gradient(to right, rgba(255,255,255,0.75) 0%, rgba(255,255,255,0.75) 70%, rgba(255,255,255,0.1) 100%)',
    bottom: 0,
    left: 0,
    paddingLeft: 2,
    position: 'absolute',
    top: 0,
  },
  panelRight: {
    background: 'linear-gradient(to left, rgba(255,255,255,0.75) 0%, rgba(255,255,255,0.75) 70%, rgba(255,255,255,0.1) 100%)',
    bottom: 0,
    paddingLeft: theme.spacing(),
    paddingRight: 2,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  root: {},
  showMoreButton: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  sideButton: {
    '& .circle': {
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.08);',
      },
      background: 'rgba(255, 255, 255, 0.75)',
      borderRadius: '50%',
      color: 'rgba(0, 0, 0, 0.54)',
      height: '3rem',
      padding: theme.spacing(1.5),
      width: '3rem',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  splitButton: {
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54);',
    },
    borderRadius: 12,
    color: 'rgba(0, 0, 0, 0.06)',
    marginBottom: 'auto',
    marginTop: 'auto',
    width: 24,
  },
  splitButtonPlaceholder: {
    width: 24,
  },
  titleBar: {
    background: 'rgba(0, 0, 0, 0.15)',
  },
  tooltipWithMaxWidth: {
    maxWidth: '170px',
  },
});
