import { createAction } from '..';
import { IndexOf } from '../types';
import { Workflow } from './../interfaces/batch-modification';
import { WorkflowFieldSet, WorkflowWithFields } from './../interfaces/batch-workflow';

export namespace WorkflowMutations {
  export function drawWorkflowsForState(workflows: Workflow[]): IndexOf<Workflow> {
    let workflowsForState: IndexOf<Workflow> = {};
    workflows.forEach((workflow) => {
      workflowsForState[workflow.id.toString()] = {
        client: { id: workflow.client.id, name: workflow.client.name },
        clientId: workflow.client.id,
        id: workflow.id,
        name: workflow.name,
      };
    });
    return workflowsForState;
  }

  export function drawFieldSetsForState(workflow: WorkflowWithFields): IndexOf<WorkflowFieldSet> {
    let fieldSetsForState: IndexOf<WorkflowFieldSet> = {};
    workflow.fields.forEach((field) => {
      if (field.fieldSet && (field.fieldSet.isRepeating === 1)) {
        const fieldSet: WorkflowFieldSet = field.fieldSet;
        fieldSetsForState[fieldSet.id.toString()] = {
          description: fieldSet.description,
          fieldRepresentation: fieldSet.fieldRepresentation,
          id: fieldSet.id,
          isRepeating: fieldSet.isRepeating,
          label: fieldSet.label,
        };
      }
    });
    return fieldSetsForState;
  }

  export const availableWorkflows = (workflows: Workflow[]) => createAction((state) => {
    const workflowsForState: IndexOf<Workflow> = drawWorkflowsForState(workflows);
    if (state.batchModification.value) {
      const prev = state.batchModification.value;
      const value = {
        ...prev,
        workflows: {
          ...prev.workflows,
          ...workflowsForState,
        },
      };

      return {
        ...state,
        batchModification: state.batchModification.setValue(value),
      };
    } else if (state.batchWorkflow.value) {
      const prev = state.batchWorkflow.value;
      const value = {
        ...prev,
        workflows: {
          ...prev.workflows,
          ...workflowsForState,
        },
      };

      return {
        ...state,
        batchWorkflow: state.batchWorkflow.setValue(value),
      };
    }
    return state;
  });
}
