import { createStyles, withStyles, Chip, Divider, Paper, Theme, WithStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { WithT } from 'i18next';
import React from 'react';
import { ReadableBatchStatus } from '../../../domain/batch-dashboard/dto/batch-dashboard-data';
import { FilterItem } from './filter';

const style = (theme: Theme) => createStyles({
  deleteIcon: {
    marginRight: 0,
  },
  icon: {
    marginLeft: 0,
  },
  paperClass: {
    '&:hover, &:focus-within': {
      height: 'auto',
    },
    boxShadow: 'none',
    height: '33px',
    overflow: 'hidden',
  },
  root: {
    fontSize: '0.7em',
    height: theme.spacing(3),
  },
});

interface Filters {
  fields?: FilterItem[];
}

interface FilterBarProps {
  filters: Filters;
  handleFilterDialogClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, filter: FilterItem | null) => void;
  handleFilterDeleteClick: (index: number) => void;
}

interface LocalState {
  filters?: FilterItem[];
}

type ControllerProps = FilterBarProps & WithT & WithStyles;

class FilterBarController extends React.PureComponent<ControllerProps, LocalState> {
  state: Readonly<LocalState>;

  constructor(props: ControllerProps) {
    super(props);

    this.state = {
      filters: props.filters.fields,
    };
  }

  componentDidUpdate() {
    this.setState({ filters: this.props.filters.fields });
  }

  render() {
    return (
      <Paper className={this.props.classes.paperClass}>
        <Divider variant="fullWidth" />
        {this.props.filters.fields
          ? this.props.filters.fields.map((filter, i) => {
            return (
              <FilterComponent
                key={i}
                index={i}
                handleFilterClick={this.props.handleFilterDialogClick}
                handleFilterDelete={this.props.handleFilterDeleteClick}
                filter={filter}
                classes={{
                  deleteIcon: this.props.classes.deleteIcon,
                  icon: this.props.classes.icon,
                  root: this.props.classes.root,
                }}
                t={this.props.t}
              />
            );
          })
          : null}
        <AddFilterComponent
          classes={{
            deleteIcon: this.props.classes.deleteIcon,
            icon: this.props.classes.icon,
            root: this.props.classes.root,
          }}
          t={this.props.t}
          handleFilterClick={this.props.handleFilterDialogClick}
        />
      </Paper>
    );
  }
}

interface AddFilterProps {
  handleFilterClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, filter: FilterItem | null) => void;
}

interface FilterProps extends AddFilterProps {
  filter: FilterItem;
  index: number;
  handleFilterDelete: (index: number) => void;
}

const FilterComponent: React.FunctionComponent<FilterProps & WithT & WithStyles> = ({
  filter,
  handleFilterClick,
  handleFilterDelete,
  classes,
  index,
}) => {
  function handleDelete() {
    handleFilterDelete(index);
  }
  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    handleFilterClick(event, filter);
  }
  const { field, operation, value } = filter;
  let label = '';
  if (field && field.fieldId === 'lockedBy') {
    label = 'Locked by me';
  } else {
    let valueLabel;

    if (field) {
      switch (field.fieldId) {
        case 'status':
          valueLabel = ReadableBatchStatus[value.value];
          break;
        case 'category':
          valueLabel = value.label;
          break;
        default:
          valueLabel = value.value;
      }
    }
    label = (field ? ((field.fieldSetId ? (field.fieldSetId + '.') : '') + field.label) : null)
      + ' ' + (operation ? operation.label : null)
      + ' ' + valueLabel;
  }
  return (
    <Chip
      classes={classes}
      style={{ margin: 4 }}
      label={label}
      onDelete={handleDelete}
      onClick={handleClick}
    />
  );
};

const AddFilterComponent: React.FunctionComponent<AddFilterProps & WithT & WithStyles> = ({
  handleFilterClick,
  t,
  classes,
}) => {
  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    handleFilterClick(event, null);
  }
  return (
    <Chip
      classes={classes}
      icon={<AddIcon />}
      variant="outlined"
      style={{ margin: 4 }}
      label={t('batchWorkflowDashboard.batchFilter.add')}
      onClick={handleClick}
    />
  );
};

export const FilterBarComponent = withStyles(style)(FilterBarController);
