import { split, ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable, Operation } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import authActions from '../actions/auth.actions';
import { KeyCacheSync } from './cache';
import { KeyStore } from './store';

type Client = ApolloClient<{}>;

class MemoryStore implements KeyStore<Client> {
  private readonly store = new Map<string, Client>();

  getItem(key: string): Client | undefined {
    return this.store.get(key);
  }

  setItem(key: string, value: Client | undefined): void {
    if (value !== undefined) {
      this.store.set(key, value);
    } else {
      this.store.delete(key);
    }
  }

  clear(): void {
    this.store.clear();
  }

  forEach(operation: (key: string, value: Client) => void): void {
    this.store.forEach((value, key) => operation(key, value));
  }
}

function clientLoader(uri: string): Client {
  const cache = new InMemoryCache({
    possibleTypes: {
      BatchWorkflowUpdates: [
        'NewBatch',
        'BatchStatusUpdate',
        'BatchDataUpdate',
        'BatchLockSub',
        'BatchUnlockSub',
      ],
      FieldSets: [
        'PerBatchFieldSet',
        'PerDocumentHeaderFieldSet',
        'PerDocumentRepeatingFieldSet',
      ],
    },
  });

  const request = async (operation: Operation) => {
    const tokenSet = await authActions.tokenCache.getItem(true);

    // tslint:disable-next-line:no-any
    operation.setContext((context: Record<string, any>) => {
      if (!tokenSet) return context;

      // return the headers to the context so httpLink can read them
      return {
        ...context,
        headers: {
          ...context.headers,
          // get the authentication token from local storage if it exists
          authorization: tokenSet.idToken.raw ? `Bearer ${tokenSet.idToken.raw}` : '',
        },
      };
    });
  };

  const requestLink = new ApolloLink((operation, forward) =>
    new Observable((observer) => {
      if (!forward) return;
      let handle: ZenObservable.Subscription;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            complete: observer.complete.bind(observer),
            error: observer.error.bind(observer),
            next: observer.next.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
  );

  // Create an http link:
  const httpLink = new HttpLink({
    credentials: 'same-origin',
    uri,
  });

  const tokenSetSync = authActions.tokenCache.getItemSync(true);

  // Create a WebSocket link:
  const wsLink = uri.search('4001') !== -1 || uri.search('global') !== -1
    ? httpLink
    : new WebSocketLink({
      options: {
        connectionParams: {
          headers : {
            // get the authentication token from local storage if it exists
            authorization: (tokenSetSync && tokenSetSync.idToken.raw) ? `Bearer ${tokenSetSync.idToken.raw}` : '',
          },
        },
        reconnect: true,
      },
      uri: uri.replace('http', 'ws'),
    });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    cache,
    link: ApolloLink.from([
      requestLink,
      link,
    ]),
  });
}

class ClientCache extends KeyCacheSync<Client> {
  clear(): void {
    this.store.forEach((_, client) => {
      client.clearStore();
    });
    this.store.clear();
  }
}

export const clientCache = new ClientCache(
  new MemoryStore(),
  (client) => !!client,
  clientLoader
);
