import { withStyles, Paper, Tooltip, Typography, WithStyles } from '@material-ui/core';
import LowIcon from '@material-ui/icons/SentimentDissatisfied';
import MidIcon from '@material-ui/icons/SentimentSatisfied';
import HighIcon from '@material-ui/icons/SentimentVerySatisfied';
import { WithT } from 'i18next';
import React from 'react';
import { ConfidenceLevels } from '../../../store/interfaces/batch-preview';
import { style } from './style';

interface ConfidenceProps extends WithT, WithStyles {
  documentConfidence: ConfidenceLevels;
  className?: string;
}

class ConfidenceBarView extends React.Component<ConfidenceProps> {
  render() {
    const { documentConfidence, classes, className, t } = this.props;
    const { low, mid, high, total } = documentConfidence;
    const noConfidence = total - (low + mid + high);
    return total ? (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={<ConfidenceDetailTooltip documentConfidence={documentConfidence} t={t} />}
      >
        <span className={classes.confidenceGraph}>
          <Paper className={className} classes={{ root: classes.confidenceGraphPaper }}>
            {high ?
              <span style={{ width: ((high / total) * 100) + '%' }} className={classes.confidenceGraphHigh}>&nbsp;</span>
              : null}
            {mid ?
              <span style={{ width: ((mid / total) * 100) + '%' }} className={classes.confidenceGraphMid}>&nbsp;</span>
              : null}
            {low ?
              <span style={{ width: ((low / total) * 100) + '%' }} className={classes.confidenceGraphLow}>&nbsp;</span>
              : null}
            {noConfidence ?
              <span style={{ width: ((noConfidence / total) * 100) + '%' }} className={classes.confidenceGraphGrey}>&nbsp;</span>
              : null}
          </Paper>
        </span>
      </Tooltip>
    ) : null;
  }
}

const ConfidenceDetailTooltip = withStyles(style)(({
  documentConfidence,
  classes,
  t,
}: ConfidenceProps) => {
  const empty = documentConfidence.total - (documentConfidence.low + documentConfidence.mid + documentConfidence.high);
  return (
    <React.Fragment>
      <Typography variant="h6">
        {t('batchPreview.field.title')}
      </Typography>
      <Typography className={classes.confidenceHigh}>
        {t('batchPreview.field.confidenceHigh', { count: documentConfidence.high })}
      </Typography>
      <Typography className={classes.confidenceMid}>
        {t('batchPreview.field.confidenceMid', { count: documentConfidence.mid })}
      </Typography>
      <Typography className={classes.confidenceLow}>
        {t('batchPreview.field.confidenceLow', { count: documentConfidence.low })}
      </Typography>
      <Typography className={classes.confidenceTotal}>
        {t('batchPreview.field.empty', { count: empty })}
      </Typography>
    </React.Fragment>
  );
});

class ConfidenceTooltipView extends React.Component<ConfidenceProps> {
  render() {
    const { documentConfidence, classes, t } = this.props;
    const { low, mid, high, total } = documentConfidence;
    const noConfidence = total - (low + mid + high);
    const mostCount = Object.keys(documentConfidence).reduce(
      (acc, rank) => {
        if (rank === 'total') return acc;
        if (!documentConfidence[acc]) return rank;
        if (documentConfidence[rank] >= documentConfidence[acc]) return rank;
        return acc;
      },
      'low' as string);
    let className = '';
    if (documentConfidence[mostCount] > noConfidence) {
      className = 'confidence' + mostCount.charAt(0).toUpperCase() + mostCount.slice(1);
    }
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={<ConfidenceDetailTooltip documentConfidence={documentConfidence} t={t} />}
      >
        {mostCount === 'high' ? (
          <HighIcon fontSize="small" className={classes.documentInfoIcon + (className ? (' ' + classes[className]) : '')} />
        ) :
          mostCount === 'low' ? (
            <LowIcon fontSize="small" className={classes.documentInfoIcon + (className ? (' ' + classes[className]) : '')} />
          ) : (
              <MidIcon fontSize="small" className={classes.documentInfoIcon + (className ? (' ' + classes[className]) : '')} />
            )
        }
      </Tooltip>
    );
  }
}

export const ConfidenceBarComponent = withStyles(style)(ConfidenceBarView);
export const ConfidenceTooltipComponent = withStyles(style)(ConfidenceTooltipView);
