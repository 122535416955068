import Button from '@material-ui/core/Button';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { WithT } from 'i18next';
import React from 'react';

const styles: Record<string, CSSProperties> = {
  root: {
    textAlign: 'center',
  },
};

interface ExternalControllerProps {
  handlePageChanged: (page: number) => void;
}

interface Props {
  currentPage: number;
  pages: number;
}

type ControllerProps = ExternalControllerProps & Props & WithT;

class PagerController extends React.PureComponent<ControllerProps> {
  componentDidUpdate(props: ControllerProps) {
    if (props.currentPage > props.pages) {
      props.handlePageChanged(props.pages);
    }
  }

  setPage = (page: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.props.handlePageChanged(page);
  }

  render() {
    const { currentPage, pages, t } = this.props;
    return (
      <PagerView
        currentPage={currentPage}
        pages={pages}
        setPage={this.setPage}
        t={t}
      />
    );
  }
}

interface ExternalViewProps {
  setPage: (page: number) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type ViewProps = ExternalViewProps & Props & WithStyles<typeof styles> & WithT;

const PagerView = withStyles(styles)(({ classes, currentPage, pages, setPage, t }: ViewProps) => (
  <div className={classes.root}>
    <Button disabled={currentPage <= 1} onClick={setPage(1)}>{t('pager.first')}</Button>
    <Button disabled={currentPage <= 1} onClick={setPage(currentPage - 1)}>{t('pager.previous')}</Button>

    {currentPage - 3 > 0 ? <Button onClick={setPage(currentPage - 3)}>{currentPage - 3}</Button> : null}
    {currentPage - 2 > 0 ? <Button onClick={setPage(currentPage - 2)}>{currentPage - 2}</Button> : null}
    {currentPage - 1 > 0 ? <Button onClick={setPage(currentPage - 1)}>{currentPage - 1}</Button> : null}

    <Button disabled={true} onClick={setPage(currentPage - 1)}>{t('pager.xofy', { x: currentPage, y: pages })}</Button>

    {currentPage + 1 <= pages ? <Button onClick={setPage(currentPage + 1)}>{currentPage + 1}</Button> : null}
    {currentPage + 2 <= pages ? <Button onClick={setPage(currentPage + 2)}>{currentPage + 2}</Button> : null}
    {currentPage + 3 <= pages ? <Button onClick={setPage(currentPage + 3)}>{currentPage + 3}</Button> : null}

    <Button disabled={currentPage >= pages} onClick={setPage(currentPage + 1)}>{t('pager.next')}</Button>
    <Button disabled={currentPage >= pages} onClick={setPage(pages)}>{t('pager.last')}</Button>
  </div>
));

export const Pager = PagerController;
