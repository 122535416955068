import { ApolloClient, SubscriptionOptions } from '@apollo/client';
import gql from 'graphql-tag';
import env from '../../environment';
import { clientCache } from '../graphql';
import { BatchWorkflow } from './../../store/interfaces/batch-modification';
import { ModifyBatchWorkflowData } from './dto/batch-workflow-modify-data';
import { BatchWorkflowAndFields } from './dto/batch-workflows-and-fields';
import { BatchWorkflowsAndWorkflows, BatchWorkflowAndWorkflow, Field } from './dto/batch-workflows-and-workflows';

const GQL_LOAD_BATCH_WORKFLOW = gql`
query ($id: Int!) {
  batchWorkflow(id: $id) {
    id
    clientId
    name
    favoriteOrdinal
    isAutoReleaseEnabled
    fieldSets {
      __typename
      ...on PerBatchFieldSet {
        id
        label
        ordinal
        fields {
          id
          label
          ordinal
          dataType
          sources {
            id
            fieldId
            priority
            source
            field {
              id
              name
              workflowData {
                id
                name
              }
            }
          }
        }
      }
      ...on PerDocumentHeaderFieldSet {
        id
        label
        ordinal
        workflowData {
          id
          name
        }
        fields {
          id
          label
          ordinal
          dataType
          field {
            id
            name
          }
        }
      }
      ...on PerDocumentRepeatingFieldSet {
        id
        label
        ordinal
        workflowData {
          id
          name
          fieldSet {
            id
            label
            description
          }
        }
        fields {
          id
          label
          ordinal
          dataType
          field {
            id
            name
          }
        }
      }
    }
    categories {
      id
      name
    }
    userPermissions
  }
}`;

const GQL_LOAD_BATCH_WORKFLOWS = gql`{
  batchWorkflows {
    id
    clientId
    name
    favoriteOrdinal
    userPermissions
  }
}`;

const GQL_LOAD_BATCH_WORKFLOW_AND_WORKFLOW = gql`
query ($batchWorkflowIds: [Int!]!, $workflowIds: [Int!]!) {
  batchWorkflowByIds(ids: $batchWorkflowIds) {
    id
    name
    client {
      id
      name
    }
  }
  workflowsByIds(ids: $workflowIds){
    id
    name
    confidenceRule {
      confidenceTo
      confidenceFrom
      colour
      notes
    }
    client {
      id
      name
    }
    fields {
      __typename
      ... on Field {
        id
        confidenceRule {
          confidenceTo
          confidenceFrom
          colour
          notes
        }
      }
      ... on FieldSet {
        fields {
          id
          confidenceRule {
            confidenceTo
            confidenceFrom
            colour
            notes
          }
        }
      }
    }
  }
}`;

const GQL_LOAD_BATCH_WORKFLOW_AND_FIELDS = gql`
query ($batchWorkflowId: Int!, $categoryId: Int) {
  batchWorkflow(id: $batchWorkflowId) {
    id
    name
    fieldSets(categoryId: $categoryId) {
      __typename
      	... on PerBatchFieldSet {
          id
          batchWorkflowId
          label
          ordinal
          fields {
            id
            batchWorkflowId
            label
            ordinal
            dashboardVisible
            dataType
            sources {
              id
              fieldId
              batchFieldId
              batchWorkflowId
              priority
            }
          }
        }
      ... on PerDocumentHeaderFieldSet {
          id
          batchWorkflowId
          label
          ordinal
          workflowId
          reference
          fields {
            id
            fieldId
            label
            ordinal
            dataType
          }
        }
      ... on PerDocumentRepeatingFieldSet {
          id
          batchWorkflowId
          label
          ordinal
          workflowId
          reference
          fields {
            id
            fieldId
            label
            ordinal
            dataType
          }
        }
    }
    client {
      id
    }
    categories {
      id
      name
    }
  }
}`;

const GQL_FAVORITE_BATCH_WORKFLOW = gql`
mutation Favorite($id: String!) {
  batchWorkflowFavorite(id: $id) {
    ordinal
  }
}`;

const GQL_UNFAVORITE_BATCH_WORKFLOW = gql`
mutation Unfavorite($id: String!) {
  batchWorkflowUnfavorite(id: $id)
}`;

const GQL_MODIFY_BATCH_WORKFLOW = gql`
mutation ($data: ModifyBatchWorkflowData!) {
  modifyBatchWorkflow(data: $data)
}`;

export class BatchWorkflowRepository {
  public static forGlobal(): BatchWorkflowRepository {
    const url = env.urlFactory.global().service('data').resolve('graphql');
    const client = clientCache.getItem(url);
    if (!client) throw new Error('Unable to create GraphQL client');
    return new BatchWorkflowRepository(client);
  }

  constructor(
    private readonly client: ApolloClient<{}>,
  ) { }

  public async findById(id: number, useCache: boolean = true): Promise<BatchWorkflow | null> {
    const queryOptions: SubscriptionOptions = {
      query: GQL_LOAD_BATCH_WORKFLOW,
      variables: { id },
    };
    if (!useCache) {
      queryOptions.fetchPolicy = 'no-cache';
    }
    const { data } = await this.client.query<{ batchWorkflow: BatchWorkflow | null }>(queryOptions);
    return data.batchWorkflow;
  }

  public async findAll(): Promise<BatchWorkflow[]> {
    const { data } = await this.client.query<{ batchWorkflows: BatchWorkflow[] }>({
      query: GQL_LOAD_BATCH_WORKFLOWS,
    });
    return data.batchWorkflows || [];
  }

  public async favorite(batchWorkflowId: string): Promise<number | null> {
    const { data } = await this.client.mutate<{ favorite: { ordinal: number } }>({
      mutation: GQL_FAVORITE_BATCH_WORKFLOW,
      variables: { id: batchWorkflowId },
    });

    if (!data) {
      return null;
    }

    return data.favorite.ordinal;
  }

  public async unfavorite(batchWorkflowId: string): Promise<void> {
    await this.client.mutate<{ favorite: { ordinal: number } }>({
      mutation: GQL_UNFAVORITE_BATCH_WORKFLOW,
      variables: { id: batchWorkflowId },
    });
  }

  public async findBatchWorkflowsAndWorkflows(batchWorkflowIds: number[], workflowIds: number[]): Promise<BatchWorkflowsAndWorkflows> {
    const { data } = await this.client.query<{
      batchWorkflowByIds: BatchWorkflowAndWorkflow[],
      workflowsByIds: BatchWorkflowAndWorkflow[],
    }>({
      query: GQL_LOAD_BATCH_WORKFLOW_AND_WORKFLOW,
      variables: { batchWorkflowIds, workflowIds },
    });

    // flatten the fields coming from updated backend format
    const workflows: BatchWorkflowAndWorkflow[] = [];
    data.workflowsByIds.forEach((workflow: BatchWorkflowAndWorkflow) => {
      const flattenFields: Field[] = [];
      workflow.fields?.forEach?.((fieldStructure: Field | { fields: Field[] }) => {
        if ('fields' in fieldStructure) {
          fieldStructure.fields.forEach((field: Field) => flattenFields.push(field));
        } else {
          flattenFields.push(fieldStructure);
        }
      });

      workflows.push({ ...workflow, fields: flattenFields });
    });

    return {
      batchWorkflows: data.batchWorkflowByIds,
      workflows,
    };
  }

  public async findBatchWorkflowAndFields(batchWorkflowId: number, categoryId?: number): Promise<BatchWorkflowAndFields> {
    const { data } = await this.client.query<{ batchWorkflow: BatchWorkflowAndFields }>({
      fetchPolicy: 'no-cache',
      query: GQL_LOAD_BATCH_WORKFLOW_AND_FIELDS,
      variables: { batchWorkflowId, categoryId: categoryId },
    });
    return {
      categories: data.batchWorkflow.categories,
      fieldSets: data.batchWorkflow.fieldSets,
      id: data.batchWorkflow.id,
      name: data.batchWorkflow.name,
    };
  }

  public async saveModifications(modifyData: ModifyBatchWorkflowData): Promise<number | undefined | null> {
    const result = await this.client.mutate<{ modifyBatchWorkflow: number }>({
      mutation: GQL_MODIFY_BATCH_WORKFLOW,
      variables: { data: modifyData },
    });
    return result.data?.modifyBatchWorkflow;
  }
}
