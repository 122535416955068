import TextField from '@material-ui/core/TextField';
import * as React from 'react';

interface ControllerProps {
  id: string;
  className: string;
  label: string;
  textValue: string;
  textValueError: boolean;
  textValueErrorMessage?: string;
  onChange: (textValue: string) => void;
}

/*
export interface LocalState {
  textValue: string;
}

class InputTextFieldController extends React.PureComponent<ControllerProps, LocalState> {
  constructor(props: ControllerProps) {
    super(props);

    this.state = { textValue: props.textValue };
  }

  handleFieldFocusIn = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ textValue: this.props.textValue });
  }

  handleFieldFocusOut = (event: React.FocusEvent<HTMLInputElement>) => {
    this.props.onChange(this.state.textValue);
  }

  handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.setState({ textValue: event.target.value });
  }

  public render() {
    const { id, className, label, textValueError, textValueErrorMessage } = this.props;

    return (
      <TextField
        id={id}
        className={className}
        label={label}
        value={this.state.textValue}
        required={true}
        onFocus={this.handleFieldFocusIn}
        onBlur={this.handleFieldFocusOut}
        onChange={this.handleFieldChange}
        margin="normal"
        variant="outlined"
        error={textValueError}
        helperText={textValueErrorMessage}
      />
    );
  }
}
*/
class InputTextFieldController extends React.PureComponent<ControllerProps> {
  handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.props.onChange(event.target.value);
  }

  public render() {
    const { id, className, label, textValueError, textValueErrorMessage } = this.props;

    return (
      <TextField
        id={id}
        className={className}
        label={label}
        value={this.props.textValue}
        required={true}
        onChange={this.handleFieldChange}
        margin="normal"
        variant="outlined"
        error={textValueError}
        helperText={textValueErrorMessage}
      />
    );
  }
}

export const InputTextField = InputTextFieldController;
