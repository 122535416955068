import { KeyMap } from 'react-hotkeys';

export const batchHotKeys: KeyMap = {
  exit: {
    action: 'keypress',
    group: 'Batch',
    name: 'Exit',
    sequence: '',
    sequences: ['command+e', 'ctrl+e'],
  },
  save: {
    action: 'keypress',
    group: 'Batch',
    name: 'Save',
    sequence: '',
    sequences: ['command+s', 'ctrl+s'],
  },
};
