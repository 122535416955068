export interface User {
  id: number;
  name: string;
}

export enum UserWorkflowPermission {
  isAdmin       = 'isAdmin',
  canIndex      = 'canIndex',
  canQa         = 'canQa',
  canReject     = 'canReject',
  canRecycleBin = 'canRecycleBin',
  canDownload   = 'canDownload',
  canStats      = 'canStats',
  canHistory    = 'canHistory',
  canTrack      = 'canTrack',
  canAssign     = 'canAssign',
  canReassign   = 'canReassign',
  canReport     = 'canReport',
  canUpload     = 'canUpload',
  canTrain      = 'canTrain',
}
