import { isEqual } from 'lodash';
import { BatchDashboardRepository } from '../domain/batch-dashboard/batch-dashboard.repository';
import { BatchWorkflowUpdates } from '../domain/batch-workflow/dto/batch-workflow-updates-subscription';
import { BatchDomain } from '../domain/batch/batch.domain';
import { UserRepository } from '../domain/user/user.repository';
import { AuthStatus } from '../store/interfaces/auth';
import { BatchStatus } from '../store/interfaces/batch';
import { AppMutations } from '../store/mutations/app.mutations';
import { BatchModificationMutations } from '../store/mutations/batch-modification.mutations';
import { Action } from '../store/types';
import { BatchRepository } from './../domain/batch/batch.repository';
import { BatchActions } from './batch.actions';

export namespace BatchModificationActions {
  export function releaseBatch(batchId: string, batchWorkflowId: number, regionId: string | undefined): Action {
    return async (dispatch) => {
      if (!batchId || !batchWorkflowId || !regionId) return;
      dispatch(BatchModificationMutations.setLoading());
      await dispatch(BatchActions.releaseBatch(batchId, batchWorkflowId, regionId));
      await BatchDomain.loadModificationData(regionId, batchWorkflowId, batchId);
    };
  }

  export function save(): Action {
    return async (dispatch, getState) => {
      const state = getState();
      if (!state.batchModification.value || state.batchModification.loading) return;

      const data = state.batchModification.value;
      if (data.undoStack.length <= 1) {
        return;
      }

      dispatch(BatchModificationMutations.setLoading());

      const original = data.undoStack[0];

      const saveData = {
        batchId: data.batch.id,
        documents: data.documentIds.map((documentId) => {
          const document = data.documents[documentId];
          const originalDocument = document.id ? original.documents[document.id] : undefined;

          const pageOrderChanged = originalDocument ? !isEqual(originalDocument.pageIds, document.pageIds) : false;

          const workflowChanged = originalDocument ? originalDocument.workflowId !== document.workflowId : false;

          const hasDeletedPages = originalDocument ? (originalDocument?.pageIds?.length > document?.pageIds?.length) : false;

          const forceExtraction = pageOrderChanged || workflowChanged || !document.id || hasDeletedPages;

          return {
            documentId: document.id,
            forceExtraction,
            pages: document.pageIds.map((pageId) => ({ pageId })),
            regionId: document.regionId,
            workflowId: document.workflowId,
          };
        }),
        orphanedPages: data.orphanedPages,
      };

      const repository = BatchRepository.forRegion(data.batch.regionId);
      await repository.saveModifications(saveData).catch((err) => {
        dispatch(AppMutations.setSnackbarMessage({
          autoHideDuration: 7000,
          messageTranslationKey: 'errors.batch-saving-failed',
          rawMessage: err.message,
          variant: 'error',
        }));
      });
      await BatchDomain.loadModificationData(data.batch.regionId, data.batch.batchWorkflowId, data.batch.id);
      dispatch(BatchModificationMutations.clearUndo());
    };
  }

  export function subscribe(regionId: string, batchWorkflowId: number): Action {
    return async (dispatch, getState) => {
      const state = getState();
      const auth = state.auth;
      const batchModificationQuery = state.batchModification.query;
      const batchDashboardRepository = BatchDashboardRepository.forRegion(regionId);
      const batchWorkflowSubClient = await batchDashboardRepository.subscribeToBatchWorkflow(batchWorkflowId);
      const subscriptionClient = batchWorkflowSubClient.subscribe({
        // tslint:disable-next-line:no-any
        async next(data: any) {
          const batchWorkflowUpdates: BatchWorkflowUpdates = data.data.batchWorkflowUpdates;
          if (!batchWorkflowUpdates || !batchModificationQuery || !(state.auth.status === AuthStatus.AUTHENTICATED)) return;
          if (batchWorkflowUpdates.batchId === batchModificationQuery.batchId) {
            if (batchWorkflowUpdates.__typename === 'BatchStatusUpdate') {
              if (batchWorkflowUpdates.status === BatchStatus.DELETED || batchWorkflowUpdates.status === BatchStatus.HOLD) {
                let name: string = '';
                if (auth.status === AuthStatus.AUTHENTICATED && (auth.tokenSet.idToken.payload.sub as number) === batchWorkflowUpdates.userId) {
                  name = 'terms.you';
                } else {
                  const userRepository = UserRepository.forGlobal();
                  const user = await userRepository.findById(batchWorkflowUpdates.userId);
                  name = user.name;
                }
                dispatch(BatchModificationMutations.setSubUpdateInfo({
                  by: name,
                  type: batchWorkflowUpdates.status as 'deleted' | 'onhold',
                }));
              }
            } else if (batchWorkflowUpdates.__typename === 'BatchUnlockSub') {
              let name: string = '';
              if (
                auth.status === AuthStatus.AUTHENTICATED &&
                (auth.tokenSet.idToken.payload.sub as number) === batchWorkflowUpdates.unlockedByUserId
              ) {
                name = 'terms.you';
              } else {
                const userRepository = UserRepository.forGlobal();
                const user = await userRepository.findById(batchWorkflowUpdates.unlockedByUserId);
                name = user.name;
              }
              dispatch(BatchModificationMutations.setSubUpdateInfo({
                by: name,
                type: 'unlock',
              }));
            }
          }
        },
      });
      dispatch(BatchModificationMutations.setSubscriber(subscriptionClient));
    };
  }
}
