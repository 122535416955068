import { createStyles, Theme } from '@material-ui/core/styles';

export const style = (theme: Theme) => createStyles({
  documentCard: {
    width: '100%',
  },
  documentCardActive: {
    background: theme.palette.secondary.light,
    boxShadow: '0 0 1px 2px ' + theme.palette.secondary.light,
  },
  documentCardContainer: {
    padding: '8px 8px 0 8px',
    width: '200px',
  },
  documentCardList: {
    height: `calc(100% - 64px)`,
    overflow: 'scroll',
  },
  documentPage: {
    width: '60px',
  },
  documentStatus: {
    width: '75px',
  },
  documentThumbnail: {
    height: '150px',
  },
  documentThumbnailPlaceholder: {
    fontSize: 50,
    opacity: 0.2,
  },
  grow: {
    flexGrow: 1,
  },
  legacyElsIframe: {
    border: 'none',
    height: '100%',
    width: '100%',
  },
});
