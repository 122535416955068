// tslint:disable:no-console
import axios from 'axios';
import { decodeTokenSet } from '../domain/auth/token';
import { LocalStorageStoreFactory, StoreFactory } from '../domain/auth/token-store';
import { Cache, Callback, Validator, Validity } from '../domain/cache';
import { clientCache } from '../domain/graphql';
import env from '../environment';
import { CombinedUrlFactory } from '../environment/url';
import store from '../store';
import { AuthenticationState, AuthStatus } from '../store/interfaces/auth';
import { Token, TokenSet } from '../store/interfaces/token';
import { AuthMutations } from '../store/mutations/auth.mutations';
import { Action } from '../store/types';

const HTTP_CREATED = 201;

export interface AuthProps {
  auth: AuthenticationState;
}

export class AuthActions {
  public readonly tokenCache: Cache<TokenSet>;

  constructor(
    storeFactory: StoreFactory,
    private readonly urlFactory: CombinedUrlFactory,
  ) {
    this.tokenCache = new Cache(
      storeFactory.createStore('auth_tokens'),
      this.tokenSetValidator.bind(this) as Validator<TokenSet>,
      this.tokenLoader.bind(this),
      this.onTokenSetChanged.bind(this),
    );
    this.tokenCache.init();
  }

  public forgotPassword = (email: string): Action => {
    return async (dispatch, getState) => {
      // TODO request email
    };
  }

  public signIn = (email: string, password: string): Action => {
    return async (dispatch) => {
      dispatch(AuthMutations.notAuthenticated(true));
      try {
        clientCache.clear();
        const response = await axios.request({
          auth: {
            password,
            username: email,
          },
          method: 'POST',
          url: this.urlFactory.global().service('auth').resolve('user/authenticate'),
          validateStatus: (status) => [200, 201, 401].indexOf(status) !== -1,
        });
        if (!response) {
          throw new Error(); // TODO better
        }
        // - success
        if (response.status === 201 && response.data.result === 'success') {
          // store tokens in cache
          this.tokenCache.setItem(decodeTokenSet(response.data));
          return;
        }
        // - totp challenge
        if (response.status === 200 && response.data.result === 'totp-challenge') {
          dispatch(AuthMutations.mfa('totp', response.data.sessionToken));
          return;
        }
        // - failure
        if (response.status === 401) {
          dispatch(AuthMutations.error('Username and password are incorrect', 'auth-failed'));
          return;
        }
        // - error
        dispatch(AuthMutations.error('An unexpected response has been received from the server', 'unexpected-response'));
      } catch (err) {
        dispatch(AuthMutations.error(err.message, 'error'));
      }
    };
  }

  public processTotp = (code: string): Action => {
    return async (dispatch, getState) => {
      const auth = getState().auth;
      if (auth.status !== AuthStatus.IN_PROGRESS_MFA) {
        this.reset(); // TODO error?
        return;
      }

      try {
        const response = await axios.request({
          data: {
            code,
          },
          headers: { 'Authorization': `Bearer ${auth.sessionToken}` },
          method: 'POST',
          url: this.urlFactory.global().service('auth').resolve('session/authenticate/totp'),
          validateStatus: (status) => [201, 400, 401].indexOf(status) !== -1,
        });

        switch (response.status) {
          case 201:
            // store tokens in cache
            this.tokenCache.setItem(decodeTokenSet(response.data));
            return;
          default:
            dispatch(AuthMutations.error('The code provided is invalid.', 'totp-failed'));
            return;
        }
      } catch (err) {
        console.error(err);
        dispatch(AuthMutations.error(err.message, 'error'));
      }
    };
  }

  public reset = (): Action => {
    return this.signOut();
  }

  public signOut = (): Action => {
    return async (dispatch) => {
      this.tokenCache.setItem(undefined);
      clientCache.clear();
      dispatch(AuthMutations.notAuthenticated());
    };
  }

  /**
   * Checks whether tokens are still valid to be used
   * @param token token to validate
   * @param minutes time before the tokens actual expire to consider it invalid
   * @returns true if valid; otherwise false
   */
  public tokenValidator(token: Token, minutes: number): Validity {
    if (!token) return Validity.Invalid;
    if (token.expires >= Date.now() + (1000 * 60 * minutes)) return Validity.Valid;
    if (token.expires >= Date.now()) return Validity.Expiring;
    return Validity.Invalid;
  }

  /**
   * Checks whether tokens are still valid to be used
   * @param tokens token set to validate
   * @returns true if valid; otherwise false
   */
  private tokenSetValidator(tokens: TokenSet): Validity {
    return tokens ? this.tokenValidator(tokens.idToken, 5) : Validity.Invalid;
  }

  /**
   * Loads new tokens using previous set of tokens
   * @param current current tokens that can be used to request new tokens, if set
   * @param done callback when done
   */
  private tokenLoader(current: TokenSet | undefined, done: Callback<TokenSet>): void {
    if (!current) {
      done(undefined, undefined);
      return;
    }
    store.dispatch(AuthMutations.authenticated(current, true));

    (async () => {
      try {
        const refreshUrl = this.urlFactory.global().service('auth').resolve('session/refresh');

        // refresh tokens
        const response = await axios.request({
          headers: {
            Authorization: `Bearer ${current.refreshToken.raw}`,
          },
          method: 'POST',
          url: refreshUrl,
          validateStatus: (status) => [201].indexOf(status) !== -1,
        });
        if (response.status !== HTTP_CREATED) {
          throw response;
        }
        const tokens = decodeTokenSet(response.data);
        done(undefined, tokens);
      } catch (err) {
        store.dispatch(AuthMutations.error(err.message, 'error', current));
        done(err);
      }
    })();
  }

  /**
   * Called when token set is changed
   * @param tokens updated tokens
   */
  private onTokenSetChanged(tokens: TokenSet | undefined) {
    if (tokens) {
      if (tokens.refreshToken && tokens.refreshToken.expires < Date.now()) {
        // tslint:disable-next-line:no-any
        store.dispatch(this.signOut() as any);
        return;
      }
      store.dispatch(AuthMutations.authenticated(tokens));
    } else {
      store.dispatch(AuthMutations.notAuthenticated());
    }
  }
}

// TODO get the below config from somewhere better
export default new AuthActions(
  new LocalStorageStoreFactory(),
  env.urlFactory,
);
