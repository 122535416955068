import { withStyles, Button, Grid, Tooltip, WithStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WithT } from 'i18next';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PerBatchFieldSource } from '../../../domain/batch-workflow/dto/batch-workflows-and-fields';
import { BatchField } from '../../../store/interfaces/batch-workflow';
import { BatchFieldSourceScene } from './batch-field-source';
import { RemovableBatchComponent } from './batch-workflow-admin';
import { BatchWorkflowValidation } from './batch-workflow.validation';
import { style } from './style';

const AddFieldSourceButton: React.FunctionComponent<{ addBatchFieldSource: () => void } & WithStyles & WithT> = ({
  addBatchFieldSource,
  classes,
  t,
}) => {
  return (
    <Grid item={true} xs={12}>
      <Tooltip title={t('batchWorkflowEdit.tooltip.addBatchWorkflowFieldSource')!}>
        <Button variant="outlined" className={classes.addGreenButton} onClick={addBatchFieldSource}>
          <AddCircleIcon />&nbsp;
          {t('batchWorkflowEdit.actions.addBatchFieldSource')}
        </Button>
      </Tooltip>
    </Grid>
  );
};

interface ControllerProps {
  batchFieldSetIndex: number;
  batchFieldIndex: number;
  batchField: BatchField;
  batchFieldSources?: PerBatchFieldSource[];
  validationData?: BatchWorkflowValidation;
  handleBatchFieldSourcesChange: (perBatchFieldSources: PerBatchFieldSource[], sourcesOpen?: boolean, notImportant?: boolean) => void;
  onDeleteBatchComponent: (removableBatchComponent: RemovableBatchComponent) => void;
}

export class BatchFieldSourceListController extends React.PureComponent<ControllerProps & WithStyles & WithTranslation> {
  addBatchFieldSource = () => {
    let batchFieldSources: PerBatchFieldSource[] = [];
    if (this.props.batchFieldSources !== undefined) {
      batchFieldSources = [...this.props.batchFieldSources];
    }
    const newBatchFieldSource: PerBatchFieldSource = { id: 0, priority: 0, source: 'DOCUMENT' };
    batchFieldSources.push(newBatchFieldSource);
    this.props.handleBatchFieldSourcesChange(batchFieldSources, true);
  }

  toggleViewBatchFieldSources = () => {
    if (!this.props.batchFieldSources) return;
    const batchFieldSources = [...this.props.batchFieldSources];
    this.props.handleBatchFieldSourcesChange(batchFieldSources, !this.props.batchField.sourcesOpen, true);
  }

  handleBatchFieldSourceChange = (batchFieldSource: PerBatchFieldSource, index: number) => {
    if (!this.props.batchFieldSources || !this.props.batchFieldSources[index]) return;
    const batchFieldSources = [...this.props.batchFieldSources];
    batchFieldSources[index] = batchFieldSource;
    this.props.handleBatchFieldSourcesChange(batchFieldSources);
  }

  handleDeleteBatchFieldSource = (batchFieldSourceIndex: number) => {
    const removableBatchComponent: RemovableBatchComponent = {
      batchFieldIndex: this.props.batchFieldIndex,
      batchFieldSetIndex: this.props.batchFieldSetIndex,
      batchFieldSourceIndex: batchFieldSourceIndex,
      type: 'batchFieldSource',
    };
    this.props.onDeleteBatchComponent(removableBatchComponent);
  }

  render() {
    const { batchFieldSetIndex, batchFieldIndex, batchField, validationData } = this.props;
    const batchFieldSources: PerBatchFieldSource[] = this.props.batchFieldSources || [];

    return (
      <BatchFieldSourceListView
        batchFieldSetIndex={batchFieldSetIndex}
        batchFieldIndex={batchFieldIndex}
        batchFieldSources={batchFieldSources}
        validationData={validationData}
        viewBatchFieldSources={Boolean(batchField.sourcesOpen)}
        toggleViewBatchFieldSources={this.toggleViewBatchFieldSources}
        addBatchFieldSource={this.addBatchFieldSource}
        handleBatchFieldSourceChange={this.handleBatchFieldSourceChange}
        handleDeleteBatchFieldSource={this.handleDeleteBatchFieldSource}
      />
    );
  }
}

interface ViewProps {
  batchFieldSetIndex: number;
  batchFieldIndex: number;
  batchFieldSources: PerBatchFieldSource[];
  validationData?: BatchWorkflowValidation;
  viewBatchFieldSources: boolean;
  toggleViewBatchFieldSources: () => void;
  addBatchFieldSource: () => void;
  handleBatchFieldSourceChange: (batchFieldSource: PerBatchFieldSource, index: number) => void;
  handleDeleteBatchFieldSource: (batchFieldSourceIndex: number) => void;
}

type AllViewProps = ViewProps & WithStyles & WithTranslation;

const BatchFieldSourceListView = withStyles(style)(withTranslation()(({
  batchFieldSetIndex,
  batchFieldIndex,
  batchFieldSources,
  validationData,
  viewBatchFieldSources,
  toggleViewBatchFieldSources,
  addBatchFieldSource,
  handleBatchFieldSourceChange,
  handleDeleteBatchFieldSource,
  classes,
  t,
}: AllViewProps) => {

  return (
    <Grid className={classes.batchFieldSourceContainer}>
      {
        batchFieldSources.length > 0
          ? <div>
            <Button onClick={toggleViewBatchFieldSources} variant="outlined" className={classes.batchFieldSourceCollapsableButton}>
              {t('batchWorkflowEdit.general.batchFieldSource')}&nbsp;
              {
                viewBatchFieldSources
                  ? <ExpandLessIcon className={classes.batchFieldSourceCollapsableIcon} />
                  : <ExpandMoreIcon className={classes.batchFieldSourceCollapsableIcon} />
              }
            </Button>
          </div>
          : null
      }
      {
        viewBatchFieldSources &&
          batchFieldSources.length > 0
          ? <Droppable droppableId={`batchFieldSources-${batchFieldSetIndex}-${batchFieldIndex}`} type="batchFieldSource" direction="vertical">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {
                  batchFieldSources.map((batchFieldSource, index) =>
                    <BatchFieldSourceScene
                      key={index}
                      batchFieldSetIndex={batchFieldSetIndex}
                      batchFieldIndex={batchFieldIndex}
                      batchFieldSourceIndex={index}
                      batchFieldSource={batchFieldSource}
                      validationData={validationData}
                      handleBatchFieldSourceChange={handleBatchFieldSourceChange}
                      handleDeleteBatchFieldSource={handleDeleteBatchFieldSource}
                    />
                  )
                }
              </div>
            )}
          </Droppable>
          : undefined
      }
      {
        (
          viewBatchFieldSources &&
          (batchFieldSources.length > 0)
        )
          || (batchFieldSources.length === 0)
          ? <AddFieldSourceButton
            addBatchFieldSource={addBatchFieldSource}
            classes={classes}
            t={t}
          />
          : undefined
      }
    </Grid>
  );
}));

export const BatchFieldSourceList = withStyles(style)(withTranslation()(BatchFieldSourceListController));
