import { withStyles, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CircularProgressComponent } from '../../../components/circular-progress';
import { ErrorComponent } from '../../../components/error';
import { DocumentRepository } from '../../../domain/document/document.repository';
import { InputFileRepository } from '../../../domain/input-file/input-file.repository';
import { SetDocumentStatus } from '../../../store/interfaces/batch-learning';
import { CodedError } from '../../../store/interfaces/error';
import { SimpleLoadable } from '../../../store/loader';
import { style } from './style';

interface Props {
  documentId: string;
  regionId: string;
  type: 'Document' | 'InputFile';
  onGetDocumentStatus: (documentId: string, status: string) => void;
}

interface ViewProps {
  url: string;
}

export interface ActiveDocument {
  documentId: string;
  documentStatus?: string;
  type: 'Document' | 'InputFile';
  elsUrl: string;
}

interface State {
  active: SimpleLoadable<ActiveDocument>;
}

class LegacyElsIframeController extends React.PureComponent<Props & WithTranslation, State> {
  public state: State = { active: { state: 'loading' } };

  componentDidMount(): void {
    const { documentId, regionId, type, onGetDocumentStatus } = this.props;

    this.getUrlAndStatus(documentId, regionId, type, onGetDocumentStatus).catch((e) => {
      this.setState({ active: { state: 'error', error: new CodedError(e.message, e.code) } });
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { documentId, regionId, type, onGetDocumentStatus } = this.props;
    const { documentId: previousDocumentId, regionId: previousRegionId } = prevProps;

    if (documentId !== previousDocumentId || regionId !== previousRegionId) {
      this.getUrlAndStatus(documentId, regionId, type, onGetDocumentStatus).catch((e) => {
        this.setState({ active: { state: 'error', error: new CodedError(e.message, e.code) } });
      });
    }
  }

  render(): React.ReactNode {
    const activeState = this.state.active;
    if (activeState.state === 'loading') {
      return <CircularProgressComponent />;
    }
    if (activeState.state === 'error') {
      return <ErrorComponent error={activeState.error} />;
    }

    const { t } = this.props;
    const unknownStatus = t('dataSourceStatus.unknown.userFriendlyName');
    const processingStatus = t('dataSourceStatus.pdf-splitting-ready.userFriendlyName'); // just one of processing statuses selected to get its name

    const statusString = 'dataSourceStatus.' + activeState.value.documentStatus + '.userFriendlyName';
    let documentStatus = t(statusString);
    if (documentStatus === statusString) { // could not get a translation
      documentStatus = unknownStatus;
    }
    if ((documentStatus === unknownStatus && activeState.value.type === 'Document') || (documentStatus === processingStatus)) {
      const noElsMessage = t('errors.cannot-open-els', { documentId: activeState.value.documentId, status: documentStatus });
      return <ErrorComponent error={new CodedError(noElsMessage, 'preformatted')} />;
    }

    return (
      <LegacyElsIframeView
        url={activeState.value.elsUrl}
      />
    );
  }

  private async getUrlAndStatus(documentId: string, regionId: string, type: 'Document' | 'InputFile', onGetDocumentStatus: SetDocumentStatus) {
    this.setState({ active: { state: 'loading' } });

    try {
      let repository: DocumentRepository | InputFileRepository;
      if (type === 'Document') {
        repository = DocumentRepository.forRegion(regionId);
      } else {
        repository = InputFileRepository.forRegion(regionId);
      }
      const url = await repository.getLegacyElsUrl(documentId);

      if (!url) {
        this.setState({
          active: {
            error: new CodedError(`Empty ELS URL received for document: ${documentId}`, 'empty-els-url'),
            state: 'error',
          },
        });
        return;
      }

      let documentStatus;
      if (type === 'Document') {
        documentStatus = await (repository as DocumentRepository).getDocumentStatus(documentId);
        if (documentStatus === null) {
          documentStatus = 'unknown';
        }
        onGetDocumentStatus(documentId, String(documentStatus));
      }

      this.setState({
        active: {
          state: 'value',
          value: {
            documentId,
            documentStatus,
            elsUrl: url,
            type,
          },
        },
      });
    } catch (e) {
      this.setState({
        active: {
          error: new CodedError(e.message, 'error'),
          state: 'error',
        },
      });
    }
  }
}

const LegacyElsIframeView = withStyles(style)(withTranslation()(({
  classes,
  url,
}: ViewProps & WithTranslation & WithStyles) => (
    <iframe
      title={'Legacy Engine Learning Screen'}
      className={classes.legacyElsIframe}
      src={url}
    />
  )));

export const LegacyElsIframe = withTranslation()(LegacyElsIframeController);
