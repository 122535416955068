import { DataList } from '../data-list';
import { Loadable, ValueFullyLoaded } from '../loader';
import { SnackbarMessage } from './app';
import { AuthenticationState, AuthStatus } from './auth';
import { Batch } from './batch';
import { BatchDashboardData, BatchDashboardQuery } from './batch-dashboard';
import { isFullyLoaded as isBatchLearningFullyLoaded, BatchLearning } from './batch-learning';
import { isFullyLoaded as isBatchModificationFullyLoaded, BatchModification } from './batch-modification';
import { BatchPreviewData, BatchPreviewQuery } from './batch-preview';
import { compareBatchWorkflows, BatchWorkflow, BatchWorkflowModification } from './batch-workflow';
import { compareRegions, Region } from './region';

export interface BatchQuery {
  batchId: string | undefined;
  batchWorkflowId: number | undefined;
  regionId: string | undefined;
}

export interface StateRoot {
  auth: AuthenticationState;
  batch: Loadable<BatchQuery, Batch>;
  batchDashboard: Loadable<BatchDashboardQuery, BatchDashboardData>;
  batchPreview: Loadable<BatchPreviewQuery, BatchPreviewData>;
  batchLearning: Loadable<BatchQuery, BatchLearning>;
  batchModification: Loadable<BatchQuery, BatchModification>;
  batchWorkflow: Loadable<number, BatchWorkflow>;
  batchWorkflowModification: Loadable<BatchWorkflowModification, BatchWorkflow>;
  batchWorkflows: DataList<number, BatchWorkflow>;
  currentRegionId?: string;
  regions: DataList<string, Region>;
  sideBarOpen: boolean;
  snackbarMessage: SnackbarMessage | undefined;
  subscriber: ZenObservable.Subscription | undefined;
}

export const initialState: StateRoot = {
  auth: { status: AuthStatus.UNKNOWN },
  batch: Loadable.initial<BatchQuery, Batch>((prev, q) => !prev || prev.id !== q.batchId),
  batchDashboard: Loadable.initial<BatchDashboardQuery, BatchDashboardData>((prev, q) => !prev || prev.batchWorkflow.id !== q.batchWorkflowId),
  batchLearning: Loadable.initial<BatchQuery, BatchLearning>(
    (prev, q) => !prev || prev.batch.id !== q.batchId, isBatchLearningFullyLoaded as ValueFullyLoaded<BatchLearning>),
  batchModification: Loadable.initial<BatchQuery, BatchModification>(
    (prev, q) => !prev || prev.batch.id !== q.batchId, isBatchModificationFullyLoaded as ValueFullyLoaded<BatchModification>),
  batchPreview: Loadable.initial<BatchPreviewQuery, BatchPreviewData>((prev, q) => !prev || prev.batchId !== q.batchId),
  batchWorkflow: Loadable.initial<number, BatchWorkflow>((prev, id) => !prev || prev.id !== id),
  batchWorkflowModification: Loadable.initial<BatchWorkflowModification, BatchWorkflow>((prev, q) => !prev || prev.id !== q.batchWorkflowId),
  batchWorkflows: DataList.empty(compareBatchWorkflows),
  regions: DataList.empty(compareRegions),
  sideBarOpen: false,
  snackbarMessage: undefined,
  subscriber: undefined,
};
