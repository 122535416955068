import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProps } from './actions/auth.actions';
import { RegionActions } from './actions/region.actions';
import './App.scss';
import { CircularProgressComponent } from './components/circular-progress';
import Sidebar from './components/sidebar';
import { drawerMiniWidth } from './components/sidebar/styles/copy';
import { SnackbarComponent } from './components/snackbar';
import menu, { Props as MenuProps } from './menu';
import { AuthScene } from './scenes/auth';
import { BatchWorkflowAdminAddScene } from './scenes/batch-workflow/admin/add';
import { BatchWorkflowAdminEditScene } from './scenes/batch-workflow/admin/edit';
import { BatchDashboardScene } from './scenes/batch-workflow/dashboard';
import { BatchWorkflowListScene } from './scenes/batch-workflow/list';
import { BatchLearningScene } from './scenes/batch/learning';
import { BatchModificationScene } from './scenes/batch/modification';
import { BatchPreviewScene } from './scenes/batch/preview';
import { NotFoundScene } from './scenes/not-found';
import { ProfileScene } from './scenes/profile';
import { SettingsScene } from './scenes/settings';
import { StateRoot } from './store/interfaces';
import { AuthStatus } from './store/interfaces/auth';
import { WithDispatch } from './store/types';
import xtractaLogo from './x-white.svg';

const style = (theme: Theme) => createStyles({
  root: {
    [theme.breakpoints.up('md')]: {
      marginLeft: `${drawerMiniWidth}px`,
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
    },
    display: 'flex',
    height: '100%',
  },
});

const App: React.FunctionComponent<MenuProps & AuthProps & WithDispatch & WithTranslation & WithStyles> = (props) => {
  const { auth, classes, dispatch, t } = props;
  const routes = menu(props);

  switch (auth.status) {
    case AuthStatus.UNKNOWN:
      return (
        <CircularProgressComponent/>
      );
    case AuthStatus.AUTHENTICATED:
      dispatch(RegionActions.ensureLoaded());
      return (
        <Router>
          <div className={'App ' + classes.root}>
            <Sidebar
              routes={routes}
              logoText={t('title')}
              logo={xtractaLogo}
              miniActive={true}
            />
            <Switch>
              <Route path="/profile" exact={true} component={ProfileScene} />
              <Route path="/" exact={true} component={BatchWorkflowListScene} />
              <Route path="/batch-workflow/add" exact={true} component={BatchWorkflowAdminAddScene} />
              <Route path="/batch-workflow/:batchWorkflowId" exact={true} component={BatchWorkflowAdminEditScene} />
              <Route path="/batch-workflow/:batchWorkflowId/dashboard" exact={true} component={BatchDashboardScene} />
              <Route path="/batch-workflow/:batchWorkflowId/batch/:batchId/preview" exact={true} component={BatchPreviewScene} />
              <Route path="/batch-workflow/:batchWorkflowId/batch/:batchId/modify" exact={true} component={BatchModificationScene} />
              <Route path="/batch-workflow/:batchWorkflowId/batch/:batchId/learning" exact={true} component={BatchLearningScene} />
              <Route
                path="/batch-workflow/:batchWorkflowId/batch/:batchId/document/:documentId/learning"
                exact={true}
                component={BatchLearningScene}
              />
              <Route path="/settings/general" exact={true} component={SettingsScene} />
              <Route component={NotFoundScene} />
            </Switch>
            <SnackbarComponent />
          </div>
        </Router>
      );
    default:
      return (
        <AuthScene />
      );
  }
};

const mapStateToProps = (state: StateRoot): MenuProps & AuthProps => ({
  auth: state.auth,
  batchWorkflowId: state.batchWorkflow.query,
});

export default connect(mapStateToProps, undefined)(withStyles(style)(withTranslation('menu')(App)));
