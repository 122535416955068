import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import CSSTransition from 'react-transition-group/CSSTransition';
import { PasswordField } from '../../../../components/password-field';
import { submit, SubmitButton } from '../../../../helpers/form';
import { NotAuthenticatedState } from '../../../../store/interfaces/auth';
import { CodedError } from '../../../../store/interfaces/error';
import AuthForgotPassword from './components/forgot-password';

import logo from '../../images/logo.svg';

export interface Props {
  auth: NotAuthenticatedState;
  handleSubmit: (email: string, password: string) => void;
}

export interface State {
  forgotPassword: boolean;
  email: string;
  password: string;
}

class AuthSignIn extends React.PureComponent<Props, State> {
  public onInputChanged = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // tslint:disable-next-line:no-any
    const change: any = { [prop]: event.target.value };
    this.setState(change);
  }

  public handleSubmit = async () => {
    this.props.handleSubmit(this.state.email, this.state.password);
  }

  public showForgotPassword = async () => {
    this.setState({ forgotPassword: true });
  }

  public showSignIn = async () => {
    this.setState({ forgotPassword: false });
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      forgotPassword: false,
      password: '',
    };
  }

  public render() {
    if (this.state.forgotPassword) {
      return (
        <AuthForgotPassword
          handleBack={this.showSignIn}
        />
      );
    }
    return (
      <AuthSignInView
        {...this.props}
        error={this.props.auth.error}
        loading={this.props.auth.loading}
        onInputChanged={this.onInputChanged}
        handleForgot={this.showForgotPassword}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

interface ViewProps {
  error?: CodedError;
  loading: boolean;
  onInputChanged: (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => Promise<void>;
  handleForgot: () => Promise<void>;
}

const AuthSignInView = withTranslation(['auth'])(
  ({
    t,
    loading,
    error,
    onInputChanged,
    handleSubmit,
    handleForgot,
  }: ViewProps & WithTranslation) => (
    <Paper className="card">
      <form onSubmit={submit(handleSubmit)}>
        <Grid item={true} container={true} direction="column" alignItems="stretch" spacing={2}>
          <Grid className="logo" container={true} alignItems="stretch" justify="center">
            {window.env.REACT_APP_HIDE_BRAND ? <Typography variant={'h3'}>Welcome</Typography> : <img alt={'Xtracta Logo'} src={logo} />}
          </Grid>
          <Grid item={true} container={true} alignItems="stretch">
            <TextField
              id="email-input"
              label={t('signIn.inputs.email.label')}
              type="email"
              name="email"
              autoComplete="email"
              disabled={loading}
              error={error && error.code === 'auth-failed'}
              fullWidth={true}
              margin="normal"
              onChange={onInputChanged('email')}
              variant="outlined"
            />
            <PasswordField
              id="password-input"
              label={t('signIn.inputs.password.label')}
              type="password"
              autoComplete="current-password"
              disabled={loading}
              error={error && error.code === 'auth-failed'}
              fullWidth={true}
              margin="normal"
              onChange={onInputChanged('password')}
              variant="outlined"
            />
          </Grid>
          <Grid item={true} container={true} justify="center">
            <CSSTransition
              in={!!error}
              timeout={300}
              classNames="message"
            >
              <div className="auth-failure">{error ? t(`errors.${error.code}`, { message: error.message }) : ''}</div>
            </CSSTransition>
          </Grid>
          <Grid item={true} container={true} justify="center">
            <Fab
              className="primary-button"
              variant="extended"
              color="secondary"
              component={SubmitButton}
              disabled={loading}
            >
              {t('signIn.buttons.submit')!}
            </Fab>
          </Grid>
          <Grid item={true} />
          <Grid item={true} />
          {/* <Grid item={true} container={true} justify="center">
          <span>{t('signIn.text.signUp')}&nbsp;</span>
          <Link>{t('signIn.buttons.signUp')}</Link>
        </Grid> */}
          <Grid item={true} container={true} justify="center">
            <Link onClick={handleForgot}>{t('signIn.buttons.forgotPassword')}</Link>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
);

export default AuthSignIn;
