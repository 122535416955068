import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Tooltip, { TooltipClassKey } from '@material-ui/core/Tooltip';
import React from 'react';

interface Props {
  allowTooltip?: boolean;
  tooltip: string;
  tooltipClass?: string;
}

interface TooltipProps {
  classes?: Partial<Record<TooltipClassKey, string>>;
  title: string;
}

class IconButtonWithTooltipController extends React.PureComponent<Props & IconButtonProps> {
  render() {
    const props = this.props;
    const { allowTooltip, tooltipClass, ...iconButtonProps } = props;

    const tooltipProps: TooltipProps = {
      classes: tooltipClass ? { tooltip: tooltipClass } : undefined,
      title: props.tooltip,
    };
    const iconButton = (
      <IconButton {...iconButtonProps}>
        {props.children}
      </IconButton>
    );
    if ((allowTooltip === undefined) ? !props.disabled : allowTooltip) {
      return (
        <Tooltip {...tooltipProps} disableFocusListener={true}>
          {iconButton}
        </Tooltip>
      );
    }
    return iconButton;
  }
}

export const IconButtonWithTooltip = IconButtonWithTooltipController;
