// tslint:disable:max-line-length

import SvgIcon from '@material-ui/core/SvgIcon';

export const AscendingIcon: React.FC = () => (
  <SvgIcon>
    <g>
      <path d="M7.19,2L10.986,11.687L9.43,11.687L8.653,9.616L4.333,9.616L3.555,11.687L2,11.687L5.804,2L7.19,2ZM8.129,8.23L6.489,3.851L4.849,8.23L8.129,8.23Z" />
      <path d="M4.923,20.655L10.101,20.655L10.101,22L2.884,22L2.884,20.909L7.893,13.666L2.918,13.666L2.918,12.313L9.941,12.313L9.941,13.379L4.923,20.655L4.923,20.655Z" />
      <path d="M16.947,19.313L22,14.26L18.21,14.26L18.21,4.687L15.684,4.687L15.684,14.26L11.894,14.26L16.947,19.313Z" />
    </g>
  </SvgIcon>
);

export const DescendingIcon: React.FC = () => (
  <SvgIcon>
    <g>
      <path d="M7.19,12.313L10.986,22L9.43,22L8.653,19.929L4.333,19.929L3.555,22L2,22L5.804,12.313L7.19,12.313ZM8.129,18.543L6.489,14.164L4.849,18.543L8.129,18.543Z" />
      <path d="M4.923,10.342L10.101,10.342L10.101,11.688L2.884,11.688L2.884,10.596L7.893,3.354L2.918,3.354L2.918,2L9.941,2L9.941,3.066L4.923,10.342L4.923,10.342Z" />
      <path d="M16.947,19.313L22,14.26L18.21,14.26L18.21,4.687L15.684,4.687L15.684,14.26L11.894,14.26L16.947,19.313Z" />
    </g>
  </SvgIcon>
);
